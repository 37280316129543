import React from "react";

import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { Popper, TextField, TextFieldProps } from "@material-ui/core";

export interface GenderSelectorProps {
  gender: string;
  setGender: (gender: string) => void;
  label: string;
}

export const defaultGenders = ["Männlich", "Weiblich", "Divers"];

export default function GenderSelector(
  props: GenderSelectorProps &
    Omit<
      AutocompleteProps<string, undefined, undefined, undefined>,
      "options" | "renderInput"
    > &
    TextFieldProps
) {
  const { setGender, gender, label, ...rest } = props;

  return (
    <Autocomplete
      options={defaultGenders}
      inputValue={gender || ""}
      onInputChange={(e, value) => e && setGender(value || "")}
      renderInput={(params) => (
        <TextField
          {...rest}
          {...params}
          label={label}
          autoComplete="sex"
          required
        />
      )}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, height: 0 }} />
      )}
      autoHighlight
      autoSelect
      {...rest}
    />
  );
}
