import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowBackRounded, HelpRounded } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import ParticipantDuplicatesAll from "./ParticipantDuplicates/ParticipantDuplicatesAll";
import Body from "./Root/Body";
import { HeaderIconButton } from "./Root/HeaderBar";

export default function ParticipantDuplicates() {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<undefined | Element>(
    undefined
  );
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(undefined)}
        disableRestoreFocus
        style={{ pointerEvents: "none" }}
      >
        <Box margin={1}>
          <Typography>
            Um doppelte Kundenkonten zusammenzufügen wählen Sie bitte von jeder
            Gruppe das Kundenkonto aus,
          </Typography>
          <Typography>
            der übrig bleiben soll und klicken Sie danach auf "Zusammenfügen".
          </Typography>
        </Box>
      </Popover>
      <HeaderBarAdmin
        title="Doppelte Kundenkonten"
        leftAction={
          <Tooltip title="Zurück zur Kundenkontendatenbank">
            <HeaderIconButton
              onClick={() => history.push("/admin/participantdb/")}
            >
              <ArrowBackRounded />
            </HeaderIconButton>
          </Tooltip>
        }
      />
      <Body>
        <ParticipantDuplicatesAll />
        <Box position="fixed" right="1rem" bottom="1rem">
          <IconButton
            size="medium"
            onMouseOver={(e) => setAnchorEl(e.target as Element)}
            onMouseLeave={(e) => setAnchorEl(undefined)}
          >
            <HelpRounded color="primary" fontSize="inherit" />
          </IconButton>
        </Box>
      </Body>
    </>
  );
}
