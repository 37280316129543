import React from "react";

import {
  Button,
  TextField,
  FormControlLabel,
  Box,
  Switch,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import GenderSelector from "../Account/GenderSelector";
import NationalitySelector from "../Account/NationalitySelector";
import FormComponent from "../FormComponent";
import InputWrapper from "../InputWrapper";
import { isAdult } from "../../Functions/Age";
import { EditMember } from "../Account/PersonEditList";

export interface EditParticipantProps {
  participant?: EditMember;
  callback: (items: EditMember) => void;
  abort: () => void;
  disabled?: boolean;
  onClick: () => void;
  additional?: React.ReactNode;
}

export default function EditParticipant(props: EditParticipantProps) {
  const callback = (items: Partial<EditMember>) => {
    if (props.participant) props.callback({ ...props.participant, ...items });
  };

  return (
    <FormComponent>
      {props.additional}
      <InputWrapper>
        <TextField
          label="Vorname"
          value={props.participant?.name || ""}
          onChange={(event) => callback({ name: event.target.value })}
          autoComplete="given-name"
          required
          fullWidth
          margin="normal"
          autoFocus
        />
        <TextField
          label="Nachname"
          value={props.participant?.surname || ""}
          onChange={(event) => callback({ surname: event.target.value })}
          autoComplete="family-name"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <GenderSelector
          gender={props.participant?.gender || ""}
          setGender={(gender) => callback({ gender })}
          label="Geschlecht"
          fullWidth
          margin="normal"
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd.MM.yyyy"
          label="Geburtstag"
          value={props.participant?.birthday || null}
          onChange={(birthday) =>
            callback({
              birthday,
            })
          }
          helperText={undefined}
          autoComplete="bday"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Straße"
          value={props.participant?.street || ""}
          onChange={(event) => callback({ street: event.target.value })}
          autoComplete="street-address"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Postleitzahl"
          value={
            props.participant && isNaN(props.participant.zip)
              ? ""
              : props.participant?.zip.toString() || ""
          }
          onChange={(event) =>
            callback({
              zip: parseInt(event.target.value.replace(/[^0-9]/g, "")),
            })
          }
          autoComplete="postal-code"
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Stadt"
          value={props.participant?.city || ""}
          onChange={(event) => callback({ city: event.target.value })}
          autoComplete="address-level2"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Telefonnummer"
          value={props.participant?.phone || ""}
          onChange={(event) => callback({ phone: event.target.value })}
          autoComplete="tel"
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <NationalitySelector
          nationality={props.participant?.nationality || null}
          setNationality={(nationality) =>
            callback({ nationality: nationality || null })
          }
          fullWidth
          margin="normal"
        />
        <TextField
          label="Schule"
          value={props.participant?.school || ""}
          onChange={(event) => callback({ school: event.target.value })}
          autoComplete="organization"
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Allergien"
          value={props.participant?.allergies || ""}
          onChange={(event) => callback({ allergies: event.target.value })}
          multiline
          fullWidth
          margin="normal"
        />
        <TextField
          label="Medikamente"
          value={props.participant?.medication || ""}
          onChange={(event) => callback({ medication: event.target.value })}
          multiline
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Weitere Anmerkungen"
          value={props.participant?.other || ""}
          onChange={(event) => callback({ other: event.target.value })}
          multiline
          rows={6}
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <FormControlLabel
          control={
            <Switch
              checked={props.participant?.swim || false}
              onChange={(event) => callback({ swim: event.target.checked })}
              color="primary"
            />
          }
          label="Kann schwimmen"
        />
        <FormControlLabel
          control={
            <Switch
              checked={props.participant?.goHomeAlone || false}
              onChange={(event) =>
                callback({ goHomeAlone: event.target.checked })
              }
              color="primary"
            />
          }
          label="Kann alleine nach Hause gehen"
        />
      </InputWrapper>
      <Box display="flex" justifyContent="space-between" m={1}>
        <Button onClick={props.abort}>Abbrechen</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={
            props.disabled ||
            !props.participant?.name ||
            !props.participant?.surname ||
            !props.participant?.gender ||
            !props.participant?.birthday ||
            isNaN(props.participant.birthday.getTime()) ||
            !props.participant?.street ||
            !props.participant?.city ||
            !props.participant?.zip ||
            !props.participant?.nationality
          }
          onClick={props.onClick}
          type="submit"
        >
          Speichern
        </Button>
      </Box>
    </FormComponent>
  );
}
