import React from "react";

import { Box, Paper, Divider } from "@material-ui/core";

import EditParticipantInfo from "./EditParticipantInfo";
import ParticipantHistory from "./ParticipantHistory";

export interface ParticipantInfoDetailsProps {
  id: string;
}

export default function ParticipantInfoDetails(
  props: ParticipantInfoDetailsProps
) {
  return (
    <Paper>
      <Box display="flex">
        <Box padding={2} width="100%">
          <EditParticipantInfo {...props} />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box paddingTop={2} width="100%" overflow="overlay" position="relative">
          <Box position="absolute" width="100%">
            <ParticipantHistory {...props} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
