export const isAdult = (birthday: Date | null): boolean => {
  // Check if over 18 years old
  return getAge(birthday) >= 18;
};

export const getAge = (
  birthday: Date | null,
  projectBeginn?: Date | null
): number => {
  if (birthday && !isNaN(birthday.getTime())) {
    // Check age based on birthday and project beginn
    const today = projectBeginn || new Date();
    const m = today.getMonth() - birthday.getMonth();

    let age = today.getFullYear() - birthday.getFullYear();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  } else return 0;
};
