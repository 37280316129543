import React from "react";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import Body from "./Root/Body";
import ProjectStats from "./Stats/ProjectStats";

export default function Stats() {
  return (
    <>
      <HeaderBarAdmin title="Statistiken" />
      <Body>
        <ProjectStats />
      </Body>
    </>
  );
}
