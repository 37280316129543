import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useServer } from "../Server/ServerContext";
import ParticipantDuplicatesList from "./ParticipantDuplicatesList";

export default function ParticipantDuplicatesAll() {
  const { peopleActions } = useServer();
  const [duplicates, setDuplicates] = React.useState<string[][]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const duplicates = await peopleActions.getDuplicates();
      setDuplicates(duplicates);
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  ) : duplicates.length === 0 ? (
    <Box display="flex" justifyContent="center">
      <Typography variant="h4">Keine doppelten Teilnehmer gefunden</Typography>
    </Box>
  ) : (
    <>
      {duplicates.map((duplicates, index) => (
        <ParticipantDuplicatesList
          duplicates={duplicates}
          key={index}
          callback={() =>
            setDuplicates((duplicates) => {
              duplicates.splice(index, 1);
              return duplicates.map((id) => id);
            })
          }
        />
      ))}
    </>
  );
}
