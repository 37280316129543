import React from "react";
import { useHistory } from "react-router-dom";

import {
  CustomerProjectOverviewObject,
  ProjectOverviewObject,
  ProjectState,
  useServer,
} from "../Server/ServerContext";
import {
  Button,
  Badge,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
  Box,
  Grow,
} from "@material-ui/core";

import { useFilter } from "./FilterSidebar";

import { TextHighlight } from "../Search/SearchProvider";
import {
  endDate,
  isAdvancedDatetime,
  millisUntilNextMeeting,
  nextMeetingDate,
  startDate,
} from "../../Functions/Datetime";

import config from "../../config";

export const sortProjects = (
  a: CustomerProjectOverviewObject | ProjectOverviewObject,
  b: CustomerProjectOverviewObject | ProjectOverviewObject
): number =>
  millisUntilNextMeeting(a.datetime) - millisUntilNextMeeting(b.datetime);

export default function ProjectPreview() {
  const styles = useStyles({ hovered: false, full: ProjectState.Free });
  const listedProjects = useServer().useListedProjects();

  const { filter } = useFilter();

  return (
    <Box display="flex" flexWrap="wrap" width="100%">
      {listedProjects
        .filter(filter)
        .sort(sortProjects)
        .map((project) => (
          <Preview project={project} key={project.id} />
        ))}
      {Array.from({ length: 10 }, (_, i) => i).map((i) => (
        <Box key={i} className={styles.root} />
      ))}
    </Box>
  );
}

interface StylesProps {
  hovered: boolean;
  full: ProjectState;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2),
      flex: "1 1 10cm",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: "100%",
    },
  },
  card: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  actionArea: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    "& > *": {
      width: "100%",
    },
  },
  overflow: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      overflow: "hidden",
    },
    "&::-webkit-scrollbar": {
      display: "none",
      scrollbarWidth: "none",
    },
  },
  media: (props: StylesProps) => ({
    height: "max(190px, 5cm)",
    maxHeight: props.hovered ? 0 : "max(190px, 5cm)",
    transition: theme.transitions.create("max-height"),
  }),
  cardAction: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: `calc(100% - 2 * ${theme.spacing(1)}px)`,
    padding: theme.spacing(1),
    background: `linear-gradient(to top, ${theme.palette.common.white} 80%, transparent)`,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  badge: {
    paddingTop: "100%",
    width: "100%",
  },
  badgeColor: (props: StylesProps) => ({
    backgroundColor: (() => {
      switch (props.full) {
        case ProjectState.Full:
          return theme.palette.error.main;
        case ProjectState.WaitingList:
          return theme.palette.warning.main;
        case ProjectState.Free:
          return theme.palette.success.main;
      }
    })(),
  }),
  date: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto !important",
    },
  },
}));

function Preview(props: { project: CustomerProjectOverviewObject }) {
  const { search } = useFilter();

  const [hovered, setHovered] = React.useState(false);

  const styles = useStyles({
    full: props.project.state,
    hovered,
  });

  const history = useHistory();

  const hoverIn = () => setHovered(true);
  const hoverOut = () => setHovered(false);

  const redirectProject = () => history.push(`/project/${props.project.id}`);

  const information = () => {
    const nextMeeting = nextMeetingDate(props.project.datetime);
    const start =
      startDate(props.project.datetime) || props.project.datetime.begin;
    const end = endDate(props.project.datetime) || props.project.datetime.end;

    return isAdvancedDatetime(props.project.datetime) && nextMeeting
      ? `Nächster Termin am ${nextMeeting.toLocaleString("de-DE", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}`
      : `${
          start
            ? new Date(start).toLocaleString("de-DE", {
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "##.##.####"
        } - ${
          end
            ? new Date(end).toLocaleString("de-DE", {
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "##.##.####"
        }`;
  };

  return (
    <Box m={2} className={styles.root}>
      <Badge
        color="primary"
        badgeContent={""}
        className={styles.badge}
        classes={{ badge: styles.badgeColor }}
        onMouseEnter={hoverIn}
        onMouseLeave={hoverOut}
      >
        <Card className={styles.card}>
          <CardActionArea
            className={styles.actionArea}
            onClick={redirectProject}
          >
            <Box className={styles.overflow}>
              {!!props.project.image && (
                <Grow in={!hovered}>
                  <Box>
                    <CardMedia
                      image={config.fileRootPath + props.project.image}
                      className={styles.media}
                    />
                  </Box>
                </Grow>
              )}
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  <TextHighlight search={search}>
                    {props.project.name}
                  </TextHighlight>
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  <TextHighlight search={search}>
                    {props.project.subtitle}
                  </TextHighlight>
                </Typography>
                <Typography
                  variant="overline"
                  color="textSecondary"
                  component="p"
                >
                  Beschreibung
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="justify"
                >
                  <TextHighlight search={search}>
                    {props.project.description}
                  </TextHighlight>
                </Typography>
              </CardContent>
            </Box>
          </CardActionArea>
          <CardActions className={styles.cardAction}>
            <Button size="small" color="primary" onClick={redirectProject}>
              Mehr erfahren
            </Button>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={styles.date}
            >
              {information()}
            </Typography>
          </CardActions>
        </Card>
      </Badge>
    </Box>
  );
}
