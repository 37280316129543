import React from "react";

import Body from "../Root/Body";
import { CreateLocation, EditLocation } from "./Locations/Location";
import LocationList from "./Locations/LocationList";
import { Room, useServer } from "../Server/ServerContext";

import { Button } from "@material-ui/core";
import { AddCircleOutlineRounded } from "@material-ui/icons";

export default function Locations() {
  const [createLocation, setCreateLocation] = React.useState(false);
  const [editLocation, setEditLocation] = React.useState<undefined | Room>(
    undefined
  );

  const useLocations = useServer().useLocations();
  const [organisations] = useServer().useOrganisations();

  return (
    <Body>
      <CreateLocation
        open={createLocation}
        onClose={() => setCreateLocation(false)}
        useLocations={useLocations}
        organisations={organisations}
      />
      <EditLocation
        open={Boolean(editLocation)}
        onClose={() => setEditLocation(undefined)}
        location={editLocation}
        useLocations={useLocations}
        organisations={organisations}
      />
      <Button
        variant="contained"
        fullWidth
        startIcon={<AddCircleOutlineRounded />}
        onClick={() => setCreateLocation(true)}
      >
        Raum erstellen
      </Button>
      <LocationList onClick={setEditLocation} useLocations={useLocations} />
    </Body>
  );
}
