import { ProviderContext } from "notistack";

export interface PromiseSnackbar {
  snackbar: ProviderContext;
  admin?: boolean;
  success?: string;
  info?: string;
  error?: string;
  onSuccess?: (res: any) => any;
  onError?: (err: any) => any;
}

// Snackbar for errors and successes
// eslint-disable-next-line
Promise.prototype.snackbar = async function <T>(
  this: Promise<T>,
  params: PromiseSnackbar
): Promise<T> {
  return this.then((res) => {
    if (params.success)
      params.snackbar.enqueueSnackbar(params.success.trim(), {
        variant: "success",
      });
    if (params.info)
      params.snackbar.enqueueSnackbar(params.info.trim(), {
        variant: "info",
      });

    let result = params.onSuccess?.(res);
    if (result && typeof result === "string")
      params.snackbar.enqueueSnackbar(result.trim(), {
        variant: "success",
      });
    return res;
  }).catch((err) => {
    if (params.error)
      params.snackbar.enqueueSnackbar(
        `${params.error.trim()}. Bitte versuchen Sie es in kurzer Zeit erneut`,
        { variant: "error" }
      );
    if (params.admin)
      params.snackbar.enqueueSnackbar(
        `Folgender Fehler ist aufgetreten: "${err?.[0]?.message || err}"`,
        { variant: "error" }
      );

    let result = params.onError?.(err);
    if (result)
      params.snackbar.enqueueSnackbar(result.trim(), {
        variant: "error",
      });
    return err;
  });
};
