import React from "react";
import { useHistory } from "react-router-dom";

import { Box, IconButton, makeStyles } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";

const styles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[20],
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function CreateProject() {
  const style = styles();
  const history = useHistory();

  const createProject = () => history.push("/admin/createProject");

  return (
    <Box position="fixed" bottom={0} right={0} margin={1}>
      <IconButton onClick={createProject} className={style.root}>
        <AddRounded fontSize="large" />
      </IconButton>
    </Box>
  );
}
