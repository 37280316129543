import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  Box,
  Accordion,
  makeStyles,
  TextField,
  Typography,
  Popover,
  ListItem,
  List,
} from "@material-ui/core";

import TextEditor from "../TextEditor/TextEditor";

import { SettingsAccordion, SettingsListItemProps } from "./SettingsList";
import { FormatedSettings, useServer } from "../Server/ServerContext";
import { useSnackbar } from "notistack";
import EmailEditor from "../EmailEditor/EmailEditor";
import FileSelectorDialog from "../FileSelectorDialog";
import { ExpandMoreRounded, HelpOutline } from "@material-ui/icons";

const helpText = (
  <>
    <Typography>
      Um Details zur Buchung einzufügen können Sie folgende Abkürzungen nutzen.
    </Typography>
    <Typography>
      Diese werden beim Versenden der Mail durch die tatsächlichen Daten
      ersetzt.
    </Typography>
    <List>
      <ListItem>{"{booking_date}: Das Datum der Buchung"}</ListItem>
      <ListItem>{"{project_name}: Der Name des gebuchten Projekts"}</ListItem>
      <ListItem>
        {"{project_subtitle}: Der Untertitel des gebuchten Projekts"}
      </ListItem>
      <ListItem>{"{project_link}: Der Link zum gebuchten Projekt"}</ListItem>
      <ListItem>
        {
          "{booking_id}: Die Buchungsnummer wird in den Notizen des Teilnehmers gespeichert und kann zur Zuordnung der Zahlung genutzt werden."
        }
      </ListItem>
      <ListItem>
        {
          "{zahlweise}: Zahlungsdetails zur Zahlmethode und der in den Textfelder weiter unten angebene Text für die jeweilige Zahlungsmethode"
        }
      </ListItem>
      <ListItem>
        {"{participants}: Die vom Nutzer angemeldeten Teilnehmer"}
      </ListItem>
      <ListItem>
        {"{ticket}: Ticketdetails, diese können weiter unten bearbeitet werden"}
      </ListItem>
    </List>
  </>
);

const ticketHelpText = (
  <>
    <Typography>
      Um Details zur Buchung einzufügen können Sie folgende Abkürzungen nutzen.
    </Typography>
    <Typography>
      Diese werden beim Versenden der Mail durch die tatsächlichen Daten
      ersetzt.
    </Typography>
    <List>
      <ListItem>{"{ticket_link}: Link zum Ticketdownload"}</ListItem>
      <ListItem>{"{booking_date}: Das Datum der Buchung"}</ListItem>
      <ListItem>{"{project_name}: Der Name des gebuchten Projekts"}</ListItem>
      <ListItem>
        {"{project_subtitle}: Der Untertitel des gebuchten Projekts"}
      </ListItem>
      <ListItem>{"{project_link}: Der Link zum gebuchten Projekt"}</ListItem>
      <ListItem>
        {
          "{booking_id}: Die Buchungsnummer wird in den Notizen des Teilnehmers gespeichert und kann zur Zuordnung der Zahlung genutzt werden."
        }
      </ListItem>
      <ListItem>
        {
          "{zahlweise}: Zahlungsdetails zur Zahlmethode und der in den Textfelder weiter unten angebene Text für die jeweilige Zahlungsmethode"
        }
      </ListItem>
      <ListItem>
        {"{participants}: Die vom Nutzer angemeldeten Teilnehmer"}
      </ListItem>
    </List>
  </>
);

export default function EmailEditors(props: SettingsListItemProps) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        Emaileditor
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          <BookingMailEditor {...props} />
          <TextEditorAccordion
            {...props}
            settingsKey="ticket_mail_html"
            title="Ticket"
            helpText={ticketHelpText}
          />
          <TextEditorAccordion
            {...props}
            settingsKey="status_update_mail_html"
            title="Buchungsstatusupdate"
            helpText={helpText}
          />
          <TextEditorAccordion
            {...props}
            settingsKey="cash_mail_string"
            title="Zahlungsmethode: Bar"
          />
          <TextEditorAccordion
            {...props}
            settingsKey="paypal_mail_string"
            title="Zahlungsmethode: PayPal"
          />
          <TextEditorAccordion
            {...props}
            settingsKey="sepa_mail_string"
            attachements
            attachementsSettingsKey="sepa_mail_attachments"
            title="Zahlungsmethode: SEPA"
          />
          <TextEditorAccordion
            {...props}
            settingsKey="payment_reminder_mail_html"
            title="Zahlungserinnerung"
            helpText={helpText}
          />
          <TextEditorAccordion
            {...props}
            settingsKey="already_registered_mail_content"
            subjectSettingsKey="already_registered_mail_subject"
            title="Accounterstellung mit bestehender Email"
            helpText={
              <Typography>
                Diese Email wird an den Nutzer verschickt, wenn versucht wurde
                mit der bestehenden Email eines Accounts einen neuen Account zu
                erstellen.
              </Typography>
            }
          />

          <PasswordMailEditor {...props} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  helpButton: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1,
  },
}));

export interface BookingMailEditorProps extends SettingsListItemProps {}

export function BookingMailEditor(props: BookingMailEditorProps) {
  const styles = useStyles();
  const snackbar = useSnackbar();
  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();

  const [bookingSubject, setBookingSubject] = React.useState(
    settings?.booking_mail_subject
  );
  const [bookingAttachments, setBookingAttachments] = React.useState(
    settings ? (JSON.parse(settings?.booking_mail_attachments) as string[]) : []
  );
  const bookingTextEditor = React.useRef() as React.RefObject<TextEditor>;
  const [bookingHTML, setBookingHTML] = React.useState(
    settings?.booking_mail_html
  );

  const [anchorEl, setAnchorEl] = React.useState(
    undefined as undefined | HTMLElement
  );

  React.useEffect(() => {
    if (bookingSubject === undefined) abort();
    // eslint-disable-next-line
  }, [settings]);

  const abort = () => {
    setBookingSubject(settings?.booking_mail_subject);
    setBookingHTML(settings?.booking_mail_html);
    setBookingAttachments(
      settings ? JSON.parse(settings?.booking_mail_attachments) : []
    );
  };

  const update = () => {
    const content = (
      bookingTextEditor.current as TextEditor
    ).getContentAsHTML();

    Promise.all([
      settingsActions.set("booking_mail_subject", bookingSubject),
      settingsActions.set("booking_mail_html", content),
      settingsActions.set(
        "booking_mail_attachments",
        JSON.stringify(bookingAttachments)
      ),
    ])
      .then(() =>
        setSettings({
          ...settings,
          booking_mail_subject: bookingSubject,
          booking_mail_html: content,
          booking_mail_attachments: JSON.stringify(bookingAttachments),
        })
      )
      .snackbar({
        snackbar,
        success: "Email erfolgreich aktualisiert",
        admin: true,
      });
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(undefined)}
        disableRestoreFocus
        style={{ pointerEvents: "none" }}
      >
        <Box margin={1}>{helpText}</Box>
      </Popover>
      <SettingsAccordion abort={abort} onClick={update}>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          Buchungsbestätigung
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <EmailEditor
            pickedFiles={bookingAttachments}
            setFiles={setBookingAttachments}
            subject={bookingSubject}
            setSubject={setBookingSubject}
            textEditorRef={bookingTextEditor}
            content={bookingHTML}
          />
          <HelpOutline
            color="primary"
            onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
            onMouseLeave={() => setAnchorEl(undefined)}
            className={styles.helpButton}
          />
        </AccordionDetails>
      </SettingsAccordion>
    </>
  );
}

interface TextEditorAccordionProps extends SettingsListItemProps {
  settingsKey: keyof FormatedSettings;
  subjectSettingsKey?: keyof FormatedSettings;
  title: string;
  attachements?: boolean;
  attachementsSettingsKey?: keyof FormatedSettings;
  helpText?: JSX.Element;
}

function TextEditorAccordion(props: TextEditorAccordionProps) {
  const styles = useStyles();
  const snackbar = useSnackbar();
  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();

  const textEditor = React.useRef() as React.RefObject<TextEditor>;
  const [content, setContent] = React.useState(
    settings?.[props.settingsKey] as string
  );

  const [subject, setSubject] = React.useState(
    props.subjectSettingsKey
      ? (settings?.[props.subjectSettingsKey] as string) || ""
      : ""
  );

  const [attachements, setAttachements] = React.useState(
    props.attachementsSettingsKey
      ? settings
        ? (JSON.parse(settings[props.attachementsSettingsKey]) as string[])
        : []
      : ([] as string[])
  );

  const [anchorEl, setAnchorEl] = React.useState(
    undefined as undefined | HTMLElement
  );

  React.useEffect(() => {
    if (content === undefined) abort();
    // eslint-disable-next-line
  }, [settings]);

  const abort = () => {
    setContent(settings?.[props.settingsKey] as string);
    if (props.subjectSettingsKey)
      setSubject(settings?.[props.subjectSettingsKey] as string);
    if (props.attachementsSettingsKey)
      setAttachements(
        settings
          ? (JSON.parse(settings[props.attachementsSettingsKey]) as string[])
          : []
      );
  };

  const update = () => {
    const content = (textEditor.current as TextEditor).getContentAsHTML();

    Promise.all(
      [settingsActions.set(props.settingsKey, content)]
        .concat(
          props.attachementsSettingsKey
            ? settingsActions.set(
                props.attachementsSettingsKey,
                JSON.stringify(attachements)
              )
            : []
        )
        .concat(
          props.subjectSettingsKey
            ? settingsActions.set(props.subjectSettingsKey, subject)
            : []
        )
    )

      .then(() =>
        setSettings({
          ...settings,
          [props.settingsKey]: content,
          ...(props.attachementsSettingsKey
            ? { [props.attachementsSettingsKey]: JSON.stringify(attachements) }
            : {}),
          ...(props.subjectSettingsKey
            ? { [props.subjectSettingsKey]: subject }
            : {}),
        })
      )
      .snackbar({
        snackbar,
        success: "Email erfolgreich aktualisiert",
        admin: true,
      });
  };

  return (
    <>
      {props.helpText ? (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setAnchorEl(undefined)}
          disableRestoreFocus
          style={{ pointerEvents: "none" }}
        >
          <Box margin={1}>{props.helpText}</Box>
        </Popover>
      ) : null}
      <SettingsAccordion abort={abort} onClick={update}>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          {props.title}
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          {(props.subjectSettingsKey || props.attachementsSettingsKey) && (
            <Box display="flex" alignItems="center" marginBottom={2}>
              {props.subjectSettingsKey && (
                <Box flex="1 0 80%">
                  <TextField
                    label="Betreff"
                    fullWidth
                    onChange={(event) => setSubject(event.target.value)}
                    value={subject || ""}
                    margin="normal"
                  />
                </Box>
              )}
              {props.attachementsSettingsKey && (
                <Box flex="1 0 20%">
                  <FileSelectorDialog
                    callbackDone={(files) =>
                      setAttachements(files.map((file) => file.path))
                    }
                    multiple
                    pickedFiles={attachements}
                  >
                    Anhang
                  </FileSelectorDialog>
                </Box>
              )}
            </Box>
          )}

          <Box
            border={1}
            borderColor="primary.main"
            borderRadius={1}
            width="100%"
          >
            <TextEditor
              alternativeTextInsertion={content || ""}
              ref={textEditor}
            />
          </Box>
          {props.helpText ? (
            <HelpOutline
              color="primary"
              onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
              onMouseLeave={() => setAnchorEl(undefined)}
              className={styles.helpButton}
            />
          ) : null}
        </AccordionDetails>
      </SettingsAccordion>
    </>
  );
}

export function PasswordMailEditor(props: SettingsListItemProps) {
  const styles = useStyles();

  const snackbar = useSnackbar();

  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();

  const [subject, setSubject] = React.useState(
    settings?.password_reset_mail_subject as string
  );
  const [content, setContent] = React.useState(
    settings?.password_reset_mail_content as string
  );

  const [anchorEl, setAnchorEl] = React.useState(
    undefined as undefined | HTMLElement
  );

  const textEditor = React.useRef() as React.RefObject<TextEditor>;

  React.useEffect(() => {
    if (subject === undefined) abort();
    // eslint-disable-next-line
  }, [settings]);

  const abort = () => {
    setSubject(settings?.password_reset_mail_subject as string);
    setContent(settings?.password_reset_mail_content as string);
  };

  const update = () => {
    const content = (textEditor.current as TextEditor).getContentAsHTML();

    Promise.all([
      settingsActions.set("password_reset_mail_subject", subject),
      settingsActions.set("password_reset_mail_content", content),
    ])
      .then(() =>
        setSettings({
          ...settings,
          password_reset_mail_subject: subject,
          password_reset_mail_content: content,
        })
      )
      .snackbar({
        snackbar,
        success: "Email erfolgreich aktualisiert",
        admin: true,
      });
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(undefined)}
        disableRestoreFocus
        style={{ pointerEvents: "none" }}
      >
        <Box margin={1}>
          <Typography>
            Um den Code zum Zurücksetzen des Passworts in die Mail einzufügen,
            fügen Sie bitte {"{code}"} in die Mail ein.
          </Typography>
        </Box>
      </Popover>
      <SettingsAccordion abort={abort} onClick={update}>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          Passwort zurücksetzen
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Betreff"
              fullWidth
              onChange={(event) => setSubject(event.target.value)}
              value={subject}
              margin="normal"
            />
          </Box>
          <Box
            border={1}
            borderColor="primary.main"
            borderRadius={1}
            width="100%"
          >
            <TextEditor
              alternativeTextInsertion={content || ""}
              ref={textEditor}
            />
          </Box>
          <HelpOutline
            color="primary"
            onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
            onMouseLeave={() => setAnchorEl(undefined)}
            className={styles.helpButton}
          />
        </AccordionDetails>
      </SettingsAccordion>
    </>
  );
}
