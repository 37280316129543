import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { ArrowForwardRounded } from "@material-ui/icons";

import { ProjectPrams } from "../Project";
import useLocalStorage from "../LocalStorage";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { CustomerProject } from "../Server/ServerContext";

export interface BookingButtonProps {
  disabled?: boolean;
  project?: Pick<CustomerProject, "bookingPeriodStart" | "bookingPeriodEnd">;
}

export default function BookingButton(props: BookingButtonProps) {
  const { id } = useParams<ProjectPrams>();
  const history = useHistory();
  const { setStorage, clear } = useLocalStorage();

  const redirect = () => {
    clear();
    setStorage({ booking: id });
    history.push("/booking");
  };

  const [inBookingPeriodKey, resetBookingKey] = React.useReducer(
    () => ({}),
    {}
  );
  React.useEffect(() => {
    const interval = setInterval(resetBookingKey, 5_000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isInBookingPeriod = React.useMemo(() => {
    if (!props.project) return "loading";
    const now = new Date();
    // old projects -> are bookable as long as they are displayed
    if (!props.project.bookingPeriodStart && !props.project.bookingPeriodEnd)
      return "in";
    const start = new Date(props.project.bookingPeriodStart);
    const end = new Date(props.project.bookingPeriodEnd);
    if (start >= now) return "early";
    if (end <= now) return "late";
    return "in";
  }, [props.project, inBookingPeriodKey]);

  return (
    <Tooltip
      title={
        <Typography>
          {isInBookingPeriod === "loading"
            ? "Lade..."
            : isInBookingPeriod === "early"
            ? `Buchung erst ab ${new Date(
                props.project?.bookingPeriodStart!
              ).toLocaleString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })} möglich`
            : isInBookingPeriod === "late"
            ? `Buchung war nur bis ${new Date(
                props.project?.bookingPeriodStart!
              ).toLocaleString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })} möglich`
            : "Jetzt buchen"}
        </Typography>
      }
    >
      <span>
        <Button
          endIcon={<ArrowForwardRounded />}
          variant="contained"
          color="primary"
          onClick={redirect}
          disabled={props.disabled || isInBookingPeriod !== "in"}
        >
          Zum Projekt anmelden
        </Button>
      </span>
    </Tooltip>
  );
}
