import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

export enum ConfirmDialogType {
  delete,
  confirm,
  custom,
}

export interface ConfirmDialogProps {
  value: any;
  textRender?: ((value: any) => string) | string;
  close: (empty: null) => void;
  onSuccess?: (value: any) => void;
  onAbort?: () => void;
  type: ConfirmDialogType;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [renderedValue, setRenderedValue] = React.useState("");

  React.useEffect(() => {
    setOpen(Boolean(props.value));
    if (props.value) setRenderedValue(textRender());
  }, [props.value]);

  const textRender = () => {
    switch (props.textRender?.constructor) {
      case Function:
        return (props.textRender as (value: any) => string)(props.value);
      case String:
        return props.textRender as string;
      default:
        return props.value;
    }
  };

  const abort = () => {
    props.close(null);
    props.onAbort?.();
  };

  const success = () => {
    props.onSuccess?.(props.value);
    props.close(null);
  };

  const buttonText = () => {
    switch (props.type) {
      case ConfirmDialogType.delete:
        return "Löschen";
      case ConfirmDialogType.confirm:
      case ConfirmDialogType.custom:
        return "Bestätigen";
    }
  };

  const contentText = () => {
    switch (props.type) {
      case ConfirmDialogType.delete:
        return `Sind Sie sicher, dass Sie den Eintrag "${renderedValue}" entgültig löschen möchten?`;
      case ConfirmDialogType.confirm:
        return `Sind Sie sicher, dass Sie den Eintrag "${renderedValue}" erstellen möchten?`;
      case ConfirmDialogType.custom:
        return renderedValue;
    }
  };

  return (
    <Dialog open={open} onClose={abort}>
      <DialogTitle>Bestätigung</DialogTitle>
      <DialogContent>{contentText()}</DialogContent>
      <DialogActions>
        <Button onClick={abort}>Abbruch</Button>
        <Button color="primary" variant="contained" onClick={success}>
          {buttonText()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
