import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import history from "./history";

import { createTheme, ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

import { ServerProvider } from "./Components/Server/ServerContext";
import { LocalStorageProvider } from "./Components/LocalStorage";

import "./Functions/PromiseSnackbar";

import "./index.css";

const theme = createTheme();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <SnackbarProvider maxSnack={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <ServerProvider>
            <LocalStorageProvider>
              <App />
            </LocalStorageProvider>
          </ServerProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
