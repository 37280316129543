import React from "react";
import { useParams } from "react-router-dom";

import BackToProjects from "./Account/BackToProjects";
import HeaderBarPublic from "./Root/HeaderBar";
import ShareProject from "./Project/ShareProject";
import BookingButton from "./Project/BookingButton";
import ProjectDetails from "./Project/ProjectDetails";
import SubHeaderBar from "./Root/SubHeaderBar";
import Body from "./Root/Body";
import { ProjectState, useServer } from "./Server/ServerContext";

export interface ProjectPrams {
  id: string;
}

export default function Project(props?: ProjectPrams) {
  const { id } = useParams<ProjectPrams>();

  const project = useServer().useProject(props?.id || id);

  return (
    <>
      <HeaderBarPublic title="Projektansicht" />
      <SubHeaderBar
        left={<BackToProjects />}
        right={
          <>
            <ShareProject />
            {project?.bookingRequired && (
              <BookingButton
                disabled={project?.state === ProjectState.Full}
                project={project}
              />
            )}
          </>
        }
      />
      <Body>
        <ProjectDetails project={project} />
      </Body>
    </>
  );
}
