import React from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Box,
  IconButton,
  IconButtonProps,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AccountCircleRounded,
  ArrowBackRounded,
  ExitToAppRounded,
  SupervisorAccountRounded,
} from "@material-ui/icons";

import { useServer } from "../Server/ServerContext";
import useLogin from "../Login";
import config from "../../config";
import { ReactComponent as Logo } from "./../../resources/varausLogo.svg";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  img: {
    height: "3rem",
    maxWidth: "30vw",
    objectFit: "contain",
    objectPosition: "center center",
    marginRight: theme.spacing(1),
    fill: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
  },
}));

export interface HeaderBarProps {
  title: string;
  leftAction?: React.ReactNode;
}

export default function HeaderBarPublic(props: HeaderBarProps) {
  const styles = useStyles();

  const history = useHistory();
  const { currentUser, logout, dataActions, useNamespace } = useServer();
  const namespace = useNamespace[0];
  const { login } = useLogin();

  const [favicon, setFavicon] = React.useState<string | undefined>();

  React.useEffect(() => {
    (async () => {
      setFavicon(await dataActions.getFavicon(namespace));
    })();
  }, []);

  const redirectAccount = () => history.push("/account");

  const logoutButton = () => {
    logout();
    history.push("/");
  };

  const redirectAdmin = () => history.push("/admin");

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <AppBar position="relative">
        <Toolbar className={styles.toolbar}>
          {props.leftAction || (
            <Tooltip title="Zurück zu den Projekten">
              <HeaderIconButton onClick={() => history.push("/")}>
                <ArrowBackRounded />
              </HeaderIconButton>
            </Tooltip>
          )}
          {!mobile ? (
            <Box display="flex" flexGrow={1} alignItems="center">
              {favicon ? (
                <img
                  src={config.fileRootPath + favicon}
                  alt={props.title}
                  className={styles.img}
                />
              ) : (
                <Logo className={styles.img} />
              )}

              <Typography variant="h6">{props.title}</Typography>
            </Box>
          ) : (
            <Box display="flex" flexGrow={1} />
          )}
          {currentUser?.access_level ? (
            <Tooltip title="Zur Mitarbeiterseite">
              <HeaderIconButton onClick={redirectAdmin}>
                <SupervisorAccountRounded />
              </HeaderIconButton>
            </Tooltip>
          ) : null}
          {currentUser ? (
            <>
              <Tooltip title="Account">
                <HeaderIconButton onClick={redirectAccount}>
                  <AccountCircleRounded />
                </HeaderIconButton>
              </Tooltip>
              <Tooltip title="Abmelden">
                <HeaderIconButton onClick={logoutButton}>
                  <ExitToAppRounded />
                </HeaderIconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Anmelden">
                <HeaderIconButton onClick={login}>
                  <p style={{ fontSize: "0.8em", paddingRight: "0.5em" }}>
                    Login
                  </p>
                  <AccountCircleRounded />
                </HeaderIconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export const useIconStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
}));

export const HeaderIconButton = React.forwardRef(function HeaderIconButton(
  props: IconButtonProps,
  ref: any
) {
  const styles = useIconStyles();

  return <IconButton {...props} className={styles.iconButton} ref={ref} />;
});
