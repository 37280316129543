import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import { useSnackbar } from "notistack";

import { Organisation, Room, useServer } from "../../Server/ServerContext";
import InputWrapper from "../../InputWrapper";

import FormComponent from "../../FormComponent";

export interface LocationProps {
  open: boolean;
  onClose: () => void;
  title: string;
  button: string;
  onClick: (room: Room) => void;
  location?: Room;
  organisations: Organisation[];
}

export default function LocationDialog(props: LocationProps) {
  const [name, setName] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [city, setCity] = React.useState("");
  const [size, setSize] = React.useState("");
  const [characteristics, setCharacteristics] = React.useState("");

  const onClick = () =>
    props.onClick({
      name,
      street,
      zip: parseInt(zip),
      city,
      size: parseInt(size),
      characteristics,
      id: props.location?.id as any,
    } as Room);

  React.useEffect(() => {
    if (props.open) {
      setName(props.location?.name || "");
      setStreet(props.location?.street || "");
      setZip(props.location?.zip.toString() || "");
      setCity(props.location?.city || "");
      setSize(props.location?.size.toString() || "");
      setCharacteristics(props.location?.characteristics || "");
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{ component: FormComponent }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <InputWrapper>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Name"
            autoComplete="organization"
            required
            fullWidth
            margin="normal"
            autoFocus
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={street}
            onChange={(event) => setStreet(event.target.value)}
            label="Straße"
            autoComplete="street-address"
            required
            fullWidth
            margin="normal"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={zip}
            onChange={(event) => setZip(event.target.value.replace(/\D/g, ""))}
            label="Postleitzahl"
            autoComplete="postal-code"
            required
            fullWidth
            margin="normal"
          />
          <TextField
            value={city}
            onChange={(event) => setCity(event.target.value)}
            label="Stadt"
            autoComplete="address-level2"
            required
            fullWidth
            margin="normal"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={size}
            onChange={(event) => setSize(event.target.value.replace(/\D/g, ""))}
            label="Raumgröße"
            InputProps={{
              endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }}
            required
            fullWidth
            margin="normal"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={characteristics}
            onChange={(event) => setCharacteristics(event.target.value)}
            label="Charakteristiken"
            multiline
            fullWidth
            margin="normal"
          />
        </InputWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onClick}
          disabled={!name || !street || !zip || !city || !size}
          type="submit"
        >
          {props.button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface CreateLocationProps {
  open: boolean;
  onClose: () => void;
  useLocations: [Room[], React.Dispatch<React.SetStateAction<Room[]>>];
  organisations: Organisation[];
}

export function CreateLocation(props: CreateLocationProps) {
  const { masterData } = useServer();
  const snackbar = useSnackbar();
  const [locations, setLocations] = props.useLocations;

  const onClick = (room: Room) => {
    masterData.rooms
      .add(room)
      .then((id) => setLocations([...locations, { ...room, id }]))
      .then(props.onClose)
      .snackbar({
        snackbar,
        success: "Raum erfolgreich hinzugefügt",
        admin: true,
      });
  };

  return (
    <LocationDialog
      title="Raum hinzufügen"
      button="Erstellen"
      onClick={onClick}
      onClose={props.onClose}
      open={props.open}
      organisations={props.organisations}
    />
  );
}

export interface EditLocationProps extends CreateLocationProps {
  location?: Room;
}

export function EditLocation(props: EditLocationProps) {
  const { masterData } = useServer();
  const snackbar = useSnackbar();
  const [locations, setLocations] = props.useLocations;

  const onClick = (room: Room) => {
    masterData.rooms
      .update((props.location as Room).id, room)
      .then(() =>
        setLocations(
          locations.map((location) =>
            location.id !== room.id ? location : room
          )
        )
      )
      .then(props.onClose)
      .snackbar({
        snackbar,
        success: "Raum erfolgreich aktualisiert",
        admin: true,
      });
  };

  return (
    <LocationDialog
      title="Raum bearbeiten"
      button="Aktualisieren"
      onClick={onClick}
      onClose={props.onClose}
      open={props.open}
      location={props.location}
      organisations={props.organisations}
    />
  );
}
