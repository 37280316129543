import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowForwardRounded } from "@material-ui/icons";

import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";

export interface PaymentButtonProps {
  disabled: boolean;
  onClick: () => void;
}

export default function PaymentButton(props: PaymentButtonProps) {
  const history = useHistory();

  const payment = () => {
    props.onClick();
    history.push("/payment");
  };

  return (
    <SubHeaderIconButton
      onClick={payment}
      icon={<ArrowForwardRounded />}
      disabled={props.disabled}
    >
      Verbindlich Buchen
    </SubHeaderIconButton>
  );
}
