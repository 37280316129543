import React from "react";
import { useParams } from "react-router-dom";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import ProjectEditorDetails from "./EditProject/ProjectEditorDetails";
import { ProjectPrams } from "./Project";
import Body from "./Root/Body";

export default function EditProject() {
  const { id } = useParams<ProjectPrams>();

  return (
    <>
      <HeaderBarAdmin title="Projekt bearbeiten" />
      <Body>
        <ProjectEditorDetails id={id} />
      </Body>
    </>
  );
}
