import React from "react";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import Body from "./Root/Body";
import SettingsList from "./Settings/SettingsList";

export default function Settings() {
  return (
    <>
      <HeaderBarAdmin title="Einstellungen" />
      <Body>
        <SettingsList />
      </Body>
    </>
  );
}
