import React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import useLocalStorage from "../LocalStorage";
import { useServer } from "../Server/ServerContext";

export interface SummaryProjectProps {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& > *:not(:first-child)": {
        textAlign: "right",
      },
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  list: {
    padding: 0,
    textAlign: "right",
  },
}));

export const calcDiscount = (
  fee: number,
  count: number,
  discountRate: number
) => ((fee * (100 - (discountRate || 0))) / 100) * (count - 1) + fee || 0;

export default function SummaryProject(props: SummaryProjectProps) {
  const styles = useStyles();

  const project = useServer().useProject(props.id);
  const members = useServer().useMembers();
  const { parsedStorage } = useLocalStorage();

  const parseSelectedPersons = () =>
    (parsedStorage("personIDs") as string[] | undefined)?.map((person) => {
      const finding = members.find((member) => member.id === person);
      if (finding) return `${finding.name} ${finding.surname}`;
      else return "";
    }) || [];

  const numberOfPersons =
    (parsedStorage("personIDs") as string[] | undefined)?.length || 0;

  return (
    <>
      <Box className={styles.root}>
        <Typography variant="h5">Veranstaltung</Typography>
        <Typography variant="h6">
          {project?.name || <Skeleton variant="text" width={250} />}
        </Typography>
      </Box>
      <Box className={styles.root}>
        <Typography variant="h5">Teilnehmer</Typography>
        {members ? (
          <List className={styles.list}>
            {parseSelectedPersons().map((person, index) => (
              <ListItem className={styles.list} key={index}>
                <ListItemText>{person}</ListItemText>
              </ListItem>
            ))}
          </List>
        ) : (
          <Skeleton variant="text" width={500} />
        )}
      </Box>
      {Boolean(project?.discountRate) && numberOfPersons > 1 && (
        <Box className={styles.root}>
          <Typography variant="h5">Geschwisterrabatt</Typography>
          <Typography variant="h6">
            {project ? (
              `${project.discountRate}%`
            ) : (
              <Skeleton variant="text" width={140} />
            )}
          </Typography>
        </Box>
      )}
      <Box className={styles.root}>
        <Typography variant="h5">Gebühren</Typography>
        <Typography variant="h6">
          {project ? (
            `${(project.fees / 100)
              .toFixed(2)
              .replace(".", ",")}€ x ${numberOfPersons} = ${(
              calcDiscount(
                project.fees,
                numberOfPersons,
                project.discountRate
              ) / 100
            )
              .toFixed(2)
              .replace(".", ",")}€`
          ) : (
            <Skeleton variant="text" width={140} />
          )}
        </Typography>
      </Box>
    </>
  );
}
