import React from "react";

import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  makeStyles,
  TableSortLabel,
  Typography,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from "@material-ui/core";
import { LinkRounded } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { PieChart } from "react-minimal-pie-chart";
import { PropsWithDefaults } from "react-minimal-pie-chart/types/Chart/Chart";
import { Data } from "react-minimal-pie-chart/types/commonTypes";

import { useServer, Stats } from "../Server/ServerContext";
import { defaultGenders } from "../Account/GenderSelector";
import Body from "../Root/Body";

import config from "../../config";
import { useSnackbar } from "notistack";
import { defaultSearchSettings } from "../Root/FilterSidebar";

interface CombinedStats extends Stats {
  units: number;
  utilisation: number;
}

type PossibleLabels =
  | "Einheiten"
  | "Anmeldungen"
  | "Männlich"
  | "Weiblich"
  | "Divers"
  | "Deutsch"
  | "Andere Nationalitäten"
  | "Durchschnittsalter"
  | "Auslastung";

const useStyles = makeStyles((theme) => ({
  chip: {
    borderWidth: 3,
  },
  table: {
    height: "max-content",
  },

  pieChartTitle: {
    textAlign: "center",
  },
  pieChart: {
    height: "auto",
  },

  blockChart: {
    fill: theme.palette.primary.main,
  },

  statisticsDownloadBox: {
    textAlign: "center",
    alignSelf: "center",
    flexDirection: "column",
    display: "flex",
    width: "100%",
  },

  csvButton: {
    whiteSpace: "nowrap",
    minWidth: "max-content",
  },

  tableHeader: {
    padding: ` 0 0 ${theme.spacing(3)}px`,
    position: "relative",
  },

  headerLabel: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    padding: theme.spacing(1),
    textAlign: "center",
  },

  headerColor: {
    width: "100%",
    height: theme.spacing(3),
    position: "absolute",
    bottom: 0,
  },

  topWrapper: {
    display: "flex",
  },

  filterWrapper: {
    flexDirection: "column",
    width: 300,
  },

  ageSlider: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },

  graphWrapper: {
    display: "flex",
    margin: theme.spacing(3),
    height: "100%",
    flex: "1 1 0",
    "& > *": {
      margin: `0 ${theme.spacing(1)}px`,
      flex: "0 0 33.33%",
    },
  },
}));

const DataColors: { [key in keyof Partial<CombinedStats>]: string } = {
  units: "#3f51b5",
  signups: "#4caf50",
  male: "#2196f3",
  female: "#e91e63",
  diverse: "#ff5722",
  german: "#ffc107",
  notGerman: "#cddc39",
  averageAge: "#607d8b",
  utilisation: "#603596",
};

export default function ProjectStats() {
  const styles = useStyles();
  const snackbar = useSnackbar();
  const settings = useServer().useSettings()[0];
  const namespace = useServer().useNamespace[0];

  const [organisations, setOrganisations] = React.useState<string[]>([]);
  const [gender, setGender] = React.useState("");
  const [from, setFrom] = React.useState<Date | null>(null);
  const [to, setTo] = React.useState<Date | null>(null);
  const [zips, setZips] = React.useState<number[]>([]);
  const [age, setAge] = React.useState<[number, number]>(
    defaultSearchSettings.age
  );

  const projectStats = useServer().useStats(
    gender,
    organisations,
    from,
    to,
    zips,
    age[0],
    age[1]
  );

  const [organisationList] = useServer().useOrganisations();
  const categories = useServer().useCategories();
  const zipList = useServer().useZips();

  const [sorting, setSorting] = React.useState<PossibleLabels>("Anmeldungen");
  const [direction, setDirection] = React.useState(false);

  const [selectedCategory, setSelectedCategory] = React.useState("");

  const [extractionOpen, setExtractionOpen] = React.useState(false);

  const calculateStatsByCategory = (): CombinedStats[] => {
    const statsByCategory: { [key: string]: CombinedStats } = {};

    categories.forEach((category) => {
      statsByCategory[category] = {
        category,
        units: 0,
        signups: 0,
        male: 0,
        female: 0,
        diverse: 0,
        german: 0,
        notGerman: 0,
        averageAge: 0,
        maximumParticipants: 0,
        utilisation: 0,
        registrations: 0,
      };
    });

    projectStats.forEach((project) => {
      const { category, ...values } = project;

      for (const key in values) {
        if (statsByCategory[category])
          (statsByCategory[category][key as keyof Stats] as number) += (
            values as Stats
          )[key as keyof Stats] as number;
      }

      if (statsByCategory[category]) statsByCategory[category].units += 1;
    });

    categories.forEach((category) => {
      statsByCategory[category].utilisation =
        Math.round(
          (statsByCategory[category].registrations /
            statsByCategory[category].maximumParticipants) *
            100
        ) || 0;
    });

    return Object.values(statsByCategory);
  };

  const [stats, setStats] = React.useState<CombinedStats[]>([]);

  React.useEffect(() => {
    setStats(calculateStatsByCategory());
  }, [projectStats, categories]);

  const handleSort = (label: string) => {
    setSelectedCategory("");

    if (sorting !== label) {
      setSorting(label as PossibleLabels);
      setDirection(false);
    } else setDirection(!direction);
  };

  const sort = (a: CombinedStats, b: CombinedStats) => {
    switch (sorting) {
      case "Einheiten":
        return a.units - b.units;
      case "Anmeldungen":
        return a.signups - b.signups;
      case "Männlich":
        return a.male - b.male;
      case "Weiblich":
        return a.female - b.female;
      case "Divers":
        return a.diverse - b.diverse;
      case "Deutsch":
        return a.german - b.german;
      case "Andere Nationalitäten":
        return a.notGerman - b.notGerman;
      case "Durchschnittsalter":
        return a.averageAge - b.averageAge;
      case "Auslastung":
        return a.utilisation - b.utilisation;
      default:
        return 1;
    }
  };

  const pieChartData = (entry: CombinedStats) => {
    switch (sorting) {
      case "Einheiten":
        return entry.units;
      case "Anmeldungen":
        return entry.signups;
      case "Männlich":
        return entry.male;
      case "Weiblich":
        return entry.female;
      case "Divers":
        return entry.diverse;
      case "Deutsch":
        return entry.german;
      case "Andere Nationalitäten":
        return entry.notGerman;
      case "Durchschnittsalter":
      case "Auslastung":
        return 0;
    }
  };

  const hashedColor = (random: string) => {
    let hash = 0;
    for (let i = 0; i < random.length; i++) {
      hash = random.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  const getCSV = (type: "projects" | "people") => {
    const startDate = (from || new Date(0)).toISOString();
    const endDate = (to || new Date(32472140400000)).toISOString();

    navigator.clipboard.writeText(
      (type === "projects" ? config.projectStats : config.peopleStats)
        .replace("$startDate", startDate)
        .replace("$endDate", endDate)
        .replace("$namespace", namespace?.toString() || "-1")
        .replace("$key", settings.excel_key)
    );
    snackbar.enqueueSnackbar(
      "Link erfolgreich in den Zwischenspeicher kopiert. Wie Sie diesen verwenden finden Sie im Handbuch!",
      { variant: "success" }
    );
  };

  return (
    <>
      <Box className={styles.topWrapper}>
        <Box className={styles.filterWrapper}>
          <Box className={styles.statisticsDownloadBox}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setExtractionOpen(true)}
              className={styles.csvButton}
              fullWidth
            >
              Daten extrahieren
            </Button>

            <Dialog
              open={extractionOpen}
              onClose={() => setExtractionOpen(false)}
            >
              <DialogTitle>Excel Verlinkungen</DialogTitle>
              <DialogContent>
                <Box display="flex" marginBottom={2}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    label="Zeitraum Beginn"
                    value={from}
                    onChange={setFrom}
                    helperText={undefined}
                    margin="normal"
                    fullWidth
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    label="Zeitraum Ende"
                    value={to}
                    onChange={setTo}
                    helperText={undefined}
                    margin="normal"
                    fullWidth
                  />
                </Box>

                <Typography>
                  Die folgenden Links können in Excel importiert werden
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => getCSV("people")}
                    endIcon={<LinkRounded />}
                  >
                    Personenstatistiken
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => getCSV("projects")}
                    endIcon={<LinkRounded />}
                  >
                    Projektstatistiken
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => setExtractionOpen(false)}
                >
                  Fertig
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Organisation</InputLabel>
            <Select
              value={organisations}
              onChange={(event) =>
                setOrganisations(event.target.value as string[])
              }
              renderValue={(selected) => (selected as string[]).join(", ")}
              input={<Input />}
              multiple
            >
              {organisationList?.map((organisation) => (
                <MenuItem value={organisation.name} key={organisation.id}>
                  <Checkbox
                    checked={organisations.includes(organisation.name)}
                    color="primary"
                  />
                  {organisation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Postleitzahlen</InputLabel>
            <Select
              value={zips}
              onChange={(event) => setZips(event.target.value as number[])}
              renderValue={(selected) => (selected as string[]).join(", ")}
              input={<Input />}
              multiple
            >
              {zipList?.map((zip) => (
                <MenuItem value={zip} key={zip}>
                  <Checkbox checked={zips.includes(zip)} color="primary" />
                  {zip}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel>Geschlecht</InputLabel>
            <Select
              value={gender}
              onChange={(event) => setGender(event.target.value as string)}
            >
              <MenuItem value="">
                <em>Ignorieren</em>
              </MenuItem>
              {defaultGenders?.map((gender) => (
                <MenuItem value={gender} key={gender}>
                  {gender}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <Typography variant="subtitle1" color={"textSecondary"}>
              Teilnehmeralter
            </Typography>
            <Slider
              defaultValue={defaultSearchSettings.age}
              onChangeCommitted={(_, value) => setAge(value as any)}
              valueLabelDisplay="auto"
              min={defaultSearchSettings.age[0]}
              max={defaultSearchSettings.age[1]}
              className={styles.ageSlider}
            />
          </FormControl>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            label="Zeitraum Beginn"
            value={from}
            onChange={setFrom}
            helperText={undefined}
            margin="normal"
            fullWidth
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            label="Zeitraum Ende"
            value={to}
            onChange={setTo}
            helperText={undefined}
            margin="normal"
            fullWidth
          />
        </Box>
        <Box className={styles.graphWrapper}>
          {Boolean(selectedCategory) ? (
            <>
              <SimpleBlockGrpah
                data={(
                  ["units", "signups"] as (keyof Partial<CombinedStats>)[]
                ).map((key) => ({
                  title: key as string,
                  value: (
                    stats.find(
                      (stat) => stat.category === selectedCategory
                    ) as CombinedStats
                  )[key] as number,
                  color: DataColors[key] as string,
                }))}
                title="Einheiten zu Anmelungen"
              />

              <SimplePieChart
                data={(
                  [
                    "male",
                    "female",
                    "diverse",
                  ] as (keyof Partial<CombinedStats>)[]
                ).map((key) => ({
                  title: key as string,
                  value: (
                    stats.find(
                      (stat) => stat.category === selectedCategory
                    ) as CombinedStats
                  )[key] as number,
                  color: DataColors[key] as string,
                }))}
                title="Geschlechterverteilung"
              />

              <SimplePieChart
                data={(
                  ["german", "notGerman"] as (keyof Partial<CombinedStats>)[]
                ).map((key) => ({
                  title: key as string,
                  value: (
                    stats.find(
                      (stat) => stat.category === selectedCategory
                    ) as CombinedStats
                  )[key] as number,
                  color: DataColors[key] as string,
                }))}
                title="Nationalitäten"
              />
            </>
          ) : (
            <SimplePieChart
              data={stats.map((entry) => ({
                title: entry.category,
                value: pieChartData(entry),
                color: hashedColor(entry.category),
              }))}
              title={sorting}
            />
          )}
        </Box>
      </Box>
      <Body display="flex" paddingLeft={0} paddingRight={0}>
        <TableContainer component={Paper} className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kategorien</TableCell>
                {[
                  ["Einheiten", "units"],
                  ["Anmeldungen", "signups"],
                  ["Männlich", "male"],
                  ["Weiblich", "female"],
                  ["Divers", "diverse"],
                  ["Deutsch", "german"],
                  ["Andere Nationalitäten", "notGerman"],
                  ["Durchschnittsalter", "averageAge"],
                  ["Auslastung", "utilisation"],
                ].map(([label, key]) => (
                  <TableCell key={label} className={styles.tableHeader}>
                    <TableSortLabel
                      active={sorting === label}
                      direction={direction ? "asc" : "desc"}
                      onClick={() => handleSort(label)}
                      className={styles.headerLabel}
                    >
                      {label}
                    </TableSortLabel>
                    <Box
                      className={styles.headerColor}
                      style={{
                        background: `${
                          DataColors[
                            key as keyof {
                              [key in keyof Partial<CombinedStats>]: string;
                            }
                          ]
                        }`,
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(direction
                ? [...stats].sort(sort)
                : [...stats].sort(sort).reverse()
              ).map((row) => (
                <TableRow key={row.category}>
                  <TableCell component="th" scope="row">
                    <Chip
                      label={row.category}
                      style={{ borderColor: hashedColor(row.category) }}
                      className={styles.chip}
                      variant="outlined"
                      onClick={() => setSelectedCategory(row.category)}
                    />
                  </TableCell>
                  <TableCell align="right">{row.units}</TableCell>
                  <TableCell align="right">{row.signups}</TableCell>
                  <TableCell align="right">{row.male}</TableCell>
                  <TableCell align="right">{row.female}</TableCell>
                  <TableCell align="right">{row.diverse}</TableCell>
                  <TableCell align="right">{row.german}</TableCell>
                  <TableCell align="right">{row.notGerman}</TableCell>
                  <TableCell align="right">{row.averageAge}</TableCell>
                  <TableCell align="right">{row.utilisation + "%"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </>
  );
}

const SimplePieChart = (
  props: Partial<Omit<PropsWithDefaults, "lineWidth" | "labelPosition">> & {
    title: string;
  }
) => {
  const styles = useStyles();

  return (
    <Box>
      <Typography className={styles.pieChartTitle}>{props.title}</Typography>
      <PieChart
        labelStyle={{ fontSize: "xx-small" }}
        label={({ dataEntry }) =>
          Math.round(dataEntry.percentage)
            ? `${Math.round(dataEntry.percentage)}%`
            : ""
        }
        animate
        rounded
        lineWidth={42}
        labelPosition={79}
        className={styles.pieChart}
        {...props}
      />
    </Box>
  );
};

const SimpleBlockGrpah = (props: { data: Data; title: string }) => {
  const styles = useStyles();

  const height = 100 / (props.data.length * 2 + 3) || 0;
  const maxWidth = Math.max(...props.data.map((entry) => entry.value));

  const leftBlockWidth = 2;

  return (
    <Box>
      <Typography className={styles.pieChartTitle}>{props.title}</Typography>
      <svg
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
        className={styles.pieChart}
      >
        {props.data.map((entry, index) => (
          <rect
            height={`${height}%`}
            width={`calc(${
              (entry.value / maxWidth) * 100 || 0
            }% - ${leftBlockWidth}px)`}
            fill={entry.color}
            y={(index * 2 + 2) * height}
            x={leftBlockWidth}
            key={index}
          />
        ))}
        <rect
          height="80%"
          width={leftBlockWidth}
          y="10%"
          className={styles.blockChart}
        />
      </svg>
    </Box>
  );
};
