import React from "react";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import CreateProjectInput from "./CreateProject/CreateProjectInput";
import Body from "./Root/Body";

export default function CreateProject() {
  return (
    <>
      <HeaderBarAdmin title="Projekt erstellen" />
      <Body>
        <CreateProjectInput />
      </Body>
    </>
  );
}
