import React from "react";

import { Organisation, useServer } from "../../Server/ServerContext";

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { DeleteForeverRounded } from "@material-ui/icons";

import { useSnackbar } from "notistack";

import useSearch, { TextHighlight } from "../../Search/SearchProvider";
import ConfirmDelete, { ConfirmDialogType } from "../../ConfirmDialog";

export interface OrganisationListProps {
  onClick: (location: Organisation) => void;
  useOrganisations: [
    Organisation[],
    React.Dispatch<React.SetStateAction<Organisation[]>>
  ];
}

export default function OrganisationList(props: OrganisationListProps) {
  const [organisations, setOrganisations] = props.useOrganisations;
  const { masterData } = useServer();
  const { search, isSearched } = useSearch();
  const snackbar = useSnackbar();

  const [requestDelete, setRequestDelete] = React.useState<Organisation | null>(
    null
  );

  const removeOrganisation = (organisation: Organisation) => {
    masterData.organisation
      .delete(organisation.id)
      .then(() =>
        setOrganisations(
          organisations.filter((filtered) => organisation.id !== filtered.id)
        )
      )
      .snackbar({
        snackbar,
        success: "Organisation erfolgreich entfernt",
        error:
          "Die Organisation kann nicht gelöscht werden, da sie noch in Projekten verwendet wird",
      });
  };

  const filter = (organisation: Organisation) => {
    const { id, ...rest } = organisation;
    return isSearched([rest]);
  };
  return (
    <List>
      <ConfirmDelete
        value={requestDelete}
        close={setRequestDelete}
        textRender={(value: Organisation) => value.name}
        onSuccess={removeOrganisation}
        type={ConfirmDialogType.delete}
      />

      {organisations.filter(filter).map((organisation, index) => (
        <React.Fragment key={organisation.id || index}>
          <ListItem button onClick={() => props.onClick(organisation)}>
            <ListItemText
              primary={
                <TextHighlight search={search}>
                  {organisation.name}
                </TextHighlight>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => setRequestDelete(organisation)}
              >
                <DeleteForeverRounded />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
