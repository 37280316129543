import React from "react";
import { getQuery } from "../../Functions/QueryParams";

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ExpandMoreRounded } from "@material-ui/icons";

import { useSnackbar } from "notistack";

import { Member, useServer } from "../Server/ServerContext";

import GenderSelector from "./GenderSelector";
import NationalitySelector from "./NationalitySelector";

import InputWrapper from "../InputWrapper";

import { isAdult } from "../../Functions/Age";
import FormComponent from "../FormComponent";
import ConfirmDialog, { ConfirmDialogType } from "../ConfirmDialog";

export interface EditMember extends Omit<Member, "birthday" | "nationality"> {
  birthday: null | Date;
  nationality: null | string;
}

export const parseEditMember = (
  member: Partial<Member> | undefined
): Partial<EditMember> | undefined => {
  if (!member || !Object.keys(member).length) return undefined;
  const { birthday, ...rest } = member;
  return { ...rest, birthday: birthday ? new Date(birthday) : undefined };
};

export const parseMember = (
  member: Partial<EditMember> | undefined
): Partial<Member> | undefined => {
  if (!member || !Object.keys(member).length) return undefined;
  const { birthday, nationality, ...rest } = member;
  return {
    ...rest,
    birthday: birthday?.toLocaleDateString("en-US"),
    nationality: nationality ? nationality : undefined,
  };
};

export default function PersonEditList() {
  const [completeMembers, setCompleteMembers] =
    useServer().useCompleteMembers();
  const maxMemberCount = useServer().useMaximumMemberCount();
  const { accountManagement, currentUser } = useServer();
  const snackbar = useSnackbar();

  const [requestRemove, setRequestRemove] = React.useState<Member | null>(null);

  const memberHidden = React.useRef(
    Boolean(getQuery({ addNew: Boolean }).addNew)
  );

  const updateMember = (member: Member) =>
    accountManagement
      .updateMember(member.id as string, member)
      .then(() =>
        setCompleteMembers(
          completeMembers.map((filtered) =>
            filtered.id !== member.id ? filtered : member
          )
        )
      )
      .snackbar({
        snackbar,
        success: "Person erfolgreich aktualisiert",
        error: "Person konnte nicht aktualisiert werden",
      });

  const removeMember = (member: Member) => {
    accountManagement
      .deleteMember(member.id as string)
      .then(() =>
        setCompleteMembers(
          completeMembers.filter((complete) => complete.id !== member.id)
        )
      )
      .snackbar({
        snackbar,
        success: "Person erfolgreich entfernt",
        error: "Person konnte nicht entfernt werden",
      });
  };

  const addMember = async (member: Member): Promise<boolean> => {
    // Check if maximum number of members is reached
    if (maxMemberCount > completeMembers.length)
      return accountManagement
        .addMember(member)
        .then((id) => {
          setCompleteMembers([
            ...completeMembers,
            {
              id,
              linkedAccount:
                completeMembers[0]?.linkedAccount || currentUser?.email,
              ...member,
            },
          ]);
          return maxMemberCount > completeMembers.length + 1;
        })
        .snackbar({
          snackbar,
          success: "Person erfolgreich hinzugefügt",
          error: "Person konnte nicht hinzugefügt werden",
        });
    else {
      snackbar.enqueueSnackbar("Maximale Mitgliederanzahl erreicht", {
        variant: "error",
      });
      throw "Maximale Mitgliederanzahl erreicht";
    }
  };

  return (
    <Box width="100%">
      <ConfirmDialog
        value={requestRemove}
        close={setRequestRemove}
        onSuccess={removeMember}
        textRender={(member: Member) =>
          `Sind Sie sicher, dass Sie "${member.name} ${member.surname}" entgültig löschen möchten?`
        }
        type={ConfirmDialogType.custom}
      />

      {completeMembers.map((member, index) => (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          defaultExpanded={!memberHidden.current && index === 0}
          key={member.id || index}
          component={FormComponent}
        >
          <AccordionSummary expandIcon={<ExpandMoreRounded />}>
            <Typography>{`${member.name} ${member.surname}`}</Typography>
          </AccordionSummary>

          <PersonEditListItem
            member={member}
            updateMember={updateMember}
            removeMember={setRequestRemove}
            // Root member is always at index === 0
            isRootMember={!index}
          />
        </Accordion>
      ))}

      {maxMemberCount > completeMembers.length && (
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          defaultExpanded={memberHidden.current}
          component={FormComponent}
        >
          <AccordionSummary expandIcon={<ExpandMoreRounded />}>
            <Typography>Person hinzufügen</Typography>
          </AccordionSummary>

          <PersonAddListItem addMember={addMember} />
        </Accordion>
      )}
    </Box>
  );
}

export interface PersonEditListItemProps {
  isRootMember: boolean;
  member: Member;
  updateMember: (member: Member) => void;
  removeMember: (member: Member) => void;
}

export function PersonEditListItem(props: PersonEditListItemProps) {
  const [member, setMember] = React.useState<EditMember>(
    parseEditMember(props.member) as EditMember
  );
  const [valid, setValid] = React.useState(true);

  const onChange = (member: EditMember, valid: boolean) => {
    setMember(member);
    setValid(valid);
  };

  const abort = () => {
    setMember(parseEditMember(props.member) as EditMember);
    setValid(true);
  };

  return (
    <>
      <AccordionDetails>
        <PersonListItem
          member={member}
          onChange={onChange}
          isRootMember={props.isRootMember}
        />
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {!props.isRootMember && (
          <Button
            size="small"
            color="secondary"
            className="align-left"
            onClick={() => props.removeMember(parseMember(member) as Member)}
          >
            Löschen
          </Button>
        )}
        <Button size="small" onClick={abort}>
          Abbrechen
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => props.updateMember(parseMember(member) as Member)}
          disabled={!valid}
          type="submit"
        >
          Speichern
        </Button>
      </AccordionActions>
    </>
  );
}

export interface PersonAddListItemProps {
  addMember: (member: Member) => Promise<any>;
}

const emptyMember: EditMember = {
  name: "",
  surname: "",
  street: "",
  zip: NaN,
  city: "",
  birthday: null,
  nationality: null,
  gender: "",
  phone: "",
  school: "",
  swim: false,
  goHomeAlone: false,
  allergies: "",
  medication: "",
  other: "",
};

export function PersonAddListItem(props: PersonAddListItemProps) {
  const [member, setMember] = React.useState(emptyMember);
  const [valid, setValid] = React.useState(false);

  const addMember = () =>
    props
      .addMember(parseMember(member) as Member)
      .then((reset) => reset && abort());

  const onChange = (member: EditMember, valid: boolean) => {
    setMember(member);
    setValid(valid);
  };

  const abort = () => {
    setMember(emptyMember);
    setValid(false);
  };

  return (
    <>
      <AccordionDetails>
        <PersonListItem
          member={member}
          onChange={onChange}
          isRootMember={false}
        />
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button size="small" onClick={abort}>
          Abbrechen
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={addMember}
          disabled={!valid}
          type="submit"
        >
          Hinzufügen
        </Button>
      </AccordionActions>
    </>
  );
}

export interface PersonListItemProps {
  isRootMember: boolean;
  member: EditMember;
  onChange: (member: EditMember, valid: boolean) => void;
}

export function PersonListItem(props: PersonListItemProps) {
  const ignoreUner18Inputs =
    props.isRootMember || isAdult(props.member.birthday);

  const callback = (item: Partial<EditMember>) => {
    const member: EditMember = { ...props.member, ...item };
    props.onChange(member, valid(member));
  };

  // Check for all needed values and if nationality is defined as well as birthday a correct date
  const valid = (member: EditMember) =>
    Boolean(
      member.name &&
        member.surname &&
        member.street &&
        member.zip &&
        member.city &&
        member.birthday &&
        !isNaN(member.birthday.getTime()) &&
        member.nationality &&
        (!props.isRootMember || member.phone)
    );

  // Pass default over 18 data
  React.useEffect(() => {
    const adult = isAdult(props.member.birthday);
    if (adult) callback({ swim: adult, goHomeAlone: adult });
  }, [props.member.birthday]);

  return (
    <Box width="100%">
      <InputWrapper>
        <TextField
          label="Vorname"
          value={props.member.name}
          onChange={(event) => callback({ name: event.target.value })}
          autoComplete="given-name"
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nachname"
          value={props.member.surname}
          onChange={(event) => callback({ surname: event.target.value })}
          autoComplete="family-name"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <InputWrapper>
        <GenderSelector
          gender={props.member.gender || ""}
          setGender={(gender) => callback({ gender })}
          label="Geschlecht"
          fullWidth
          margin="normal"
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd.MM.yyyy"
          label="Geburtstag"
          value={props.member.birthday}
          onChange={(birthday) => callback({ birthday })}
          helperText={undefined}
          autoComplete="bday"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <TextField
        label="Straße"
        value={props.member.street}
        onChange={(event) => callback({ street: event.target.value })}
        autoComplete="street-address"
        required
        fullWidth
        margin="normal"
      />
      <InputWrapper>
        <TextField
          label="Postleitzahl"
          value={isNaN(props.member.zip) ? "" : props.member.zip.toString()}
          onChange={(event) =>
            callback({
              zip: parseInt(event.target.value.replace(/[^0-9]/g, "")),
            })
          }
          autoComplete="postal-code"
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Stadt"
          value={props.member.city}
          onChange={(event) => callback({ city: event.target.value })}
          autoComplete="address-level2"
          required
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <TextField
        label="Telefonnummer"
        value={props.member.phone}
        onChange={(event) => callback({ phone: event.target.value })}
        autoComplete="tel"
        required={props.isRootMember}
        fullWidth
        margin="normal"
      />
      <InputWrapper>
        <NationalitySelector
          nationality={props.member.nationality}
          setNationality={(nationality) => callback({ nationality })}
          fullWidth
          margin="normal"
        />
        {!ignoreUner18Inputs && (
          <TextField
            label="Schule"
            value={props.member.school}
            onChange={(event) => callback({ school: event.target.value })}
            autoComplete="organization"
            fullWidth
            margin="normal"
          />
        )}
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Allergien"
          value={props.member.allergies}
          onChange={(event) => callback({ allergies: event.target.value })}
          multiline
          fullWidth
          margin="normal"
        />
        <TextField
          label="Medikamente"
          value={props.member.medication}
          onChange={(event) => callback({ medication: event.target.value })}
          multiline
          fullWidth
          margin="normal"
        />
      </InputWrapper>
      <TextField
        label="Weitere Anmerkungen"
        value={props.member.other}
        onChange={(event) => callback({ other: event.target.value })}
        multiline
        fullWidth
        margin="normal"
      />
      {!ignoreUner18Inputs && (
        <InputWrapper>
          <FormControlLabel
            control={
              <Switch
                checked={props.member.swim}
                onChange={(event) => callback({ swim: event.target.checked })}
                color="primary"
              />
            }
            label="Kann schwimmen"
          />
          <FormControlLabel
            control={
              <Switch
                checked={props.member.goHomeAlone}
                onChange={(event) =>
                  callback({ goHomeAlone: event.target.checked })
                }
                color="primary"
              />
            }
            label="Kann alleine nach Hause gehen"
          />
        </InputWrapper>
      )}
    </Box>
  );
}
