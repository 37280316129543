import React from "react";

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  TextField,
  Typography,
  List,
  ListItem,
  Popover,
} from "@material-ui/core";
import { ExpandMoreRounded, HelpOutline } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import { SettingsAccordion, SettingsListItemProps } from "./SettingsList";
import { useServer } from "../Server/ServerContext";
import TextEditor from "../TextEditor/TextEditor";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(1),
  },
  helpButton: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1,
  },
}));

export default function GeneralSettings(props: SettingsListItemProps) {
  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();
  const snackbar = useSnackbar();
  const styles = useStyles();

  const [members_per_account, setMaxMembersPerAccound] = React.useState(
    settings?.members_per_account || ""
  );

  const [name, setName] = React.useState(settings?.name || "");
  const [address, setAddress] = React.useState(settings?.address || "");
  const [city, setCity] = React.useState(settings?.city || "");
  const [zip, setZip] = React.useState(settings?.zip || "");
  const [phone, setPhone] = React.useState(settings?.phone || "");
  const additional_info_tickets = React.useRef(
    settings?.additional_info_tickets || ""
  );

  const textEditorRef = React.useRef<TextEditor>(null!);

  const edited = React.useMemo(() => {
    if (members_per_account !== (settings?.members_per_account || ""))
      return true;
    if (name !== (settings?.name || "")) return true;
    if (address !== (settings?.address || "")) return true;
    if (city !== (settings?.city || "")) return true;
    if (zip !== (settings?.zip || "")) return true;
    if (phone !== (settings?.phone || "")) return true;
    if (
      additional_info_tickets.current !==
      (settings?.additional_info_tickets || "")
    )
      return true;
    return false;
  }, [
    members_per_account,
    name,
    address,
    city,
    zip,
    phone,
    additional_info_tickets,
  ]);

  React.useEffect(() => {
    if (!edited) abort();
  }, [settings]);

  const update = () => {
    const content = getTextEditorContent();
    Promise.all([
      settingsActions.set("members_per_account", members_per_account),
      settingsActions.set("name", name),
      settingsActions.set("address", address),
      settingsActions.set("city", city),
      settingsActions.set("zip", zip),
      settingsActions.set("phone", phone),
      settingsActions.set("additional_info_tickets", content),
    ])
      .then(() => {
        setSettings({
          ...settings,
          members_per_account,
          name,
          address,
          city,
          zip,
          phone,
          additional_info_tickets: content,
        });
      })
      .snackbar({
        snackbar,
        success: "Einstellungen erfolgreich aktualisiert",
        admin: true,
      });
  };

  const abort = () => {
    setMaxMembersPerAccound(settings?.members_per_account || "");
    setName(settings?.name || "");
    setAddress(settings?.address || "");
    setCity(settings?.city || "");
    setZip(settings?.zip || "");
    setPhone(settings?.phone || "");
    additional_info_tickets.current = settings?.additional_info_tickets || "";
  };

  const getTextEditorContent = () => {
    return textEditorRef.current?.getContentAsHTML();
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(
    undefined
  );

  return (
    <SettingsAccordion
      abort={abort}
      onClick={update}
      disabled={!members_per_account}
    >
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        Einstellungen
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <TextField
            label="Mitglieder pro Account"
            fullWidth
            onChange={(event) =>
              setMaxMembersPerAccound(event.target.value.replace(/[^0-9]/g, ""))
            }
            value={members_per_account}
            margin="normal"
          />
          <Typography variant="h6" className={styles.marginTop}>
            Ticket Informationen
          </Typography>
          <TextField
            label="Name"
            fullWidth
            onChange={(event) => setName(event.target.value)}
            value={name}
            margin="none"
          />
          <TextField
            label="Adresse"
            fullWidth
            onChange={(event) => setAddress(event.target.value)}
            value={address}
            margin="normal"
          />
          <TextField
            label="Postleitzahl"
            fullWidth
            onChange={(event) => setZip(event.target.value)}
            value={zip}
            margin="normal"
          />
          <TextField
            label="Stadt"
            fullWidth
            onChange={(event) => setCity(event.target.value)}
            value={city}
            margin="normal"
          />
          <TextField
            label="Telefonnummer"
            fullWidth
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            margin="normal"
          />
          <Typography className={styles.marginTop}>
            Tickets: "Bitte beachten" Text
          </Typography>
          <Box
            border={1}
            borderColor="primary.main"
            borderRadius={1}
            width="100%"
            position="relative"
          >
            <TextEditor
              alternativeTextInsertion={additional_info_tickets.current}
              ref={textEditorRef}
            />
            <HelpOutline
              color="primary"
              onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
              onMouseLeave={() => setAnchorEl(undefined)}
              className={styles.helpButton}
            />
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setAnchorEl(undefined)}
          disableRestoreFocus
          style={{ pointerEvents: "none" }}
        >
          <Box margin={1}>
            <Typography>
              Um Details zum Ticket einzufügen können Sie folgende Abkürzungen
              nutzen.
            </Typography>
            <Typography>
              Diese werden beim Erstellen des Tickets durch die tatsächlichen
              Daten ersetzt.
            </Typography>
            <List>
              <ListItem>
                {"{project_link}: Der Link zum gebuchten Projekt"}
              </ListItem>
              <ListItem>{"{agb_link}: Der Link zu den AGBs"}</ListItem>
              <ListItem>{"{image_link}: Der Link zu den Bildrechten"}</ListItem>
              <ListItem>{"{data_privacy_link}: Der Datenschutzlink"}</ListItem>
              <ListItem>
                {"{support_email}: Die eingestellte Support Email"}
              </ListItem>
            </List>
          </Box>
        </Popover>
      </AccordionDetails>
    </SettingsAccordion>
  );
}
