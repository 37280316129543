import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Account, useServer } from "../Server/ServerContext";

import { useSnackbar } from "notistack";

import generator from "generate-password";

import FormComponent from "../FormComponent";

import validator from "validator";

export interface CreateEmployeeUserProps {
  open: boolean;
  onClose: () => void;
  useUsermanagement: [
    Account[],
    React.Dispatch<React.SetStateAction<Account[]>>
  ];
}

const generatePassword = () =>
  generator.generate({
    length: 12,
    numbers: true,
    excludeSimilarCharacters: true,
    strict: true,
  });

export default function CreateEmployeeUser(props: CreateEmployeeUserProps) {
  const { userManagement } = useServer();
  const [usermanagement, setUsermanagement] = props.useUsermanagement;
  const snackbar = useSnackbar();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [access_level, setAccessLevel] = React.useState(1);

  const createEmployee = () => {
    userManagement
      .add({ email, password, access_level } as Account)
      .then(() =>
        setUsermanagement([
          ...usermanagement,
          { email, password, access_level },
        ])
      )
      .then(() => setEmail(""))
      .then(props.onClose)
      .snackbar({
        snackbar,
        success: "Mitarbeiter erfolgreich angelegt",
        admin: true,
      });
  };

  React.useLayoutEffect(() => {
    if (props.open) setPassword(generatePassword());
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionProps={{ onExited: () => setPassword("") }}
      maxWidth="xs"
      fullWidth
      PaperProps={{ component: FormComponent }}
    >
      <DialogTitle>Mitarbeiter anlegen</DialogTitle>
      <DialogContent>
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value.toLocaleLowerCase())}
          label="Email"
          type="email"
          autoComplete="email"
          required
          margin="normal"
          fullWidth
          autoFocus
        />
        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          label="Passwort"
          autoComplete="new-password"
          margin="normal"
          required
          fullWidth
        />
        <TextField
          value={access_level}
          onChange={(event) =>
            setAccessLevel(event.target.value as unknown as number)
          }
          select
          fullWidth
          margin="normal"
          label="Rechte"
        >
          <MenuItem value={1}>Mitarbeiter</MenuItem>
          <MenuItem value={2}>Admin</MenuItem>
          <MenuItem value={3}>Super-Admin</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button
          onClick={createEmployee}
          variant="contained"
          color="primary"
          disabled={!password || !validator.isEmail(email)}
          type="submit"
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
