import React from "react";
import { useHistory } from "react-router-dom";

import { UsermanagementTypes } from "../Usermanagement";
import { StyledToggleButton } from "../Basedata/BaseDataType";

import { ToggleButtonGroup } from "@material-ui/lab";

export interface UsermanagementTypeProps {
  type: UsermanagementTypes;
}

export default function UsermanagementType(props: UsermanagementTypeProps) {
  const history = useHistory();

  const redirect = (target: UsermanagementTypes) =>
    history.replace("/admin/usermanagement/" + target);

  return (
    <ToggleButtonGroup value={props.type} size="large" color="secondary">
      <StyledToggleButton value="employee" onClick={() => redirect("employee")}>
        Mitarbeiter
      </StyledToggleButton>
      <StyledToggleButton value="customer" onClick={() => redirect("customer")}>
        Kundenkonto
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
}
