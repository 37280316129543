import React from "react";

const LocalStorageContext = React.createContext<LocalStorageProps>(undefined!);

export type Items = { [key: string]: string | undefined };
export type UnparsedItems = { [key: string]: Object | Number };

export interface LocalStorageProps {
  storage: Items;
  session: Items;
  setStorage: (items: Items, setStorage?: Storage) => void;
  parsedStorage: (key: string, getStorage?: Storage) => Object | undefined;
  setParsedStorage: (items: UnparsedItems, setStorage?: Storage) => void;
  clear: (storage?: Storage) => void;
}

export enum Storage {
  local,
  session,
}

export default function useLocalStorage() {
  return React.useContext(LocalStorageContext);
}

export function LocalStorageProvider(props: { children: React.ReactNode }) {
  const [storage, setStorage] = React.useState(localStorage);
  const [session, setSession] = React.useState(sessionStorage);

  const set = (items: Items, setStorage: Storage = Storage.local) => {
    for (const item in items) {
      if (items[item] !== undefined)
        (setStorage === Storage.local ? localStorage : sessionStorage).setItem(
          item,
          items[item] as string
        );
      else
        (setStorage === Storage.local
          ? localStorage
          : sessionStorage
        ).removeItem(item);
    }
    get();
  };

  const get = () => {
    setStorage({ ...localStorage });
    setSession({ ...sessionStorage });
  };

  const parsedStorage = (key: string, getStorage: Storage = Storage.local) => {
    try {
      return JSON.parse(
        (getStorage === Storage.local ? storage : session)[key]
      );
    } catch (_) {
      return undefined;
    }
  };

  const setParsedStorage = (
    items: UnparsedItems,
    setStorage: Storage = Storage.local
  ) =>
    set(
      Object.fromEntries(
        Object.entries(items).map(([key, entry]) => [
          key,
          JSON.stringify(entry),
        ])
      ),
      setStorage
    );

  const clear = (storage: Storage = Storage.local) =>
    (storage === Storage.local ? localStorage : sessionStorage).clear();

  React.useEffect(() => {
    window.addEventListener("storage", get);

    return () => {
      window.removeEventListener("storage", get);
    };
  }, []);

  return (
    <LocalStorageContext.Provider
      value={{
        storage,
        session,
        setStorage: set,
        parsedStorage,
        setParsedStorage,
        clear,
      }}
    >
      {props.children}
    </LocalStorageContext.Provider>
  );
}
