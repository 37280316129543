import React from "react";
import { useParams } from "react-router-dom";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import UsermanagementType from "./Usermanagement/UsermanagementType";
import EmployeeUserList from "./Usermanagement/EmployeeUserList";
import CustomerUserList from "./Usermanagement/CustomerUserList";
import { Search, SearchProvider } from "./Search/SearchProvider";
import Body from "./Root/Body";

import { Box } from "@material-ui/core";

import { useServer } from "./Server/ServerContext";

export type UsermanagementTypes = "employee" | "customer";

export interface UsermanagementParams {
  type: UsermanagementTypes;
}

export default function Usermanagement() {
  const { type } = useParams<UsermanagementParams>();

  const useUsermanagement = useServer().useUsermanagement();

  const usermanagementType = () => {
    switch (type) {
      case "employee":
        return <EmployeeUserList useUsermanagement={useUsermanagement} />;
      case "customer":
        return <CustomerUserList useUsermanagement={useUsermanagement} />;
    }
  };

  return (
    <SearchProvider>
      <HeaderBarAdmin title="Kundenkonto- und Rechteverwaltung" />
      <Body>
        <Box display="flex" flexDirection="column" alignItems="center">
          <UsermanagementType type={type} />
        </Box>
        <Search />
        {usermanagementType()}
      </Body>
    </SearchProvider>
  );
}
