import React from "react";

import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

export default function CombineDupplicateParticipants() {
  const history = useHistory();
  const combineDupplicateParticipants = () => {
    history.push("/admin/participantdb/duplicates");
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={combineDupplicateParticipants}
    >
      Dopplungen erkennen und zusammenfügen
    </Button>
  );
}
