import React from "react";

import {
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItem,
  IconButton,
  Divider,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  AddCircleOutlineRounded,
  DeleteForeverRounded,
} from "@material-ui/icons";

import { useSnackbar } from "notistack";

import { Account, useServer } from "../Server/ServerContext";
import Body from "../Root/Body";
import CreateEmployeeUser from "./CreateEmployeeUser";
import { User } from "../../Interfaces";

import useSearch, { TextHighlight } from "../Search/SearchProvider";
import ConfirmDelete, { ConfirmDialogType } from "../ConfirmDialog";

export type AccessLevel = 1 | 2 | 3;

export interface EmployeeUserListProps {
  useUsermanagement: [
    Account[],
    React.Dispatch<React.SetStateAction<Account[]>>
  ];
}

export default function EmployeeUserList(props: EmployeeUserListProps) {
  const { userManagement, currentUser } = useServer();
  const [usermanagement, setUsermanagement] = props.useUsermanagement;
  const { search, isSearched } = useSearch();
  const snackbar = useSnackbar();

  const [createEmployee, setCreateEmployee] = React.useState(false);

  const [requestDelete, setRequestDelete] = React.useState<Account | null>(
    null
  );

  const filterEmployee = (user: Account) =>
    user.access_level > 0 && isSearched([user.email, user.access_level]);

  const updateAccessLevel = (user: Account, access_level: AccessLevel) =>
    userManagement
      .update(user.email, access_level)
      .then(() =>
        setUsermanagement(
          usermanagement.map((current) =>
            current.email !== user.email
              ? current
              : { ...current, access_level }
          )
        )
      )
      .snackbar({
        snackbar,
        success: "Adminrechte erfolgreich aktualisiert",
        admin: true,
      });

  const removeUser = (user: Account) =>
    userManagement
      .delete(user.email)
      .then(() =>
        setUsermanagement(
          usermanagement.filter((current) => current.email !== user.email)
        )
      )
      .snackbar({
        snackbar,
        success: "Mitarbeiter erfolgreich entfernt",
        admin: true,
      });

  return (
    <Body>
      <Button
        variant="contained"
        fullWidth
        startIcon={<AddCircleOutlineRounded />}
        onClick={() => setCreateEmployee(true)}
      >
        Mitarbeiter anlegen
      </Button>
      <CreateEmployeeUser
        open={createEmployee}
        onClose={() => setCreateEmployee(false)}
        useUsermanagement={props.useUsermanagement}
      />
      <List>
        <ConfirmDelete
          value={requestDelete}
          close={setRequestDelete}
          textRender={(value: Account) => value.email}
          onSuccess={removeUser}
          type={ConfirmDialogType.delete}
        />

        {usermanagement.filter(filterEmployee).map((user) => (
          <React.Fragment key={user.email}>
            <ListItem button>
              <ListItemText
                primary={
                  <TextHighlight search={search}>{user.email}</TextHighlight>
                }
              />
              <ListItemSecondaryAction>
                <Select
                  value={user.access_level}
                  onChange={(event) =>
                    updateAccessLevel(user, event.target.value as AccessLevel)
                  }
                  disabled={
                    (currentUser as User).access_level < user.access_level ||
                    (currentUser as User).email === user.email
                  }
                >
                  <MenuItem value={1}>Mitarbeiter</MenuItem>
                  <MenuItem value={2}>Admin</MenuItem>
                  <MenuItem
                    value={3}
                    disabled={(currentUser as User).access_level !== 3}
                  >
                    Super-Admin
                  </MenuItem>
                </Select>
                <IconButton
                  edge="end"
                  onClick={() => setRequestDelete(user)}
                  disabled={
                    (currentUser as User).access_level < user.access_level ||
                    (currentUser as User).email === user.email
                  }
                >
                  <DeleteForeverRounded />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Body>
  );
}
