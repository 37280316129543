import {
  DatetimeRepeat,
  DatetimeRepeatMonth,
  DatetimeRepeatMonthTiming,
  DatetimeRepeatMonthType,
  DatetimeRepeatWeek,
} from "../Components/Server/ServerContext";

const weekdaysString = [
  "Montag:\t\t",
  "Dienstag:\t",
  "Mittwoch:\t",
  "Donnerstag:\t",
  "Freitag:\t\t",
  "Samstag:\t\t",
  "Sonntag:\t\t",
];

const weekdaysMonthString = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];

export const parseProjectDateTime = (datetime: DatetimeRepeat): string => {
  // remove pointer
  datetime = { ...datetime };
  datetime.daily = { ...datetime.daily };
  datetime.month = datetime.month.map((obj) => ({ ...obj }));
  datetime.week = datetime.week.map((obj) => ({ ...obj }));

  const time = (from: Date | null, to: Date | null) =>
    `${simpleTime(from)} bis ${simpleTime(to)}`;

  const simpleTime = (time: Date | null) =>
    `${timeToString(time as Date) || "##:##"} Uhr`;

  const monthDay = (type: DatetimeRepeatMonthType) => {
    if (!type) return "Monatstag";
    else return weekdaysMonthString[type - 1];
  };

  const monthDaySingle = (type: DatetimeRepeatMonthType) => {
    if (!type) return "Tag im Monat";
    else return weekdaysMonthString[type - 1] + " im Monat";
  };

  const monthBackDaySingle = (type: DatetimeRepeatMonthType) => {
    if (!type) return "Tag vorm Monatsende";
    else return weekdaysMonthString[type - 1] + " vorm Monatsende";
  };

  const monthTiming = (month: DatetimeRepeatMonth) => {
    switch (month.timing) {
      case DatetimeRepeatMonthTiming.AfterStart:
        return `Am ${month.day || "#"}. ${monthDay(month.type)} von ${time(
          month.from,
          month.to
        )}`;
      case DatetimeRepeatMonthTiming.BeforeEnd:
        return `Am ${month.day || "#"}. ${monthDay(
          month.type
        )} vor dem Monatsende von ${time(month.from, month.to)}`;
    }
  };

  const monthTimingSingle = (month: DatetimeRepeatMonth) => {
    switch (month.timing) {
      case DatetimeRepeatMonthTiming.AfterStart:
        return `am ${month.day || "#"}. ${monthDaySingle(
          month.type
        )} von ${time(month.from, month.to)}`;
      case DatetimeRepeatMonthTiming.BeforeEnd:
        return `am ${month.day || "#"}. ${monthBackDaySingle(
          month.type
        )} von ${time(month.from, month.to)}`;
    }
  };

  const months = () => {
    const sortedMonths = datetime.month.sort((a, b) => a.day - b.day);

    return sortedMonths.map(monthTiming).join("\n");
  };

  const weekday = (
    weekday: {
      start: number;
      text: string;
    }[],
    index: number
  ) => {
    if (weekday.length === 0) return "";
    return `${weekdaysString[index]} ${weekday
      .map((time) => `${time.text}`)
      .join(", ")}\n`;
  };

  const weekdays = () => {
    const sortedWeekdays = [[], [], [], [], [], [], []] as unknown as {
      start: number;
      text: string;
    }[][];

    // Map time strings and start index for sorting
    datetime.week.forEach((week) => {
      week.weekdays.forEach((weekday) => {
        sortedWeekdays[weekday].push({
          start:
            (week.from?.getHours() || 0) * 100 + (week.from?.getMinutes() || 0),
          text: time(week.from, week.to),
        });
      });
    });

    return sortedWeekdays
      .map((weekday) => weekday.sort((a, b) => a.start - b.start))
      .map(weekday)
      .filter(Boolean)
      .join("");
  };

  const weekdaySingle = (week: DatetimeRepeatWeek) => {
    const sortedWeekdays = week.weekdays
      .sort()
      .map((weekday) => weekdaysMonthString[weekday]);

    return `${
      sortedWeekdays.length > 1
        ? sortedWeekdays.slice(0, -1).join(", ") +
          " und " +
          sortedWeekdays.slice(-1)[0]
        : sortedWeekdays[0]
    } von ${time(week.from, week.to)}`;
  };

  const repeat = () => {
    if (datetime.week.length) {
      if (datetime.repeat > 1) return `alle ${datetime.repeat} Wochen `;
      else return "jede Woche ";
    } else return "";
  };

  const repeatSingle = () => {
    if (datetime.repeat > 1) return `alle ${datetime.repeat} Wochen`;
    else return "jeden";
  };

  const daily = () =>
    datetime.daily.from && datetime.daily.to
      ? `Täglich von ${time(datetime.daily.from, datetime.daily.to)}\n`
      : "";

  const duration = () =>
    `Ab dem ${
      dateToGermanDateString(datetime.begin as Date) || "##.##.####"
    } findet ${repeat()}bis zum ${
      dateToGermanDateString(datetime.end as Date) || "##.##.####"
    } das Projekt statt\n`;

  const simpleDatetime = () => {
    // Check if same day
    if (
      datetime.begin?.getDate() === datetime.end?.getDate() &&
      datetime.begin?.getMonth() === datetime.end?.getMonth() &&
      datetime.begin?.getFullYear() === datetime.end?.getFullYear()
    )
      return `am ${
        dateToGermanDateString(datetime.begin as Date) || "##.##.####"
      } von ${time(datetime.begin, datetime.end)}`;
    else
      return `in dem Zeitraum vom ${
        dateToGermanDateString(datetime.begin as Date) || "##.##.####"
      }, ${simpleTime(datetime.begin)} bis ${
        dateToGermanDateString(datetime.end as Date) || "##.##.####"
      }, ${simpleTime(datetime.end)}`;
  };

  const advancedDatetime = () => {
    switch (true) {
      case !datetime.daily.from &&
        !datetime.daily.to &&
        !datetime.week.length &&
        datetime.month.length === 1:
        return `Das Projekt findet statt in dem Zeitraum ${monthTimingSingle(
          datetime.month[0]
        )}`;
      case !datetime.daily.from &&
        !datetime.daily.to &&
        !datetime.month.length &&
        datetime.week.length === 1:
        return `Das Projekt findet statt in dem Zeitraum ${repeatSingle()} ${weekdaySingle(
          datetime.week[0]
        )}`;
      case !datetime.week.length && !datetime.week.length:
        return `Das Projekt findet statt in dem Zeitraum jeden Tag von ${time(
          datetime.daily.from,
          datetime.daily.to
        )}`;
      default:
        return `${duration()}${daily()}${weekdays()}${months()}`;
    }
  };

  return isAdvancedDatetime(datetime)
    ? advancedDatetime()
    : `Das Projekt findet statt ${simpleDatetime()}`;
};

export const datetimeValid = (datetime: DatetimeRepeat) => {
  // Check if some values are not valid
  if (!datetime.begin || isNaN(datetime.begin.getTime())) return false;
  if (!datetime.end || isNaN(datetime.end.getTime())) return false;
  if (datetime.daily.from && isNaN(datetime.daily.from.getTime())) return false;
  if (datetime.daily.to && isNaN(datetime.daily.to.getTime())) return false;

  return true;
};

// get days of project
export const getProjectTimes = (datetime: DatetimeRepeat): Date[] => {
  if (!datetimeValid(datetime)) return [];

  const { repeat, daily, week, month } = datetime;
  // norm begin and end time
  const begin = new Date(datetime.begin as Date);
  const end = new Date(datetime.end as Date);
  begin?.setHours(0);
  begin?.setMinutes(0);
  begin?.setSeconds(0);
  end?.setHours(23);
  end?.setMinutes(59);
  end?.setSeconds(59);

  const msPerDay = 24 * 60 * 60 * 1000;

  // get passed time from date day
  const getPassedTimeToday = (date: Date) => {
    return new Date(
      1970,
      0,
      1,
      1 + date.getHours(),
      date.getMinutes()
    ).getTime();
  };

  // difference in days between two dates
  const dayDifference = (date1: Date, date2: Date): number => {
    const difTime = Math.abs(date1.getTime() - date2.getTime());
    return Math.ceil(difTime / msPerDay);
  };

  const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };

  // day from month type
  const getMonthDay = (
    year: number,
    month: number,
    day: number,
    type: DatetimeRepeatMonthType,
    timing: DatetimeRepeatMonthTiming
  ): Date => {
    if (type === DatetimeRepeatMonthType.SpecificDay) {
      let daysInMonth = getDaysInMonth(year, month);

      // invalid data
      if (day > daysInMonth) return new Date(NaN);

      if (timing === DatetimeRepeatMonthTiming.AfterStart)
        return new Date(year, month, day);
      else return new Date(year, month, daysInMonth - day + 1);
    }

    if (timing === DatetimeRepeatMonthTiming.AfterStart) {
      const firstDay = new Date(year, month);
      // set firstDay to first sunday if searched weekday is before the weekday of the first day in the month
      if (type < firstDay.getDay()) firstDay.setDate(7 + 1 - firstDay.getDay());

      firstDay.setDate(
        firstDay.getDate() - firstDay.getDay() + type + 7 * (day - 1)
      );

      return firstDay;
    } else {
      const lastDay = new Date(year, month + 1, 0);
      // set lastDay to last sunday if searched weekday is after the weekday of the last day in the month
      if (type > lastDay.getDay())
        lastDay.setDate(lastDay.getDate() - lastDay.getDay() + 1 - 7);

      lastDay.setDate(
        lastDay.getDate() - lastDay.getDay() + type - 7 * (day - 1)
      );

      return lastDay;
    }
  };

  // only once
  if (!isAdvancedDatetime(datetime)) return [begin as Date];

  // if only weekly and/or monthly put all days
  // conains all project days stringified
  let days = [] as Date[];

  // daily
  if (daily.from && daily.to) {
    const dayCount = dayDifference(begin as Date, end as Date);

    for (let i = 0; i < dayCount; i++)
      days.push(
        new Date(
          (begin as Date).getTime() +
            i * msPerDay +
            getPassedTimeToday(daily.from)
        )
      );
  }

  // has weekly repetition
  if (week.length > 0) {
    let current = new Date(
      (begin as Date).getTime() - getDay(begin as Date) * msPerDay
    );
    current.setHours(0);

    // loop through weekdays + go to next week until end is reached
    while (current.getTime() < (end as Date).getTime()) {
      week.forEach((week) => {
        week.weekdays.forEach((weekday) => {
          // edge case for first week
          if (weekday >= getDay(current)) {
            let newDate = new Date(current);
            newDate.setDate(newDate.getDate() + (weekday - getDay(current)));
            newDate.setHours(week.from?.getHours() || 0);
            newDate.setMinutes(week.from?.getMinutes() || 0);
            newDate.setSeconds(week.from?.getSeconds() || 0);
            newDate.setMilliseconds(week.from?.getMilliseconds() || 0);
            if (
              newDate.getTime() <= end.getTime() &&
              newDate.getTime() >= begin.getTime()
            )
              days.push(newDate);
          }
        });
      });

      // set current to monday of next viable week
      // prevention for zeitumstellung
      current.setHours(12);
      current = new Date(current.getTime() + 7 * (repeat || 1) * msPerDay);
      current.setHours(0);
    }
  }

  // has monthly repetition
  month.forEach((month) => {
    let currentMonth = new Date(begin as Date);
    currentMonth.setDate(1);
    let current = getMonthDay(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      month.day,
      month.type,
      month.timing
    );

    while (current.getTime() < end.getTime()) {
      if (current.getTime() >= begin.getTime())
        days.push(
          new Date(current.getTime() + getPassedTimeToday(month.from as Date))
        );

      // add one month
      if (currentMonth.getMonth() === 11) {
        currentMonth.setMonth(0);
        currentMonth.setFullYear(currentMonth.getFullYear() + 1);
      } else currentMonth.setMonth(currentMonth.getMonth() + 1);
      current = getMonthDay(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        month.day,
        month.type,
        month.timing
      );
    }
  });

  return days
    .map((date) => date.getTime())
    .sort()
    .map((date) => new Date(date));
};

export const getProjectDays = (datetime: DatetimeRepeat): Date[] => {
  return getProjectTimes(datetime).filter(
    (date, i, arr) =>
      !arr.some(
        (date1, i1) => i1 > i && date1.toDateString() === date.toDateString()
      )
  );
};

// get number of project meetings
export const getTotalProjectMeetings = (datetime: DatetimeRepeat): number =>
  getProjectTimes(datetime).length;

export const getTotalProjectDays = (datetime: DatetimeRepeat): number =>
  getProjectDays(datetime).length;

export const startDate = (datetime: DatetimeRepeat): Date | undefined =>
  isAdvancedDatetime(datetime)
    ? getProjectTimes(datetime)[0]
    : (datetime.begin as Date | undefined);

export const endDate = (datetime: DatetimeRepeat): Date | undefined =>
  isAdvancedDatetime(datetime)
    ? getProjectTimes(datetime).splice(-1, 1)[0]
    : (datetime.end as Date | undefined);

export const millisUntilNextMeeting = (datetime: DatetimeRepeat) => {
  const nextMeeting = nextMeetingDate(datetime);
  return nextMeeting ? nextMeeting.getTime() - new Date().getTime() : Infinity;
};

export const nextMeetingDate = (datetime: DatetimeRepeat) => {
  const today = new Date();
  const projectTimes = getProjectTimes(datetime);
  return isAdvancedDatetime(datetime)
    ? projectTimes.find((day) => day.getTime() > today.getTime()) ||
        projectTimes.pop()
    : projectTimes[0];
};

// false if only one timeslot, true if more complex datetime
export const isAdvancedDatetime = (
  datetime: DatetimeRepeat | undefined
): boolean => {
  if (!datetime) return false;
  if (datetime.week.length > 0) return true;
  if (datetime.month.length > 0) return true;
  if (datetime.daily.from && datetime.daily.to) return true;
  return false;
};

export const getDay = (date: Date): number => (date.getDay() + 6) % 7;

function dateToGermanDateString(date: Date) {
  date = new Date(date);
  return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.${
    date.getMonth() < 9 ? "0" : ""
  }${date.getMonth() + 1}.${date.getFullYear()}`;
}

function timeToString(time: Date) {
  time = new Date(time);
  return `${time.getHours() < 10 ? "0" : ""}${time.getHours()}:${
    time.getMinutes() < 10 ? "0" : ""
  }${time.getMinutes()}`;
}

function datetimeToGermanDateTimeString(date: Date) {
  return `${dateToGermanDateString(date)} ${timeToString(date)}`;
}
