import React from "react";

import { Button, DialogProps } from "@material-ui/core";
import { FindInPageRounded } from "@material-ui/icons";

import FileSelector, { FileSelectorProps } from "@idot-digital/file-selector";
import { useServer } from "./Server/ServerContext";
import config from "../config";

export default function FileSelectorDialog(
  props: Omit<FileSelectorProps, "links"> &
    Omit<DialogProps, "open"> & { children: string; fullWidth?: boolean }
) {
  const { fullWidth, children, ...rest } = props;

  const [fileSelectorOpen, setFileSelectorOpen] = React.useState(false);

  const settings = {
    credentials: "omit",
    headers: {
      Authorization: `Bearer ${useServer().jwt}`,
      origin: config.corsURL,
    },
    mode: "cors",
  } as Omit<RequestInit, "method">;

  return (
    <>
      <FileSelector
        links={{
          get: {
            url: config.fileAPIPath,
            method: "GET",
            settings,
          },
          rename: {
            url: config.fileAPIPath,
            method: "PUT",
            settings,
          },
          delete: {
            url: config.fileAPIPath,
            method: "DELETE",
            settings,
          },
          upload: {
            url: config.fileAPIPath,
            method: "POST",
            settings,
          },
          root: config.fileRootPath,
        }}
        open={fileSelectorOpen}
        onClose={() => setFileSelectorOpen(false)}
        {...rest}
        callbackDone={(files) => {
          setFileSelectorOpen(false);
          props.callbackDone?.(files);
        }}
      />
      <Button
        onClick={() => setFileSelectorOpen(true)}
        endIcon={<FindInPageRounded />}
        color="primary"
        variant="contained"
        fullWidth={fullWidth}
      >
        {children}
      </Button>
    </>
  );
}
