import React from "react";

import FilterSidebar, { FilterProvider } from "./Root/FilterSidebar";
import HeaderBarPublic, { HeaderIconButton } from "./Root/HeaderBar";
import Impressum from "./Root/Impressum";
import ProjectPreview from "./Root/ProjectPreview";

import {
  Tooltip,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "overlay",
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
    boxSizing: "border-box",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

export default function Root() {
  const styles = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [filterOpen, setFilterOpen] = React.useState(!mobile);

  React.useLayoutEffect(() => setFilterOpen(!mobile), [mobile]);

  return (
    <FilterProvider>
      <HeaderBarPublic
        title="Online Anmeldung"
        leftAction={
          <Tooltip title={`Filter ${filterOpen ? "schließen" : "öffnen"}`}>
            <HeaderIconButton onClick={() => setFilterOpen(!filterOpen)}>
              <MenuRounded />
            </HeaderIconButton>
          </Tooltip>
        }
      />
      <Box className={styles.root}>
        <FilterSidebar in={filterOpen} />
        <ProjectPreview />
      </Box>
      <Impressum />
    </FilterProvider>
  );
}
