import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { Member } from "../Server/ServerContext";
import ShowParticipantDetails from "./ShowParticipantDetails";

interface ShowDuplicateParticipantProps {
  participant: Member;
  onChecked: (checked: boolean) => void;
  checked: boolean;
}

export default function ShowDuplicateParticipant(
  props: ShowDuplicateParticipantProps
) {
  return (
    <Box display="flex" width="100%" alignItems="flex-start">
      <Checkbox
        edge="start"
        tabIndex={-1}
        disableRipple
        color="primary"
        checked={props.checked}
        onChange={(_, checked) => {
          props.onChecked(checked);
        }}
      />
      <Box flexGrow="1">
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{`${props.participant.street}, ${props.participant.zip} ${props.participant.city}, ${props.participant.nationality}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <ShowParticipantDetails participant={props.participant} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
