import React from "react";

import { Box, Divider, List, ListItem, ListItemText } from "@material-ui/core";

import {
  DatetimeRepeat,
  ProjectOverviewObject,
  ProjectState,
  useServer,
} from "../Server/ServerContext";

import { useFilter } from "../Root/FilterSidebar";
import { useHistory } from "react-router-dom";

import { TextHighlight } from "../Search/SearchProvider";
import { sortProjects } from "../Root/ProjectPreview";
import {
  endDate,
  isAdvancedDatetime,
  nextMeetingDate,
  startDate,
} from "../../Functions/Datetime";

export default function ProjectList() {
  const history = useHistory();
  const [listedProjects] = useServer().useAdminListedProjects();
  const { search, filter } = useFilter();

  const projectState = (state: ProjectState) => {
    switch (state) {
      case ProjectState.Free:
        return "Freie Plätze";
      case ProjectState.Full:
        return "Voll";
      case ProjectState.WaitingList:
        return "Warteliste";
    }
  };

  const projectDescription = (
    bookings: number,
    maximumParticipants: number,
    waitingListSize: number,
    bookingRequired: boolean
  ) => {
    return bookingRequired
      ? `${bookings} von ${maximumParticipants}`
      : (waitingListSize || 0) + " Teilnehmer";
  };

  const redirectProject = (project: ProjectOverviewObject) =>
    history.push(`/admin/project/${project.id}`);

  const getTimeInformation = (
    datetime: DatetimeRepeat,
    publicationPeriodStart: Date,
    publicationPeriodEnd: Date
  ) => {
    const nextMeeting = nextMeetingDate(datetime);
    const start =
      startDate(datetime) || datetime.begin || publicationPeriodStart;
    const end = endDate(datetime) || datetime.end || publicationPeriodEnd;

    return isAdvancedDatetime(datetime) && nextMeeting
      ? `Nächster Termin am ${nextMeeting.toLocaleString("de-DE", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}`
      : `${start.toLocaleString("de-DE", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })} - ${end.toLocaleString("de-DE", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}`;
  };

  return (
    <Box width="100%" p={2}>
      <List>
        {listedProjects
          .filter(filter)
          .sort(sortProjects)
          .map((project) => (
            <React.Fragment key={project.id}>
              <ListItem button onClick={() => redirectProject(project)}>
                <ListItemText
                  primary={
                    <TextHighlight search={search}>
                      {project.name}
                    </TextHighlight>
                  }
                  secondary={
                    <TextHighlight search={search}>
                      {project.subtitle}
                    </TextHighlight>
                  }
                />
                <Box>
                  <ListItemText
                    primaryTypographyProps={{ align: "right" }}
                    primary={projectDescription(
                      project.bookings,
                      project.maximumParticipants,
                      project.waitingListSize,
                      project.bookingRequired
                    )}
                    secondary={getTimeInformation(
                      project.datetime,
                      new Date(project.publicationPeriodStart),
                      new Date(project.publicationPeriodEnd)
                    )}
                  />
                </Box>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
      </List>
    </Box>
  );
}
