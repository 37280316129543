import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowBackRounded } from "@material-ui/icons";

import useLocalStorage from "../LocalStorage";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";

export default function BackToSummary() {
  const history = useHistory();
  const { setStorage } = useLocalStorage();

  const backToSummary = () => {
    history.push("/summary");
    setStorage({ imageRights: undefined });
  };

  return (
    <SubHeaderIconButton onClick={backToSummary} icon={<ArrowBackRounded />}>
      Zurück zur Zusammenfassung
    </SubHeaderIconButton>
  );
}
