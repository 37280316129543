import React from "react";
import { useHistory } from "react-router-dom";
import { updateQuery } from "../../Functions/QueryParams";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";

import { Member, useServer } from "../Server/ServerContext";
import { getAge } from "../../Functions/Age";
import { isGenderAllowed } from "../../Functions/Gender";

export interface PersonSelectListProps {
  selectedMembers: string[]; // ids
  setSelectedMembers: (selectedMembers: string[]) => void;
  id: string;
}

export default function PersonSelectList(props: PersonSelectListProps) {
  const history = useHistory();
  const project = useServer().useProject(props.id);
  const [completeMembers] = useServer().useCompleteMembers();

  const toggleMember = (checked: boolean, id: string) => {
    if (!checked)
      props.setSelectedMembers(
        props.selectedMembers.filter((selected) => selected !== id)
      );
    else props.setSelectedMembers([...props.selectedMembers, id]);
  };

  const notAllowedToProjectReasons = (member: Member): string[] => {
    const reasons: string[] = [];

    if (
      project &&
      member.gender &&
      !isGenderAllowed(project?.allowedGenders, member.gender)
    )
      reasons.push(
        "das Projekt befasst sich ausschließlich mit einem anderen Geschlecht"
      );
    if (
      (project?.minimumAge || 0) >
        getAge(new Date(member.birthday), project?.datetime.begin) ||
      (project?.maximumAge || Infinity) <
        getAge(new Date(member.birthday), project?.datetime.begin)
    )
      reasons.push("das Projekt ist für eine andere Altersgruppe ausgelegt");

    if (
      project?.zipFilter.length &&
      !project.zipFilter
        .split(",")
        .some((zip) => member.zip.toString().startsWith(zip))
    )
      reasons.push("die Postleitzahl ist nicht im Projekt erlaubt");

    return reasons;
  };

  return (
    <>
      {completeMembers.map((member, index) => {
        const notAllowedReasons = notAllowedToProjectReasons(member);
        const notAllowed = Boolean(notAllowedReasons.length);

        return (
          <Accordion
            key={index + (member?.id || "")}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    checked={props.selectedMembers.includes(
                      member.id as string
                    )}
                    color="primary"
                    onClick={(event) =>
                      toggleMember(
                        (event.target as HTMLInputElement).checked,
                        member.id as string
                      )
                    }
                    disabled={notAllowed}
                  />
                }
                label={
                  <Typography>{`${member.name} ${member.surname}`}</Typography>
                }
              />
            </AccordionSummary>
            {!notAllowed ? (
              <AccordionDetails>ist für das Projekt geeignet.</AccordionDetails>
            ) : (
              <AccordionDetails>
                <Box>
                  <Typography>
                    ist nicht für das Projekt geeignet. Grund dafür ist
                  </Typography>

                  <Box component="ul">
                    {notAllowedReasons.map((reason) => (
                      <Typography component="li" key={reason}>
                        {reason}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}

      <Accordion key="addPerson">
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          <Link
            onClick={() => {
              history.push("/account");
              updateQuery({ addNew: true });
            }}
          >
            Person hinzufügen
          </Link>
        </AccordionSummary>
      </Accordion>
    </>
  );
}
