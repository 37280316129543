import React from "react";
import { useHistory } from "react-router-dom";

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { DeleteForeverRounded } from "@material-ui/icons";

import { useSnackbar } from "notistack";

import { PersonOverviewObject, useServer } from "../Server/ServerContext";
import useSearch, { TextHighlight } from "../Search/SearchProvider";
import { ParticipantDBProps } from "../ParticipantDB";
import ConfirmDelete, { ConfirmDialogType } from "../ConfirmDialog";

export default function ParticipantList(props: ParticipantDBProps) {
  const [participants, setParticipants] = props.useParticipant;
  const { search, isSearched } = useSearch();
  const { peopleActions } = useServer();
  const history = useHistory();
  const snackbar = useSnackbar();

  const [requestDelete, setRequestDelete] =
    React.useState<PersonOverviewObject | null>(null);

  const participantSearch = (participant: PersonOverviewObject) => {
    const { birthday, id, street, city, ...stringValues } = participant;
    return isSearched([
      stringValues,
      new Date(birthday).toLocaleString("de-DE"),
      `${participant.street}, ${participant.city}`,
    ]);
  };

  const deleteParticipant = (participant: PersonOverviewObject) =>
    peopleActions
      .delete(participant.id)
      .then(() =>
        setParticipants(
          participants.filter((filtered) => participant.id !== filtered.id)
        )
      )
      .snackbar({
        snackbar,
        success: "Kundenkonto erfolgreich gelöscht",
        admin: true,
      });

  const redirectParticipant = (participant: PersonOverviewObject) =>
    history.push(`/admin/participantinfo/${participant.id}`);

  return (
    <List>
      <ConfirmDelete
        value={requestDelete}
        close={setRequestDelete}
        textRender={(value: PersonOverviewObject) =>
          `${value.name} ${value.surname}`
        }
        onSuccess={deleteParticipant}
        type={ConfirmDialogType.delete}
      />

      {participants.filter(participantSearch).map((participant) => (
        <React.Fragment key={participant.id}>
          <ListItem button onClick={() => redirectParticipant(participant)}>
            <ListItemText
              primary={
                <TextHighlight
                  search={search}
                >{`${participant.name} ${participant.surname}`}</TextHighlight>
              }
              secondary={
                <TextHighlight
                  search={search}
                >{`${participant.street}, ${participant.city}`}</TextHighlight>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => setRequestDelete(participant)}
              >
                <DeleteForeverRounded />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
