import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import FormComponent from "../FormComponent";

export interface ProjectDummyParticipantsEditorProps {
  bookings: number;
  addBookings: (count: number) => void;
}

export default function ProjectDummyParticipantsEditor(
  props: ProjectDummyParticipantsEditorProps
) {
  const [addBookings, setAddBookings] = React.useState(false);
  const [count, setCount] = React.useState("");

  return (
    <>
      <Box display="flex" justifyContent="space-evenly" alignItems="center">
        <Typography>Anzahl an Teilnehmern: {props.bookings}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddBookings(true)}
        >
          Teilnehmer hinzufügen
        </Button>
      </Box>

      <Dialog
        open={addBookings}
        onClose={() => setAddBookings(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{ component: FormComponent }}
        TransitionProps={{ onExited: () => setCount("") }}
      >
        <DialogTitle>Teilnehmern hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label="Anzahl an Teilnehmern"
            value={count}
            onChange={(event) =>
              setCount(event.target.value.replace(/[^0-9]/g, ""))
            }
            margin="normal"
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddBookings(false)}>Abbrechen</Button>
          <Button
            onClick={() => {
              props.addBookings(parseInt(count));
              setAddBookings(false);
            }}
            variant="contained"
            color="primary"
            disabled={!count}
            type="submit"
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
