import React from "react";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";

import NumberFormat from "react-number-format";

import {
  AllowedGenders,
  Organisation,
  ProjectEditObject,
  ProjectState,
  useServer,
} from "../Server/ServerContext";
import { defaultSearchSettings } from "../Root/FilterSidebar";
import InputWrapper from "../InputWrapper";
import FileSelectorDialog from "../FileSelectorDialog";

import config from "../../config";
import CreateTemplateButton from "./CreateTemplateButton";
import ConfirmDialog, { ConfirmDialogType } from "../ConfirmDialog";
import { Send } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: theme.spacing(2),
    flex: "1 0",
    display: "flex",
    flexDirection: "column",
  },
  box: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  boxWithoutMargin: {
    flex: `1 0 ${theme.spacing(2)}px`,
    maxWidth: "33.33%",
    display: "flex",
    flexDirection: "column",
  },
  imagePreview: {
    maxHeight: 200,
    minHeight: 200,
    width: "100%",
    minWidth: "100%",
    objectFit: "contain",
    marginTop: theme.spacing(1),
  },
  bottom: {
    marginTop: "auto",
  },
  zipWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
  },
}));

export interface EditProject
  extends Omit<
    ProjectEditObject,
    | "publicationPeriodStart"
    | "publicationPeriodEnd"
    | "bookingPeriodStart"
    | "bookingPeriodEnd"
    | "discountRate"
    | "fees"
    | "maximumAge"
    | "minimumAge"
    | "maximumParticipants"
    | "mimimumParticipants"
    | "waitingListSize"
  > {
  publicationPeriodStart: Date | null;
  publicationPeriodEnd: Date | null;
  bookingPeriodStart: Date | null;
  bookingPeriodEnd: Date | null;
  discountRate: string | number;
  fees: string | number;
  maximumAge: string | number;
  minimumAge: string | number;
  maximumParticipants: string | number;
  mimimumParticipants: string | number;
  waitingListSize: string | number;
}

export interface ProjectEditorProps {
  defaultProject: EditProject;
  onApply?: (project: EditProject) => void;
  onChange?: (project: EditProject, valid: boolean) => void;
  copyProject?: () => void;
  deleteProject?: () => void;
  editBooking?: boolean;
  id?: string;
  repeat?: boolean;
}

export default function ProjectEditor(props: ProjectEditorProps) {
  const styles = useStyles();

  const [organisations] = useServer().useOrganisations();
  const locations = useServer()
    .useLocations()[0]
    .map((location) => location.name);
  const [employees] = useServer().useEmployees();
  const categories = useServer().useCategories();

  const [project, setProject] = React.useState(props.defaultProject);
  const [edited, setEdited] = React.useState(false);

  const [requestDelete, setRequestDelete] = React.useState<string | null>(null);
  const [requestCopyProject, setRequestCopyProject] = React.useState<
    boolean | null
  >(false);

  const [zipFilterInput, setZipFilterInput] = React.useState("");

  React.useEffect(
    () =>
      setEdited(
        JSON.stringify({
          ...project,
          maximumParticipants:
            Number(project.maximumParticipants) -
            (props.defaultProject.waitinglistIncludedInMax
              ? Number(props.defaultProject.waitingListSize)
              : 0),
        }) !== JSON.stringify(props.defaultProject)
      ),
    [project, props.defaultProject]
  );

  React.useEffect(() => {
    if (!edited)
      setProject({
        ...props.defaultProject,
        maximumParticipants:
          Number(props.defaultProject.maximumParticipants) +
          (props.defaultProject.waitinglistIncludedInMax
            ? Number(props.defaultProject.waitingListSize)
            : 0),
        bookingPeriodStart:
          props.defaultProject.bookingPeriodStart ??
          props.defaultProject.publicationPeriodStart,
        bookingPeriodEnd:
          props.defaultProject.bookingPeriodEnd ??
          props.defaultProject.publicationPeriodEnd,
      });
  }, [props.defaultProject]);

  const projectState = (state: ProjectState | undefined) => {
    switch (state) {
      case ProjectState.Free:
        return "Freie Plätze";
      case ProjectState.WaitingList:
        return "Warteliste";
      case ProjectState.Full:
        return "Keine freien Plätze";
      default:
        return "";
    }
  };

  const abort = () => callback(props.defaultProject as EditProject);

  const callback = (newValues: Partial<EditProject>) => {
    if (
      "publicationPeriodStart" in newValues &&
      newValues.publicationPeriodStart &&
      !isNaN(newValues.publicationPeriodStart?.getTime()) &&
      (!project.bookingPeriodStart ||
        project.bookingPeriodStart?.getTime() ===
          project.publicationPeriodStart?.getTime())
    ) {
      newValues.bookingPeriodStart = newValues.publicationPeriodStart;
    }
    if (
      "publicationPeriodEnd" in newValues &&
      newValues.publicationPeriodEnd &&
      !isNaN(newValues.publicationPeriodEnd?.getTime()) &&
      (!project.bookingPeriodEnd ||
        project.bookingPeriodEnd?.getTime() ===
          project.publicationPeriodEnd?.getTime())
    ) {
      newValues.bookingPeriodEnd = newValues.publicationPeriodEnd;
    }

    const newProject = { ...(project as EditProject), ...newValues };
    setProject(newProject);
    props.onChange?.(newProject, !getDisabled(newProject));
  };

  const updateProject = () => {
    props.onApply?.({
      ...(project as EditProject),
      maximumParticipants:
        Number(project.maximumParticipants) -
        (project.waitinglistIncludedInMax
          ? Number(project.waitingListSize)
          : 0),
    });
    setEdited(false);
  };

  const isWithoutBooking = Boolean(!project?.bookingRequired);

  const getDisabled = (projectProp?: EditProject) => {
    const proj = projectProp || project;
    return (
      !proj ||
      !proj.name ||
      !proj.subtitle ||
      !proj.description ||
      !proj.employee ||
      !proj.category ||
      !proj.publicationPeriodStart ||
      isNaN(proj.publicationPeriodStart.getTime()) ||
      !proj.publicationPeriodEnd ||
      isNaN(proj.publicationPeriodEnd.getTime()) ||
      !proj.bookingPeriodStart ||
      isNaN(proj.bookingPeriodStart.getTime()) ||
      !proj.bookingPeriodEnd ||
      isNaN(proj.bookingPeriodEnd.getTime()) ||
      (!isWithoutBooking &&
        (proj.mimimumParticipants === "" || proj.maximumParticipants === "")) ||
      // if waiting list included in max, waiting list size must be lower than max
      (proj.waitinglistIncludedInMax &&
        Number(proj.waitingListSize) > Number(proj.maximumParticipants))
    );
  };

  const disabled = getDisabled();

  const defaultSliderValue = React.useState([
    (props.defaultProject &&
      parseInt(props.defaultProject.minimumAge as string)) ||
      defaultSearchSettings.age[0],
    (props.defaultProject &&
      parseInt(props.defaultProject.maximumAge as string)) ||
      defaultSearchSettings.age[1],
  ])[0];

  const addZipFilter = () => {
    callback({
      zipFilter: project.zipFilter
        ? project.zipFilter + "," + zipFilterInput
        : zipFilterInput,
    });
    setZipFilterInput("");
  };

  return (
    <Paper>
      <Box display="flex">
        <Box className={styles.boxWithoutMargin}>
          <Box className={styles.box}>
            <Typography>{projectState(project?.state)}</Typography>
            {props.editBooking && (
              <FormControlLabel
                control={
                  <Switch
                    checked={project?.bookingRequired || false}
                    onChange={(event) =>
                      callback({ bookingRequired: event.target.checked })
                    }
                    color="primary"
                  />
                }
                label="Buchung notwendig"
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={project?.publish || false}
                  onChange={(event) =>
                    callback({ publish: event.target.checked })
                  }
                  color="primary"
                />
              }
              label="Öffentlich"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={project?.createTickets || false}
                  onChange={(event) =>
                    callback({ createTickets: event.target.checked })
                  }
                  disabled={!project?.publish || !project.bookingRequired}
                  color="primary"
                />
              }
              label="Tickets erstellen"
            />
          </Box>
          <Divider />
          <Box className={styles.box}>
            <TextField
              label="Titel"
              value={project?.name || ""}
              onChange={(event) => callback({ name: event.target.value })}
              fullWidth
              margin="normal"
              required
              autoFocus
            />
            <TextField
              label="Untertitel"
              value={project?.subtitle || ""}
              onChange={(event) => callback({ subtitle: event.target.value })}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Beschreibung"
              value={project?.description || ""}
              onChange={(event) =>
                callback({ description: event.target.value })
              }
              fullWidth
              margin="normal"
              required
              multiline
            />
            <Box marginBottom={1}>
              <FileSelectorDialog
                callbackDone={(files) =>
                  callback({
                    attachments: JSON.stringify(files.map((file) => file.path)),
                  })
                }
                pickedFiles={
                  project?.attachments ? JSON.parse(project.attachments) : []
                }
                multiple
                fullWidth
              >
                Anhang
              </FileSelectorDialog>
            </Box>
            <Box className={styles.bottom}>
              {project?.image && (
                <img
                  src={config.fileRootPath + project?.image}
                  alt="Projektbild"
                  className={styles.imagePreview}
                />
              )}
              <FileSelectorDialog
                callbackDone={(files) =>
                  callback({ image: files?.[0]?.path || "" })
                }
                pickedFiles={project?.image ? [project.image] : []}
                fullWidth
                filter={(files) =>
                  files.filter(
                    (file) =>
                      file.filetype === "image" ||
                      file.filetype === "vectorgraphic"
                  )
                }
              >
                Bild auswählen
              </FileSelectorDialog>
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className={styles.boxWithoutMargin}>
          <Box className={styles.box}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Organisationen</InputLabel>
              <Select
                multiple
                value={project?.organisationids || []}
                onChange={(event) =>
                  callback({ organisationids: event.target.value as string[] })
                }
                renderValue={(selected) =>
                  (selected as string[])
                    .map(
                      (id) =>
                        (
                          organisations.find(
                            (org) => org.id === id
                          ) as Organisation
                        )?.name
                    )
                    .filter(Boolean)
                    .join(", ")
                }
                fullWidth
              >
                {organisations.map((organisations) => (
                  <MenuItem key={organisations.id} value={organisations.id}>
                    <Checkbox
                      color="primary"
                      checked={(project?.organisationids || []).includes(
                        organisations.id
                      )}
                    />
                    {organisations.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              options={locations}
              inputValue={project?.roomName || ""}
              onInputChange={(e, value) =>
                e && callback({ roomName: value || "" })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) =>
                    callback({ roomName: event.target.value })
                  }
                  label="Raum"
                  margin="normal"
                />
              )}
              PopperComponent={({ style, ...props }) => (
                <Popper {...props} style={{ ...style, height: 0 }} />
              )}
              freeSolo
              autoHighlight
            />
            <Autocomplete
              options={employees}
              inputValue={project?.employee || ""}
              onInputChange={(e, value) =>
                e && callback({ employee: value || "" })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) =>
                    callback({ employee: event.target.value })
                  }
                  label="Mitarbeiter"
                  margin="normal"
                  required
                />
              )}
              PopperComponent={({ style, ...props }) => (
                <Popper {...props} style={{ ...style, height: 0 }} />
              )}
              freeSolo
              autoHighlight
            />
            <TextField
              label="Kategorie"
              value={project?.category || ""}
              onChange={(event) => callback({ category: event.target.value })}
              fullWidth
              margin="normal"
              select
              required
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Divider />
          <Box className={styles.box}>
            <Box>
              <KeyboardDateTimePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy HH:mm"
                label="Veröffentlichungsbeginn"
                value={project?.publicationPeriodStart}
                onChange={(publicationPeriodStart: Date | null) => {
                  publicationPeriodStart?.setSeconds(0);
                  publicationPeriodStart?.setMilliseconds(0);

                  callback({
                    publicationPeriodStart,
                  });
                }}
                helperText={undefined}
                required
                margin="normal"
                fullWidth
                ampm={false}
              />

              <KeyboardDateTimePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy HH:mm"
                label="Veröffentlichungsende"
                value={project?.publicationPeriodEnd || null}
                onChange={(publicationPeriodEnd: Date | null) => {
                  publicationPeriodEnd?.setSeconds(0);
                  publicationPeriodEnd?.setMilliseconds(0);

                  callback({
                    publicationPeriodEnd,
                  });
                }}
                helperText={undefined}
                required
                margin="normal"
                fullWidth
                ampm={false}
              />
            </Box>
          </Box>
          <Box className={styles.box}>
            <Box>
              <KeyboardDateTimePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy HH:mm"
                label="Buchungsbeginn"
                value={project?.bookingPeriodStart ?? null}
                onChange={(bookingPeriodStart: Date | null) => {
                  bookingPeriodStart?.setSeconds(0);
                  bookingPeriodStart?.setMilliseconds(0);

                  callback({
                    bookingPeriodStart,
                  });
                }}
                helperText={undefined}
                required
                margin="normal"
                fullWidth
                ampm={false}
              />

              <KeyboardDateTimePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy HH:mm"
                label="Buchungsende"
                value={project?.bookingPeriodEnd ?? null}
                onChange={(bookingPeriodEnd: Date | null) => {
                  bookingPeriodEnd?.setSeconds(0);
                  bookingPeriodEnd?.setMilliseconds(0);

                  callback({
                    bookingPeriodEnd,
                  });
                }}
                helperText={undefined}
                required
                margin="normal"
                fullWidth
                ampm={false}
              />
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className={styles.boxWithoutMargin}>
          <Box className={styles.box}>
            <TextField
              label="Gebühren"
              placeholder="0,00"
              value={project?.fees || 0}
              onChange={(event) =>
                callback({ fees: parseInt(event.target.value) })
              }
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
              fullWidth
              margin="normal"
              required
            />
            <FormControl margin="normal" fullWidth>
              <InputLabel>Rabatt</InputLabel>
              <Input
                value={project?.discountRate || ""}
                placeholder="0"
                onChange={(event) =>
                  callback({
                    discountRate: Math.min(
                      parseInt(
                        event.target.value.replace(/[^0-9]/g, "").slice(0, 3)
                      ),
                      100
                    ),
                  })
                }
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                fullWidth
              />
            </FormControl>
          </Box>
          <Divider />
          <Box className={styles.box}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Geschlecht</FormLabel>
              <RadioGroup
                value={project?.allowedGenders || AllowedGenders.Both}
                onChange={(event) =>
                  callback({
                    allowedGenders: parseInt(
                      event.target.value
                    ) as AllowedGenders,
                  })
                }
              >
                <FormControlLabel
                  value={AllowedGenders.Both}
                  control={<Radio color="primary" />}
                  label="Keine Einschränkung"
                />
                <FormControlLabel
                  value={AllowedGenders.WomenOnly}
                  control={<Radio color="primary" />}
                  label="Nur Weiblich"
                />
                <FormControlLabel
                  value={AllowedGenders.MenOnly}
                  control={<Radio color="primary" />}
                  label="Nur Männlich"
                />
              </RadioGroup>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Typography>Altersbeschränkung</Typography>
              <Slider
                defaultValue={defaultSliderValue}
                onChangeCommitted={(_, value) =>
                  callback({
                    minimumAge: (value as number[])[0],
                    maximumAge: (value as number[])[1],
                  })
                }
                valueLabelDisplay="auto"
                min={defaultSearchSettings.age[0]}
                max={defaultSearchSettings.age[1]}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Typography>Postleitzahl Beschränkungen</Typography>
              <TextField
                value={zipFilterInput}
                onChange={(e) =>
                  setZipFilterInput(e.target.value.replace(/\D/g, ""))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" && zipFilterInput.length)
                    addZipFilter();
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => addZipFilter()}
                      disabled={zipFilterInput.length === 0}
                    >
                      <Send />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
            <Box className={styles.zipWrapper}>
              {project?.zipFilter
                ?.split(",")
                .filter(Boolean)
                .map((zip) => (
                  <Chip
                    key={zip}
                    onDelete={() => {
                      callback({
                        zipFilter: project.zipFilter
                          ?.split(",")
                          .filter((z) => z !== zip)
                          .join(","),
                      });
                    }}
                    label={zip.padEnd(5, "X")}
                  />
                ))}
            </Box>
          </Box>
          {!isWithoutBooking && (
            <>
              <Divider />
              <Box className={styles.box}>
                <Typography>Teilnehmerbegrenzung</Typography>
                <InputWrapper>
                  <TextField
                    label="Minimal"
                    value={
                      project?.mimimumParticipants !== undefined
                        ? project?.mimimumParticipants
                        : ""
                    }
                    onChange={(event) =>
                      callback({
                        mimimumParticipants: event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        ),
                      })
                    }
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Maximal"
                    value={
                      project?.maximumParticipants !== undefined
                        ? project.maximumParticipants
                        : ""
                    }
                    onChange={(event) =>
                      callback({
                        maximumParticipants: event.target.value.replace(
                          /[^0-9]/g,
                          ""
                        ),
                      })
                    }
                    fullWidth
                    margin="normal"
                    required
                  />
                </InputWrapper>
                <TextField
                  label="Wartelistenplätze"
                  placeholder="0"
                  value={
                    project?.waitingListSize !== undefined
                      ? project?.waitingListSize
                      : ""
                  }
                  onChange={(event) =>
                    callback({
                      waitingListSize: event.target.value.replace(
                        /[^0-9]/g,
                        ""
                      ),
                    })
                  }
                  fullWidth
                  margin="normal"
                />
              </Box>
              <InputWrapper>
                <FormControlLabel
                  control={
                    <Switch
                      checked={project.waitinglistIncludedInMax}
                      onChange={(_, checked) =>
                        callback({ waitinglistIncludedInMax: checked })
                      }
                      color="primary"
                    />
                  }
                  label="Warteliste in Teilnehmerbegrenzung einbeziehen"
                />
              </InputWrapper>
              <Divider />
              <Box className={styles.box}>
                <Typography>Zahlungsmöglichkeiten</Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={project.paypalEnabled}
                        onChange={(_, checked) =>
                          callback({ paypalEnabled: checked })
                        }
                        color="primary"
                        disabled={!project.sepaEnabled && !project.cashEnabled}
                      />
                    }
                    label="PayPal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={project.sepaEnabled}
                        onChange={(_, checked) =>
                          callback({ sepaEnabled: checked })
                        }
                        color="primary"
                        disabled={!project.cashEnabled}
                      />
                    }
                    label="SEPA"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={project.cashEnabled}
                        onChange={(_, checked) =>
                          callback({ cashEnabled: checked })
                        }
                        color="primary"
                        disabled={!project.sepaEnabled}
                      />
                    }
                    label="Bar"
                  />
                </FormGroup>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {props.onApply && (
        <>
          <ConfirmDialog
            value={requestDelete}
            close={setRequestDelete}
            onSuccess={props.deleteProject}
            type={ConfirmDialogType.delete}
          />
          <ConfirmDialog
            value={requestCopyProject}
            close={setRequestCopyProject}
            textRender="Möchten Sie das Projekt, mitsamt aller Anmeldungen, wirklich kopieren?"
            onSuccess={props.copyProject}
            type={ConfirmDialogType.custom}
          />

          <Divider />
          <Box display="flex" justifyContent="space-between">
            <Box p={2}>
              {Boolean(props.deleteProject) && (
                <Button
                  onClick={() => setRequestDelete(project?.name || "Projekt")}
                >
                  Löschen
                </Button>
              )}
              {Boolean(props.copyProject) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setRequestCopyProject(true)}
                >
                  Kopieren
                </Button>
              )}
            </Box>
            <Box p={2}>
              {props.id && <CreateTemplateButton id={props.id} />}
              <Button onClick={abort}>Abbrechen</Button>
              <Button
                color="primary"
                variant="contained"
                onClick={updateProject}
                disabled={!edited || disabled}
              >
                Speichern
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Paper>
  );
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value.replace(/[^0-9]/g, ""),
          },
        });
      }}
      format={(value) => {
        const formatted = value.replace(/^0+/, "").padStart(3, "0");
        return `${formatted.slice(0, -2)},${formatted.slice(-2)}€`;
      }}
      isNumericString
      allowNegative={false}
    />
  );
}
