import React from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";

import { useServer } from "../Server/ServerContext";
import useLogin from "../Login";
import Root from "../Root";
import { User } from "../../Interfaces";

export interface AdminRouteProps extends Omit<RouteProps, "component"> {
  component: () => JSX.Element;
  access_level?: number;
}

export default function AdminRoute(props: AdminRouteProps) {
  const { currentUser, dataActions } = useServer();
  const { login } = useLogin();
  const history = useHistory();

  const loggedIn = Boolean(currentUser);

  React.useEffect(() => {
    if (!loggedIn) login();
  });

  React.useEffect(() => {
    dataActions.isInitialized().then((initialized) => {
      // Redirect to admin root and initialize data if uninitialized
      if (!initialized) history.push("/admin/?initialize=true");
    });
  }, [currentUser]);

  const render = () => {
    // Wait to show admin component until the user is logged in
    if (
      !loggedIn ||
      (currentUser as User).access_level >= (props.access_level || 1)
    ) {
      return (
        <Route
          {...props}
          component={undefined}
          render={() => (loggedIn ? <props.component /> : <Root />)}
        />
      );
    }
    // Redirect if user is not an admin
    else if (!(currentUser as User).access_level) return <Redirect to="/" />;
    // Else redirect as admin access level is too low
    else return <Redirect to="/admin" />;
  };

  return render();
}
