import React from "react";
import { useHistory } from "react-router";

import { TextField, Button, makeStyles } from "@material-ui/core";

import { useSnackbar } from "notistack";

import { Member, useServer } from "../Server/ServerContext";
import { ParticipantInfoDetailsProps } from "./ParticipantInfoDetails";
import InputWrapper from "../InputWrapper";
import EditParticipant from "./EditParticipant";
import {
  EditMember,
  parseEditMember,
  parseMember,
} from "../Account/PersonEditList";

const useStyles = makeStyles({
  button: {
    height: "fit-content",
    alignSelf: "center",
  },
});

export default function EditParticipantInfo(
  props: ParticipantInfoDetailsProps
) {
  const styles = useStyles();
  const history = useHistory();

  const [participantInfo, setParticipantInfo] = useServer().useParticipantInfo(
    props.id
  );

  const { accountManagement, peopleActions } = useServer();
  const snackbar = useSnackbar();

  const [editParticipant, setEditParticipant] = React.useState<
    EditMember | undefined
  >(undefined);
  const [edit, setEdited] = React.useState(false);

  React.useEffect(
    () =>
      setEdited(
        JSON.stringify(editParticipant) !== JSON.stringify(participantInfo)
      ),
    [editParticipant]
  );

  React.useEffect(() => {
    if (!edit || !participantInfo) abort();
  }, [participantInfo]);

  const abort = () =>
    setEditParticipant(
      parseEditMember({ ...participantInfo }) as EditMember | undefined
    );

  const redirectParent = () => {
    if (editParticipant) {
      const parsed = parseMember(editParticipant) as Member;

      peopleActions.getParent(parsed.id as string).then((id) => {
        if (id !== parsed.id) history.push(`/admin/participantinfo/${id}`);
        else
          snackbar.enqueueSnackbar(
            "Beim Teilnehmer handelt es sich um den Haupt- und Elternaccount"
          );
      });
    }
  };

  const updateParticipantInfo = () => {
    if (editParticipant) {
      const parsed = parseMember(editParticipant) as Member;

      accountManagement
        .updateMember(parsed.id as string, parsed)
        .then(() => {
          setParticipantInfo(parsed);
          setEdited(false);
        })
        .snackbar({
          snackbar,
          success: "Kundendaten erfolgreich aktualisiert",
          admin: true,
        });
    }
  };

  React.useEffect(abort, []);

  return (
    <EditParticipant
      participant={editParticipant}
      callback={setEditParticipant}
      abort={abort}
      onClick={updateParticipantInfo}
      disabled={!edit}
      additional={
        editParticipant?.linkedAccount && (
          <InputWrapper>
            <TextField
              label="Kontaktmail"
              // Shows placeholder in case no linkedAccount is given
              placeholder="Kontaktmail nicht vorhanden"
              value={editParticipant.linkedAccount}
              fullWidth
              margin="normal"
            />
            <Button
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={redirectParent}
            >
              Elternteil
            </Button>
          </InputWrapper>
        )
      }
    />
  );
}
