import { AllowedGenders } from "../Components/Server/ServerContext";

export const allowsGenderToString = (
  allowedGenders: AllowedGenders
): string => {
  switch (allowedGenders) {
    case AllowedGenders.MenOnly:
      return "Jungen und Männer";
    case AllowedGenders.WomenOnly:
      return "Mädchen und Frauen";
    case AllowedGenders.Both:
      return "alle";
  }
};

export const isGenderAllowed = (
  allowedGenders: AllowedGenders,
  gender: string
) => {
  switch (allowedGenders) {
    case AllowedGenders.Both:
      return true;
    case AllowedGenders.MenOnly:
      return gender.toLowerCase() === "männlich";
    case AllowedGenders.WomenOnly:
      return gender.toLowerCase() === "weiblich";
  }
};
