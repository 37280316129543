import React from "react";

import {
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItem,
  IconButton,
  Divider,
} from "@material-ui/core";
import { DeleteForeverRounded } from "@material-ui/icons";

import { useSnackbar } from "notistack";

import { Account, useServer } from "../Server/ServerContext";
import Body from "../Root/Body";

import useSearch, { TextHighlight } from "../Search/SearchProvider";
import ConfirmDelete, { ConfirmDialogType } from "../ConfirmDialog";

export interface CustomerUserListProps {
  useUsermanagement: [
    Account[],
    React.Dispatch<React.SetStateAction<Account[]>>
  ];
}

export default function CustomerUserList(props: CustomerUserListProps) {
  const { userManagement } = useServer();
  const [usermanagement, setUser] = props.useUsermanagement;
  const { search, isSearched } = useSearch();
  const snackbar = useSnackbar();

  const [requestDelete, setRequestDelete] = React.useState<Account | null>(
    null
  );

  const filterCustomer = (user: Account) =>
    user.access_level === 0 && isSearched([user.email]);

  const removeUser = (user: Account) =>
    userManagement
      .delete(user.email)
      .then(() => {
        setUser((users) =>
          users.filter((current) => current.email !== user.email)
        );
      })
      .snackbar({
        snackbar,
        success: "Kundenkonto erfolgreich entfernt",
        admin: true,
      });

  return (
    <Body>
      <List>
        <ConfirmDelete
          value={requestDelete}
          close={setRequestDelete}
          textRender={(value: Account) => value.email}
          onSuccess={removeUser}
          type={ConfirmDialogType.delete}
        />

        {usermanagement.filter(filterCustomer).map((user) => (
          <React.Fragment key={user.email}>
            <ListItem button>
              <ListItemText
                primary={
                  <TextHighlight search={search}>{user.email}</TextHighlight>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => setRequestDelete(user)}>
                  <DeleteForeverRounded />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Body>
  );
}
