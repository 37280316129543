import React from "react";
import { useLocation } from "react-router-dom";

import { Tooltip, Box } from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import FilterSidebar, { FilterProvider } from "./Root/FilterSidebar";
import CreateProject from "./Admin/CreateProject";
import ProjectList from "./Admin/ProjectList";
import SetupAssistant from "./Settings/SetupAssistant";
import { getQuery, updateQuery } from "../Functions/QueryParams";
import { HeaderIconButton } from "./Root/HeaderBar";

export default function Admin() {
  const location = useLocation();

  const [filterOpen, setFilterOpen] = React.useState(true);
  const [openAssistant, setOpenAssistant] = React.useState(false);

  React.useEffect(() => {
    if (
      (getQuery({ initialize: Boolean }) as { initialize: boolean })?.initialize
    ) {
      setOpenAssistant(true);
    }
  }, [location]);

  const closeAssistant = () => {
    setOpenAssistant(false);
    updateQuery({ initialize: false }, { initialize: false });
  };

  return (
    <FilterProvider>
      <HeaderBarAdmin
        title="Projekte"
        leftAction={
          <Tooltip title={`Filter ${filterOpen ? "schließen" : "öffnen"}`}>
            <HeaderIconButton onClick={() => setFilterOpen(!filterOpen)}>
              <MenuRounded />
            </HeaderIconButton>
          </Tooltip>
        }
      />
      <Box display="flex">
        <FilterSidebar in={filterOpen} />
        <ProjectList />
      </Box>
      <CreateProject />

      <SetupAssistant open={openAssistant} close={closeAssistant} />
    </FilterProvider>
  );
}
