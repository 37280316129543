import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  Chip,
  TextField,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";

import { SettingsAccordion, SettingsListItemProps } from "./SettingsList";
import { useServer } from "../Server/ServerContext";

import { useSnackbar } from "notistack";
import { ExpandMoreRounded, SendRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function CategoryEditor(props: SettingsListItemProps) {
  const style = useStyles();

  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();
  const snackbar = useSnackbar();

  const [categories, setCategories] = React.useState<string[] | undefined>(
    settings ? JSON.parse(settings.categories) : undefined
  );
  const [edited, setEdited] = React.useState(false);
  const [input, setInput] = React.useState("");

  React.useEffect(() => {
    if (!edited) abort();
  }, [settings]);

  React.useEffect(
    () => setEdited(JSON.stringify(categories) !== settings?.categories),
    [categories, settings]
  );

  const addCategory = () => {
    if (categories && input && !categories.includes(input))
      setCategories([...categories, input]);
    setInput("");
  };

  const abort = () => {
    setCategories(settings ? JSON.parse(settings.categories) : undefined);
    setInput("");
  };

  const updateCategories = () => {
    settingsActions
      .set("categories", JSON.stringify(categories))
      .then(() =>
        setSettings({
          ...settings,
          categories: JSON.stringify(categories),
        })
      )
      .snackbar({
        snackbar,
        success: "Kategorien erfolgreich aktualisiert",
        admin: true,
      });
  };

  return (
    <SettingsAccordion
      abort={abort}
      onClick={updateCategories}
      disabled={!categories || !categories.length || !edited}
    >
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        Kategorieneditor
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" alignItems="center">
            <TextField
              value={input}
              onChange={(event) => setInput(event.target.value)}
              fullWidth
              label="Ka­te­go­rie hinzufügen"
              margin="dense"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  addCategory();
                  event.preventDefault();
                }
              }}
            />
            <IconButton onClick={addCategory}>
              <SendRounded />
            </IconButton>
          </Box>
          <Box>
            {categories?.map((category) => (
              <Chip
                label={category}
                key={category}
                onDelete={() =>
                  setCategories(
                    categories.filter((filter) => category !== filter)
                  )
                }
                className={style.chip}
              />
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </SettingsAccordion>
  );
}
