import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowBackRounded } from "@material-ui/icons";

import useLocalStorage from "../LocalStorage";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";

export default function BackToProjectPreview() {
  const history = useHistory();
  const { storage } = useLocalStorage();

  const backToProject = () => {
    history.push(`/project/${storage.booking || ""}`);
  };

  return (
    <SubHeaderIconButton onClick={backToProject} icon={<ArrowBackRounded />}>
      Zurück zum Projekt
    </SubHeaderIconButton>
  );
}
