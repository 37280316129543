import React from "react";

import { ShareRounded } from "@material-ui/icons";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";
import { useSnackbar } from "notistack";

export const share = (href: string) => navigator.clipboard.writeText(href);

export default function ShareProject() {
  const { enqueueSnackbar } = useSnackbar();

  const shareButton = () => {
    share(window.location.href);
    enqueueSnackbar("Link kopiert", { variant: "success" });
  };

  return (
    <SubHeaderIconButton icon={<ShareRounded />} onClick={shareButton}>
      Projekt teilen
    </SubHeaderIconButton>
  );
}
