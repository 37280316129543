import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import config from "../config";
import HeaderBarPublic from "./Root/HeaderBar";
import { useServer } from "./Server/ServerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Ticket() {
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();
  const { customerProjectActions } = useServer();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const mounted = React.useRef(true);

  const downloadTicket = async () => {
    if (!mounted.current) return;
    setLoading(true);

    try {
      const ticket = await customerProjectActions.getTicket(id);
      if (!mounted.current) return;
      window.open(config.ticketFileLink + ticket);
    } catch (e) {
      if (!mounted.current) return;
      setError((e as Error).message);
    }

    if (!mounted.current) return;
    setLoading(false);
  };

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      <HeaderBarPublic title="Online Anmeldung" />
      <Box className={styles.root}>
        {loading ? (
          <CircularProgress />
        ) : !error ? (
          <>
            <Typography variant="h4" className={styles.header}>
              Laden Sie hier Ihr Ticket herunter
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadTicket}
            >
              {loading ? <CircularProgress /> : "Ticket herunterladen"}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4" className={styles.header}>
              {error}
            </Typography>
            <Button variant="contained" color="primary" disabled>
              Ticket herunterladen
            </Button>
          </>
        )}
      </Box>
    </>
  );
}
