export interface URLSearchParamsAdvanced {
  [key: string]: URLSearchParamTypes;
}

export type URLParseParamsAdvanced<T> = { [K in keyof T]: URLParseParamTypes };

export type URLSearchParamTypes =
  | string
  | number
  | Date
  | Array<URLSearchParamTypes>
  | boolean
  | null;

export type URLParseParamTypes =
  | StringConstructor
  | NumberConstructor
  | DateConstructor
  | ArrayConstructor
  | BooleanConstructor;

export const updateQuery = (
  newParams: URLSearchParamsAdvanced,
  defaultParams?: URLSearchParamsAdvanced,
  initialURLSearchParams: string = window.location.search
) => {
  const params = new URLSearchParams(initialURLSearchParams);

  const loopEntries = (
    key: string,
    value: URLSearchParamTypes,
    defaultParams: URLSearchParamsAdvanced | undefined,
    arrayForce = false
  ) => {
    switch (value?.constructor) {
      case String:
        if (value && (defaultParams?.[key] !== value || arrayForce)) {
          params.append(key, value as string);
          return true;
        }
        break;
      case Number:
        if (
          !isNaN(value as number) &&
          (defaultParams?.[key] !== value || arrayForce)
        ) {
          params.append(key, (value as number).toString());
          return true;
        }
        break;
      case Date:
        if (
          (value as Date).getTime() &&
          !isNaN((value as Date).getTime()) &&
          (!defaultParams?.[key] ||
            arrayForce ||
            (defaultParams?.[key] as Date).getTime() !==
              (value as Date).getTime())
        ) {
          params.append(key, value.toLocaleString());
          return true;
        }
        break;
      case Array:
        const repeat = (value as URLSearchParamTypes[]).find((entry, index) =>
          loopEntries(key, entry, {
            [key]: (defaultParams?.[key] as URLSearchParamTypes[])?.[
              index
            ] as URLSearchParamTypes,
          })
        );

        // Force entire array to be written if one value is set
        if (repeat !== undefined) {
          params.delete(key);

          (value as URLSearchParamTypes[]).forEach((entry, index) =>
            loopEntries(
              key,
              entry,
              {
                [key]: (defaultParams?.[key] as URLSearchParamTypes[])?.[
                  index
                ] as URLSearchParamTypes,
              },
              true
            )
          );
        }
        break;
      case Boolean:
        if (defaultParams?.[key] !== value || arrayForce) {
          params.append(key, (value as boolean).toString());
          return true;
        }
        break;
    }

    return false;
  };

  Object.entries(newParams).forEach(([key, value]) => {
    params.delete(key);

    loopEntries(key, value, defaultParams);
  });

  const locationSubStrings = window.location.pathname.split("?");
  let target: string;

  if (locationSubStrings.length > 1)
    target = locationSubStrings.slice(0, -1).join("");
  else target = locationSubStrings[0];

  window.history.replaceState(
    null,
    "",
    `${(target + "/").replaceAll("//", "/")}?${params.toString()}`
  );
};

export function getQuery<T>(
  parse: URLParseParamsAdvanced<T>,
  initialURLSearchParams: string = window.location.search
): Partial<{ [K in keyof T]: URLSearchParamTypes }> {
  const parsed: Partial<{ [K in keyof T]: URLSearchParamTypes }> = {};
  const params = new URLSearchParams(initialURLSearchParams);

  const loopEntries = (key: keyof T, type: URLParseParamTypes) => {
    switch (type) {
      case String:
        const string = params.get(key as string);
        if (string) parsed[key] = params.get(key as string);
        break;
      case Number:
        const number = parseInt(params.get(key as string) as string);
        if (!isNaN(number)) parsed[key] = number;
        break;
      case Date:
        const date = new Date(params.get(key as string) as string);
        if (date.getTime() && !isNaN(date.getTime())) parsed[key] = date;
        break;
      case Array:
        const array = params.getAll(key as string);
        if (array.length) parsed[key] = array;
        break;
      case Boolean:
        const value = params.get(key as string);
        if (value) parsed[key] = value === "true";
        break;
    }
  };

  Object.entries(parse).forEach(([key, type]) => {
    loopEntries(key as keyof T, type as URLParseParamTypes);
  });

  return parsed;
}
