import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import FormComponent from "../FormComponent";

import { FormatedSettings, useServer } from "../Server/ServerContext";
import { useStyles } from "./ColorEditor";
import { ChromePicker } from "react-color";
import FileSelectorDialog from "../FileSelectorDialog";

import config from "../../config";

/*
    Set default settings
    Mention mail texts, categories, basedata, and usermanagement
*/

const defaultSettings: Partial<FormatedSettings> = {
  booking_mail_subject: "Ihre Onlinebuchung – {project_name}",
  booking_mail_attachments: "[]",
  booking_mail_html:
    "<p>Vielen Dank für Ihre Buchung. Nachfolgende Daten haben wir nun registriert:</p><p><br></p><p><strong>Teilnehmer/in:</strong></p><p>{participants}</p><p><br></p><p>Die Informationen des Angebots finden sich <a rel='noreferrer noopener' href='{project_link}'>hier</a>.</p><p><br></p><p><br></p><p><strong>Ihre Zahlweise:</strong></p><p>{zahlweise}</p><p><br></p><p>Für weitere Rückfragen stehen wir Ihnen gerne zur Verfügung.</p>",
  ticket_mail_html:
    "<p>Hier können Sie Ihr Ticket herunterladen: {ticket_link}</p>",
  status_update_mail_html:
    "<p>Der Status des Projektes „{project_name}“ hat sich geändert. Nachfolgende Daten sind nun registriert:</p><p><br></p><p><strong>Teilnehmer/in:</strong></p><p>{participants}</p><p><br></p><p>Die Informationen des Angebots finden sich <a rel='noreferrer noopener' href='{project_link}'>hier</a>.</p><p><br></p><p><br></p><p><strong>Ihre Zahlweise:</strong></p><p>{zahlweise}</p><p><br></p><p>Für weitere Rückfragen stehen wir Ihnen gerne zur Verfügung.</p>",

  cash_mail_string:
    "<p>Da die Gebühr in <strong>bar </strong>bezahlt werden möchte, gelten folgende Einschränkungen:</p><p>Eine Buchung bzw. eine Reservierung des Teilnehmerplatzes erfolgt nur durch Zahlungseingang der fälligen Teilnahmegebühr. Diese muss <strong>14 Tage</strong> vor Beginn des Angebotes bei uns eingegangen sein, andernfalls geben wir den Teilnehmerplatz, ohne Rücksprache mit Ihren, für andere TeilnehmerInnen frei.</p>",
  paypal_mail_string:
    "<p>Die Gebühr wurde per <strong>PayPal/Kreditkarte</strong> überwiesen.</p>",
  sepa_mail_string:
    "<p>Da die Gebühr mit <strong>SEPA </strong>bezahlt werden möchte, gelten folgende Einschränkungen:</p><p>Eine Buchung bzw. eine Reservierung des Teilnehmerplatzes erfolgt erst nach Eingang des vollständig ausgefüllten SEPA-Lastschriftmandats. Dieses muss <strong>14 Tage</strong> vor Beginn des Angebotes bei uns eingegangen sein, andernfalls geben wir den Teilnehmerplatz, ohne Rücksprache mit Ihren, für andere TeilnehmerInnen frei.</p>",
  sepa_mail_attachments: "[]",

  payment_reminder_mail_html:
    "<p>Es liegt eine offene Zahlung des Projekts „{project_name}“ vom {booking_date} vor. Nachfolgende Daten wurden registriert:</p><p><br></p><p><strong>Teilnehmer/in:</strong></p><p>{participants}</p><p><br></p><p>Die Informationen des Angebots finden sich <a rel='noreferrer noopener' href='{project_link}'>hier</a>.</p><p><br></p><p><br></p><p><strong>Ihre Zahlweise:</strong></p><p>{zahlweise}</p><p><br></p><p>Sie haben bereits bezahlt? Bitte überprüfen Sie Ihre Zahlung und kontaktieren uns.</p><p>Für weitere Rückfragen stehen wir Ihnen gerne zur Verfügung.</p>",

  password_reset_mail_subject: "Passwort Zurücksetzen",
  password_reset_mail_content:
    "<p>Ihr Verifikationscode lautet: {code}</p><p><br></p><p>Sie haben keinen Verifikationscode angefragt? Ignorieren Sie die Nachricht oder wenden Sie sich an einen Mitarbeiter.</p>",

  categories: "[]",

  members_per_account: "5",
  name: "",
  additional_info_tickets: "",
  address: "",
  city: "",
  zip: "",
  phone: "",

  initialized: "true",
};

export interface SetupAssistantProps {
  open: boolean;
  close: (_: boolean) => void;
}

export default function SetupAssistant(props: SetupAssistantProps) {
  const styles = useStyles();

  const [settings, setSettings] = useServer().useSettings();
  const { settingsActions, requestColorFetch } = useServer();
  const snackbar = useSnackbar();

  const [step, setStep] = React.useState(0);

  const [paypal_id, setPayPalId] = React.useState("");
  const [paypal_secret, setPayPalSecret] = React.useState("");

  const [primary_color, setPrimaryColor] = React.useState("#000000");
  const [text_color, setTextColor] = React.useState("#000000");
  const [icon_path, setIconPath] = React.useState("");

  const [data_privacy_url, setDataPrivacyURL] = React.useState(
    settings?.data_privacy_url
  );
  const [agb_url, setAgbURL] = React.useState(settings?.agb_url);
  const [image_rights_url, setImageRightsURL] = React.useState(
    settings?.image_rights_url
  );
  const [imprint_url, setImprintURL] = React.useState(settings?.imprint_url);
  const [support_email, setSupportEmail] = React.useState(
    settings?.support_email
  );

  const steps = [
    "PayPal-Daten",
    "Farbschema",
    "Rechtliche Daten",
    "Zusätzliche Daten",
  ];

  const randomString = (length: number) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const buttonContent = () => {
    switch (step) {
      case 0:
      case 1:
      case 2:
        return "Weiter";
      case 3:
        return "Fertigstellen";
      default:
        return "";
    }
  };

  const stepContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              value={paypal_id}
              onChange={(event) => setPayPalId(event.target.value)}
              label="Client ID"
              fullWidth
              margin="normal"
            />
            <TextField
              value={paypal_secret}
              onChange={(event) => setPayPalSecret(event.target.value)}
              label="Client Secret"
              type="password"
              autoComplete="disabled"
              fullWidth
              margin="normal"
            />
          </>
        );
      case 1:
        return (
          <>
            <Box display="flex" justifyContent="space-around">
              <Box>
                <Typography variant="h5">Primärfarbe</Typography>
                <ChromePicker
                  className={styles.colorPicker}
                  color={primary_color}
                  onChange={(color) => setPrimaryColor(color.hex)}
                />
              </Box>
              <Box>
                <Typography variant="h5">Textfarbe</Typography>
                <ChromePicker
                  className={styles.colorPicker}
                  color={text_color}
                  onChange={(color) => setTextColor(color.hex)}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box m={2}>
                  {icon_path ? (
                    <img
                      className={styles.icon}
                      src={config.fileRootPath + icon_path}
                      alt="Icon der Webseite"
                    />
                  ) : (
                    "Kein Icon ausgewählt"
                  )}
                </Box>
                <FileSelectorDialog
                  callbackDone={(files) => {
                    setIconPath(files?.[0]?.path || "");
                  }}
                  pickedFiles={[icon_path]}
                  filter={(files) =>
                    files.filter(
                      (file) =>
                        file.filetype === "image" ||
                        file.filetype === "vectorgraphic"
                    )
                  }
                >
                  Icon auswählen
                </FileSelectorDialog>
              </Box>
            </Box>
          </>
        );
      case 2:
        return (
          <Box display="flex" flexDirection="column" width="100%">
            <TextField
              value={data_privacy_url || ""}
              onChange={(event) => setDataPrivacyURL(event.target.value)}
              label="Datenschutzerklärung Link"
              autoComplete="disabled"
              fullWidth
              margin="dense"
            />
            <TextField
              value={agb_url || ""}
              onChange={(event) => setAgbURL(event.target.value)}
              label="AGB Link"
              autoComplete="disabled"
              fullWidth
              margin="dense"
            />
            <TextField
              value={image_rights_url || ""}
              onChange={(event) => setImageRightsURL(event.target.value)}
              label="Bildrechte Link"
              autoComplete="disabled"
              fullWidth
              margin="dense"
            />
            <TextField
              value={imprint_url || ""}
              onChange={(event) => setImprintURL(event.target.value)}
              label="Impressum Link"
              autoComplete="disabled"
              fullWidth
              margin="dense"
            />
            <TextField
              value={support_email || ""}
              onChange={(event) => setSupportEmail(event.target.value)}
              label="Support Email Adresse"
              autoComplete="disabled"
              fullWidth
              margin="dense"
            />
          </Box>
        );
      case 3:
        return (
          <>
            Um die Software vollständig nutzen zu können, empfiehlt es sich in
            den Einstellungen, Stammdaten und in der Nutzerverwaltung, jegliche
            Accounts, Räume, Mitarbeiter, Einrichtungen, Kategorien und Emails
            anzupassen bzw. hinzuzufügen. Diese Oberpunkte können in der oberen
            Leiste aufgefunden und aufgerufen werden.
          </>
        );
    }
  };

  const stepDisabled = (currentStep: number = step): boolean => {
    switch (currentStep) {
      case 0:
        return !paypal_id || !paypal_secret;
      case 1:
        return !primary_color || !text_color;
      case 2:
        return (
          !agb_url ||
          !data_privacy_url ||
          !image_rights_url ||
          !support_email ||
          !imprint_url
        );
      case 3:
        return stepDisabled(0) || stepDisabled(1) || stepDisabled(2);
      default:
        return false;
    }
  };

  const applySettings = () => {
    Promise.all([
      ...Object.entries(defaultSettings).map(([key, value]) =>
        settingsActions.set(key as keyof FormatedSettings, value)
      ),
      settingsActions.set("paypal_id", paypal_id),
      settingsActions.set("paypal_secret", paypal_secret),
      settingsActions.set("primary_color", primary_color),
      settingsActions.set("text_color", text_color),
      settingsActions.set("icon_path", icon_path),
      settingsActions.set("data_privacy_url", data_privacy_url),
      settingsActions.set("agb_url", agb_url),
      settingsActions.set("imprint_url", imprint_url),
      settingsActions.set("image_rights_url", image_rights_url),
      settingsActions.set("support_email", support_email),
      settingsActions.set("excel_key", randomString(32)),
    ])
      .then(() => {
        setSettings({
          ...settings,
          ...defaultSettings,
          paypal_id,
          paypal_secret,
          primary_color,
          text_color,
          icon_path,
          data_privacy_url,
          agb_url,
          image_rights_url,
          imprint_url,
          support_email,
        });
        props.close(false);

        // Force color update
        requestColorFetch();
      })
      .snackbar({
        snackbar,
        success: "Grundeinstellungen erfolgreich eingerichtet",
        admin: true,
      });
  };

  React.useEffect(() => {
    if (step === steps.length) applySettings();
  }, [step]);

  return (
    <Dialog
      open={props.open}
      PaperProps={{ component: FormComponent }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box textAlign="center">
          <Typography variant="h4" color="primary">
            Einrichtungsassistent
          </Typography>
        </Box>
        <Stepper nonLinear activeStep={step} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                completed={!stepDisabled(index)}
                color="primary"
                onClick={() => setStep(index)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>{stepContent()}</DialogContent>
      <DialogActions>
        <Button
          disabled={step === 0 || step === steps.length + 1}
          onClick={() => setStep(step - 1)}
        >
          Zurück
        </Button>
        <Button
          disabled={stepDisabled()}
          color="primary"
          onClick={() => setStep(step + 1)}
          type="submit"
        >
          {buttonContent()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
