import React from "react";
import { Redirect } from "react-router-dom";

import Body from "./Root/Body";
import HeaderBarPublic from "./Root/HeaderBar";
import SubHeaderBar from "./Root/SubHeaderBar";
import BackToBooking from "./Summary/BackToBooking";
import SignButton from "./Summary/SignButton";
import SummaryAcceptTerms from "./Summary/SummaryAcceptTerms";
import SummaryProject from "./Summary/SummaryProject";

import useLocalStorage from "./LocalStorage";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
}));

export default function Summary() {
  const styles = useStyles();

  const { storage } = useLocalStorage();

  const [privacy, setPrivacy] = React.useState(false);
  const [agb, setAgb] = React.useState(false);
  const [imageRights, setImageRights] = React.useState(false);

  return storage.booking && storage.personIDs ? (
    <>
      <HeaderBarPublic title="Zusammenfassung" />
      <SubHeaderBar
        left={<BackToBooking />}
        right={
          <SignButton imageRights={imageRights} disabled={!privacy || !agb} />
        }
      />
      <Body className={styles.root}>
        <SummaryProject id={storage.booking} />
        <SummaryAcceptTerms
          privacy={privacy}
          setPrivacy={setPrivacy}
          agb={agb}
          setAgb={setAgb}
          imageRights={imageRights}
          setImageRights={setImageRights}
        />
      </Body>
    </>
  ) : storage.booking ? (
    <Redirect to="/booking" />
  ) : (
    <Redirect to="/" />
  );
}
