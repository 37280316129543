import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { ChromePicker } from "react-color";

import { useSnackbar } from "notistack";

import { AppColor, Organisation, useServer } from "../../Server/ServerContext";
import InputWrapper from "../../InputWrapper";

import FormComponent from "../../FormComponent";

import { useStyles } from "../../Settings/ColorEditor";

export interface OrganisationProps {
  open: boolean;
  onClose: () => void;
  title: string;
  button: string;
  onClick: (room: Organisation) => void;
  organisation?: Organisation;
}

const emptyColor = {
  primary_color: "#2196f3",
  text_color: "#000000",
};

export default function OrganisationDialog(props: OrganisationProps) {
  const styles = useStyles();

  const [name, setName] = React.useState("");
  const [colorScheme, setColorScheme] = React.useState<AppColor>(emptyColor);
  const [impLink, setImpLink] = React.useState("");

  const onClick = () =>
    props.onClick({
      name,
      colorScheme: JSON.stringify(colorScheme),
      impLink,
      id: props.organisation?.id,
    } as Organisation);

  React.useEffect(() => {
    if (props.open) {
      setName(props.organisation?.name || "");
      setColorScheme(
        props.organisation
          ? JSON.parse(props.organisation.colorScheme)
          : emptyColor
      );
      setImpLink(props.organisation?.impLink || "");
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ component: FormComponent }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <InputWrapper>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Name"
            autoComplete="organization"
            required
            fullWidth
            margin="normal"
            autoFocus
          />
        </InputWrapper>
        <InputWrapper>
          <Box display="flex" justifyContent="space-around" width="100%">
            <Box>
              <Typography variant="h5">Primärfarbe</Typography>
              <ChromePicker
                className={styles.colorPicker}
                color={colorScheme.primary_color}
                onChange={(color) =>
                  setColorScheme({ ...colorScheme, primary_color: color.hex })
                }
                disableAlpha
              />
            </Box>
            <Box>
              <Typography variant="h5">Textfarbe</Typography>
              <ChromePicker
                className={styles.colorPicker}
                color={colorScheme.text_color}
                onChange={(color) =>
                  setColorScheme({ ...colorScheme, text_color: color.hex })
                }
                disableAlpha
              />
            </Box>
          </Box>
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={impLink}
            onChange={(event) => setImpLink(event.target.value)}
            label="Impressum Link"
            required
            fullWidth
            margin="normal"
          />
        </InputWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onClick}
          disabled={!name || !colorScheme || !impLink}
          type="submit"
        >
          {props.button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface CreateOrganisationProps {
  open: boolean;
  onClose: () => void;
  useOrganisations: [
    Organisation[],
    React.Dispatch<React.SetStateAction<Organisation[]>>
  ];
}

export function CreateOrganisation(props: CreateOrganisationProps) {
  const { masterData } = useServer();
  const [organisations, setOrganisations] = props.useOrganisations;
  const snackbar = useSnackbar();

  const onClick = (organisation: Organisation) => {
    masterData.organisation
      .add(organisation)
      .then((id) =>
        setOrganisations([...organisations, { ...organisation, id }])
      )
      .then(props.onClose)
      .snackbar({
        snackbar,
        success: "Organisation erfolgreich erstellt",
        admin: true,
      });
  };

  return (
    <OrganisationDialog
      title="Organisation hinzufügen"
      button="Erstellen"
      onClick={onClick}
      onClose={props.onClose}
      open={props.open}
    />
  );
}

export interface EditOrganisationProps extends CreateOrganisationProps {
  organisation?: Organisation;
}

export function EditOrganisation(props: EditOrganisationProps) {
  const { masterData } = useServer();
  const [organisations, setOrganisations] = props.useOrganisations;
  const snackbar = useSnackbar();

  const onClick = (organisation: Organisation) => {
    masterData.organisation
      .update((props.organisation as Organisation).id, organisation)
      .then(() =>
        setOrganisations(
          organisations.map((org) =>
            org.id !== organisation.id ? org : organisation
          )
        )
      )
      .then(props.onClose)
      .snackbar({
        snackbar,
        success: "Organisation erfolgreich erstellt",
        admin: true,
      });
  };

  return (
    <OrganisationDialog
      title="Organisation bearbeiten"
      button="Aktualisieren"
      onClick={onClick}
      onClose={props.onClose}
      open={props.open}
      organisation={props.organisation}
    />
  );
}
