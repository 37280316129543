import React from "react";
import { Redirect } from "react-router-dom";

import Body from "./Root/Body";
import HeaderBarPublic from "./Root/HeaderBar";
import SubHeaderBar from "./Root/SubHeaderBar";
import BackToSummary from "./Sign/BackToSummary";
import PaymentButton from "./Sign/PaymentButton";
import SignField from "./Sign/SignField";

import useLocalStorage from "./LocalStorage";
import config from "../config";
import { getQuery } from "../Functions/QueryParams";
import { Typography } from "@material-ui/core";

export default function Sign() {
  const { storage, setStorage } = useLocalStorage();
  const { namespace } = getQuery({ namespace: Number });

  const [signature, setSignature] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => setStorage({ waiting: undefined }), []);
  React.useEffect(() => {
    if (
      namespace !== undefined &&
      namespace !== null &&
      !document.cookie.includes("nscookie")
    ) {
      (async () => {
        await fetch(config.bookingServerLink + "nscookie", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            namespace,
          }),
          credentials: "include",
          mode: "cors",
        });
        setTimeout(() => window.location.reload(), 100);
      })();
    }
  }, [namespace]);

  return storage.booking && storage.personIDs ? (
    <>
      <HeaderBarPublic title="Unterschrift" />
      <SubHeaderBar
        left={<BackToSummary />}
        right={
          <PaymentButton
            disabled={!signature}
            onClick={() => setStorage({ sign: signature })}
          />
        }
      />
      <Body>
        {document.cookie.includes("nscookie") ? (
          <SignField onChange={setSignature} />
        ) : (
          <Typography variant="h4">
            Bitte warten, Sie werden weitergeleitet.
          </Typography>
        )}
      </Body>
    </>
  ) : storage.booking ? (
    <Redirect to="/booking" />
  ) : (
    <Redirect to="/" />
  );
}
