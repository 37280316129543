import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { useServer } from "../Server/ServerContext";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../LocalStorage";

import { ReactComponent as Logo } from "./../../resources/varausLogo.svg";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    fill: theme.palette.primary.main,
    maxWidth: 600,
  },
}));

export default function CompletedText() {
  const styles = useStyles();

  const history = useHistory();
  const { currentUser } = useServer();
  const { clear } = useLocalStorage();

  // Redirect back to root
  React.useLayoutEffect(() => {
    if (!currentUser) history.replace("/");
  }, [currentUser]);

  React.useEffect(() => clear(), []);

  return (
    <>
      <Typography variant="h4" className={styles.text}>
        Wir haben ihre Anmeldung entgegengenommen.
      </Typography>
      <Typography variant="h5" className={styles.text}>
        Bitte entnehmen Sie aus der Bestätigungsmail den aktuellen Status Ihrer
        Buchung. Falls Sie innerhalb der nächsten 5 Minuten keine
        Bestätigungsemail erhalten, melden Sie sich bitte erneut an.
      </Typography>
      <Typography variant="subtitle1" className={styles.text}>
        Viel Spaß beim Projekt wünscht
      </Typography>
      <Box className={styles.logoBox}>
        <Logo className={styles.logo} />
      </Box>
    </>
  );
}
