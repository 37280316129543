import React from "react";

import { Box, Link, makeStyles } from "@material-ui/core";
import { useServer } from "../Server/ServerContext";
import { getQuery } from "../../Functions/QueryParams";

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    left: 0,
    width: "100%",
    position: "fixed",
    background: "#ffffffa0",
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    margin: theme.spacing(1),
  },
}));

export default function Impressum() {
  const styles = useStyles();
  const { dataActions } = useServer();
  const query = getQuery({ forced: Boolean, facilities: Array }) as {
    forced: boolean;
    facilities: string[];
  };

  const [imprint, setImprint] = React.useState("");

  const updateImprint = async () => {
    if (query.forced && query.facilities?.[0])
      setImprint(await dataActions.getFacilityImprint(query.facilities[0]));
    else setImprint((await dataActions.getLegalURLs()).imprint_url);
  };

  React.useEffect(() => {
    updateImprint();
  }, [query.forced]);

  return (
    <Box className={styles.root}>
      <Box className={styles.box}>
        <Link target="_blank noopener noreferrer" href={imprint}>
          Impressum
        </Link>
      </Box>
      <Box className={styles.box}>
        Varaus © 2020 - {new Date().getFullYear()}
      </Box>
    </Box>
  );
}
