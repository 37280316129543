import React from "react";
import { useHistory } from "react-router";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  Dialog,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import {
  ParticipantState,
  ProjectHistory,
  useServer,
} from "../Server/ServerContext";
import { ParticipantInfoDetailsProps } from "./ParticipantInfoDetails";

const useStyles = makeStyles({
  textWrap: {
    whiteSpace: "pre-wrap",
  },
  changeStateButton: {
    float: "right",
  },
});

export default function ParticipantHistory(props: ParticipantInfoDetailsProps) {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [requestBookingChange, setRequestBookingChange] =
    React.useState<ProjectHistory | null>(null);
  const [participantState, setParticipantState] =
    React.useState<ParticipantState>(ParticipantState.booked);

  const [projectHistory, setProjectHistory] = useServer().useParticipantHistory(
    props.id
  );
  const { participantActions, projectActions } = useServer();

  const projectState = (entry: ProjectHistory) => {
    switch (entry.state) {
      case ParticipantState.booked:
        return "Angenommen";
      case ParticipantState.cancelled:
        return "Abgemeldet";
      case ParticipantState.waiting:
        return "Wartend";
    }
  };

  const redirectProject = (id: string) => history.push(`/admin/project/${id}`);

  const updateBookingState = async () => {
    const project = await projectActions.get(
      (requestBookingChange as ProjectHistory).id
    );

    const bookingPossible =
      project.bookings.filter(
        (booking) => booking.state === ParticipantState.booked
      ).length < project.maximumParticipants;
    const waitingPossible =
      project.bookings.filter(
        (booking) => booking.state === ParticipantState.waiting
      ).length < project.waitingListSize;

    switch (participantState) {
      case ParticipantState.booked:
        if (!bookingPossible)
          return enqueueSnackbar(
            "Die maximale Teilnehmerbegrenzung wurde bereits erreicht. Buchung nicht möglich",
            { variant: "error" }
          );
        break;
      case ParticipantState.waiting:
        if (!waitingPossible)
          return enqueueSnackbar(
            "Die maximale Wartelistengröße wurde bereits erreicht. Buchung nicht möglich",
            { variant: "error" }
          );
        break;
      case ParticipantState.cancelled:
        break;
    }

    await participantActions.updateState(
      props.id,
      (requestBookingChange as ProjectHistory).id,
      participantState
    );

    await projectActions.updateState(
      (requestBookingChange as ProjectHistory).id
    );

    // Update states and close inputs
    if (projectHistory)
      setProjectHistory(
        projectHistory.map((entry) =>
          entry.id !== (requestBookingChange as ProjectHistory).id
            ? entry
            : { ...entry, state: participantState }
        )
      );
    enqueueSnackbar("Buchung erfolgreich aktualisiert", { variant: "success" });
    setRequestBookingChange(null);
  };

  return (
    <>
      {!projectHistory?.length ? (
        <>
          <Typography variant="subtitle1" align="center">
            Es liegt keine Projekthistorie vor
          </Typography>
          <Typography variant="subtitle1" align="center">
            Der Nutzer hat bis jetzt an keinem Projekt teilgenommen
          </Typography>
        </>
      ) : (
        <List>
          <Dialog
            open={Boolean(requestBookingChange)}
            onClose={() => setRequestBookingChange(null)}
            TransitionProps={{
              onEntering: () => {
                setParticipantState(
                  (requestBookingChange as ProjectHistory).state
                );
              },
            }}
          >
            <DialogTitle>Buchungsstatus ändern</DialogTitle>

            <DialogContent>
              <TextField
                value={participantState}
                onChange={(event) =>
                  setParticipantState(parseInt(event.target.value))
                }
                margin="normal"
                select
                fullWidth
              >
                <MenuItem
                  value={ParticipantState.booked}
                  disabled={
                    requestBookingChange?.state === ParticipantState.booked
                  }
                >
                  Angenommen
                </MenuItem>
                <MenuItem
                  value={ParticipantState.waiting}
                  disabled={
                    requestBookingChange?.state === ParticipantState.waiting
                  }
                >
                  Wartend
                </MenuItem>
                <MenuItem
                  value={ParticipantState.cancelled}
                  disabled={
                    requestBookingChange?.state === ParticipantState.cancelled
                  }
                >
                  Abgemeldet
                </MenuItem>
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRequestBookingChange(null)}>
                Abbrechen
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={updateBookingState}
                disabled={requestBookingChange?.state === participantState}
              >
                Speichern
              </Button>
            </DialogActions>
          </Dialog>

          {projectHistory?.map((entry) => (
            <React.Fragment key={entry.id}>
              <ListItem onClick={() => redirectProject(entry.id)} button>
                <ListItemText
                  primary={entry.name}
                  secondary={
                    <>
                      {projectState(entry)}
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();

                          setRequestBookingChange(entry);
                        }}
                        className={styles.changeStateButton}
                      >
                        Status ändern
                      </Button>
                    </>
                  }
                  classes={{ secondary: styles.textWrap }}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
}
