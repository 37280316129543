import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  TextField,
  Box,
} from "@material-ui/core";

import { SettingsAccordion, SettingsListItemProps } from "./SettingsList";

import { useServer } from "../Server/ServerContext";
import { useSnackbar } from "notistack";
import { ExpandMoreRounded } from "@material-ui/icons";

export default function Paypal(props: SettingsListItemProps) {
  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();
  const snackbar = useSnackbar();

  const [clientID, setClientID] = React.useState(settings?.paypal_id);
  const [clientSecret, setClientSecret] = React.useState(
    settings?.paypal_secret
  );
  const [edited, setEdited] = React.useState(false);

  React.useEffect(() => {
    if (!edited) abort();
  }, [settings]);

  React.useEffect(
    () =>
      setEdited(
        clientID !== settings?.paypal_id ||
          clientSecret !== settings?.paypal_secret
      ),
    [clientID, clientSecret, settings]
  );

  const updatePaypal = () => {
    Promise.all([
      settingsActions.set("paypal_id", clientID),
      settingsActions.set("paypal_secret", clientSecret),
    ])
      .then(() =>
        setSettings({
          ...settings,
          paypal_id: clientID,
          paypal_secret: clientSecret,
        })
      )
      .snackbar({
        snackbar,
        success: "PayPal-Daten erfolgreich aktualisiert",
        admin: true,
      });
  };

  const abort = () => {
    setClientID(settings?.paypal_id);
    setClientSecret(settings?.paypal_secret);
  };

  return (
    <SettingsAccordion abort={abort} onClick={updatePaypal} disabled={!edited}>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        PayPal Accountdaten
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          <TextField
            value={clientID || ""}
            onChange={(event) => setClientID(event.target.value)}
            label="Client ID"
            fullWidth
            margin="dense"
          />
          <TextField
            value={clientSecret || ""}
            onChange={(event) => setClientSecret(event.target.value)}
            label="Client Secret"
            type="password"
            autoComplete="disabled"
            fullWidth
            margin="dense"
          />
        </Box>
      </AccordionDetails>
    </SettingsAccordion>
  );
}
