import React from "react";

import {
  Box,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ClearRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "inline-flex",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: "100%",
      position: "relative",
    },
  },
  sign: {
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
  },
}));

export interface SignFieldProps {
  onChange: (image: string | undefined) => void;
}

export default function SignField(props: SignFieldProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const styles = useStyles();

  const sign = React.useRef() as React.RefObject<Sign>;

  return (
    <Box textAlign="center">
      <Typography variant="subtitle1">Bitte hier unterschreiben</Typography>
      <Box color="primary.main" className={styles.wrapper} border={1}>
        <Sign
          ref={sign}
          resolution={
            mobile ? { height: 450, width: 450 } : { height: 300, width: 900 }
          }
          onChange={props.onChange}
          className={styles.sign}
        />
      </Box>
      <Box>
        {/* <Tooltip title="Rückgängig">
          <IconButton onClick={() => sign.current?.undo()}>
            <UndoRounded />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Löschen">
          <IconButton onClick={() => sign.current?.clear()}>
            <ClearRounded />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Wiederherstellen">
          <IconButton onClick={() => sign.current?.redo()}>
            <RedoRounded />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Box>
  );
}

export interface SignatureProps {
  color: string;
  strokeWidth: number;
  resolution: {
    height: number;
    width: number;
  };
  onChange?: (image: string | undefined) => void;
  className?: string;
}

class Sign extends React.Component<SignatureProps> {
  public static defaultProps: Partial<SignatureProps> = {
    color: "#220786",
    resolution: {
      height: 480,
      width: 480,
    },
    strokeWidth: 2,
  };

  constructor(props: any) {
    super(props);

    this.canvas = React.createRef();
  }
  moves: { x: number; y: number; isDown: boolean }[][] = [];
  redoMoves: { x: number; y: number; isDown: boolean }[][] = [];
  startMove: boolean = true;
  //button spam protection
  disableButton: boolean = false;

  canvas: React.RefObject<HTMLCanvasElement>;
  mouseDown: boolean = false;
  ctx?: CanvasRenderingContext2D;
  lastPos: { x: number; y: number } = { x: 0, y: 0 };

  componentDidMount = () => {
    if (this.canvas.current) {
      this.ctx = this.canvas.current.getContext(
        "2d"
      ) as CanvasRenderingContext2D;
      this.canvas.current.addEventListener("mousedown", this.mousedownListener);
      this.canvas.current.addEventListener("mousemove", this.mousemoveListener);
      this.canvas.current.addEventListener("mouseup", this.mouseendListener);
      this.canvas.current.addEventListener("mouseleave", this.mouseendListener);
      this.canvas.current.addEventListener(
        "mouseenter",
        this.mouseenterListener
      );
      this.canvas.current.addEventListener("click", this.mouseclickListener);
      this.canvas.current.addEventListener(
        "touchstart",
        this.touchstartListener
      );
      this.canvas.current.addEventListener("touchmove", this.touchmoveListener);
      this.canvas.current.addEventListener(
        "touchcancel",
        this.touchendListener
      );
      this.canvas.current.addEventListener("touchend", this.touchendListener);
    }
  };

  componentWillUnmount = () => {
    this.canvas.current?.removeEventListener(
      "mousedown",
      this.mousedownListener
    );
    this.canvas.current?.removeEventListener(
      "mousemove",
      this.mousemoveListener
    );
    this.canvas.current?.removeEventListener("mouseup", this.mouseendListener);
    this.canvas.current?.removeEventListener(
      "mouseleave",
      this.mouseendListener
    );
    this.canvas.current?.removeEventListener(
      "mouseenter",
      this.mouseenterListener
    );
    this.canvas.current?.removeEventListener("click", this.mouseclickListener);
    this.canvas.current?.removeEventListener(
      "touchstart",
      this.touchstartListener
    );
    this.canvas.current?.removeEventListener(
      "touchmove",
      this.touchmoveListener
    );
    this.canvas.current?.removeEventListener(
      "touchcancel",
      this.touchendListener
    );
    this.canvas.current?.removeEventListener("touchend", this.touchendListener);
  };

  touchstartListener = (e: any) => {
    this.redoMoves = [];
    this.startMove = true;
    if (this.canvas.current) {
      let rect = this.canvas.current.getBoundingClientRect();
      this.mouseDown = true;
      this.draw(
        ((e.touches[0].pageX - rect.left - window.scrollX) / rect.width) *
          this.props.resolution.width,
        ((e.touches[0].pageY - rect.top - window.scrollY) / rect.height) *
          this.props.resolution.height,
        false
      );
    }
  };

  touchmoveListener = (e: any) => {
    e.preventDefault();
    this.startMove = false;
    if (this.mouseDown && this.canvas.current) {
      let rect = this.canvas.current.getBoundingClientRect();
      this.draw(
        ((e.touches[0].pageX - rect.left - window.scrollX) / rect.width) *
          this.props.resolution.width,
        ((e.touches[0].pageY - rect.top - window.scrollY) / rect.height) *
          this.props.resolution.height,
        true
      );
    }
  };

  touchendListener = () => {
    this.mouseDown = false;
    this.props.onChange?.(this.getImage());
  };

  mouseclickListener = (e: any) => {
    this.redoMoves = [];
    e.preventDefault();
    if (this.canvas.current) {
      let rect = this.canvas.current.getBoundingClientRect();
      let [xPos, yPos] = [
        ((e.pageX - rect.left - window.scrollX) / rect.width) *
          this.props.resolution.width,
        ((e.pageY - rect.top - window.scrollY) / rect.height) *
          this.props.resolution.height,
      ];
      this.draw(xPos, yPos, false);
      this.draw(xPos + 1, yPos + 1, true);
      this.props.onChange?.(this.getImage());
    }
  };

  mousedownListener = (e: any) => {
    this.redoMoves = [];
    this.startMove = true;
    if (this.canvas.current) {
      let rect = this.canvas.current.getBoundingClientRect();
      this.mouseDown = true;
      this.draw(
        ((e.pageX - rect.left - window.scrollX) / rect.width) *
          this.props.resolution.width,
        ((e.pageY - rect.top - window.scrollY) / rect.height) *
          this.props.resolution.height,
        false
      );
    }
  };

  mousemoveListener = (e: any) => {
    this.startMove = false;
    if (this.mouseDown && this.canvas.current) {
      let rect = this.canvas.current.getBoundingClientRect();
      this.draw(
        ((e.pageX - rect.left - window.scrollX) / rect.width) *
          this.props.resolution.width,
        ((e.pageY - rect.top - window.scrollY) / rect.height) *
          this.props.resolution.height,
        true
      );
    }
  };

  mouseendListener = () => {
    this.mouseDown = false;
    this.props.onChange?.(this.getImage() as string);
  };

  mouseenterListener = (e: any) => {
    if (this.canvas.current && e.buttons === 1) {
      let rect = this.canvas.current.getBoundingClientRect();
      this.mouseDown = true;
      this.draw(
        ((e.pageX - rect.left - window.scrollX) / rect.width) *
          this.props.resolution.width,
        ((e.pageY - rect.top - window.scrollY) / rect.height) *
          this.props.resolution.height,
        false
      );
    }
  };

  draw = (x: number, y: number, isDown: boolean) => {
    if (this.startMove && !isDown) this.moves.push([{ x, y, isDown }]);
    else this.moves[this.moves.length - 1].push({ x, y, isDown });
    if (isDown && this.ctx) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.props.color;
      this.ctx.lineWidth = this.props.strokeWidth;
      this.ctx.lineJoin = "round";
      this.ctx.moveTo(this.lastPos.x, this.lastPos.y);
      this.ctx.lineTo(x, y);
      this.ctx.closePath();
      this.ctx.stroke();
    }
    this.lastPos = { x, y };
  };

  clearArea = () => {
    if (this.ctx) {
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    }
  };

  undo = () => {
    if (!this.disableButton) {
      this.disableButton = true;
      this.clearArea();
      this.lastPos = { x: 0, y: 0 };
      //@ts-ignore
      if (this.moves.length) this.redoMoves.push(this.moves.pop());
      this.moves.forEach((move) =>
        move.forEach((path) => this.draw(path.x, path.y, path.isDown))
      );
      setTimeout(() => (this.disableButton = false), 250);
    }

    if (this.moves.length) this.props.onChange?.(this.getImage());
    else this.props.onChange?.(undefined);
  };

  redo = () => {
    if (!this.disableButton) {
      this.disableButton = true;
      this.clearArea();
      this.lastPos = { x: 0, y: 0 };
      //@ts-ignore
      if (this.redoMoves.length) this.moves.push(this.redoMoves.pop());
      this.moves.forEach((move) =>
        move.forEach((path) => this.draw(path.x, path.y, path.isDown))
      );
      setTimeout(() => (this.disableButton = false), 250);
    }

    if (this.moves.length) this.props.onChange?.(this.getImage());
    else this.props.onChange?.(undefined);
  };

  getImage = () => {
    return this.canvas.current?.toDataURL();
  };

  getPath = () => this.moves;

  clear = () => {
    this.clearArea();
    this.moves = [];
    this.props.onChange?.(undefined);
  };

  render() {
    return (
      <canvas
        ref={this.canvas}
        style={{ touchAction: "none" }}
        height={this.props.resolution.height}
        width={this.props.resolution.width}
        {...(this.props.className ? { className: this.props.className } : {})}
      />
    );
  }
}
