import { FormControlLabel, TextField, Switch } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { isAdult } from "../../Functions/Age";
import GenderSelector from "../Account/GenderSelector";
import NationalitySelector from "../Account/NationalitySelector";
import InputWrapper from "../InputWrapper";
import { Member } from "../Server/ServerContext";

interface ShowParticipantDetailsProps {
  participant: Member;
}

export default function ShowParticipantDetails(
  props: ShowParticipantDetailsProps
) {
  const ignoreUner18Inputs =
    !props.participant?.linkedAccount ||
    isAdult(new Date(props.participant.birthday));

  return (
    <>
      {props.participant?.linkedAccount && (
        <InputWrapper>
          <TextField
            label="Email des zugehörigen Accounts"
            value={props.participant?.linkedAccount || ""}
            fullWidth
            margin="normal"
            disabled
          />
        </InputWrapper>
      )}
      <InputWrapper>
        <TextField
          label="Vorname"
          value={props.participant?.name || ""}
          autoComplete="given-name"
          required
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Nachname"
          value={props.participant?.surname || ""}
          autoComplete="family-name"
          required
          fullWidth
          margin="normal"
          disabled
        />
      </InputWrapper>
      <InputWrapper>
        <GenderSelector
          gender={props.participant?.gender || ""}
          label="Geschlecht"
          fullWidth
          margin="normal"
          setGender={() => {}}
          disabled
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd.MM.yyyy"
          label="Geburtstag"
          value={
            props.participant && props.participant.birthday
              ? new Date(props.participant.birthday)
              : null
          }
          helperText={undefined}
          autoComplete="bday"
          required
          fullWidth
          margin="normal"
          onChange={() => {}}
          disabled
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Straße"
          value={props.participant?.street || ""}
          autoComplete="street-address"
          required
          fullWidth
          margin="normal"
          disabled
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Postleitzahl"
          value={
            props.participant && isNaN(props.participant.zip)
              ? ""
              : props.participant?.zip.toString() || ""
          }
          autoComplete="postal-code"
          required
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Stadt"
          value={props.participant?.city || ""}
          autoComplete="address-level2"
          required
          fullWidth
          margin="normal"
          disabled
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Telefonnummer"
          value={props.participant?.phone || ""}
          autoComplete="tel"
          fullWidth
          margin="normal"
          disabled
        />
      </InputWrapper>
      <InputWrapper>
        <NationalitySelector
          nationality={props.participant?.nationality || null}
          fullWidth
          margin="normal"
          setNationality={() => {}}
          disabled
        />
        {!ignoreUner18Inputs && (
          <TextField
            label="Schule"
            value={props.participant?.school || ""}
            autoComplete="organization"
            fullWidth
            margin="normal"
            disabled
          />
        )}
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Allergien"
          value={props.participant?.allergies || ""}
          multiline
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Medikamente"
          value={props.participant?.medication || ""}
          multiline
          fullWidth
          margin="normal"
          disabled
        />
      </InputWrapper>
      <InputWrapper>
        <TextField
          label="Weitere Anmerkungen"
          value={props.participant?.other || ""}
          multiline
          rows={6}
          fullWidth
          margin="normal"
          disabled
        />
      </InputWrapper>
      {!ignoreUner18Inputs && (
        <InputWrapper>
          <FormControlLabel
            control={
              <Switch
                checked={props.participant?.swim || false}
                color="primary"
                disabled
              />
            }
            label="Kann schwimmen"
          />
          <FormControlLabel
            control={
              <Switch
                checked={props.participant?.goHomeAlone || false}
                color="primary"
                disabled
              />
            }
            label="Kann alleine nach Hause gehen"
          />
        </InputWrapper>
      )}
    </>
  );
}
