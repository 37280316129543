import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowForwardRounded } from "@material-ui/icons";

import useLocalStorage from "../LocalStorage";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";

export interface SummaryButtonProps {
  memberIDs: string[];
}

export default function SummaryButton(props: SummaryButtonProps) {
  const history = useHistory();
  const { setParsedStorage } = useLocalStorage();

  const redirect = () => {
    setParsedStorage({ personIDs: props.memberIDs });
    history.push("/summary");
  };

  return (
    <SubHeaderIconButton
      icon={<ArrowForwardRounded />}
      onClick={redirect}
      disabled={!Boolean(props.memberIDs.length)}
    >
      Weiter
    </SubHeaderIconButton>
  );
}
