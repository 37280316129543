import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { useSnackbar } from "notistack";

import { useServer } from "../../Server/ServerContext";
import FormComponent from "../../FormComponent";

export interface CreateEmployeeProps {
  open: boolean;
  onClose: () => void;
  useEmployees: [string[], React.Dispatch<React.SetStateAction<string[]>>];
}

export default function CreateEmployee(props: CreateEmployeeProps) {
  const [employees, setEmployees] = props.useEmployees;
  const { masterData } = useServer();
  const snackbar = useSnackbar();

  const [name, setName] = React.useState("");

  const createEmployee = () => {
    masterData.employees
      .add(name)
      .then(props.onClose)
      .then(() => {
        setEmployees([...employees, name]);
        setName("");
      })
      .snackbar({
        snackbar,
        success: "Mitarbeiter erfolgreich hinzugefügt",
        admin: true,
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{ component: FormComponent }}
    >
      <DialogTitle>Mitarbeiter hinzufügen</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={(event) => setName(event.target.value)}
          label="Name"
          fullWidth
          margin="normal"
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Abbrechen</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={createEmployee}
          disabled={!name}
          type="submit"
        >
          Erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
