import React from "react";

import { Room, useServer } from "../../Server/ServerContext";

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { DeleteForeverRounded } from "@material-ui/icons";

import { useSnackbar } from "notistack";

import useSearch, { TextHighlight } from "../../Search/SearchProvider";
import ConfirmDelete, { ConfirmDialogType } from "../../ConfirmDialog";

const useStyles = makeStyles(() => ({
  first: {
    width: "70%",
  },
  second: {
    width: "30%",
  },
}));

export interface LocationListProps {
  onClick: (location: Room) => void;
  useLocations: [Room[], React.Dispatch<React.SetStateAction<Room[]>>];
}

export default function LocationList(props: LocationListProps) {
  const styles = useStyles();

  const [locations, setLocations] = props.useLocations;
  const { masterData } = useServer();
  const snackbar = useSnackbar();
  const { search, isSearched } = useSearch();

  const [requestDelete, setRequestDelete] = React.useState<Room | null>(null);

  const removeRoom = (location: Room) => {
    masterData.rooms
      .delete(location.id)
      .then(() =>
        setLocations(
          locations.filter((filtered) => location.id !== filtered.id)
        )
      )
      .snackbar({
        snackbar,
        success: "Raum erfolgreich entfernt",
        error:
          "Der Raum kann nicht gelöscht werden, da er noch in Projekten verwendet wird",
      });
  };

  const filter = (location: Room) => {
    const { id, size, street, city, zip, ...rest } = location;
    return isSearched([
      rest,
      `Größe: ${location.size}m²`,
      `${location.street}, ${location.city}, ${location.zip}`,
    ]);
  };

  return (
    <List>
      <ConfirmDelete
        value={requestDelete}
        close={setRequestDelete}
        textRender={(value: Room) => value.name}
        onSuccess={removeRoom}
        type={ConfirmDialogType.delete}
      />

      {locations.filter(filter).map((location) => (
        <React.Fragment key={location.id}>
          <ListItem button onClick={() => props.onClick(location)}>
            <ListItemText
              primary={
                <TextHighlight search={search}>{location.name}</TextHighlight>
              }
              secondary={
                <TextHighlight
                  search={search}
                >{`${location.street}, ${location.city}, ${location.zip}`}</TextHighlight>
              }
              className={styles.first}
            />
            <ListItemText
              primary={
                <TextHighlight
                  search={search}
                >{`Größe: ${location.size}m²`}</TextHighlight>
              }
              secondary={
                <TextHighlight search={search}>
                  {location.characteristics}
                </TextHighlight>
              }
              className={styles.second}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => setRequestDelete(location)}>
                <DeleteForeverRounded />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
