import React from "react";

import Body from "../Root/Body";
import EmployeeList from "./Employee/EmployeeList";
import CreateEmployee from "./Employee/CreateEmployee";

import { Button } from "@material-ui/core";
import { AddCircleOutlineRounded } from "@material-ui/icons";

import { useServer } from "../Server/ServerContext";

export default function Employee() {
  const [createEmployee, setCreateEmployee] = React.useState(false);

  const useEmployees = useServer().useEmployees();

  return (
    <Body>
      <CreateEmployee
        open={createEmployee}
        onClose={() => setCreateEmployee(false)}
        useEmployees={useEmployees}
      />
      <Button
        variant="contained"
        fullWidth
        startIcon={<AddCircleOutlineRounded />}
        onClick={() => setCreateEmployee(true)}
      >
        Mitarbeiter erstellen
      </Button>
      <EmployeeList useEmployees={useEmployees} />
    </Body>
  );
}
