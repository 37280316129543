import ReactDOM from "react-dom";
import React from "react";
import { useServer } from "../Server/ServerContext";
import { useHistory } from "react-router";
import useLocalStorage from "../LocalStorage";
import { CircularProgress } from "@material-ui/core";

let PayPalButton: JSX.IntrinsicAttributes | null = null;

export default function PaypalPayment(props: {
  bookProject: () => Promise<string>;
}) {
  const history = useHistory();
  const { customerProjectActions } = useServer();
  const setBookingid = React.useState("")[1];
  const { setParsedStorage } = useLocalStorage();
  const [loading, setLoading] = React.useState(true);

  const createOrder = async () => {
    const bookingid = await props.bookProject();
    if (typeof bookingid !== "string") return "";
    setBookingid(bookingid);
    try {
      const paymentID = await customerProjectActions.createPaymentIntend(
        bookingid
      );
      return paymentID;
    } catch (err) {
      setParsedStorage({ forcePaypal: true });
      throw err;
    }
  };

  const onApprove = async (data: any, actions: any) => {
    setBookingid((bookingid) => {
      actions.order.capture().then(() => alert("Payment Successful"));
      customerProjectActions
        .completePayment(bookingid)
        .then(() => history.push("/completed"))
        .catch((err) => {
          setParsedStorage({ forcePaypal: true });
          throw err;
        });
      return bookingid;
    });
  };

  React.useEffect(() => {
    //@ts-ignore
    PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
    setLoading(false);
  }, []);

  return !loading ? (
    //@ts-ignore
    <PayPalButton
      createOrder={createOrder}
      onApprove={(data: any, actions: any) => {
        onApprove(data, actions);
      }}
    />
  ) : (
    <CircularProgress />
  );
}
