import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowBackRounded } from "@material-ui/icons";

import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";

export default function BackToSummary() {
  const history = useHistory();
  const backToSign = () => {
    history.push("/sign");
  };

  return (
    <SubHeaderIconButton onClick={backToSign} icon={<ArrowBackRounded />}>
      Zurück zum Unterschreiben
    </SubHeaderIconButton>
  );
}
