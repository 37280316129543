import React from "react";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import CombineDupplicateParticipants from "./ParticipantDB/CombineDupplicateParticipants";
import ParticipantList from "./ParticipantDB/ParticipantList";
import Body from "./Root/Body";

import { Box } from "@material-ui/core";
import { Search, SearchProvider } from "./Search/SearchProvider";
import AddParticipant from "./ParticipantDB/AddParticipant";
import { PersonOverviewObject, useServer } from "./Server/ServerContext";

export interface ParticipantDBProps {
  useParticipant: [
    PersonOverviewObject[],
    React.Dispatch<React.SetStateAction<PersonOverviewObject[]>>
  ];
}

export default function ParticipantDB() {
  const useParticipant = useServer().useParticipants();

  return (
    <SearchProvider>
      <HeaderBarAdmin title="Kundenkontendatenbank" />
      <Body>
        <Box display="flex" justifyContent="space-between">
          <Search />
          <Box>
            <AddParticipant useParticipant={useParticipant} />
            <CombineDupplicateParticipants />
          </Box>
        </Box>
        <ParticipantList useParticipant={useParticipant} />
      </Body>
    </SearchProvider>
  );
}
