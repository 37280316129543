import React from "react";
import { updateQuery, getQuery } from "../../Functions/QueryParams";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grow,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import {
  CustomerProjectOverviewObject,
  DatetimeRepeat,
  ProjectOverviewObject,
  ProjectState,
  useServer,
} from "../Server/ServerContext";
import { isSearched } from "../Search/SearchProvider";
import useLocalStorage, { Storage } from "../LocalStorage";
import { nextMeetingDate } from "../../Functions/Datetime";

export const defaultSearchSettings = {
  search: "",
  hideOld: true,
  hideFull: true,
  facilities: [],
  durationStart: null,
  durationEnd: null,
  age: [0, 99] as [number, number],
  forced: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: FilterSidebarProps) => (props.in ? 250 : 100),
      marginLeft: (props: FilterSidebarProps) =>
        props.in ? theme.spacing(3) : 0,
      marginRight: (props: FilterSidebarProps) =>
        props.in ? theme.spacing(3) : 0,
      transition: theme.transitions.create(["max-width", "margin"]),
      width: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: (props: FilterSidebarProps) => (props.in ? 490 : 200),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(3) * 2}px) !important`,
      transition: theme.transitions.create(["max-height"]),
      height: "auto",
    },
  },
  switch: {
    whiteSpace: "nowrap",
  },
  container: {
    height: "fit-content",
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: { in: boolean }) => (props.in ? "100vw" : "0"),
      transition: theme.transitions.create(["max-width"]),
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: (props: { in: boolean }) => (props.in ? "120vh" : "0"),
      transition: theme.transitions.create(["max-height"]),
    },
  },
  grow: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      width: "max-content",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export interface QueryParams {
  search: string;
  hideOld: boolean;
  hideFull: boolean;
  facilities: string[];
  durationStart: Date;
  durationEnd: Date;
  age: [number, number];
  forced: boolean;
}

export interface FilterSidebarProps {
  in: boolean;
}

export default function FilterSidebar(props: FilterSidebarProps) {
  const styles = useStyles(props);

  const serverFacilities = useServer().useFacilities();
  const {
    search,
    setSearch,
    hideOld,
    setHideOld,
    hideFull,
    setHideFull,
    facilities,
    setFacilities,
    durationStart,
    setDurationStart,
    durationEnd,
    setDurationEnd,
    age,
    setAge,
    ignoreFacility,
  } = React.useContext(FilterContext);

  const resetFilter = () => {
    setSearch(defaultSearchSettings.search);
    setHideOld(defaultSearchSettings.hideOld);
    setHideFull(defaultSearchSettings.hideFull);
    setFacilities(defaultSearchSettings.facilities);
    setDurationStart(defaultSearchSettings.durationStart);
    setDurationEnd(defaultSearchSettings.durationEnd);
    setAge(defaultSearchSettings.age);
  };

  return (
    <Box className={styles.container}>
      <Grow in={props.in}>
        <Box className={styles.grow}>
          <Box
            display="inline-flex"
            flexDirection="column"
            flexShrink={0}
            className={styles.root}
          >
            <TextField
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              label="Suche"
              margin="normal"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={hideOld}
                  onChange={(event) => setHideOld(event.target.checked)}
                  color="primary"
                />
              }
              label="Aktuelle Angebote anzeigen"
              classes={{ label: styles.switch }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={hideFull}
                  onChange={(event) => setHideFull(event.target.checked)}
                  color="primary"
                />
              }
              label="Freie Plätze anzeigen"
              classes={{ label: styles.switch }}
            />

            {!ignoreFacility && (
              <FormControl margin="normal">
                <InputLabel>Einrichtungen</InputLabel>
                <Select
                  multiple
                  value={facilities}
                  onChange={(event) =>
                    setFacilities(event.target.value as string[])
                  }
                  renderValue={(selected) => (selected as string[]).join(", ")}
                  input={<Input />}
                >
                  {serverFacilities.map((facility) => (
                    <MenuItem key={facility} value={facility}>
                      <Checkbox
                        checked={facilities.includes(facility)}
                        color="primary"
                      />
                      <ListItemText primary={facility} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              label="Zeitraum Beginn"
              value={durationStart}
              onChange={setDurationStart}
              helperText=""
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              label="Zeitraum Ende"
              value={durationEnd}
              onChange={setDurationEnd}
              helperText=""
            />

            <FormControl margin="normal">
              <Typography>Alter</Typography>
              <Slider
                value={age}
                onChange={(_, value) => setAge(value as [number, number])}
                valueLabelDisplay="auto"
                min={defaultSearchSettings.age[0]}
                max={defaultSearchSettings.age[1]}
              />
            </FormControl>

            <Button onClick={resetFilter} variant="outlined">
              Filter löschen
            </Button>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

const FilterContext = React.createContext<Filter>(undefined!);

export interface Filter {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  hideOld: boolean;
  setHideOld: React.Dispatch<React.SetStateAction<boolean>>;
  hideFull: boolean;
  setHideFull: React.Dispatch<React.SetStateAction<boolean>>;
  facilities: string[];
  setFacilities: React.Dispatch<React.SetStateAction<string[]>>;
  durationStart: Date | null;
  setDurationStart: React.Dispatch<React.SetStateAction<Date | null>>;
  durationEnd: Date | null;
  setDurationEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  age: [number, number];
  setAge: React.Dispatch<React.SetStateAction<[number, number]>>;
  filter: (
    project: CustomerProjectOverviewObject | ProjectOverviewObject
  ) => boolean;
  ignoreFacility: boolean;
}

export function useFilter() {
  return React.useContext(FilterContext);
}

export function FilterProvider(props: { children: React.ReactNode }) {
  const isOnAdminSite = window.location.href.includes("admin");

  const { setParsedStorage, parsedStorage } = useLocalStorage();
  const { setAdminProjectDuration } = useServer();

  const [search, setSearch] = React.useState<string>(
    defaultSearchSettings.search
  );
  const [hideOld, setHideOld] = React.useState<boolean>(
    defaultSearchSettings.hideOld
  );
  const [hideFull, setHideFull] = React.useState<boolean>(
    defaultSearchSettings.hideFull
  );
  const [facilities, setFacilities] = React.useState<string[]>(
    defaultSearchSettings.facilities
  );
  const [durationStart, setDurationStart] = React.useState<Date | null>(
    defaultSearchSettings.durationStart
  );
  const [durationEnd, setDurationEnd] = React.useState<Date | null>(
    defaultSearchSettings.durationEnd
  );
  const [age, setAge] = React.useState<[number, number]>(
    defaultSearchSettings.age
  );

  const setup = React.useRef(false);

  const ignoreFacility = React.useRef(false);

  React.useEffect(() => {
    if (!setup.current) return;

    const params = {
      search,
      hideOld,
      hideFull,
      facilities: [...facilities],
      durationStart,
      durationEnd,
      age: [...age],
    };

    setParsedStorage(
      {
        search: params,
      },
      Storage.session
    );

    updateQuery(
      { ...params, ...{ forced: ignoreFacility.current } },
      defaultSearchSettings
    );

    setAdminProjectDuration({
      durationStart: durationStart && new Date(durationStart),
      durationEnd: durationEnd && new Date(durationEnd),
    });
  }, [
    search,
    hideOld,
    hideFull,
    facilities,
    durationStart,
    durationEnd,
    age,
    ignoreFacility,
  ]);

  React.useEffect(() => {
    const storage = parsedStorage("search", Storage.session) as
      | QueryParams
      | undefined;

    const query = getQuery({
      search: String,
      hideOld: Boolean,
      hideFull: Boolean,
      facilities: Array,
      durationStart: Date,
      durationEnd: Date,
      age: Array,
      forced: Boolean,
    }) as QueryParams | undefined;

    setSearch(query?.search ?? storage?.search ?? defaultSearchSettings.search);
    setHideOld(
      query?.hideOld ?? storage?.hideOld ?? defaultSearchSettings.hideOld
    );
    setHideFull(
      query?.hideFull ?? storage?.hideFull ?? defaultSearchSettings.hideFull
    );
    setFacilities(
      query?.facilities ??
        storage?.facilities ??
        defaultSearchSettings.facilities
    );
    setDurationStart(
      query?.durationStart ??
        storage?.durationStart ??
        defaultSearchSettings.durationStart
    );
    setDurationEnd(
      query?.durationEnd ??
        storage?.durationEnd ??
        defaultSearchSettings.durationEnd
    );
    setAge(
      (query?.age?.map((string) => parseInt(string as any) || 0) as [
        number,
        number
      ]) ??
        storage?.age ??
        defaultSearchSettings.age
    );

    setAdminProjectDuration({
      durationStart:
        (query?.durationStart || storage?.durationStart) &&
        new Date(query?.durationStart ?? (storage?.durationStart as Date)),
      durationEnd:
        (query?.durationEnd || storage?.durationEnd) &&
        new Date(query?.durationEnd ?? (storage?.durationEnd as Date)),
    });

    ignoreFacility.current = query?.forced ?? storage?.forced ?? false;

    setup.current = true;
  }, []);

  const filter = (
    project: CustomerProjectOverviewObject | ProjectOverviewObject
  ) => {
    const {
      organisations,
      state,
      minimumAge,
      maximumAge,
      datetime,
      ...rest
    }: Partial<CustomerProjectOverviewObject | ProjectOverviewObject> = project;

    // Remove unused keys
    delete rest.id;
    // @ts-ignore
    delete rest.image;

    // Age in boundaries
    if (maximumAge < age[0] || age[1] < minimumAge) return false;

    // At current location
    if (
      facilities.length &&
      !organisations.some((facility) =>
        facilities.includes(facility.organisation.name)
      )
    )
      return false;

    // If full or old
    if (hideFull && state === ProjectState.Full) return false;

    const nextMeeting = nextMeetingDate(datetime);
    if (hideOld && nextMeeting && nextMeeting.getTime() < new Date().getTime())
      return false;

    // Duration
    if (
      durationStart &&
      (nextMeeting
        ? nextMeeting.getTime()
        : new Date(datetime.begin || Infinity).getTime()) <
        new Date(durationStart).getTime()
    )
      return false;
    if (
      durationEnd &&
      (nextMeeting
        ? nextMeeting.getTime()
        : new Date(datetime.end || 0).getTime()) >
        new Date(durationEnd).getTime()
    )
      return false;

    // Search in name, description, and subtitle
    if (search && !isSearched([rest], search)) return false;

    // Alternatively show project
    return true;
  };

  return (
    <FilterContext.Provider
      value={{
        search,
        setSearch,
        hideOld,
        setHideOld,
        hideFull,
        setHideFull,
        facilities,
        setFacilities,
        durationStart,
        setDurationStart,
        durationEnd,
        setDurationEnd,
        age,
        setAge,
        filter,
        ignoreFacility: ignoreFacility.current,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
}
