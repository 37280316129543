import React from "react";

import { Button, Link } from "@material-ui/core";

export default function SepaPayment(props: { bookProject: () => void }) {
  return (
    <Link
      onClick={props.bookProject}
      target="_blank noopener noreferrer"
      style={{ width: "100%" }}
    >
      <Button variant="contained" color="primary" fullWidth>
        SEPA-Lastschrift (Drucker erforderlich)
      </Button>
    </Link>
  );
}
