import React from "react";

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";

import { DatetimeRepeat } from "../Server/ServerContext";
import { isAdvancedDatetime } from "../../Functions/Datetime";
import ProjectRepeatComplex from "./ProjectRepeatComplex";
import ProjectRepeatSimple from "./ProjectRepeatSimple";
import { parseProjectDateTime } from "../../Functions/Datetime";
import { SettingsAccordion } from "../Settings/SettingsList";
import { emptyProject } from "../CreateProject/CreateProjectInput";

const useStyles = makeStyles({
  preview: {
    whiteSpace: "pre-wrap",
  },
});

export interface ProjectRepeatProps {
  repeat: DatetimeRepeat;
  applyRepeat?: (repeat: DatetimeRepeat) => void;
  onChange?: (repeat: DatetimeRepeat, valid: boolean) => void;
}

export default function ProjectRepeat(props: ProjectRepeatProps) {
  const styles = useStyles();

  const [showAdvanced, setShowAdvanced] = React.useState(
    isAdvancedDatetime(props.repeat)
  );

  const [datetime, setDatetime] = React.useState({ ...props.repeat });
  const [simpleIsValid, setSimpleIsValid] = React.useState(false);
  const [complexIsValid, setComplexIsValid] = React.useState(false);

  React.useEffect(() => setDatetime(props.repeat), [props.repeat]);

  const onChange = (
    datetimeNew: Partial<DatetimeRepeat>,
    valid: boolean,
    type: "simple" | "complex"
  ) => {
    const repeat = { ...datetime, ...datetimeNew };
    if (props.onChange) return props.onChange(repeat, isValid(type, valid));
    if (valid) setDatetime(repeat);
  };

  const applyRepeat = (datetimeNew: Partial<DatetimeRepeat>) => {
    if (props.onChange)
      return props.applyRepeat?.({ ...datetime, ...datetimeNew });
    props.applyRepeat?.(datetime);
  };

  // "Override" valid state for simple and complex check -- setState is not updated immediately to simple and complex
  const isValid = (type: "simple" | "complex", override: boolean) => {
    switch (type) {
      case "simple":
        return showAdvanced ? complexIsValid && override : override;
      case "complex":
        return showAdvanced ? override && simpleIsValid : simpleIsValid;
    }
  };

  const { begin, end, ...complex } = props.repeat;

  return (
    <Box paddingBottom={4}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={showAdvanced}
              onChange={(_, checked) => setShowAdvanced(checked)}
              color="primary"
            />
          }
          label={showAdvanced ? "Komplexe Ansicht" : "Einfache Ansicht"}
        />
      </Box>
      <ProjectRepeatSimple
        repeat={{ begin, end }}
        onChange={(repeat, valid) => {
          setSimpleIsValid(valid);
          onChange(repeat, valid, "simple");
        }}
        applyRepeat={props.applyRepeat ? applyRepeat : undefined}
        showTime={!showAdvanced}
      />
      {showAdvanced && (
        <ProjectRepeatComplex
          repeat={complex}
          onChange={(repeat, valid) => {
            setComplexIsValid(valid);
            onChange({ ...repeat }, valid, "complex");
          }}
          applyRepeat={props.applyRepeat ? applyRepeat : undefined}
        />
      )}

      <SettingsAccordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          Nutzeransicht
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={styles.preview}>
            {parseProjectDateTime({
              repeat: showAdvanced
                ? datetime.repeat ?? props.repeat.repeat
                : emptyProject.datetime.repeat,
              daily: showAdvanced
                ? datetime.daily
                : emptyProject.datetime.daily,
              week: showAdvanced
                ? datetime.week ?? props.repeat.week
                : emptyProject.datetime.week,
              month: showAdvanced
                ? datetime.month ?? props.repeat.daily
                : emptyProject.datetime.month,
              begin: datetime.begin ?? props.repeat.begin,
              end: datetime.end ?? props.repeat.end,
            })}
          </Typography>
        </AccordionDetails>
      </SettingsAccordion>
    </Box>
  );
}
