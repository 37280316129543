import React from "react";

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import BookingButton from "./BookingButton";
import {
  AllowedGenders,
  CustomerProject,
  ProjectState,
} from "../Server/ServerContext";
import { parseProjectDateTime } from "../../Functions/Datetime";
import { allowsGenderToString } from "../../Functions/Gender";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  projectDetails: {
    display: "flex",
    margin: theme.spacing(1),
    flexDirection: "column",
  },
  projectDetailsContent: {
    display: "flex",
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row-reverse",
    },
  },
  description: {
    flex: "1 1 0",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "40%",
    },
  },
  project: {
    flex: "1 1 0",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "60%",
    },
  },
  imageWrapper: {
    width: "100%",
    maxHeight: "50vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  image: {
    flexGrow: 1,
    maxWidth: "100%",
    maxHeight: "50vh",
    objectFit: "contain",
    boxShadow: "0 4px 8px 0 rgb( 0 0 0 / 40%)",
  },
  projectActions: {
    justifyContent: "flex-end",
  },
  textWrapSpace: {
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
  },
}));

export interface ProjectDetailsProps {
  project?: CustomerProject;
}

export default function ProjectDetails(props: ProjectDetailsProps) {
  const styles = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Card className={styles.projectDetails}>
        <CardContent className={styles.projectDetailsContent}>
          <Box className={styles.project}>
            <Box {...(!mobile ? { textAlign: "right" } : {})}>
              <Typography variant={mobile ? "h4" : "h3"}>
                {props.project?.name || (
                  <Skeleton
                    className={!mobile ? styles.alignRight : undefined}
                    width={300}
                  />
                )}
              </Typography>
              <Typography color="textSecondary">
                {props.project?.subtitle || (
                  <Skeleton className={styles.alignRight} width={500} />
                )}
              </Typography>
            </Box>
            <Box
              borderLeft={1}
              marginTop={5}
              paddingLeft={3}
              {...(!mobile ? { marginLeft: 5 } : {})}
              color="primary.main"
            >
              {(!props.project || props.project?.bookingPeriodStart) && (
                <Typography
                  color="textSecondary"
                  className={styles.textWrapSpace}
                >
                  {props.project ? (
                    `Das Projekt kann vom ${new Date(
                      props.project.bookingPeriodStart
                    ).toLocaleString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })} bis ${new Date(
                      props.project.bookingPeriodEnd
                    ).toLocaleString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })} gebucht werden`
                  ) : (
                    <Skeleton width={150} />
                  )}
                </Typography>
              )}
              <Typography
                color="textSecondary"
                className={styles.textWrapSpace}
              >
                {props.project ? (
                  parseProjectDateTime(props.project.datetime)
                ) : (
                  <Skeleton width={150} />
                )}
              </Typography>
              <Typography
                color="textSecondary"
                className={styles.textWrapSpace}
              >
                {props.project ? (
                  `Im Alter von ${props.project.minimumAge} bis ${props.project.maximumAge} Jahren`
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
              {props.project?.zipFilter && (
                <Typography
                  color="textSecondary"
                  className={styles.textWrapSpace}
                >
                  {`Es sind nur TeilnehmerInnen aus den Postleitzahlen ${props.project.zipFilter
                    .split(",")
                    .map((zip) => (zip.length >= 5 ? zip : zip.padEnd(5, "X")))
                    .join(", ")} erlaubt`}
                </Typography>
              )}
              {!props.project?.bookingRequired && (
                <Typography
                  color="textSecondary"
                  className={styles.textWrapSpace}
                >
                  Anmeldung nicht notwendig
                </Typography>
              )}

              <Typography
                color="textSecondary"
                className={styles.textWrapSpace}
              >
                {props.project ? (
                  props.project.allowedGenders ===
                  AllowedGenders.Both ? null : (
                    `Für ${allowsGenderToString(
                      props.project.allowedGenders
                    )} geeignet`
                  )
                ) : (
                  <Skeleton width={200} />
                )}
              </Typography>
              <Typography
                color="textSecondary"
                className={styles.textWrapSpace}
              >
                {props.project ? (
                  `Die Teilnahmegebühr beträgt: ${props.project.fees
                    .toString()
                    .padStart(3, "0")
                    .replace(/(\d*)(\d{2})/, "$1,$2")}€`
                ) : (
                  <Skeleton width={150} />
                )}
              </Typography>
              {props.project?.bookingRequired && (
                <Typography
                  color="textSecondary"
                  className={styles.textWrapSpace}
                >
                  {(() => {
                    switch (props.project?.state) {
                      case ProjectState.Free:
                        return "Aktuell sind noch Plätze buchbar";
                      case ProjectState.WaitingList:
                        return "Das Projekt ist bereits ausgebucht. Sie können sich aber durch Ihre Buchung auf der Warteliste vormerken lassen";
                      case ProjectState.Full:
                        return "Das Projekt ist bereits ausgebucht";
                    }
                  })()}
                </Typography>
              )}
            </Box>
            <Box
              borderRight={1}
              paddingRight={3}
              textAlign="right"
              color="primary.main"
              {...(mobile ? { marginTop: 3 } : { marginRight: 2 })}
            >
              <Typography
                color="textSecondary"
                className={styles.textWrapSpace}
              >
                {props.project ? (
                  props.project.organisations.join(", ")
                ) : (
                  <Skeleton className={styles.alignRight} width={400} />
                )}
              </Typography>
              <Typography
                color="textSecondary"
                className={styles.textWrapSpace}
              >
                {props.project ? (
                  props.project.room.name
                ) : (
                  <Skeleton className={styles.alignRight} width={250} />
                )}
              </Typography>
              {props.project?.room.zip !== null && (
                <>
                  <Typography
                    color="textSecondary"
                    className={styles.textWrapSpace}
                  >
                    {props.project?.room.street === undefined ? (
                      <Skeleton className={styles.alignRight} width={200} />
                    ) : (
                      props.project.room.street
                    )}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={styles.textWrapSpace}
                  >
                    {props.project ? (
                      `${props.project.room.city}, ${props.project.room.zip}`
                    ) : (
                      <Skeleton className={styles.alignRight} width={230} />
                    )}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Box className={styles.description}>
            {props.project?.image && (
              <Box className={styles.imageWrapper}>
                <img
                  className={styles.image}
                  src={config.fileRootPath + props.project.image}
                  alt="Projektbild"
                />
              </Box>
            )}
            <Typography color="textSecondary" className={styles.textWrapSpace}>
              Projektbeschreibung
            </Typography>
            <Typography className={styles.textWrapSpace}>
              {props.project?.description || (
                <Skeleton variant="rect" height={500} />
              )}
            </Typography>
          </Box>
        </CardContent>
        <CardActions className={styles.projectActions}>
          {props.project?.bookingRequired && (
            <BookingButton
              disabled={props.project?.state === ProjectState.Full}
              project={props.project}
            />
          )}
        </CardActions>
      </Card>
    </>
  );
}
