import React from "react";

import { Box, useMediaQuery, useTheme } from "@material-ui/core";

import PersonEditList from "./Account/PersonEditList";
import HeaderBarPublic from "./Root/HeaderBar";
import BackToProjects from "./Account/BackToProjects";
import UpdateLogin from "./Account/UpdateLogin";
import SubHeaderBar from "./Root/SubHeaderBar";
import Body from "./Root/Body";

export function Account() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <HeaderBarPublic title="Account" />
      <SubHeaderBar left={<BackToProjects />} />
      {!mobile ? (
        <Body display="flex">
          <PersonEditList />
          <UpdateLogin />
        </Body>
      ) : (
        <Body>
          <Box marginBottom={3}>
            <UpdateLogin />
          </Box>
          <PersonEditList />
        </Body>
      )}
    </>
  );
}
