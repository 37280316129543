import React from "react";

import { colors, makeStyles, TextField } from "@material-ui/core";

import Highlighter from "react-highlight-words";

export function Search() {
  const { search, setSearch } = useSearch();

  return (
    <TextField
      value={search}
      onChange={(event) => setSearch(event.target.value)}
      label="Suche"
    />
  );
}

export type isSearchedValueParam = (string | number | object)[];

export const matchLowerCased = (match: string, search: string) =>
  search
    // Lowercase in order to check strings without casing
    .toLowerCase()
    // Split into substrings
    .split(" ")
    // Filter out any "" strings
    .filter(Boolean)
    // Check if searched substrings match
    .some((search) => match.toLowerCase().includes(search));

export const isSearched = (
  values: isSearchedValueParam,
  search: string
): boolean =>
  !search ||
  values.some((value) => {
    switch (typeof value) {
      case "string":
        return matchLowerCased(value, search);
      case "number":
        return matchLowerCased(value.toString(), search);
      case "object":
        // filter out values that should/can't be converted to string
        if (value === undefined || value === null) return false;
        // parse date to string
        if (Object.prototype.toString.call(value) === "[object Date]")
          return matchLowerCased((value as Date).toLocaleString(), search);
        // recursion for object with a depth bigger than 1
        // Object.values also works for arrays
        return Object.values(value).some((value: string | number | object) => {
          return isSearched([value], search);
        });
    }
    return false;
  });

export interface SearchProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  isSearched: (values: isSearchedValueParam) => boolean;
}

export const SearchContext = React.createContext<SearchProps>(undefined!);

export function SearchProvider(props: { children: React.ReactNode }) {
  const [search, setSearch] = React.useState("");

  const isSearchedHander = (values: isSearchedValueParam) =>
    isSearched(values, search);

  return (
    <SearchContext.Provider
      value={{ search, setSearch, isSearched: isSearchedHander }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}

export default function useSearch() {
  return React.useContext(SearchContext);
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.yellow[200],
    borderRadius: theme.shape.borderRadius,
  },
}));

export interface TextHighlightProps {
  children: string;
  search: string;
}

export function TextHighlight(props: TextHighlightProps) {
  const styles = useStyles();

  return (
    <Highlighter
      searchWords={props.search.split(" ")}
      textToHighlight={props.children}
      highlightClassName={styles.root}
      autoEscape
    />
  );
}
