import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  TextField,
  Box,
} from "@material-ui/core";

import { SettingsAccordion, SettingsListItemProps } from "./SettingsList";

import { useServer } from "../Server/ServerContext";
import { useSnackbar } from "notistack";
import { ExpandMoreRounded } from "@material-ui/icons";

export default function LegalURLsEditor(props: SettingsListItemProps) {
  const [settings, setSettings] = props.useSettings;
  const { settingsActions } = useServer();
  const snackbar = useSnackbar();

  const [dataPrivacyURL, setDataPrivacyURL] = React.useState(
    settings?.data_privacy_url
  );
  const [agbURL, setAgbURL] = React.useState(settings?.agb_url);
  const [imageRightsURL, setImageRightsURL] = React.useState(
    settings?.image_rights_url
  );
  const [imprintURL, setImprintURL] = React.useState(settings?.imprint_url);
  const [supportEmail, setSupportEmail] = React.useState(
    settings?.support_email
  );
  const [edited, setEdited] = React.useState(false);

  React.useEffect(() => {
    if (!edited) abort();
  }, [settings]);

  React.useEffect(
    () =>
      setEdited(
        dataPrivacyURL !== settings?.data_privacy_url ||
          agbURL !== settings?.agb_url ||
          imageRightsURL !== settings?.image_rights_url ||
          imprintURL !== settings?.imprint_url ||
          supportEmail !== settings?.support_email
      ),
    [dataPrivacyURL, agbURL, imageRightsURL, supportEmail, imprintURL, settings]
  );

  const updateURLs = () => {
    Promise.all([
      settingsActions.set("data_privacy_url", dataPrivacyURL),
      settingsActions.set("agb_url", agbURL),
      settingsActions.set("image_rights_url", imageRightsURL),
      settingsActions.set("imprint_url", imprintURL),
      settingsActions.set("support_email", supportEmail),
    ])
      .then(() =>
        setSettings({
          ...settings,
          data_privacy_url: dataPrivacyURL,
          agb_url: agbURL,
          image_rights_url: imageRightsURL,
          imprint_url: imprintURL,
          support_email: supportEmail,
        })
      )
      .snackbar({
        snackbar,
        success: "Daten erfolgreich aktualisiert",
        admin: true,
      });
  };

  const abort = () => {
    setDataPrivacyURL(settings?.data_privacy_url);
    setAgbURL(settings?.agb_url);
    setImageRightsURL(settings?.image_rights_url);
    setImprintURL(settings?.imprint_url);
    setSupportEmail(settings?.support_email);
  };

  return (
    <SettingsAccordion abort={abort} onClick={updateURLs} disabled={!edited}>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        Rechtliche Daten
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          <TextField
            value={dataPrivacyURL || ""}
            onChange={(event) => setDataPrivacyURL(event.target.value)}
            label="Datenschutzerklärung Link"
            autoComplete="disabled"
            fullWidth
            margin="dense"
          />
          <TextField
            value={agbURL || ""}
            onChange={(event) => setAgbURL(event.target.value)}
            label="AGB Link"
            autoComplete="disabled"
            fullWidth
            margin="dense"
          />
          <TextField
            value={imageRightsURL || ""}
            onChange={(event) => setImageRightsURL(event.target.value)}
            label="Bildrechte Link"
            autoComplete="disabled"
            fullWidth
            margin="dense"
          />
          <TextField
            value={imprintURL || ""}
            onChange={(event) => setImprintURL(event.target.value)}
            label="Impressum Link"
            autoComplete="disabled"
            fullWidth
            margin="dense"
          />
          <TextField
            value={supportEmail || ""}
            onChange={(event) => setSupportEmail(event.target.value)}
            label="Support Email Adresse"
            autoComplete="disabled"
            fullWidth
            margin="dense"
          />
        </Box>
      </AccordionDetails>
    </SettingsAccordion>
  );
}
