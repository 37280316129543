import React from "react";
import { useParams } from "react-router-dom";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import Body from "./Root/Body";
import BaseDataType, { BaseDataTypes } from "./Basedata/BaseDataType";
import Employee from "./Basedata/Employee";
import Organisations from "./Basedata/Organisations";
import Locations from "./Basedata/Locations";
import { Search, SearchProvider } from "./Search/SearchProvider";

import { Box } from "@material-ui/core";

export interface BaseDataParams {
  type: BaseDataTypes;
}

export default function BaseData() {
  const { type } = useParams<BaseDataParams>();

  const baseDataType = () => {
    switch (type) {
      case "employee":
        return <Employee />;
      case "locations":
        return <Locations />;
      case "organisations":
        return <Organisations />;
    }
  };

  return (
    <SearchProvider>
      <HeaderBarAdmin title="Stammdaten" />
      <Body>
        <Box display="flex" flexDirection="column" alignItems="center">
          <BaseDataType type={type} />
        </Box>
        <Search />
        {baseDataType()}
      </Body>
    </SearchProvider>
  );
}
