import React from "react";

import { useServer } from "../../Server/ServerContext";

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { DeleteForeverRounded } from "@material-ui/icons";

import { useSnackbar } from "notistack";

import useSearch, { TextHighlight } from "../../Search/SearchProvider";
import ConfirmDelete, { ConfirmDialogType } from "../../ConfirmDialog";

export interface EmployeeListProps {
  useEmployees: [string[], React.Dispatch<React.SetStateAction<string[]>>];
}

export default function EmployeeList(props: EmployeeListProps) {
  const [employees, setEmployees] = props.useEmployees;
  const { masterData } = useServer();
  const { search, isSearched } = useSearch();
  const snackbar = useSnackbar();

  const [requestDelete, setRequestDelete] = React.useState<string | null>(null);

  const removeEmployee = (employee: string) => {
    masterData.employees
      .delete(employee)
      .then(() =>
        setEmployees(employees.filter((filtered) => employee !== filtered))
      )
      .snackbar({
        snackbar,
        success: "Mitarbeiter erfolgreich entfernt",
        admin: true,
      });
  };

  const filter = (employee: string) => isSearched([employee]);

  return (
    <List>
      <ConfirmDelete
        value={requestDelete}
        close={setRequestDelete}
        onSuccess={removeEmployee}
        type={ConfirmDialogType.delete}
      />

      {employees.filter(filter).map((employee) => (
        <React.Fragment key={employee}>
          <ListItem button>
            <ListItemText
              primary={
                <TextHighlight search={search}>{employee}</TextHighlight>
              }
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => setRequestDelete(employee)}>
                <DeleteForeverRounded />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
