import React from "react";
import { useHistory } from "react-router-dom";
import { getQuery } from "../Functions/QueryParams";

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
  Slide,
  Link,
  Tooltip,
  MobileStepper,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";

import { Member, UserCredential, useServer } from "./Server/ServerContext";

import GenderSelector from "./Account/GenderSelector";
import NationalitySelector from "./Account/NationalitySelector";
import { isAdult } from "../Functions/Age";

import InputWrapper from "./InputWrapper";

import { ReactComponent as Logo } from "./../resources/varausLogo.svg";
import FormComponent from "./FormComponent";

import validator from "validator";

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: 0,
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  dialogWrapper: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  login: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  dialogContent: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  loginDialogActions: {
    flexDirection: "column",
    "& > *": {
      marginLeft: "0 !important",
    },
  },
  loginDialogButton: {
    borderBottom: `1px dashed ${theme.palette.grey[500]}`,
    color: theme.palette.grey[500],
  },
  button: {
    margin: theme.spacing(2),
  },
  logo: {
    fill: theme.palette.primary.main,
  },
  inputRow: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      "&>*:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  },
  noPadding: {
    padding: 0,

    "& > *": {
      flex: "1 1 33.3%",
      justifyContent: "center",
    },
  },
  changePasswordDialogActions: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  stepper: {},
}));

interface LoginProps {
  open: boolean;
  close: () => void;
  onLogin: (email: string, password: string) => Promise<UserCredential>;
  requestResetPassword: (email: string) => Promise<void>;
  resetPassword: (newPassword: string, key: string) => Promise<void>;
  createAccount: (email: string, password: string) => Promise<void>;
  login: (email: string, password: string) => Promise<string>;
  logout: () => void;
  addMember: (member: Member) => Promise<string>;
}

function Login(props: LoginProps) {
  const styles = useStyles();
  const snackbar = useSnackbar();
  const { dataActions } = useServer();

  const [highlightRegister, setHighlightRegister] = React.useState(false);

  const [supportEmail, setSupportEmail] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setSupportEmail(await dataActions.getSupportEmail());
    })();
  }, []);

  React.useEffect(() => {
    if (props.open)
      setHighlightRegister(
        Boolean(getQuery({ highlightRegister: Boolean }).highlightRegister)
      );
  }, [props.open]);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showError, setError] = React.useState(false);

  const [securityKey, setSecurityKey] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = React.useState("");

  const [createAccountPassword, setCreateAccountPassword] = React.useState("");
  const [createAccountPasswordRepeat, setCreateAccountPasswordRepeat] =
    React.useState("");

  const [helperTextOpen, setHerlperTextOpen] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [createAccountOpen, setCreateAccountOpen] = React.useState(false);

  const [forgotPasswordError, setForgotPasswordError] = React.useState(false);

  const [requestPasswordResetStep, setRequestPasswordResetStep] =
    React.useState(0);
  const [createAccountStep, setCreateAccountStep] = React.useState(0);

  // Prevent onClose event during password reset and account creation
  const onClose = () => !createAccountOpen && !forgotPassword && props.close();

  const backToLogin = () => {
    setHerlperTextOpen(false);
    setForgotPassword(false);
    setCreateAccountOpen(false);

    // Copy newPassword from createAccount if repeat matches
    if (
      createAccountPassword &&
      createAccountPassword === createAccountPasswordRepeat
    )
      setPassword(createAccountPassword);
  };

  const openHelperText = () => {
    setHerlperTextOpen(true);
    setForgotPassword(false);
    setCreateAccountOpen(false);
  };

  const openResetPassword = () => {
    setHerlperTextOpen(false);
    setForgotPassword(true);
    setCreateAccountOpen(false);
  };

  const openCreateAccount = () => {
    setHerlperTextOpen(false);
    setForgotPassword(false);
    setCreateAccountOpen(true);

    // Copy given password into createAccount states
    setCreateAccountPassword(password);
    setCreateAccountPasswordRepeat(password);
  };

  const login = () =>
    props
      .onLogin(email, password)
      .then(props.close)
      .snackbar({
        snackbar,
        error: "Beim Log-In ist ein Fehler aufgetreten",
        onError: () => setError(true),
      });

  const requestResetPassword = () =>
    props
      .requestResetPassword(email)
      .then(() => {
        // Cleaup states and go to new step
        setRequestPasswordResetStep(1);
        setForgotPasswordError(false);
      })
      .snackbar({
        snackbar,
        info: "Der Verifikationscode wurde an Ihre Email versendet",
        error:
          "Beim versenden des Verifikationscodes an Ihre Email ist ein Fehler aufgetreten",
        onError: () => setForgotPasswordError(true),
      });

  const resetPassword = () =>
    props
      .resetPassword(newPassword, securityKey)
      .then(backToLogin)
      .then(() => {
        // Cleaup states
        setRequestPasswordResetStep(0);
        setForgotPasswordError(false);
        setError(false);
        setPassword("");
        setSecurityKey("");
        setNewPassword("");
        setNewPasswordRepeat("");
      })
      .snackbar({
        snackbar,
        error:
          "Beim aktualisieren mit Ihrem Verifikationscode ist ein Fehler aufgetreten",
        success: "Ihr Passwort wurde erfolgreich aktualisiert",
        onError: () => setForgotPasswordError(true),
      });

  const createAccount = (
    account: { email: string; password: string },
    member: Member
  ) =>
    props
      .createAccount(account.email, account.password)
      .then(async () => await props.login(account.email, account.password))
      .then(async () => await props.addMember(member))
      .then(async () => await props.logout())
      .then(backToLogin)
      .then(() => {
        // Cleaup states
        setError(false);
        setCreateAccountStep(0);
        setCreateAccountPassword("");
        setCreateAccountPasswordRepeat("");
        setPassword("");
      })
      .snackbar({
        snackbar,
        error:
          "Bei der Accounterstellung mit Ihrer Email ist ein Fehler aufgetreten",
        success:
          "Ihr Account wurde erfolgreich erstellt. Sie können sich jetzt einloggen",
      });

  const isLogin = !helperTextOpen && !forgotPassword && !createAccountOpen;

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          // Cleaup states
          backToLogin();

          setPassword("");
          setEmail("");
          setError(false);

          setRequestPasswordResetStep(0);
          setSecurityKey("");
          setNewPassword("");
          setNewPasswordRepeat("");
          setForgotPasswordError(false);

          setCreateAccountStep(0);
          setCreateAccountPassword("");
          setCreateAccountPasswordRepeat("");
        },
      }}
      fullWidth
      maxWidth="xs"
      PaperProps={{ component: FormComponent }}
      classes={{ paper: styles.dialog }}
    >
      <DialogTitle>
        <Logo className={styles.logo} />
      </DialogTitle>

      <DialogContent className={styles.dialogWrapper}>
        <Box overflow="hidden" position="relative">
          <Slide in={isLogin}>
            <Box className={styles.login} padding={3}>
              <TextField
                label="Meine Email"
                value={email}
                onChange={(event) =>
                  setEmail(event.target.value.toLocaleLowerCase())
                }
                error={showError}
                type="email"
                autoComplete={isLogin ? "username" : "off"}
                fullWidth
                margin="normal"
                autoFocus
              />
              <TextField
                label="Mein Passwort"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={showError}
                type="password"
                autoComplete={isLogin ? "current-password" : "off"}
                fullWidth
                margin="normal"
              />
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={login}
                type={isLogin ? "submit" : "button"}
                className={styles.button}
              >
                Anmelden
              </Button>
            </Box>
          </Slide>
          <Slide in={helperTextOpen} direction="up" mountOnEnter unmountOnExit>
            <Box
              className={styles.dialogContent}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography>Nutzername oder Passwort vergessen?</Typography>
              <Typography>
                Klicken Sie
                <Typography component="span"> </Typography>
                <Link onClick={openResetPassword} color="primary">
                  hier
                </Link>
                <Typography component="span"> </Typography>
                um ihr Passwort zurückzusetzen
              </Typography>
              <Typography>oder kontaktieren Sie unser Support Team</Typography>
              <Typography>
                per E-Mail:
                <Typography component="span"> </Typography>
                <Link href={"mailto:" + supportEmail}>{supportEmail}</Link>
              </Typography>
            </Box>
          </Slide>
          <Slide
            in={forgotPassword && requestPasswordResetStep === 0}
            direction="up"
            mountOnEnter
            unmountOnExit
          >
            <Box className={styles.dialogContent}>
              <Typography>Ein Verifikationscode wird nachträglich</Typography>
              <Typography>an Ihre Email versendet</Typography>
              <TextField
                label="Meine Email"
                value={email}
                onChange={(event) =>
                  setEmail(event.target.value.toLocaleLowerCase())
                }
                error={forgotPasswordError}
                type="email"
                autoComplete="username"
                fullWidth
                margin="normal"
                autoFocus
              />
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={requestResetPassword}
                type="submit"
                className={styles.button}
                disabled={!validator.isEmail(email)}
              >
                Verifikationscode anfragen
              </Button>
            </Box>
          </Slide>
          <Slide
            in={forgotPassword && requestPasswordResetStep === 1}
            direction="up"
            mountOnEnter
            unmountOnExit
          >
            <Box className={styles.dialogContent}>
              <Tooltip title="Dieser Code wurde an Ihre Email versendet">
                <TextField
                  label="Mein Verifikationscode"
                  value={securityKey}
                  onChange={(event) => setSecurityKey(event.target.value)}
                  error={forgotPasswordError}
                  autoComplete="one-time-code"
                  fullWidth
                  margin="normal"
                  autoFocus
                />
              </Tooltip>
              <InputWrapper>
                <TextField
                  label="Mein neues Passwort"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  error={forgotPasswordError}
                  type="password"
                  autoComplete="new-password"
                  margin="normal"
                />
                <TextField
                  label="Passwort wiederholen"
                  value={newPasswordRepeat}
                  onChange={(event) => setNewPasswordRepeat(event.target.value)}
                  error={
                    forgotPasswordError ||
                    (!!newPasswordRepeat && newPassword !== newPasswordRepeat)
                  }
                  type="password"
                  autoComplete="new-password"
                  margin="normal"
                />
              </InputWrapper>
              <Button
                color="primary"
                size="large"
                variant="contained"
                disabled={
                  !securityKey ||
                  !newPassword ||
                  newPassword !== newPasswordRepeat
                }
                onClick={resetPassword}
                type="submit"
                className={styles.button}
              >
                Passwort aktualisieren
              </Button>
            </Box>
          </Slide>
          <Slide
            in={createAccountOpen}
            direction="up"
            mountOnEnter
            unmountOnExit
          >
            <Box className={styles.dialogContent}>
              <CreactAccountStepper
                step={createAccountStep}
                setStep={setCreateAccountStep}
                email={email}
                setEmail={setEmail}
                password={createAccountPassword}
                passwordRepeat={createAccountPasswordRepeat}
                setPassword={setCreateAccountPassword}
                setPasswordRepeat={setCreateAccountPasswordRepeat}
                createAccount={createAccount}
              />
            </Box>
          </Slide>
        </Box>
      </DialogContent>

      <DialogActions className={styles.loginDialogActions}>
        <Button
          size="small"
          onClick={() => (helperTextOpen ? backToLogin() : openHelperText())}
          classes={{ label: styles.loginDialogButton }}
        >
          {helperTextOpen ? "Zurück zum Log-In" : "Probleme mit dem Log-In?"}
        </Button>
        <Button
          size="small"
          onClick={() => (forgotPassword ? backToLogin() : openResetPassword())}
          classes={{ label: styles.loginDialogButton }}
        >
          {forgotPassword ? "Zurück zum Log-In" : "Passwort vergessen"}
        </Button>
        {highlightRegister ? (
          <Button
            size="small"
            onClick={() =>
              createAccountOpen ? backToLogin() : openCreateAccount()
            }
            color="primary"
            variant="contained"
          >
            {createAccountOpen ? "Zurück zum Log-In" : "Account erstellen"}
          </Button>
        ) : (
          <Button
            size="small"
            onClick={() =>
              createAccountOpen ? backToLogin() : openCreateAccount()
            }
            classes={{ label: styles.loginDialogButton }}
          >
            {createAccountOpen ? "Zurück zum Log-In" : "Account erstellen"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function CreactAccountStepper(props: {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  passwordRepeat: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordRepeat: React.Dispatch<React.SetStateAction<string>>;
  createAccount: (
    account: { email: string; password: string },
    member: Member
  ) => void;
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const margin = mobile ? "dense" : "normal";
  const styles = useStyles();

  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [birthday, setBirthday] = React.useState<Date | null>(null);

  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [zip, setZip] = React.useState("");

  const [phone, setPhone] = React.useState("");
  const [nationality, setNationality] = React.useState<null | string>(null);

  const stepCount = 4;

  const steps = () => {
    switch (props.step) {
      case 0:
        return (
          <>
            <TextField
              label="Meine Email"
              value={props.email}
              onChange={(event) =>
                props.setEmail(event.target.value.toLocaleLowerCase())
              }
              type="email"
              autoComplete="username"
              fullWidth
              margin={margin}
              autoFocus
            />
            <Box className={styles.inputRow}>
              <TextField
                label="Mein Passwort"
                value={props.password}
                onChange={(event) => props.setPassword(event.target.value)}
                type="password"
                autoComplete="new-password"
                margin={margin}
              />
              <TextField
                label="Passwort wiederholen"
                value={props.passwordRepeat}
                onChange={(event) =>
                  props.setPasswordRepeat(event.target.value)
                }
                error={
                  !!props.passwordRepeat &&
                  props.password !== props.passwordRepeat
                }
                type="password"
                autoComplete="new-password"
                margin={margin}
              />
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Box className={styles.inputRow}>
              <TextField
                label="Mein Vorname"
                value={name}
                onChange={(event) => setName(event.target.value)}
                autoComplete="given-name"
                required
                margin={margin}
                autoFocus
              />
              <TextField
                label="Mein Nachname"
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
                autoComplete="family-name"
                required
                margin={margin}
              />
            </Box>
            <Box className={styles.inputRow}>
              <GenderSelector
                gender={gender}
                setGender={setGender}
                label="Mein Geschlecht"
                margin={margin}
                fullWidth
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                label="Mein Geburtstag"
                autoComplete="bday"
                value={birthday}
                onChange={setBirthday}
                helperText={undefined}
                required
                margin={margin}
                fullWidth
              />
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <TextField
              label="Straße"
              value={street}
              onChange={(event) => setStreet(event.target.value)}
              autoComplete="street-address"
              required
              margin={margin}
              fullWidth
              autoFocus
            />
            <InputWrapper>
              <TextField
                label="Postleitzahl"
                value={zip}
                onChange={(event) =>
                  setZip(event.target.value.replace(/[^0-9]/g, ""))
                }
                autoComplete="postal-code"
                required
                margin={margin}
              />
              <TextField
                label="Stadt"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                autoComplete="address-level2"
                required
                margin={margin}
              />
            </InputWrapper>
          </>
        );
      case 3:
        return (
          <>
            <TextField
              label="Telefonnummer"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              autoComplete="tel"
              required
              margin={margin}
              fullWidth
              autoFocus
            />
            <NationalitySelector
              nationality={nationality}
              setNationality={setNationality}
              margin={margin}
            />
          </>
        );
      case -1:
        return (
          <>
            <Typography>
              Die Accounterstellung ist nur für Erwachsene oder Eltern
              vorgesehen. Falls Sie sich in ein Projekt eintragen möchten, so
              bitten Sie einen Erziehungsberechtigten dies an ihrer Stelle zu
              tun.
            </Typography>
          </>
        );
    }
  };

  const disabled = () => {
    switch (props.step) {
      case 0:
        return (
          !validator.isEmail(props.email) ||
          !props.password ||
          props.password !== props.passwordRepeat
        );
      case 1:
        return (
          !name || !surname || !gender || !birthday || isNaN(birthday.getTime())
        );
      case 2:
        return !street || !city || !zip;
      case 3:
        return !phone || !nationality;
      case -1:
        return true;
    }
  };

  const nextText = () => {
    switch (props.step) {
      case 0:
      case 1:
      case 2:
        return "Weiter";
      case 3:
        return mobile ? "Fertig" : "Registrieren";
    }
  };

  const createAccountBack = () => {
    if (props.step === -1) {
      props.setStep(0);

      // Delete all entries
      props.setEmail("");
      props.setPassword("");
      props.setPasswordRepeat("");

      setName("");
      setSurname("");
      setGender("");
      setBirthday(null);

      setStreet("");
      setCity("");
      setZip("");

      setPhone("");
      setNationality(null);
    } else props.setStep(props.step - 1);
  };
  const createAccountNext = () => {
    // Check if account creation is complete
    if (props.step + 1 === stepCount)
      props.createAccount(
        { email: props.email, password: props.password },
        {
          name,
          surname,
          street,
          zip: parseInt(zip),
          city,
          birthday: (birthday as Date).toLocaleDateString("en-US"),
          nationality: nationality as string,
          gender,
          phone,
          swim: true,
          goHomeAlone: true,
          allergies: "",
          medication: "",
          other: "",
          linkedAccount: props.email,
        }
      );
    // Stop if under 18 years
    else if (birthday && !isAdult(birthday)) props.setStep(-1);
    // Else continue collection data
    else props.setStep(props.step + 1);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <MobileStepper
        variant="dots"
        steps={stepCount}
        position="static"
        activeStep={props.step}
        backButton={
          <Button onClick={createAccountBack} disabled={!props.step}>
            Zurück
          </Button>
        }
        nextButton={
          <Button
            onClick={createAccountNext}
            disabled={disabled()}
            type="submit"
          >
            {nextText()}
          </Button>
        }
        className={styles.noPadding}
      />
      <Box
        flexGrow="1"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        key={props.step}
      >
        {steps()}
      </Box>
    </Box>
  );
}

interface ChangePasswordProps {
  open: boolean;
  close: () => void;
  requestResetPassword: (email: string) => Promise<void>;
  resetPassword: (newPassword: string, key: string) => Promise<void>;
}

function ChangePassword(props: ChangePasswordProps) {
  const styles = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const stepCount = 2;

  const snackbar = useSnackbar();

  const [step, setStep] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [securityKey, setSecurityKey] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = React.useState("");

  const [error, setError] = React.useState(false);

  const requestResetPassword = () =>
    props
      .requestResetPassword(email)
      .then(() => {
        // Cleaup states and go to new step
        setStep(1);
        setError(false);
      })
      .snackbar({
        snackbar,
        info: "Der Verifikationscode wurde an Ihre Email versendet",
        error:
          "Beim versenden des Verifikationscodes an Ihre Email ist ein Fehler aufgetreten",
        onError: () => setError(true),
      });

  const resetPassword = () =>
    props
      .resetPassword(newPassword, securityKey)
      .then(props.close)
      .snackbar({
        snackbar,
        error:
          "Beim aktualisieren mit Ihrem Verifikationscode ist ein Fehler aufgetreten",
        success: "Ihr Passwort wurde erfolgreich aktualisiert",
        onError: () => setError(true),
      });

  const steps = () => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              label="Meine Email"
              value={email}
              onChange={(event) =>
                setEmail(event.target.value.toLocaleLowerCase())
              }
              error={error}
              type="email"
              autoComplete="username"
              fullWidth
              margin="normal"
              helperText="Ein Verifikationscode wird nachträglich an Ihre Email versendet"
              autoFocus
            />
          </>
        );
      case 1:
        return (
          <>
            <Tooltip title="Dieser Code wurde an Ihre Email versendet">
              <TextField
                label="Mein Verifikationscode"
                value={securityKey}
                onChange={(event) => setSecurityKey(event.target.value)}
                error={error}
                autoComplete="one-time-code"
                fullWidth
                margin="normal"
                autoFocus
              />
            </Tooltip>

            <Box className={styles.inputRow}>
              <TextField
                label="Mein neues Passwort"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                error={error}
                type="password"
                autoComplete="new-password"
                margin="normal"
                fullWidth
              />

              <TextField
                label="Passwort wiederholen"
                value={newPasswordRepeat}
                onChange={(event) => setNewPasswordRepeat(event.target.value)}
                error={
                  error ||
                  (!!newPasswordRepeat && newPassword !== newPasswordRepeat)
                }
                type="password"
                autoComplete="new-password"
                margin="normal"
                fullWidth
              />
            </Box>
          </>
        );
    }
  };

  const nextStepDisabled = () => {
    switch (step) {
      case 0:
        return !validator.isEmail(email);
      case 1:
        return (
          !securityKey || !newPassword || newPassword !== newPasswordRepeat
        );
    }
  };

  const nextStepClick = () => {
    switch (step) {
      case 0:
        return requestResetPassword;
      case 1:
        return resetPassword;
    }
  };

  const nextStepText = (index?: number) => {
    switch (index !== undefined ? index : step) {
      case 0:
        return "Verifikationscode anfragen";
      case 1:
        return "Passwort aktualisieren";
    }
  };

  const cleanup = () => {
    setStep(0);
    setError(false);
    setSecurityKey("");
    setNewPassword("");
    setNewPasswordRepeat("");
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      TransitionProps={{
        onExited: cleanup,
      }}
      PaperProps={{ component: FormComponent }}
    >
      <DialogTitle>
        <Stepper
          activeStep={step}
          {...(mobile ? { orientation: "vertical" } : {})}
        >
          {new Array(stepCount).fill(null).map((_, index) => (
            <Step key={index}>
              <StepLabel>{nextStepText(index)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>{steps()}</DialogContent>
      <DialogActions className={styles.changePasswordDialogActions}>
        <Button onClick={props.close}>Abbrechen</Button>
        <Button
          onClick={nextStepClick()}
          disabled={nextStepDisabled()}
          color="primary"
          variant="outlined"
          type="submit"
        >
          {nextStepText()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ChangeEmailProps {
  open: boolean;
  close: () => void;
  changeEmail: (email: string) => Promise<void>;
}

function ChangeEmail(props: ChangeEmailProps) {
  const snackbar = useSnackbar();

  const [newEmail, setNewEmail] = React.useState("");
  const [newEmailRepeat, setNewEmailRepeat] = React.useState("");
  const [error, setError] = React.useState(false);

  const updateEmail = () =>
    props
      .changeEmail(newEmail)
      .snackbar({
        snackbar,
        error:
          "Bei der Emailänderung ist ein Fehler aufgetreten. Die Email Adresse könnte potentiell schon vergeben sein.",
        success:
          "Email erfolgreich geändert. Sie können sich nun mit dieser anmelden",
      })
      .then(() => props.close());

  const cleanup = () => {
    setNewEmail("");
    setNewEmailRepeat("");
    setError(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      TransitionProps={{ onExited: cleanup }}
      PaperProps={{ component: FormComponent }}
    >
      <DialogTitle>Email ändern</DialogTitle>
      <DialogContent>
        <Typography>Sie werden im Nachhinein automatisch abgemeldet</Typography>

        <TextField
          label="Meine neue Email"
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
          error={error}
          type="email"
          autoComplete="username"
          fullWidth
          margin="normal"
          autoFocus
        />
        <TextField
          label="Email wiederholen"
          value={newEmailRepeat}
          onChange={(event) => setNewEmailRepeat(event.target.value)}
          error={error}
          type="email"
          autoComplete="username"
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close}>Abbrechen</Button>
        <Button
          onClick={updateEmail}
          disabled={!validator.isEmail(newEmail) || newEmail !== newEmailRepeat}
          color="primary"
          variant="outlined"
          type="submit"
        >
          Email speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// Login context

const LoginContext = React.createContext<LoginContextProps>(undefined!);

export interface LoginContextProps {
  login: () => void;
  logout: () => void;
  changePassword: () => void;
  changeEmail: () => void;
  abort: number;
  close: () => void;
}

export default function useLogin() {
  return React.useContext(LoginContext);
}

export function LoginProvider(props: { children: React.ReactNode }) {
  const history = useHistory();

  const server = useServer();

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
  const [changeEmailOpen, setChangeEmailOpen] = React.useState(false);
  const [abort, setAbort] = React.useState(0);

  const login = () => setLoggingIn(true);
  const logout = () => {
    setLoggingIn(false);
    setChangePasswordOpen(false);
    setChangeEmailOpen(false);

    server.logout();

    history.push("/");
  };
  const changePassword = () => setChangePasswordOpen(true);
  const changeEmail = () => setChangeEmailOpen(true);

  const close = () => {
    setLoggingIn(false);
    setChangePasswordOpen(false);
    setChangeEmailOpen(false);
  };

  // Close login when going back/forth in browser history
  React.useEffect(() => history.listen(close), []);

  return (
    <LoginContext.Provider
      value={{ login, logout, changePassword, changeEmail, abort, close }}
    >
      <Login
        open={loggingIn}
        close={() => {
          setAbort(abort + 1);
          setLoggingIn(false);
        }}
        onLogin={server.login}
        requestResetPassword={server.accountManagement.requestPasswordReset}
        resetPassword={server.accountManagement.resetPassword}
        createAccount={server.accountManagement.signup}
        addMember={server.accountManagement.addMember}
        login={server.login}
        logout={server.logout}
      />
      <ChangePassword
        open={changePasswordOpen}
        close={() => setChangePasswordOpen(false)}
        requestResetPassword={server.accountManagement.requestPasswordReset}
        resetPassword={server.accountManagement.resetPassword}
      />
      <ChangeEmail
        open={changeEmailOpen}
        close={() => setChangeEmailOpen(false)}
        changeEmail={server.accountManagement.updateEmail}
      />
      {props.children}
    </LoginContext.Provider>
  );
}
