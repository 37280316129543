import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowForwardRounded } from "@material-ui/icons";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";
import useLocalStorage from "../LocalStorage";

export default function BackToProjectsDone() {
  const history = useHistory();
  const { setParsedStorage } = useLocalStorage();

  const backToProjects = () => {
    setParsedStorage({ forcePaypal: false });
    history.push("/");
  };

  return (
    <SubHeaderIconButton
      onClick={backToProjects}
      icon={<ArrowForwardRounded />}
    >
      Zurück zu den Projekten
    </SubHeaderIconButton>
  );
}
