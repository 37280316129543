import React from "react";

import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { ParticipantDBProps } from "../ParticipantDB";
import { Member, useServer } from "../Server/ServerContext";
import EditParticipant from "../ParticipantInfo/EditParticipant";
import { EditMember, parseMember } from "../Account/PersonEditList";

const emptyMember = {
  name: "",
  surname: "",
  street: "",
  zip: NaN,
  city: "",
  birthday: null,
  nationality: "",
  gender: "",
  phone: "",
  school: "",
  swim: false,
  goHomeAlone: false,
  allergies: "",
  medication: "",
  other: "",
  linkedAccount: "dummyToEnableNonAdultInputs",
};

export default function AddParticipant(props: ParticipantDBProps) {
  const [participants, setParticipants] = props.useParticipant;
  const { peopleActions } = useServer();
  const snackbar = useSnackbar();

  const [addPersonOpen, setAddPersonOpen] = React.useState(false);
  const [editPerson, setAddPerson] = React.useState<EditMember>(emptyMember);

  const addPerson = () => {
    if (editPerson) {
      const parsed = parseMember(editPerson) as Member;
      delete parsed.linkedAccount;

      peopleActions
        .add(parsed)
        .then((id) => setParticipants([...participants, { id, ...parsed }]))
        .then(abort)
        .snackbar({
          snackbar,
          success: "Person erfolgreich angelegt",
          admin: true,
        });
    }
  };

  const abort = () => setAddPersonOpen(false);

  return (
    <>
      <Button color="primary" onClick={() => setAddPersonOpen(true)}>
        Person erstellen
      </Button>

      <Dialog
        open={addPersonOpen}
        onClose={() => setAddPersonOpen(false)}
        maxWidth="md"
        fullWidth
        TransitionProps={{ onExited: () => setAddPerson(emptyMember) }}
      >
        <DialogTitle>Person erstellen</DialogTitle>
        <DialogContent>
          <EditParticipant
            participant={editPerson}
            callback={setAddPerson}
            abort={abort}
            onClick={addPerson}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
