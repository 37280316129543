import config from "../../config";

export async function executeGraphQL(
  doc: string,
  jwt: string | undefined,
  variables = {}
): Promise<any> {
  const headers =
    // eslint-disable-next-line
    jwt != undefined ? { Authorization: `Bearer ${jwt}` } : undefined;
  const request = await fetch(config.graphQlLink, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      query: doc,
      variables,
      operationName: "operation",
    }),
  });
  const { errors, data } = await request.json();
  if (errors) {
    // handle those errors like a pro
    console.error(errors);
    throw errors;
  }
  return data;
}

export async function getNamespaceName(namespaceID: number): Promise<string> {
  const query = `query operation($id: Int) {
    namespaces(where: {id: {_eq: $id}}) {
      name
    }
  }`;

  const data = await executeGraphQL(query, undefined, {
    id: Number(namespaceID),
  });

  return data.namespaces[0]?.name;
}
