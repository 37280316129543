import { AccordionSummary, AccordionDetails } from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import React from "react";
import { SettingsAccordion } from "../Settings/SettingsList";
import { ProjectRepeatComplexProps } from "./ProjectRepeatComplex";

export interface ProjectRepeatSimpleProps
  extends Omit<ProjectRepeatComplexProps, "repeat"> {
  repeat: { begin: Date | null; end: Date | null };
  showTime: boolean;
}

export default function ProjectRepeatSimple(props: ProjectRepeatSimpleProps) {
  const [datetime, setDatetime] = React.useState(props.repeat);
  const [edited, setEdited] = React.useState(false);

  const isValid = (repeat: { begin: Date | null; end: Date | null }) =>
    Boolean(
      repeat.begin &&
        repeat.end &&
        !isNaN(repeat.begin.getTime()) &&
        !isNaN(repeat.end.getTime()) &&
        repeat.begin.getTime() <= repeat.end.getTime()
    );

  const valid = isValid(datetime);

  React.useEffect(
    () => props.onChange?.(datetime, valid),
    [datetime, props.showTime]
  );

  React.useEffect(
    () =>
      setEdited(
        datetime.begin?.getTime() !== props.repeat.begin?.getTime() ||
          datetime.end?.getTime() !== props.repeat.end?.getTime()
      ),
    [datetime, props.repeat]
  );

  React.useEffect(() => {
    if (datetime.end || !datetime.begin) return;
    const datetimeNew = { ...datetime };
    datetimeNew.end = new Date(datetime.begin);
    datetimeNew.end?.setHours((datetimeNew.begin?.getHours() || 0) + 2);
    datetimeNew.end?.setMinutes(0);
    setDatetime(datetimeNew);
  }, [datetime.begin]);

  return (
    <SettingsAccordion
      defaultExpanded
      onClick={
        props.applyRepeat
          ? () => (valid ? props.applyRepeat?.(datetime) : undefined)
          : undefined
      }
      abort={!props.onChange ? () => setDatetime(props.repeat) : undefined}
      disabled={!valid || !edited}
    >
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        Zeitraum
      </AccordionSummary>
      <AccordionDetails>
        {props.showTime ? (
          <>
            <KeyboardDateTimePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy HH:mm"
              label="Beginn"
              value={datetime.begin}
              onChange={(begin: Date | null) => {
                begin?.setSeconds(0);
                begin?.setMilliseconds(0);
                setDatetime({ ...datetime, ...{ begin } });
              }}
              helperText={undefined}
              required
              margin="normal"
              fullWidth
              ampm={false}
            />
            <KeyboardDateTimePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy HH:mm"
              label="Ende"
              value={datetime.end}
              onChange={(end: Date | null) => {
                end?.setSeconds(0);
                end?.setMilliseconds(0);
                setDatetime({ ...datetime, ...{ end } });
              }}
              helperText={undefined}
              required
              margin="normal"
              fullWidth
              ampm={false}
            />
          </>
        ) : (
          <>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              label="Beginn"
              value={datetime.begin}
              onChange={(begin: Date | null) => {
                begin?.setMinutes(0);
                begin?.setSeconds(0);
                begin?.setMilliseconds(0);
                setDatetime({ ...datetime, ...{ begin } });
              }}
              helperText={undefined}
              required
              margin="normal"
              fullWidth
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              label="Ende"
              value={datetime.end}
              onChange={(end: Date | null) => {
                end?.setHours(end.getHours() + 1);
                end?.setMinutes(0);
                end?.setSeconds(0);
                end?.setMilliseconds(0);
                setDatetime({ ...datetime, ...{ end } });
              }}
              helperText={undefined}
              required
              margin="normal"
              fullWidth
            />
          </>
        )}
      </AccordionDetails>
    </SettingsAccordion>
  );
}
