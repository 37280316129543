import React from "react";

import {
  Accordion,
  AccordionActions,
  AccordionProps,
  Button,
} from "@material-ui/core";

import Paypal from "./Paypal";
import CategoryEditor from "./CategoryEditor";
import EmailEditor from "./EmailEditors";
import ColorEditor from "./ColorEditor";

import { FormatedSettings, useServer } from "../Server/ServerContext";

import FormComponent from "../FormComponent";
import LegalURLsEditor from "./LegalURLsEditor";
import GeneralSettings from "./GeneralSettings";

export const SettingsAccordion = (
  props: AccordionProps & SettingsAccordionActionProps
) => (
  <Accordion
    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
    component={FormComponent}
    defaultExpanded={props.defaultExpanded}
  >
    {props.children}
    <SettingsAccordionAction {...props} />
  </Accordion>
);

interface SettingsAccordionActionProps {
  abort?: () => void;
  onClick?: () => void;
  disabled?: boolean;
}

const SettingsAccordionAction = (props: SettingsAccordionActionProps) => (
  <AccordionActions>
    {props.abort && <Button onClick={props.abort}>Abbrechen</Button>}
    {props.onClick && (
      <Button
        onClick={props.onClick}
        disabled={props.disabled}
        variant="contained"
        color="primary"
        type="submit"
      >
        Speichern
      </Button>
    )}
  </AccordionActions>
);

export interface SettingsListItemProps {
  useSettings: [
    FormatedSettings,
    React.Dispatch<React.SetStateAction<FormatedSettings>>
  ];
}

export default function SettingsList() {
  const useSettings = useServer().useSettings();

  return (
    <>
      <EmailEditor useSettings={useSettings} />
      <Paypal useSettings={useSettings} />
      <ColorEditor useSettings={useSettings} />
      <CategoryEditor useSettings={useSettings} />
      <LegalURLsEditor useSettings={useSettings} />
      <GeneralSettings useSettings={useSettings} />
    </>
  );
}
