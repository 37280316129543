import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowForwardRounded } from "@material-ui/icons";

import useLocalStorage from "../LocalStorage";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@material-ui/core";

import { useServer } from "../Server/ServerContext";

export interface SignButtonProps {
  imageRights: boolean;
  disabled: boolean;
}

export default function SignButton(props: SignButtonProps) {
  const history = useHistory();
  const { setParsedStorage } = useLocalStorage();
  const { dataActions } = useServer();

  const [imageRightsReminderOpen, setImageRightsReminderOpen] =
    React.useState(false);

  const [imageRights, setImageRights] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setImageRights((await dataActions.getLegalURLs()).image_rights_url);
    })();
  }, []);

  const sign = () => {
    if (props.imageRights) {
      route();
      setParsedStorage({ imageRights: props.imageRights });
    } else setImageRightsReminderOpen(true);
  };

  const acceptImageRights = () => {
    route();
    setParsedStorage({ imageRights: true });
  };

  const rejectImageRights = () => {
    route();
    setParsedStorage({ imageRights: false });
  };

  const route = () => history.push("/sign");

  return (
    <>
      <Dialog open={imageRightsReminderOpen}>
        <DialogTitle>Bildrechte</DialogTitle>
        <DialogContent>
          <Typography component="span">Durch das ablehnen der</Typography>
          <Typography component="span"> </Typography>
          <Link rel="noopener noreferrer" href={imageRights} target="_blank">
            Bildrechte
          </Link>
          <Typography component="span"> </Typography>
          <Typography component="span">
            werden Sie möglicherweise nicht in der Lage sein, an gewissen Teilen
            des Angebots teilzunehmen!
          </Typography>
          <Typography variant="overline" component="p">
            Sind Sie sich sicher?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={rejectImageRights}>Ablehnen</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={acceptImageRights}
          >
            Bildrechte akzeptieren
          </Button>
        </DialogActions>
      </Dialog>

      <SubHeaderIconButton
        onClick={sign}
        icon={<ArrowForwardRounded />}
        disabled={props.disabled}
      >
        Unterschreiben
      </SubHeaderIconButton>
    </>
  );
}
