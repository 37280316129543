import React from "react";
import { useHistory } from "react-router";

import { Box, Button, Typography, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";

import ProjectEditor, { EditProject } from "../EditProject/ProjectEditor";
import {
  AllowedGenders,
  ProjectState,
  useServer,
} from "../Server/ServerContext";

import { parseServerProject } from "../EditProject/ProjectEditorDetails";
import ProjectRepeat from "../EditProject/ProjectRepeat";
import ConfirmDialog, { ConfirmDialogType } from "../ConfirmDialog";

export const emptyProject: EditProject = {
  allowedGenders: AllowedGenders.Both,
  attachments: "[]",
  bookingRequired: false,
  category: "",
  datetime: {
    repeat: NaN,
    daily: {
      from: null,
      to: null,
    },
    week: [],
    month: [],
    begin: null,
    end: null,
  },
  description: "",
  discountRate: "",
  fees: 0,
  image: "",
  maximumAge: 99,
  minimumAge: 0,
  maximumParticipants: "",
  mimimumParticipants: "",
  name: "",
  publicationPeriodEnd: null,
  publicationPeriodStart: null,
  publish: true,
  state: ProjectState.Free,
  subtitle: "",
  waitingListSize: "",
  roomName: "",
  employee: "",
  organisationids: [],
  createTickets: false,

  cashEnabled: true,
  paypalEnabled: true,
  sepaEnabled: true,
  bookingPeriodEnd: null,
  bookingPeriodStart: null,
  zipFilter: "",
  waitinglistIncludedInMax: false,
};

export default function CreateProjectInput() {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { projectActions } = useServer();
  const [confirmNoBooking, setConfirmNoBooking] = React.useState(false);

  const onApply = () => {
    if (project.bookingRequired) return createProject();
    setConfirmNoBooking(true);
  };

  const createProject = () => {
    const parsedProject = parseServerProject(project);

    projectActions
      .add({
        ...parsedProject,
        waitingListSize: parsedProject.bookingRequired
          ? parsedProject.waitingListSize
          : 0,
        datetime: JSON.stringify(datetime) as any,
      })
      .then(() => history.push("/admin"))
      .snackbar({
        snackbar,
        success: "Projekt erfolgreich hinzugefügt",
        admin: true,
      });
  };

  const [project, setProject] = React.useState(emptyProject);
  const [datetime, setDatetime] = React.useState({ ...emptyProject.datetime });

  const [projectValid, setProjectValid] = React.useState(false);
  const [datetimeValid, setDatetimeValid] = React.useState(false);

  const valid = projectValid && datetimeValid;

  return (
    <>
      <ConfirmDialog
        value={confirmNoBooking}
        close={() => setConfirmNoBooking(false)}
        onSuccess={createProject}
        textRender={() =>
          `Buchungen sind für das Projekt deaktiviert. Sie können im Nachhinein nicht mehr aktiviert werden. Sind Sie sich sicher, dass Sie das Projekt so erstellen wollen?`
        }
        type={ConfirmDialogType.custom}
      />
      <CreateProject onApply={onApply} disabled={!valid} />
      <ProjectEditor
        defaultProject={emptyProject}
        onChange={(project, valid) => {
          setProject(project);
          setProjectValid(valid);
        }}
        editBooking
        repeat
      />
      <Typography variant="h5" style={{ margin: theme.spacing(2) }}>
        Zeitpunkt festlegen
      </Typography>
      <ProjectRepeat
        repeat={datetime}
        onChange={(datetime, valid) => {
          setDatetime(datetime);
          setDatetimeValid(valid);
        }}
      />
      <CreateProject onApply={onApply} disabled={!valid} />
    </>
  );
}

function CreateProject(props: { onApply: () => void; disabled: boolean }) {
  return (
    <Box marginBottom={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onApply}
        fullWidth
        disabled={props.disabled}
      >
        Projekt erstellen
      </Button>
    </Box>
  );
}
