import { Box, withStyles } from "@material-ui/core";

export const InputWrapper = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    "& > :first-child": {
      marginLeft: 0,
    },
    "& > :last-child": {
      marginRight: 0,
    },
  },
}))(Box);

export default InputWrapper;
