import React from "react";
import { Switch, useHistory } from "react-router-dom";
import { getQuery, updateQuery } from "./Functions/QueryParams";

import Root from "./Components/Root";
import { Account } from "./Components/Account";
import Project from "./Components/Project";
import Booking from "./Components/Booking";
import Summary from "./Components/Summary";
import Sign from "./Components/Sign";
import Payment from "./Components/Payment";
import Completed from "./Components/Completed";
import Admin from "./Components/Admin";
import Stats from "./Components/Stats";
import BaseData from "./Components/BaseData";
import Settings from "./Components/Settings";
import Usermanagement from "./Components/Usermanagement";
import ParticipantDB from "./Components/ParticipantDB";
import ParticipantInfo from "./Components/ParticipantInfo";
import CreateProject from "./Components/CreateProject";
import EditProject from "./Components/EditProject";

import {
  Box,
  CircularProgress,
  createTheme,
  Theme,
  ThemeProvider,
} from "@material-ui/core";

import { useServer } from "./Components/Server/ServerContext";
import { useSnackbar } from "notistack";

import PrivateRoute from "./Components/Routes/PrivateRoute";
import AdminRoute from "./Components/Routes/AdminRoute";
import PublicRoute from "./Components/Routes/PublicRoute";

import { LoginProvider } from "./Components/Login";
import config from "./config";
import ParticipantDuplicates from "./Components/ParticipantDuplicates";
import { QueryParams } from "./Components/Root/FilterSidebar";
import Ticket from "./Components/Ticket";

function App() {
  const server = useServer();
  const [namespaceid, setNamespaceid] = server.useNamespace;

  const snackbar = useSnackbar();
  const history = useHistory();

  const [colors, setColors] = React.useState<Theme | undefined>(undefined);
  const [forcedFacility, setForcedFacility] = React.useState("");

  React.useEffect(() => {
    const search = getQuery({
      namespace: Number,
      facilities: Array,
      forced: Boolean,
    }) as Partial<QueryParams> & { namespace?: number };

    let namespace: number | undefined;

    if (!namespaceid) {
      namespace =
        search.namespace !== undefined
          ? search.namespace
          : config.defaultNamespace;

      setNamespaceid(namespace);
    }

    const forced = search.facilities?.length === 1 && search.forced;
    const facility = search.facilities?.[0];

    if (forced) setForcedFacility(facility as string);

    // Fetch facility colors if forced mode - otherwise pull namespace colors
    (forced
      ? server.dataActions.getFacilityColor(facility as string)
      : server.dataActions.getColors(namespace || namespaceid)
    )
      .then(
        (colors) =>
          colors &&
          setColors(
            createTheme({
              palette: {
                primary: {
                  main: colors.primary_color,
                  contrastText: colors.text_color,
                },
              },
            })
          )
      )
      .catch(() => {
        // Set default colors in case namespace was not initialized yet
        setColors(
          createTheme({
            palette: {
              primary: {
                main: "#ffc107",
                contrastText: "#000000",
              },
            },
          })
        );
      })
      .snackbar({ snackbar, error: "Der Server ist nicht erreichbar" });

    // Fetch favicon
    server.dataActions.getFavicon(namespace || namespaceid).then((path) => {
      if (path) {
        const link = document.getElementById("favicon") as HTMLLinkElement;
        link.href = config.fileRootPath + path;
      }
    });
  }, [namespaceid, server.forceFetch]);

  React.useEffect(() => {
    const update = () => {
      if (namespaceid) {
        if (forcedFacility)
          updateQuery({
            forced: true,
            facilities: forcedFacility,
            namespace: namespaceid,
          });
        else
          updateQuery({
            namespace: namespaceid,
          });
      }
    };

    update();
    const unlisten = history.listen(update);

    return () => unlisten();
  }, [forcedFacility]);

  //@ts-ignore
  window.server = server;

  return colors ? (
    <ThemeProvider theme={colors}>
      <LoginProvider>
        <Switch>
          <AdminRoute path="/admin/project/:id" component={EditProject} />
          <AdminRoute path="/admin/createProject" component={CreateProject} />
          <AdminRoute
            path="/admin/participantinfo/:id"
            component={ParticipantInfo}
          />
          <AdminRoute
            path="/admin/participantdb/duplicates"
            component={ParticipantDuplicates}
          />
          <AdminRoute path="/admin/participantdb" component={ParticipantDB} />
          <AdminRoute
            access_level={2}
            path="/admin/usermanagement/:type"
            component={Usermanagement}
          />
          <AdminRoute
            access_level={2}
            path="/admin/settings"
            component={Settings}
          />
          <AdminRoute
            access_level={2}
            path="/admin/basedata/:type"
            component={BaseData}
          />
          <AdminRoute path="/admin/stats" component={Stats} />
          <AdminRoute path="/admin" component={Admin} />

          <PrivateRoute path="/completed" component={Completed} />
          <PrivateRoute path="/payment" component={Payment} />
          <PrivateRoute path="/sign" component={Sign} />
          <PrivateRoute path="/summary" component={Summary} />
          <PrivateRoute path="/booking" component={Booking} />
          <PublicRoute path="/project/:id" component={Project} />
          <PrivateRoute path="/account" component={Account} />
          <PublicRoute path="/ticket/:id" component={Ticket} />
          <PublicRoute path="/" component={Root} />
        </Switch>
      </LoginProvider>
    </ThemeProvider>
  ) : (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
}

export default App;
