import React from "react";

import { Popper, TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";

import nationalities from "i18n-nationality";

nationalities.registerLocale(require("i18n-nationality/langs/de.json"));

export interface NationalitySelectorProps {
  nationality: string | null;
  setNationality: (nationality: string | null) => void;
}

const mappedNationalities = Array.from(
  new Set([
    "deutsch",
    ...Object.entries(nationalities.getNames("de")).map(([_, entry]) =>
      entry.toLowerCase()
    ),
  ])
);

export default function NationalitySelector(
  props: NationalitySelectorProps &
    Omit<
      AutocompleteProps<string, undefined, undefined, undefined>,
      "options" | "renderInput"
    > &
    TextFieldProps
) {
  const { setNationality, nationality, ...rest } = props;

  return (
    <Autocomplete
      options={mappedNationalities}
      inputValue={nationality || ""}
      onInputChange={(e, value) => e && setNationality(value)}
      renderInput={(params) => (
        <TextField {...rest} {...params} label="Nationalität" required />
      )}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, height: 0 }} />
      )}
      autoHighlight
      {...rest}
    />
  );
}
