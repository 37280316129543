import React from "react";
import { useHistory } from "react-router";

import {
  Stepper,
  Step,
  StepButton,
  Box,
  makeStyles,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import {
  AccountTreeRounded,
  Block,
  HourglassEmptyRounded,
  PeopleOutlineRounded,
  RepeatRounded,
} from "@material-ui/icons";

import ProjectEditor, { EditProject } from "./ProjectEditor";
import { ProjectPrams } from "../Project";
import {
  Booking,
  DatetimeRepeat,
  ParticipantState,
  PaymentMethod,
  ProjectEditObject,
  Projects,
  useServer,
} from "../Server/ServerContext";
import { useSnackbar } from "notistack";
import ProjectParticipantsEditor from "./ProjectParticipantsEditor";
import { SearchProvider } from "../Search/SearchProvider";
import ProjectRepeat from "./ProjectRepeat";
import ProjectDummyParticipantsEditor from "./ProjectDummyParticipantsEditor";
import { calcDiscount } from "../Summary/SummaryProject";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  grow: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
});

export const parseServerProject = (
  editProject: EditProject
): ProjectEditObject => {
  return {
    ...editProject,
    publicationPeriodStart: (
      editProject.publicationPeriodStart as Date
    ).toISOString(),
    publicationPeriodEnd: (
      editProject.publicationPeriodEnd as Date
    ).toISOString(),
    bookingPeriodStart:
      editProject.bookingPeriodStart?.toISOString() ??
      (editProject.publicationPeriodStart as Date).toISOString(),
    bookingPeriodEnd:
      editProject.bookingPeriodEnd?.toISOString() ??
      (editProject.publicationPeriodEnd as Date).toISOString(),
    discountRate: parseInt(editProject.discountRate as string) || 0,
    fees: parseInt(editProject.fees as string),
    maximumAge: parseInt(editProject.maximumAge as string),
    minimumAge: parseInt(editProject.minimumAge as string) || 0,
    maximumParticipants:
      parseInt(editProject.maximumParticipants as string) || 0,
    mimimumParticipants:
      parseInt(editProject.mimimumParticipants as string) || 0,
    waitingListSize: parseInt(editProject.waitingListSize as string) || 0,
  };
};

export default function ProjectEditorDetails(props: ProjectPrams) {
  const styles = useStyles();
  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(0);
  const [previousStep, setPreviousStep] = React.useState(activeStep);

  const [project, setProject] = useServer().useEditProject(props.id);
  const { projectActions } = useServer();
  const snackbar = useSnackbar();

  const parseToEditProject = (project: Projects): EditProject => {
    const {
      id,
      room,
      roomid,
      organisations,
      bookings,
      publicationPeriodStart,
      publicationPeriodEnd,
      ...rest
    } = project;

    return {
      ...rest,
      publicationPeriodStart: new Date(publicationPeriodStart),
      publicationPeriodEnd: new Date(publicationPeriodEnd),
      bookingPeriodStart: project.bookingPeriodStart
        ? new Date(project.bookingPeriodStart)
        : null,
      bookingPeriodEnd: project.bookingPeriodEnd
        ? new Date(project.bookingPeriodEnd)
        : null,
      roomName: room,
      organisationids: organisations.map(
        (organisation) => organisation.organisationid
      ),
    };
  };

  const parseToProjects = (
    editProject: ProjectEditObject
  ): Projects | undefined => {
    if (project)
      return {
        ...project,
        ...editProject,
        room: editProject.roomName || "",
        roomid:
          project.room === editProject.roomName ? project.roomid : "unknown",
        organisations: (editProject.organisationids as string[]).map(
          (organisation) => ({
            organisation: "unknwon",
            organisationid: organisation,
          })
        ),
      };
  };

  const slide = (target: number) =>
    activeStep === target
      ? previousStep < activeStep
        ? "left"
        : "right"
      : activeStep < target
      ? "left"
      : "right";

  const onApply = async (projectNew: EditProject) => {
    const parsedProject = parseServerProject(projectNew);

    projectActions
      .update(
        {
          ...parsedProject,
          datetime: JSON.stringify(projectNew.datetime) as any,
        },
        props.id
      )
      .then(() => setProject(parseToProjects(parsedProject)))
      .then(() => {
        if (
          project?.maximumParticipants !== projectNew.maximumParticipants ||
          project?.waitingListSize !== projectNew.waitingListSize
        )
          projectActions.updateState(props.id);
      })
      .snackbar({
        snackbar,
        success: "Projekt erfolgreich aktualisiert",
        error: "Das Projekt konnte nicht aktualisiert werden",
      });

    await projectActions.updateState(props.id);
  };

  const applyRepeat = (repeat: DatetimeRepeat) => {
    const parsedProject = parseServerProject(
      parseToEditProject(project as Projects) as EditProject
    );

    if (project) {
      projectActions
        .update(
          {
            ...parsedProject,
            datetime: JSON.stringify(repeat) as any,
          },
          props.id
        )
        .then(() =>
          setProject(parseToProjects({ ...parsedProject, datetime: repeat }))
        )
        .snackbar({
          snackbar,
          success: "Wiederholdungen erfolgreich aktualisiert",
          error: "Die Wiederholungen konnten nicht aktualisiert werden",
        });
    }
  };

  const applyStep = (step: number) => {
    setPreviousStep(activeStep);
    setActiveStep(step);
  };

  const copyProject = () => {
    if (project)
      projectActions
        .add({
          ...parseServerProject(parseToEditProject(project) as EditProject),
          datetime: JSON.stringify(project.datetime) as any,
        })
        .then(async (id) => {
          await projectActions.copyProjectParticipants(project.id, id);
          return id;
        })
        .then(async (id) => {
          await projectActions.cleanProject(id);
          return id;
        })
        .then((id) => history.push(`/admin/project/${id}`))
        .snackbar({
          snackbar,
          admin: true,
          success: "Projekt erfolgreich kopiert",
        });
  };

  const deleteProject = async () => {
    if (project) {
      await projectActions.cleanProject(project.id);
      projectActions
        .delete(project.id)
        .then(() => history.push("/admin"))
        .snackbar({
          snackbar,
          admin: true,
          success: "Projekt erfolgreich gelöscht",
        });
    }
  };

  const filterBooked = (booking: Booking) =>
    booking.state === ParticipantState.booked;
  const filterWaitingList = (booking: Booking) =>
    booking.state === ParticipantState.waiting;
  const filterCancelled = (booking: Booking) =>
    booking.state === ParticipantState.cancelled;

  const removeBooking = (id: string) => {
    projectActions.updateState(props.id);
    if (project)
      setProject((project) => {
        if (!project) return project;
        const removedPar = project.bookings.find(
          (booking) => booking.id === id
        );

        if (removedPar) removedPar.state = ParticipantState.cancelled;

        return { ...project };
      });
  };

  const updateBooking = (bookings: Booking[]) => {
    projectActions.updateState(props.id);
    project && setProject({ ...project, bookings });
  };

  const updateBookingState = (id: string, state: ParticipantState) => {
    setProject((project) => {
      if (!project) return project;
      const booking = project.bookings.find((booking) => booking.id === id);
      if (booking) booking.state = state;

      return { ...project };
    });
  };

  const addDummyBooking = (count: number) => {
    if (project)
      projectActions.createDummyPeople(project?.id, count).then(() =>
        setProject({
          ...project,
          waitingListSize: project.waitingListSize + count,
        })
      );
  };

  const clearWaitingList = () => {
    projectActions.updateState(props.id);
    project &&
      setProject({
        ...project,
        bookings: project.bookings.map((booking) =>
          booking.state === ParticipantState.waiting
            ? { ...booking, state: ParticipantState.cancelled }
            : booking
        ),
      });
  };

  const togglePaid = (
    ids: string[],
    payment: {
      paid: boolean;
      paymentDate: string;
      price: number;
      method: PaymentMethod;
      count: number;
      note: string;
    }
  ) =>
    project &&
    setProject({
      ...project,
      bookings: project.bookings.map((booking) =>
        !ids.includes(booking.id)
          ? booking
          : {
              ...booking,
              payment,
            }
      ),
    });

  const toggleImageRights = (booking: Booking, imageRights: boolean) =>
    project &&
    setProject({
      ...project,
      bookings: project.bookings.map((booking1) =>
        booking.id !== booking1.id
          ? booking1
          : {
              ...booking1,
              imageRights,
            }
      ),
    });

  return (
    <SearchProvider>
      <Stepper activeStep={activeStep} alternativeLabel nonLinear>
        <Step>
          <StepButton
            onClick={() => applyStep(0)}
            icon={<AccountTreeRounded color="primary" />}
          >
            Projektübersicht
          </StepButton>
        </Step>
        <Step>
          <StepButton
            onClick={() => applyStep(1)}
            icon={<RepeatRounded color="primary" />}
          >
            Wiederholungen
          </StepButton>
        </Step>
        <Step>
          <StepButton
            onClick={() => applyStep(2)}
            icon={<PeopleOutlineRounded color="primary" />}
          >
            Anmeldungen
          </StepButton>
        </Step>
        {project?.bookingRequired && (
          <Step>
            <StepButton
              onClick={() => applyStep(3)}
              icon={<HourglassEmptyRounded color="primary" />}
            >
              Warteliste
            </StepButton>
          </Step>
        )}
        {project?.bookingRequired && (
          <Step>
            <StepButton
              onClick={() => applyStep(4)}
              icon={<Block color="primary" />}
            >
              Abgemeldet
            </StepButton>
          </Step>
        )}
      </Stepper>

      <Box className={styles.root}>
        <Slide
          in={activeStep === 0}
          unmountOnExit
          mountOnEnter
          direction="right"
        >
          <Box className={styles.grow}>
            {project ? (
              <ProjectEditor
                id={props.id}
                defaultProject={parseToEditProject(project)}
                onApply={onApply}
                copyProject={copyProject}
                deleteProject={deleteProject}
              />
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Slide>
        <Slide
          in={activeStep === 1}
          unmountOnExit
          mountOnEnter
          direction={slide(1)}
        >
          {project ? (
            <Box className={styles.grow}>
              <ProjectRepeat
                repeat={project.datetime}
                applyRepeat={applyRepeat}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <CircularProgress />
            </Box>
          )}
        </Slide>
        {project?.bookingRequired ? (
          <>
            <Slide
              in={activeStep === 2}
              unmountOnExit
              mountOnEnter
              direction={slide(2)}
            >
              <Box className={styles.grow}>
                <ProjectParticipantsEditor
                  alreadyBookedSlots={
                    project?.bookings.filter(filterBooked).length
                  }
                  waitingListSlots={
                    project?.bookings.filter(filterWaitingList).length
                  }
                  maximumParticipants={project?.maximumParticipants || 0}
                  waitingListSize={project?.waitingListSize || 0}
                  bookings={project?.bookings.filter(filterBooked)}
                  removeBooking={removeBooking}
                  updateBooking={updateBooking}
                  updateBookingState={updateBookingState}
                  projectID={project?.id}
                  togglePaid={togglePaid}
                  price={(count: number) =>
                    project
                      ? calcDiscount(project.fees, count, project.discountRate)
                      : 0
                  }
                  toggleImageRights={toggleImageRights}
                />
              </Box>
            </Slide>
            <Slide
              in={activeStep === 3}
              unmountOnExit
              mountOnEnter
              direction={slide(3)}
            >
              <Box className={styles.grow}>
                <ProjectParticipantsEditor
                  alreadyBookedSlots={
                    project?.bookings.filter(filterBooked).length
                  }
                  waitingListSlots={
                    project?.bookings.filter(filterWaitingList).length
                  }
                  maximumParticipants={project?.maximumParticipants || 0}
                  waitingListSize={project?.waitingListSize || 0}
                  bookings={project?.bookings.filter(filterWaitingList)}
                  removeBooking={removeBooking}
                  updateBooking={updateBooking}
                  updateBookingState={updateBookingState}
                  projectID={project?.id}
                  waitingList
                  togglePaid={togglePaid}
                  price={(count: number) =>
                    project
                      ? calcDiscount(project.fees, count, project.discountRate)
                      : 0
                  }
                  toggleImageRights={toggleImageRights}
                />
              </Box>
            </Slide>
            <Slide
              in={activeStep === 4}
              unmountOnExit
              mountOnEnter
              direction="left"
            >
              <Box className={styles.grow}>
                <ProjectParticipantsEditor
                  alreadyBookedSlots={
                    project?.bookings.filter(filterBooked).length
                  }
                  waitingListSlots={
                    project?.bookings.filter(filterWaitingList).length
                  }
                  maximumParticipants={project?.maximumParticipants || 0}
                  waitingListSize={project?.waitingListSize || 0}
                  bookings={project?.bookings.filter(filterCancelled)}
                  removeBooking={removeBooking}
                  updateBooking={updateBooking}
                  clearWaitingList={clearWaitingList}
                  updateBookingState={updateBookingState}
                  projectID={project?.id}
                  cancelled
                  togglePaid={togglePaid}
                  price={(count: number) =>
                    project
                      ? calcDiscount(project.fees, count, project.discountRate)
                      : 0
                  }
                  toggleImageRights={toggleImageRights}
                />
              </Box>
            </Slide>
          </>
        ) : (
          <Slide
            in={activeStep === 2}
            unmountOnExit
            mountOnEnter
            direction={slide(2)}
          >
            <Box className={styles.grow}>
              <ProjectDummyParticipantsEditor
                bookings={project?.waitingListSize || 0}
                addBookings={addDummyBooking}
              />
            </Box>
          </Slide>
        )}
      </Box>
    </SearchProvider>
  );
}
