import React from "react";

import { Tooltip } from "@material-ui/core";
import { ArrowBackRounded } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

import HeaderBarAdmin from "./Admin/HeaderBarAdmin";
import ParticipantInfoDetails from "./ParticipantInfo/ParticipantInfoDetails";
import Body from "./Root/Body";
import { HeaderIconButton } from "./Root/HeaderBar";

export interface ParticipantInfoParams {
  id: string;
}

export default function ParticipantInfo() {
  const { id } = useParams<ParticipantInfoParams>();
  const history = useHistory();

  return (
    <>
      <HeaderBarAdmin
        title="Kundenkonto bearbeiten"
        leftAction={
          <Tooltip title="Zurück zur Kundenkontendatenbank">
            <HeaderIconButton
              onClick={() => history.push("/admin/participantdb/")}
            >
              <ArrowBackRounded />
            </HeaderIconButton>
          </Tooltip>
        }
      />
      <Body>
        <ParticipantInfoDetails id={id} />
      </Body>
    </>
  );
}
