import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { useServer } from "../Server/ServerContext";
import config from "../../config";
import { useSnackbar } from "notistack";

interface CreateTemplateButtonProps {
  id: string;
}

export default function CreateTemplateButton(props: CreateTemplateButtonProps) {
  const { projectActions } = useServer();
  const snackbar = useSnackbar();

  const templates = useServer().useTemplates();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const createTemplate = async (template: string) => {
    setLoading(true);
    await projectActions.createTemplate(props.id, template).snackbar({
      snackbar,
      onError: () => setLoading(false),
      admin: true,
      onSuccess: () =>
        window.open(config.templatesDownloadPath + template, "_blank"),
    });
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Wählen Sie die Vorlage aus</DialogTitle>
        {loading ? (
          <Box margin={2} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {templates.map((template) => (
              <ListItem
                button
                onClick={() => createTemplate(template)}
                key={template}
              >
                <ListItemText primary={template} />
              </ListItem>
            ))}
          </List>
        )}
      </Dialog>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Dokument erstellen
      </Button>
    </>
  );
}
