import React from "react";

import { Box, Button, makeStyles } from "@material-ui/core";

import useLogin from "../Login";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function UpdateLogin() {
  const styles = useStyles();

  const { changePassword, changeEmail } = useLogin();

  return (
    <Box display="flex" flexDirection="column" flexShrink={0}>
      {/* <Button
        color="primary"
        variant="outlined"
        onClick={changeEmail}
        className={styles.button}
      >
        Email aktualisieren
      </Button>*/}
      <Button
        color="primary"
        variant="contained"
        onClick={changePassword}
        className={styles.button}
      >
        Passwort ändern
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={changeEmail}
        className={styles.button}
      >
        Email ändern
      </Button>
    </Box>
  );
}
