import React from "react";

import Body from "../Root/Body";
import {
  CreateOrganisation,
  EditOrganisation,
} from "./Organisations/Organisation";
import { Organisation, useServer } from "../Server/ServerContext";
import OrganisationList from "./Organisations/OrganisationList";

import { Button } from "@material-ui/core";
import { AddCircleOutlineRounded } from "@material-ui/icons";

export default function Organisations() {
  const [createOrganisation, setCreateOrganisation] = React.useState(false);
  const [editOrganisation, setEditOrganisation] = React.useState<
    undefined | Organisation
  >(undefined);

  const useOrganisations = useServer().useOrganisations();

  return (
    <Body>
      <CreateOrganisation
        open={createOrganisation}
        onClose={() => setCreateOrganisation(false)}
        useOrganisations={useOrganisations}
      />
      <EditOrganisation
        open={Boolean(editOrganisation)}
        onClose={() => setEditOrganisation(undefined)}
        organisation={editOrganisation}
        useOrganisations={useOrganisations}
      />
      <Button
        variant="contained"
        fullWidth
        startIcon={<AddCircleOutlineRounded />}
        onClick={() => setCreateOrganisation(true)}
      >
        Organisation hinzufügen
      </Button>
      <OrganisationList
        onClick={setEditOrganisation}
        useOrganisations={useOrganisations}
      />
    </Body>
  );
}
