import React from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { updateQuery } from "../../Functions/QueryParams";

import { useServer } from "../Server/ServerContext";
import useLogin from "../Login";
import Root from "../Root";
import useLocalStorage from "../LocalStorage";
import Project from "../Project";

export default function PrivateRoute(props: RouteProps) {
  const history = useHistory();

  const { currentUser } = useServer();
  const { login, abort, close } = useLogin();
  const { storage } = useLocalStorage();

  const iniAbort = React.useRef(abort);

  const loggedIn = Boolean(currentUser);

  React.useEffect(() => {
    if (!loggedIn) {
      login();
      updateQuery({ highlightRegister: true });
    }
  });

  React.useEffect(() => {
    if (iniAbort.current - abort && !loggedIn) {
      history.push(storage.booking ? `/project/${storage.booking}` : "/");
      close();
    }
  }, [abort]);

  return !currentUser ? (
    storage.booking ? (
      <Project id={storage.booking} />
    ) : (
      <Root />
    )
  ) : (
    <Route {...props} />
  );
}
