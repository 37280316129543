import React from "react";

import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Chip,
  Box,
  Button,
  IconButton,
  TextField,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { DeleteOutlineRounded, ExpandMoreRounded } from "@material-ui/icons";

import {
  DatetimeRepeat,
  DatetimeRepeatDaily,
  DatetimeRepeatMonth,
  DatetimeRepeatMonthTiming,
  DatetimeRepeatMonthType,
  DatetimeRepeatWeek,
  DatetimeRepeatWeekdays,
} from "../Server/ServerContext";
import { SettingsAccordion } from "../Settings/SettingsList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  margin: {
    marginLeft: "auto",
  },
  input: {
    textAlign: "center",
  },
  chip: {
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  addWeek: {
    alignSelf: "center",
    marginTop: theme.spacing(2),
  },
}));

export interface ProjectRepeatComplexProps {
  repeat: Omit<DatetimeRepeat, "begin" | "end">;
  applyRepeat?: (repeat: Partial<DatetimeRepeat>) => void;
  onChange?: (repeat: Partial<DatetimeRepeat>, valid: boolean) => void;
}

export default function ProjectRepeatComplex(props: ProjectRepeatComplexProps) {
  const styles = useStyles();

  const [editRepeatDaily, setEditRepeatDaily] = React.useState(
    props.repeat ? { ...props.repeat.daily } : undefined
  );
  const [editDaily, setEditDaily] = React.useState(false);

  React.useEffect(() => {
    if (
      !editDaily &&
      JSON.stringify(props.repeat.daily) !== JSON.stringify(editRepeatDaily)
    )
      abortDaily();
  }, [props.repeat]);

  React.useEffect(
    () =>
      setEditDaily(
        JSON.stringify(editRepeatDaily) !== JSON.stringify(props.repeat?.daily)
      ),
    [editRepeatDaily, props.repeat]
  );

  const [editRepeatNumber, setEditRepeatNumber] = React.useState(
    props.repeat?.repeat
  );
  const [editRepeatWeek, setEditRepeatWeek] = React.useState(
    props.repeat?.week
  );
  const [editedWeek, setEditedWeek] = React.useState(false);

  React.useEffect(() => {
    if (
      !editedWeek &&
      JSON.stringify(props.repeat.repeat) !==
        JSON.stringify(editRepeatNumber) &&
      JSON.stringify(props.repeat.week) !== JSON.stringify(editRepeatWeek)
    )
      abortWeek();
  }, [props.repeat]);

  React.useEffect(
    () =>
      setEditedWeek(
        JSON.stringify(editRepeatWeek) !== JSON.stringify(props.repeat?.week) ||
          (editRepeatNumber || null) !== props.repeat?.repeat
      ),
    [editRepeatWeek, editRepeatNumber, props.repeat]
  );

  const [editRepeatMonth, setEditRepeatMonth] = React.useState(
    props.repeat?.month.map((obj) => ({ ...obj }))
  );
  const [editMonth, setEditedMonth] = React.useState(false);

  React.useEffect(() => {
    if (
      !editMonth &&
      JSON.stringify(props.repeat.month) !== JSON.stringify(editRepeatMonth)
    )
      abortMonth();
  }, [props.repeat]);

  React.useEffect(
    () =>
      setEditedMonth(
        JSON.stringify(editRepeatMonth) !== JSON.stringify(props.repeat?.month)
      ),
    [editRepeatMonth, props.repeat]
  );

  const callbackDaily = (newTime: DatetimeRepeatDaily) =>
    setEditRepeatDaily(newTime);

  const callbackRepeat = (repeat: number) => setEditRepeatNumber(repeat);

  const callbackWeekday = (
    weekIndex: number,
    toggleWeekday: DatetimeRepeatWeekdays
  ) => {
    if (editRepeatWeek) {
      const time = editRepeatWeek.map((week) => ({
        ...week,
        weekdays: [...week.weekdays],
      }));
      // Toggle weekday at weekIndex
      if (time[weekIndex].weekdays.includes(toggleWeekday))
        time[weekIndex].weekdays = time[weekIndex].weekdays.filter(
          (weekday) => weekday !== toggleWeekday
        );
      else
        time[weekIndex].weekdays = [...time[weekIndex].weekdays, toggleWeekday];

      setEditRepeatWeek(time);
    }
  };
  const callbackTime = (weekIndex: number, newWeek: DatetimeRepeatWeek) => {
    if (editRepeatWeek) {
      const time = [...editRepeatWeek];
      // Update time
      time[weekIndex] = newWeek;

      setEditRepeatWeek(time);
    }
  };
  const removeWeekRepeat = (index: number) => {
    if (editRepeatWeek) {
      const time = [
        ...editRepeatWeek.slice(0, index),
        ...editRepeatWeek.slice(index + 1),
      ];

      setEditRepeatWeek(time);
    }
  };
  const addWeekRepeat = () => {
    if (editRepeatWeek) {
      // Add new time
      const time = [
        ...editRepeatWeek,
        {
          weekdays: [],
          from: null,
          to: null,
        },
      ];

      setEditRepeatWeek(time);
    }
  };

  const callbackMonth = (index: number, newMonth: DatetimeRepeatMonth) => {
    if (editRepeatMonth) {
      const month = editRepeatMonth.map((obj) => ({ ...obj }));
      month[index] = newMonth;

      setEditRepeatMonth(month);
    }
  };
  const removeMonthRepeat = (index: number) => {
    if (editRepeatMonth) {
      const month = [
        ...editRepeatMonth.slice(0, index),
        ...editRepeatMonth.slice(index + 1),
      ];

      setEditRepeatMonth(month);
    }
  };
  const addMonthRepeat = () => {
    if (editRepeatMonth) {
      const month = [
        ...editRepeatMonth.map((obj) => ({ ...obj })),
        {
          day: 0,
          type: 0,
          timing: 0,
          from: null,
          to: null,
        },
      ];

      setEditRepeatMonth(month.map((obj) => ({ ...obj })));
    }
  };

  const abortDaily = () =>
    setEditRepeatDaily(props.repeat ? { ...props.repeat.daily } : undefined);
  const abortWeek = () =>
    setEditRepeatWeek(props.repeat?.week.map((obj) => ({ ...obj })));
  const abortMonth = () =>
    setEditRepeatMonth(props.repeat?.month.map((obj) => ({ ...obj })));

  const disableDay: boolean = Boolean(
    Boolean(editRepeatDaily?.from) !== Boolean(editRepeatDaily?.to) ||
      (editRepeatDaily?.from &&
        isNaN(new Date(editRepeatDaily.from).getTime())) ||
      (editRepeatDaily?.to && isNaN(new Date(editRepeatDaily.to).getTime()))
  );

  const disableWeek: boolean = Boolean(
    editRepeatWeek?.some(
      (week) =>
        !week.weekdays.length ||
        !week.from ||
        isNaN(new Date(week.from).getTime()) ||
        !week.to ||
        isNaN(new Date(week.to).getTime())
    )
  );

  const disableMonth: boolean = Boolean(
    editRepeatMonth?.some(
      (month) =>
        !month.day ||
        !month.from ||
        isNaN(new Date(month.from).getTime()) ||
        !month.to ||
        isNaN(new Date(month.to).getTime())
    )
  );

  React.useEffect(() => {
    if (props.repeat && (editDaily || editMonth || editedWeek))
      props.onChange?.(
        {
          daily: editRepeatDaily as DatetimeRepeatDaily,
          repeat: editRepeatNumber as number,
          week: editRepeatWeek as DatetimeRepeatWeek[],
          month: editRepeatMonth as DatetimeRepeatMonth[],
        },
        !(disableDay || disableWeek || disableMonth)
      );
  }, [
    editRepeatDaily,
    editRepeatNumber,
    editRepeatWeek,
    editRepeatMonth,
    editDaily,
    editMonth,
    editedWeek,
  ]);

  return (
    <>
      <SettingsAccordion
        defaultExpanded
        disabled={!editDaily || disableDay}
        onClick={
          props.applyRepeat
            ? () =>
                props.repeat &&
                (props.applyRepeat as any)({
                  ...props.repeat,
                  daily: editRepeatDaily as DatetimeRepeatDaily,
                })
            : undefined
        }
        abort={!props.onChange ? abortDaily : undefined}
      >
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          Täglich
        </AccordionSummary>
        <AccordionDetails>
          <TimeInput
            time={editRepeatDaily?.from || null}
            callback={(from) =>
              editRepeatDaily && callbackDaily({ ...editRepeatDaily, from })
            }
            label="Projektbeginn"
            fullWidth
          />
          <TimeInput
            time={editRepeatDaily?.to || null}
            callback={(to) =>
              editRepeatDaily && callbackDaily({ ...editRepeatDaily, to })
            }
            label="Projektende"
            fullWidth
          />
        </AccordionDetails>
      </SettingsAccordion>
      <SettingsAccordion
        defaultExpanded
        disabled={!editedWeek || disableWeek}
        onClick={
          props.applyRepeat
            ? () =>
                props.repeat &&
                (props.applyRepeat as any)({
                  ...props.repeat,
                  repeat: editRepeatNumber as number,
                  week: editRepeatWeek as DatetimeRepeatWeek[],
                })
            : undefined
        }
        abort={!props.onChange ? abortWeek : undefined}
      >
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          <Typography>Wochentage</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.root}>
          <FormControl margin="normal">
            <InputLabel>Wiederholung</InputLabel>
            <Input
              startAdornment="alle"
              endAdornment="Wochen"
              value={editRepeatNumber || ""}
              onChange={(event) =>
                callbackRepeat(
                  parseInt(
                    event.target.value.replace(/[^0-9]/g, "").slice(0, 2)
                  )
                )
              }
              placeholder="1"
              classes={{ input: styles.input }}
            />
          </FormControl>
          {editRepeatWeek?.map((week, weekIndex) => (
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-around"
              width="100%"
              key={`${weekIndex}${editRepeatWeek.length}`}
            >
              <Box marginRight={1}>
                {[
                  "Montag",
                  "Dienstag",
                  "Mittwoch",
                  "Donnerstag",
                  "Freitag",
                  "Samstag",
                  "Sonntag",
                ].map((weekday, index) => (
                  <Chip
                    key={index}
                    label={weekday}
                    color={
                      week.weekdays.includes(index) ? "primary" : "default"
                    }
                    onClick={() => callbackWeekday(weekIndex, index)}
                    className={styles.chip}
                  />
                ))}
              </Box>
              <Box className={styles.margin}>
                <TimeInput
                  time={week.from}
                  callback={(from) =>
                    callbackTime(weekIndex, {
                      ...week,
                      from,
                    })
                  }
                  label="Projektbeginn"
                />
                <TimeInput
                  time={week.to}
                  callback={(to) =>
                    callbackTime(weekIndex, {
                      ...week,
                      to,
                    })
                  }
                  label="Projektende"
                />
              </Box>
              <IconButton
                onClick={() => removeWeekRepeat(weekIndex)}
                className={styles.margin}
              >
                <DeleteOutlineRounded />
              </IconButton>
            </Box>
          ))}
          <Button
            color="primary"
            variant="contained"
            onClick={addWeekRepeat}
            className={styles.addWeek}
          >
            Weitere Zeiten hinzufügen
          </Button>
        </AccordionDetails>
      </SettingsAccordion>
      <SettingsAccordion
        defaultExpanded
        disabled={!editMonth || disableMonth}
        onClick={
          props.applyRepeat
            ? () =>
                props.repeat &&
                (props.applyRepeat as any)({
                  ...props.repeat,
                  month: editRepeatMonth as DatetimeRepeatMonth[],
                })
            : undefined
        }
        abort={!props.onChange ? abortMonth : undefined}
      >
        <AccordionSummary expandIcon={<ExpandMoreRounded />}>
          <Typography>Monatstage</Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.root}>
          {editRepeatMonth?.map((month, index) => (
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-around"
              width="100%"
              key={index}
            >
              <TextField
                value={month.day || ""}
                onChange={(event) =>
                  callbackMonth(index, {
                    ...month,
                    day: parseInt(event.target.value.replace(/[^0-9]/g, "")),
                  })
                }
                margin="normal"
                label="Tag"
                fullWidth
              />
              <TextField
                value={month.type}
                onChange={(event) =>
                  callbackMonth(index, {
                    ...month,
                    type: event.target
                      .value as unknown as DatetimeRepeatMonthType,
                  })
                }
                margin="normal"
                label=" "
                select
                fullWidth
              >
                <ListSubheader>Generell</ListSubheader>
                <MenuItem value={DatetimeRepeatMonthType.SpecificDay}>
                  Tag
                </MenuItem>
                <ListSubheader>Wochentage</ListSubheader>
                <MenuItem value={DatetimeRepeatMonthType.Monday}>
                  Montag
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthType.Tuesday}>
                  Dienstag
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthType.Wednesday}>
                  Mittwoch
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthType.Thursday}>
                  Donnerstag
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthType.Friday}>
                  Freitag
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthType.Saturday}>
                  Samstag
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthType.Sunday}>
                  Sonntag
                </MenuItem>
              </TextField>
              <TextField
                value={month.timing}
                onChange={(event) =>
                  callbackMonth(index, {
                    ...month,
                    timing: event.target
                      .value as unknown as DatetimeRepeatMonthTiming,
                  })
                }
                margin="normal"
                label=" "
                select
                fullWidth
              >
                <MenuItem value={DatetimeRepeatMonthTiming.AfterStart}>
                  im Monat
                </MenuItem>
                <MenuItem value={DatetimeRepeatMonthTiming.BeforeEnd}>
                  vor dem Monatsende
                </MenuItem>
              </TextField>
              <TimeInput
                time={month.from}
                callback={(from) => callbackMonth(index, { ...month, from })}
                label="Projektbeginn"
                fullWidth
              />
              <TimeInput
                time={month.to}
                callback={(to) => callbackMonth(index, { ...month, to })}
                label="Projektende"
                fullWidth
              />
              <IconButton onClick={() => removeMonthRepeat(index)}>
                <DeleteOutlineRounded />
              </IconButton>
            </Box>
          ))}
          <Button
            color="primary"
            variant="contained"
            onClick={addMonthRepeat}
            className={styles.addWeek}
          >
            Weitere Zeiten hinzufügen
          </Button>
        </AccordionDetails>
      </SettingsAccordion>
    </>
  );
}

export function TimeInput(props: {
  time: Date | null;
  callback: (newTime: Date | null) => void;
  fullWidth?: boolean;
  label: string;
}) {
  return (
    <KeyboardTimePicker
      margin="normal"
      label={props.label}
      value={props.time}
      onChange={(date: Date | null) => {
        const valid = date && !isNaN(date.getTime());
        props.callback(valid ? date : null);
      }}
      helperText={undefined}
      variant="inline"
      ampm={false}
      fullWidth={props.fullWidth}
    />
  );
}
