import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowBackRounded } from "@material-ui/icons";

import useLocalStorage from "../LocalStorage";
import SubHeaderIconButton from "../Mobile/SubHeaderIconButton";

export default function BackToBooking() {
  const history = useHistory();
  const { setStorage } = useLocalStorage();

  const backToBooking = () => {
    history.push("/booking");
    setStorage({ memberIDs: undefined });
  };

  return (
    <SubHeaderIconButton onClick={backToBooking} icon={<ArrowBackRounded />}>
      Zurück zur Buchung
    </SubHeaderIconButton>
  );
}
