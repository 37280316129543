import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  AccordionActions,
  Button,
  Box,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React from "react";
import { Member, useServer } from "../Server/ServerContext";
import ShowDuplicateParticipant from "./ShowDuplicateParticipant";

export interface ParticipantDuplicatesListProps {
  duplicates: string[];
  callback: () => void;
}

export default function ParticipantDuplicatesList(
  props: ParticipantDuplicatesListProps
) {
  const snackbar = useSnackbar();
  const { peopleActions } = useServer();
  const [participants, setParticipants] = React.useState<Member[]>([]);
  const [checkedParticipant, setCheckedParticipant] = React.useState(-1);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      setParticipants([await peopleActions.get(props.duplicates[0])]);
    })();
  }, []);

  const getData = () => {
    (async () => {
      setParticipants(
        await Promise.all(props.duplicates.map((id) => peopleActions.get(id)))
      );
    })();
  };

  const fuseTogether = async () => {
    const leader = props.duplicates[checkedParticipant];
    const duplicates = props.duplicates;
    duplicates.splice(checkedParticipant, 1);
    await Promise.all(
      duplicates.map((duplicate) =>
        peopleActions.rebindProjects(duplicate, leader)
      )
    ).snackbar({
      snackbar,
      error:
        "Mehrere der Personen sind zum selben Projekt angemeldet und können deswegen nicht zusammengefügt werden",
      onSuccess: async () => {
        await Promise.all(
          duplicates.map((duplicate) => peopleActions.delete(duplicate))
        ).snackbar({
          snackbar,
          success: "Die Kundenkonten wurde erfolgreich zusammengefügt",
          error: "Beim Zusammenfügen ist ein Fehler aufgetreten",
        });
        props.callback();
      },
    });
  };

  return (
    <Accordion
      expanded={open}
      onChange={(_, open) => setOpen(open)}
      TransitionProps={{ onEnter: getData }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {participants.length === 0 ? (
          <Skeleton width={200} />
        ) : (
          `${participants[0].name} ${participants[0].surname}, ${new Date(
            participants[0].birthday
          ).toLocaleDateString("de")}`
        )}
      </AccordionSummary>
      <AccordionDetails>
        {participants.length <= 1 ? (
          <CircularProgress />
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            {participants.map((participant, index) => (
              <ShowDuplicateParticipant
                participant={participant}
                key={index}
                onChecked={() =>
                  setCheckedParticipant(
                    checkedParticipant === index ? -1 : index
                  )
                }
                checked={checkedParticipant === index}
              />
            ))}
          </Box>
        )}
      </AccordionDetails>
      <AccordionActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
            setCheckedParticipant(-1);
          }}
        >
          Abbrechen
        </Button>
        <Button
          disabled={checkedParticipant === -1}
          variant="contained"
          color="primary"
          onClick={fuseTogether}
        >
          Zusammenfügen
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
