import React from "react";
import { useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export type BaseDataTypes = "employee" | "organisations" | "locations";

export const StyledToggleButton = withStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: theme.palette.common.white + " !important",
    transition: theme.transitions.create("background-color"),
    "&:hover": {
      backgroundColor: theme.palette.primary.dark + " !important",
    },
  },
}))(ToggleButton);

export interface BaseDataTypeProps {
  type: BaseDataTypes;
}

export default function BaseDataType(props: BaseDataTypeProps) {
  const history = useHistory();

  const redirect = (target: BaseDataTypes) =>
    history.push(`/admin/basedata/${target}`);

  return (
    <ToggleButtonGroup value={props.type} size="large" color="secondary">
      <StyledToggleButton value="employee" onClick={() => redirect("employee")}>
        Mitarbeiter
      </StyledToggleButton>
      <StyledToggleButton
        value="organisations"
        onClick={() => redirect("organisations")}
      >
        Einrichtungen
      </StyledToggleButton>
      <StyledToggleButton
        value="locations"
        onClick={() => redirect("locations")}
      >
        Räume
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
}
