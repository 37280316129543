import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowBackRounded } from "@material-ui/icons";

import useLocalStorage from "../LocalStorage";
import { Button } from "@material-ui/core";

export interface BackToProjectsProps {
  onClick?: () => void;
}

export default function BackToProjects(props: BackToProjectsProps) {
  const history = useHistory();
  const { storage } = useLocalStorage();

  const backToBooking = () => {
    history.push("/booking");
    props.onClick?.();
  };

  return !storage.booking ? null : (
    <Button
      startIcon={<ArrowBackRounded />}
      variant="contained"
      color="primary"
      onClick={backToBooking}
    >
      Zurück zur Buchung
    </Button>
  );
}
