import React from "react";
import { Redirect } from "react-router-dom";

import HeaderBarPublic from "./Root/HeaderBar";
import SubHeaderBar from "./Root/SubHeaderBar";
import PersonSelectList from "./Booking/PersonSelectList";
import Body from "./Root/Body";
import BackToProjectPreview from "./Booking/BackToProjectPreview";
import SummaryButton from "./Booking/SummaryButton";

import { Box, Typography } from "@material-ui/core";

import useLocalStorage from "./LocalStorage";

export default function Booking() {
  const { storage } = useLocalStorage();

  const [selectedMembers, setSelectedMembers] = React.useState([] as string[]);

  return storage.booking ? (
    <>
      <HeaderBarPublic title="Buchung" />
      <SubHeaderBar
        left={<BackToProjectPreview />}
        right={<SummaryButton memberIDs={selectedMembers} />}
      />
      <Body>
        <>
          <Box m={2}>
            <Typography component="h2">
              Bitte wählen Sie die teilnehmenden Familienmitglieder aus:
            </Typography>
          </Box>
          <PersonSelectList
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            id={storage.booking}
          />
        </>
      </Body>
    </>
  ) : (
    <Redirect to="/" />
  );
}
