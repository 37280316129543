import React from "react";

import { Button, Link } from "@material-ui/core";

export default function CashPayment(props: { bookProject: () => void }) {
  return (
    <Link onClick={props.bookProject} style={{ width: "100%" }}>
      <Button variant="contained" color="primary" fullWidth>
        Bar bezahlen
      </Button>
    </Link>
  );
}
