import React from "react";

import {
  Checkbox,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { LegalURLs, useServer } from "../Server/ServerContext";

export interface SummaryAcceptTermsProps {
  privacy: boolean;
  setPrivacy: (set: boolean) => void;
  agb: boolean;
  setAgb: (set: boolean) => void;
  imageRights: boolean;
  setImageRights: (set: boolean) => void;
}

export default function SummaryAcceptTerms(props: SummaryAcceptTermsProps) {
  const { dataActions } = useServer();
  const preventBubble = (event: React.MouseEvent<HTMLSpanElement>) =>
    event.stopPropagation();

  const [legalURLs, setLegalURLs] = React.useState<undefined | LegalURLs>(
    undefined
  );

  React.useEffect(() => {
    (async () => {
      setLegalURLs(await dataActions.getLegalURLs());
    })();
  }, []);

  return (
    <List>
      <ListItem button onClick={() => props.setPrivacy(!props.privacy)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={props.privacy} color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Link
                rel="noopener noreferrer"
                href={legalURLs?.data_privacy_url}
                target="_blank"
                onClick={preventBubble}
              >
                Datenschutzerklärung
              </Link>
              {" habe ich zur Kenntnis genommen"}
            </>
          }
        />
      </ListItem>
      <ListItem button onClick={() => props.setAgb(!props.agb)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={props.agb} color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Link
                rel="noopener noreferrer"
                href={legalURLs?.agb_url}
                target="_blank"
                onClick={preventBubble}
              >
                AGB
              </Link>
              {" habe ich zur Kenntnis genommen und stimme diesen zu"}
            </>
          }
        />
      </ListItem>
      <ListItem button onClick={() => props.setImageRights(!props.imageRights)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={props.imageRights} color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Link
                rel="noopener noreferrer"
                href={legalURLs?.image_rights_url}
                target="_blank"
                onClick={preventBubble}
              >
                Bildrechte
              </Link>
            </>
          }
        />
      </ListItem>
    </List>
  );
}
