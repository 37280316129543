import React from "react";
import { useHistory } from "react-router-dom";

import { Tooltip, Box, Typography, AppBar, Toolbar } from "@material-ui/core";
import {
  AccountCircleRounded,
  ArrowBackRounded,
  ContactsRounded,
  ExitToAppRounded,
  FaceRounded,
  InsertChartRounded,
  SettingsRounded,
  ShowChartRounded,
  SupervisedUserCircleRounded,
} from "@material-ui/icons";

import useLogin from "../Login";
import { HeaderIconButton, useStyles } from "../Root/HeaderBar";
import { useServer } from "../Server/ServerContext";
import { User } from "../../Interfaces";
import config from "../../config";
import { ReactComponent as Logo } from "./../../resources/varausLogo.svg";

export interface HeaderBarProps {
  title: string;
  leftAction?: React.ReactNode;
}

export default function HeaderBarAdmin(props: HeaderBarProps) {
  const styles = useStyles();

  const history = useHistory();
  const { changePassword, logout } = useLogin();
  const { currentUser, dataActions, useNamespace } = useServer();
  const namespace = useNamespace[0];

  const [favicon, setFavicon] = React.useState<string | undefined>();

  React.useEffect(() => {
    (async () => {
      setFavicon(await dataActions.getFavicon(namespace));
    })();
  }, []);

  const logoutButton = () => logout();

  return (
    <>
      <AppBar position="relative">
        <Toolbar className={styles.toolbar}>
          {props.leftAction || (
            <Tooltip title="Zurück zu den Projekten">
              <HeaderIconButton onClick={() => history.push("/admin")}>
                <ArrowBackRounded />
              </HeaderIconButton>
            </Tooltip>
          )}
          <Box display="flex" flexGrow={1} alignItems="center">
            {favicon ? (
              <img
                src={config.fileRootPath + favicon}
                alt={props.title}
                className={styles.img}
              />
            ) : (
              <Logo className={styles.img} />
            )}
            <Typography variant="h6">{props.title}</Typography>
          </Box>
          <>
            <Tooltip title="Statistiken">
              <HeaderIconButton onClick={() => history.push("/admin/stats")}>
                <ShowChartRounded />
              </HeaderIconButton>
            </Tooltip>
            {(currentUser as User).access_level > 1 ? (
              <Tooltip title="Stammdaten">
                <HeaderIconButton
                  onClick={() => history.push("/admin/basedata/employee")}
                >
                  <InsertChartRounded />
                </HeaderIconButton>
              </Tooltip>
            ) : null}
            {(currentUser as User).access_level > 1 ? (
              <Tooltip title="Einstellungen">
                <HeaderIconButton
                  onClick={() => history.push("/admin/settings")}
                >
                  <SettingsRounded />
                </HeaderIconButton>
              </Tooltip>
            ) : null}
            {(currentUser as User).access_level > 1 ? (
              <Tooltip title="Nutzerverwaltung">
                <HeaderIconButton
                  onClick={() => history.push("/admin/usermanagement/employee")}
                >
                  <SupervisedUserCircleRounded />
                </HeaderIconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Teilnehmerdatenbank">
              <HeaderIconButton
                onClick={() => history.push("/admin/participantdb")}
              >
                <ContactsRounded />
              </HeaderIconButton>
            </Tooltip>
            <Tooltip title="Accountverwaltung">
              <HeaderIconButton onClick={changePassword}>
                <AccountCircleRounded />
              </HeaderIconButton>
            </Tooltip>
            <Tooltip title="Zur Kundenseite">
              <HeaderIconButton onClick={() => history.push("/")}>
                <FaceRounded />
              </HeaderIconButton>
            </Tooltip>
            <Tooltip title="Abmelden">
              <HeaderIconButton onClick={logoutButton}>
                <ExitToAppRounded />
              </HeaderIconButton>
            </Tooltip>
          </>
        </Toolbar>
      </AppBar>
    </>
  );
}
