import React from "react";
import { Redirect } from "react-router-dom";

import BackToProjectsDone from "./Completed/BackToProjectsDone";
import CompletedText from "./Completed/CompletedText";
import Body from "./Root/Body";
import HeaderBarPublic from "./Root/HeaderBar";
import SubHeaderBar from "./Root/SubHeaderBar";

import useLocalStorage from "./LocalStorage";

export default function Completed() {
  const { storage } = useLocalStorage();

  return storage.booking && storage.personIDs ? (
    <>
      <HeaderBarPublic title="Anmeldung erfolgreich" />
      <SubHeaderBar right={<BackToProjectsDone />} />
      <Body>
        <CompletedText />
      </Body>
    </>
  ) : (
    <Redirect to="/" />
  );
}
