import React from "react";

import {
  AccordionSummary,
  AccordionDetails,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import { ChromePicker } from "react-color";
import { useSnackbar } from "notistack";

import { SettingsAccordion, SettingsListItemProps } from "./SettingsList";
import { useServer } from "../Server/ServerContext";
import FileSelectorDialog from "../FileSelectorDialog";

import config from "../../config";

export const useStyles = makeStyles({
  colorPicker: {
    boxShadow: "none !important",
  },
  icon: {
    maxWidth: 100,
    maxHeight: 100,
  },
});

export default function ColorEditor(props: SettingsListItemProps) {
  const styles = useStyles();

  const [settings, setSettings] = props.useSettings;
  const { settingsActions, requestColorFetch } = useServer();
  const snackbar = useSnackbar();

  const [icon, setIcon] = React.useState(settings?.icon_path);
  const [primary, setPrimary] = React.useState(settings?.primary_color);
  const [text, setText] = React.useState(settings?.text_color);
  const [edited, setEdited] = React.useState(false);

  React.useEffect(() => {
    if (!edited) abort();
  }, [settings]);

  React.useEffect(
    () =>
      setEdited(
        icon !== settings?.icon_path ||
          primary !== settings?.primary_color ||
          text !== settings?.text_color
      ),
    [icon, primary, text, settings]
  );

  const updateColors = () => {
    Promise.all([
      settingsActions.set("icon_path", icon),
      settingsActions.set("primary_color", primary),
      settingsActions.set("text_color", text),
    ])
      .then(() => {
        setSettings({
          ...settings,
          icon_path: icon,
          primary_color: primary,
          text_color: text,
        });
        requestColorFetch();
      })
      .snackbar({
        snackbar,
        success: "Farbschema erfolgreich aktualisiert",
        admin: true,
      });
  };

  const abort = () => {
    setIcon(settings?.icon_path);
    setPrimary(settings?.primary_color);
    setText(settings?.text_color);
  };

  return (
    <SettingsAccordion abort={abort} onClick={updateColors} disabled={!edited}>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        Farbschema
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" display="flex">
          <Box display="flex" justifyContent="space-around" width="100%">
            <Box>
              <Typography variant="h5">Primärfarbe</Typography>
              <ChromePicker
                className={styles.colorPicker}
                color={primary}
                onChange={(color) => setPrimary(color.hex)}
                disableAlpha
              />
            </Box>
            <Box>
              <Typography variant="h5">Textfarbe</Typography>
              <ChromePicker
                className={styles.colorPicker}
                color={text}
                onChange={(color) => setText(color.hex)}
                disableAlpha
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box m={2}>
                {icon ? (
                  <img
                    className={styles.icon}
                    src={config.fileRootPath + icon}
                    alt="Icon der Webseite"
                  />
                ) : (
                  "Kein Icon ausgewählt"
                )}
              </Box>
              <FileSelectorDialog
                callbackDone={(files) => setIcon(files?.[0]?.path || "")}
                pickedFiles={[icon]}
                filter={(files) =>
                  files.filter(
                    (file) =>
                      file.filetype === "image" ||
                      file.filetype === "vectorgraphic"
                  )
                }
              >
                Icon auswählen
              </FileSelectorDialog>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </SettingsAccordion>
  );
}
