import React, { RefObject } from "react";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Editor,
  RichUtils,
  CompositeDecorator,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  FormatBoldRounded,
  FormatItalicRounded,
  FormatUnderlinedRounded,
  InsertLinkRounded,
  LinkOffRounded,
} from "@material-ui/icons";

import "draft-js/dist/Draft.css";

import "./texteditor.css";
import "./toolbar.css";

export type InlineStyles = "BOLD" | "ITALIC" | "UNDERLINE";

export type BlockStyles =
  | "paragraph"
  | "header-one"
  | "header-two"
  | "header-three"
  | "header-four"
  | "header-five"
  | "header-six"
  | "unordered-list-item"
  | "ordered-list-item";

export type FormattingStyles = {
  Textblock: "paragraph";
  "Titel 1": "header-one";
  "Titel 2": "header-two";
  "Titel 3": "header-three";
  "Titel 4": "header-four";
  "Titel 5": "header-five";
  "Titel 6": "header-six";
};

interface TextEditorToolBarProps {
  children: React.ReactNode;
  onStyleToggle: (style: string) => void;
  onBlockToggle: (block: string) => void;
  promptForLink: () => boolean;
  confirmLink: (link: string) => void;
  removeLink: () => boolean;
}

const formattingStyles: FormattingStyles = {
  Textblock: "paragraph",
  "Titel 1": "header-one",
  "Titel 2": "header-two",
  "Titel 3": "header-three",
  "Titel 4": "header-four",
  "Titel 5": "header-five",
  "Titel 6": "header-six",
};

const defaultFormattingStyle = "Textblock";

function TextEditorToolBar(props: TextEditorToolBarProps) {
  // Manage links
  const [linkErrorOpen, setLinkErrorOpen] = React.useState(false);
  const [linkError, setLinkError] = React.useState("");
  const [link, setLink] = React.useState("");
  const [addLink, setAddLink] = React.useState(false);

  const linkInput = React.useRef() as RefObject<HTMLInputElement>;

  React.useEffect(() => {
    if (linkError) setLinkErrorOpen(true);
  }, [linkError]);

  React.useEffect(() => setLink(""), [addLink]);

  const toggleBold = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onStyleToggle("BOLD");
  };
  const toggleItalic = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onStyleToggle("ITALIC");
  };
  const toggleUnderline = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onStyleToggle("UNDERLINE");
  };

  const blockUpdate = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) =>
    props.onBlockToggle(
      formattingStyles[
        event.target.value as unknown as keyof FormattingStyles
      ] as BlockStyles
    );

  const promptForLink = () => {
    if (!props.promptForLink())
      setLinkError("Sie müssen Text markieren um einen Link einzufügen.");
    else setAddLink(true);
  };

  const removeLink = () => {
    if (!props.removeLink())
      setLinkError("Sie müssen einen Link markieren um diesen zu entfernen.");
  };

  return (
    <>
      <Dialog
        open={addLink}
        onClose={() => setAddLink(false)}
        TransitionProps={{ onEntered: () => linkInput.current?.focus() }}
      >
        <DialogTitle>Link hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label="Geben Sie den Link ein:"
            value={link}
            onChange={(event) => setLink(event.target.value)}
            inputRef={linkInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddLink(false)}>Abbrechen</Button>
          <Button
            color="primary"
            onClick={() => {
              props.confirmLink(link);
              setAddLink(false);
            }}
            disabled={!link}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={linkErrorOpen}
        onClose={() => setLinkErrorOpen(false)}
        TransitionProps={{ onExited: () => setLinkError("") }}
      >
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>{linkError}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setLinkErrorOpen(false)}>
            Verstanden
          </Button>
        </DialogActions>
      </Dialog>

      <div className="texteditor">
        <div className="toolBar">
          <Select defaultValue={defaultFormattingStyle} onChange={blockUpdate}>
            {Object.keys(formattingStyles).map((title) => (
              <MenuItem value={title} key={title}>
                {title}
              </MenuItem>
            ))}
          </Select>

          <IconButton onMouseDown={toggleBold}>
            <FormatBoldRounded />
          </IconButton>
          <IconButton onMouseDown={toggleItalic}>
            <FormatItalicRounded />
          </IconButton>
          <IconButton onMouseDown={toggleUnderline}>
            <FormatUnderlinedRounded />
          </IconButton>

          <IconButton onMouseDown={promptForLink}>
            <InsertLinkRounded />
          </IconButton>
          <IconButton onMouseDown={removeLink}>
            <LinkOffRounded />
          </IconButton>
        </div>

        {props.children}
      </div>
    </>
  );
}

export interface TextEditorProps {
  contentState?: any;
  alternativeTextInsertion: string;
}

export interface TextEditorState {
  textState: EditorState;
}

export default class TextEditor extends React.PureComponent<
  TextEditorProps,
  TextEditorState
> {
  constructor(props: TextEditorProps) {
    super(props);

    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);

    if (this.props.contentState)
      this.state = {
        textState: EditorState.createWithContent(
          convertFromRaw(this.props.contentState),
          decorator
        ),
      };
    else {
      const contentState = stateFromHTML(this.props.alternativeTextInsertion);

      this.state = {
        textState: EditorState.createWithContent(contentState, decorator),
      };
    }
  }

  // Public functions
  getContentAsHTML = () => {
    return stateToHTML(this.state.textState.getCurrentContent()).replace(
      new RegExp('<a href="', "g"),
      '<a rel="noreferrer noopener" href="'
    );
  };

  getContentState = () => {
    return convertToRaw(this.state.textState.getCurrentContent());
  };

  componentDidMount = () => {
    this.onBlockToggle(formattingStyles[defaultFormattingStyle]);
  };

  componentDidUpdate = (prevProps: TextEditorProps) => {
    if (
      JSON.stringify(prevProps.contentState) !==
      JSON.stringify(this.props.contentState)
    ) {
      this.setState({
        textState: this.props.contentState
          ? EditorState.createWithContent(
              convertFromRaw(this.props.contentState)
            )
          : EditorState.createEmpty(),
      });
    } else if (
      prevProps.alternativeTextInsertion !== this.props.alternativeTextInsertion
    ) {
      const contentState = stateFromHTML(this.props.alternativeTextInsertion);

      this.setState({
        textState: this.props.alternativeTextInsertion
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty(),
      });
    }
  };

  onStyleToggle = (style: string) =>
    this.setState({
      textState: RichUtils.toggleInlineStyle(this.state.textState, style),
    });

  onBlockToggle = (block: string) =>
    this.setState({
      textState: RichUtils.toggleBlockType(this.state.textState, block),
    });

  promptForLink = () => {
    const { textState } = this.state;
    const selection = textState.getSelection();

    if (!selection.isCollapsed()) return true;
    else return false;
  };

  confirmLink = (url: string) => {
    const { textState } = this.state;

    const contentState = textState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(textState, {
      currentContent: contentStateWithEntity,
    });
    this.setState({
      textState: RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      ),
    });
  };

  removeLink = () => {
    const { textState } = this.state;
    const selection = textState.getSelection();

    if (!selection.isCollapsed()) {
      this.setState({
        textState: RichUtils.toggleLink(textState, selection, null),
      });
      return true;
    }
    return false;
  };

  handleKeyCommand = (command: any, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.setState({ textState: newState });
      return "handled";
    }
    return "not-handled";
  };

  render() {
    return (
      <TextEditorToolBar
        onStyleToggle={this.onStyleToggle}
        onBlockToggle={this.onBlockToggle}
        promptForLink={this.promptForLink}
        confirmLink={this.confirmLink}
        removeLink={this.removeLink}
      >
        <Editor
          onChange={(textState: EditorState) => {
            this.setState({ textState });
          }}
          editorState={this.state.textState}
          handleKeyCommand={this.handleKeyCommand}
        />
      </TextEditorToolBar>
    );
  }
}

function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const Link = (props: any) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  link: {
    textDecoration: "underline",
  },
};
