import React, { useContext, useState } from "react";

import { useSnackbar } from "notistack";

import config from "../../config";
import { User } from "../../Interfaces";
import jsonwebtoken from "jsonwebtoken";
import { executeGraphQL, getNamespaceName } from "./serverFunctions";
import { defaultGenders } from "../Account/GenderSelector";
import { getAge } from "../../Functions/Age";
import { FileResponse } from "@idot-digital/file-selector";
import { millisUntilNextMeeting } from "../../Functions/Datetime";
import data from "../../data";
import {
  Dialog,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { updateQuery } from "../../Functions/QueryParams";
import { useLocation } from "react-router-dom";

const ServerContext = React.createContext<AuthHook>(undefined!);

export function useServer() {
  return useContext(ServerContext);
}

export function ServerProvider(props: { children: JSX.Element }) {
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = useState(null as User | null);
  const [namespaceid, setnamespaceid] = useState(null as number | null);
  const [forceFetch, setForceFetch] = React.useState(0);
  const [jwt, setjwt] = useState("");

  React.useEffect(() => {
    if (jwt) return;
    const newJWT = config.developmentMode
      ? localStorage.getItem("jwt")
      : sessionStorage.getItem("jwt");
    if (newJWT) {
      setjwt(newJWT);
      verifyAndParseJWT(newJWT);
    }
  }, [jwt]);

  React.useEffect(() => {
    updateQuery({ namespace: namespaceid });
  }, [namespaceid, pathname]);

  const [namespaceErrorOpen, setNamespaceErrorOpen] = React.useState(false);
  const [namespaceErrorInfoLoading, setNamespaceErrorInfoLoading] =
    React.useState(true);
  const [namespaceErrorInfo, setNamespaceErrorInfo] = React.useState({
    currentNamespace: "",
    accountNamespace: "",
  });
  React.useEffect(() => {
    if (currentUser && namespaceid && currentUser.namespaceid != namespaceid) {
      setNamespaceErrorOpen(true);
      (async () => {
        try {
          setNamespaceErrorInfoLoading(true);
          const currentNamespace = await getNamespaceName(namespaceid);
          const accountNamespace = await getNamespaceName(
            currentUser.namespaceid
          );
          setNamespaceErrorInfo({ currentNamespace, accountNamespace });
        } finally {
          setNamespaceErrorInfoLoading(false);
        }
      })();
    }
  }, [namespaceid, currentUser?.namespaceid]);

  React.useEffect(() => {
    const namespace = new URLSearchParams(window.location.search).get(
      "namespace"
    );
    if (!namespace) return;
    const parsedNamespace = parseInt(namespace);
    if (!isNaN(parsedNamespace)) setnamespaceid(parsedNamespace);
  }, [new URLSearchParams(window.location.search).get("namespace")]);

  const requestColorFetch = () => setForceFetch(forceFetch + 1);

  async function login(email: string, password: string) {
    const jwt = await (
      await fetch(config.authserverLink + "login", {
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
        },
        method: "POST",
        body: JSON.stringify({ email: email.toLowerCase(), password }),
        mode: "cors",
        credentials: "omit",
      })
    ).text();
    setjwt(jwt);
    verifyAndParseJWT(jwt);
  }

  function verifyAndParseJWT(jwt: string) {
    jsonwebtoken.verify(
      jwt,
      config.publicKey,
      async (err: any, decoded: any) => {
        if (err) throw err;
        const storage = config.developmentMode ? localStorage : sessionStorage;
        storage.setItem("jwt", jwt);
        // setnamespaceid(decoded.namespaceid);
        setCurrentUser(decoded);
      }
    );
  }

  function logout() {
    const storage = config.developmentMode ? localStorage : sessionStorage;
    storage.removeItem("jwt");
    setjwt("");
    setCurrentUser(null);
  }

  const parseDatetimeServer = (project: any) => {
    const datetime: DatetimeRepeat = JSON.parse(
      project.datetime as unknown as string
    );

    const parseDate = (date: Date | null) =>
      date ? new Date(date as unknown as string) : null;

    datetime.begin = parseDate(datetime.begin);
    datetime.end = parseDate(datetime.end);
    datetime.daily = {
      ...datetime.daily,
      from: parseDate(datetime.daily.from),
      to: parseDate(datetime.daily.to),
    };
    datetime.week = datetime.week.map((week) => ({
      ...week,
      from: parseDate(week.from),
      to: parseDate(week.to),
    }));
    datetime.month = datetime.month.map((month) => ({
      ...month,
      from: parseDate(month.from),
      to: parseDate(month.to),
    }));

    return { ...project, datetime };
  };

  const accountManagement = {
    signup: async (email: string, password: string) => {
      const res = await fetch(config.authserverLink + "signup", {
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
        },
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          namespace: namespaceid,
        }),
        mode: "cors",
        credentials: "omit",
      });

      if (res.status === 500 || !res.ok)
        throw new Error("Error while creating account: " + (await res.text()));
    },
    updateEmail: async (email: string): Promise<void> => {
      const res = await fetch(config.authserverLink + "updateEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ email: email.toLowerCase() }),
        mode: "cors",
        credentials: "omit",
      });
      if (res.status !== 200) throw new Error(await res.text());
      else logout();
    },
    requestPasswordReset: async (email?: string): Promise<void> => {
      await fetch(config.authserverLink + "requestPasswordReset", {
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
        },
        method: "POST",
        body: JSON.stringify({
          email: email?.toLowerCase() || currentUser?.email.toLowerCase(),
        }),
        mode: "cors",
        credentials: "omit",
      });
    },
    resetPassword: async (newPassword: string, key: string): Promise<void> => {
      await fetch(config.authserverLink + "resetPassword", {
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
        },
        method: "POST",
        body: JSON.stringify({ code: key, password: newPassword }),
        mode: "cors",
        credentials: "omit",
      });
    },
    addMember: async (member: Member): Promise<string> => {
      // pseudo await currentUser react hooks (workaround)
      return await new Promise((resolve) =>
        setCurrentUser((currentUser) => {
          resolve(
            peopleActions.add({
              ...member,
              linkedAccount: currentUser?.email,
            })
          );
          return currentUser;
        })
      );
    },
    updateMember: async (id: string, member: Member): Promise<void> => {
      return peopleActions.update(member, id);
    },
    deleteMember: async (id: string): Promise<void> => {
      const QUERY = `
      mutation operation($id: uuid) {
        update_people(where: {id: {_eq: $id}}, _set: {linkedAccount: null}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, { id });
    },
    listMembers: async (): Promise<MemberOverviewObject[]> => {
      const accountID = currentUser?.email;
      const QUERY = `
      query operation($accountID: bpchar) {
        people(where: {linkedAccount: {_eq: $accountID}}, order_by: {created_at: asc}) {
          id
          name
          surname
          birthday
        }
      }
    `;
      return (
        await executeGraphQL(QUERY, jwt, { accountID: accountID?.toString() })
      ).people;
    },
    listCompleteMembers: async (): Promise<Member[]> => {
      const accountID = currentUser?.email;
      const QUERY = `
      query operation($accountID: bpchar) {
        people(where: {linkedAccount: {_eq: $accountID}}, order_by: {created_at: asc}) {
          id
          name
          surname
          birthday
          allergies
          city
          gender
          goHomeAlone
          linkedAccount
          medication
          nationality
          other
          phone
          school
          street
          swim
          zip
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { accountID })).people;
    },
    getMember: async (id: string): Promise<Member> => {
      return peopleActions.get(id);
    },
  };

  const customerProjectActions = {
    listProjects: async (
      namespace?: number
    ): Promise<CustomerProjectOverviewObject[]> => {
      const QUERY = `
      query operation($namespaceid: Int, $date: timestamptz) {
        projects(where: {namespaceid: {_eq: $namespaceid}, publish: {_eq: true}, publicationPeriodStart: {_lte: $date}, publicationPeriodEnd: {_gte: $date}}) {
          datetime
          name
          description
          subtitle
          image
          state
          id
          maximumAge
          minimumAge
          organisations {
            organisation {
              name
            }
          }
        }
      }      
    `;
      return (
        await executeGraphQL(QUERY, undefined, {
          namespaceid: namespaceid || namespace,
          date: new Date(),
        })
      ).projects.map(parseDatetimeServer);
    },
    getProject: async (id: string): Promise<CustomerProject> => {
      const QUERY = `
      query operation($id: uuid) {
        projects(where: {id: {_eq: $id}}) {
          namespaceid
          discountRate
          id
          bookingRequired
          fees
          maximumAge
          minimumAge
          datetime
          room {
            name
            street
            zip
            city
          }
          image
          name
          description
          subtitle
          allowedGenders
          state
          organisations {
            organisation {
              name
            }
          }
          bookingPeriodEnd
          bookingPeriodStart
          sepaEnabled
          cashEnabled
          paypalEnabled
          publish
          zipFilter
          waitinglistIncludedInMax
        }
      }
    `;
      const project = (await executeGraphQL(QUERY, undefined, { id }))
        .projects[0];
      project.organisations = project.organisations.map(
        (org: any) => org.organisation.name
      );

      // Automatically update namespace
      setnamespaceid(project.namespaceid);

      return parseDatetimeServer(project);
    },
    getPayPalStatus: async (): Promise<boolean> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {namespaceid: {_eq: $namespaceid}, name: {_eq: "paypal_id"}}) {
          value
        }
      }      
    `;
      const paypalClientID = (
        await executeGraphQL(QUERY, undefined, { namespaceid })
      ).settings[0].value;

      return Boolean(paypalClientID);
    },
    bookProject: async (
      projectid: string,
      personids: string[],
      imageRights: boolean,
      signature: string,
      paymentMethod: PaymentMethod
    ): Promise<{ bookingid: string; state: ParticipantState }> => {
      try {
        const response = await fetch(config.bookingServerLink + "book", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            origin: config.corsURL,
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            projectid,
            paymentMethod,
            signature,
            personids,
            imageRights,
          }),
          mode: "cors",
          credentials: "omit",
        });

        if (response.status === 409)
          throw new Error(
            "Für mindestens einen der Personen liegt schon eine Anmeldung für das Projekt vor"
          );

        const data: { bookingid: string; state: ParticipantState } =
          await response.json();

        if (response.status === 201) return data;
        throw new Error("Fehler bei der Buchung");
      } catch (err) {
        throw err;
      }
    },
    createPaymentIntend: async (bookingid: string): Promise<string> => {
      const response = await fetch(
        config.bookingServerLink + "createPaymentIntend",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            origin: config.corsURL,
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            bookingid,
          }),
        }
      );
      const responseText = await response.text();
      if (response.ok) return responseText;
      throw new Error(
        "Creating payment intend failed with error: " + responseText
      );
    },
    completePayment: async (bookingid: string): Promise<void> => {
      const response = await fetch(
        config.bookingServerLink + "completePayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            origin: config.corsURL,
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            bookingid,
          }),
        }
      );
      if (response.ok) return;
      throw new Error(
        "Completing payment failed with error " + (await response.text())
      );
    },
    getTicket: async (bookingid: string): Promise<string> => {
      const response = await fetch(config.templatesAPIPath + "getTicket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
        },
        body: JSON.stringify({
          bookingid,
        }),
      });
      if (response.ok) return response.text();

      if (response.status === 402)
        throw new Error(
          "Sie können Ihr Ticket noch nicht herunterladen, da Sie noch nicht bezahlt haben."
        );

      console.error(response);
      throw new Error("Beim Abrufen des Tickets ist ein Fehler aufgetreten");
    },
  };

  const masterData = {
    employees: {
      add: async (name: string): Promise<string> => {
        const QUERY = `
        mutation operation($namespaceid: Int, $name: bpchar = "") {
          insert_employees_one(object: {name: $name, namespaceid: $namespaceid}) {
            name
          }
        }
      `;
        return (await executeGraphQL(QUERY, jwt, { namespaceid, name }))
          .insert_employees_one.name;
      },
      list: async (): Promise<string[]> => {
        const QUERY = `
        query operation($namespaceid: Int) {
          employees(where: {namespaceid: {_eq: $namespaceid}}) {
            name
          }
        }      
      `;
        return (
          await executeGraphQL(QUERY, jwt, { namespaceid })
        ).employees.map((obj: any) => obj.name);
      },
      delete: async (name: string): Promise<void> => {
        const QUERY = `
        mutation operation($namespaceid: Int, $name: bpchar = "") {
          delete_employees(where: {name: {_eq: $name}, namespaceid: {_eq: $namespaceid}}) {
            affected_rows
          }
        }
      `;
        await executeGraphQL(QUERY, jwt, { namespaceid, name });
      },
    },
    rooms: {
      add: async (room: Room): Promise<string> => {
        const QUERY = `
        mutation operation($characteristics: String, $city: bpchar, $name: bpchar, $namespaceid: Int, $size: Int, $street: String, $zip: Int) {
          insert_rooms_one(object: {characteristics: $characteristics, city: $city, name: $name, namespaceid: $namespaceid, size: $size, street: $street, zip: $zip}) {
            id
          }
        }
      `;
        return (await executeGraphQL(QUERY, jwt, { ...room, namespaceid }))
          .insert_rooms_one.id;
      },
      list: async (): Promise<Room[]> => {
        const QUERY = `
        query operation($namespaceid: Int) {
          rooms(where: {namespaceid: {_eq: $namespaceid}, visibility: {_eq: true}}) {
            name
            street
            zip
            city
            size
            characteristics
            id
          }
        }
      `;
        return (await executeGraphQL(QUERY, jwt, { namespaceid })).rooms;
      },
      update: async (id: string, room: Room): Promise<void> => {
        const QUERY = `
        mutation operation($id: uuid, $street: String = "", $size: Int = 10, $name: bpchar = "", $city: bpchar = "", $characteristics: String, $zip: Int) {
          update_rooms(where: {id: {_eq: $id}}, _set: {characteristics: $characteristics, city: $city, name: $name, size: $size, street: $street, zip: $zip}) {
            affected_rows
          }
        }
      `;
        await executeGraphQL(QUERY, jwt, { ...room, id });
      },
      delete: async (id: string): Promise<void> => {
        const QUERY = `
        mutation operation($id: uuid) {
          delete_rooms(where: {id: {_eq: $id}}) {
            affected_rows
          }
        }
      `;
        await executeGraphQL(QUERY, jwt, { id });
      },
    },
    organisation: {
      add: async (org: Organisation): Promise<string> => {
        const QUERY = `
        mutation operation($name: bpchar, $namespaceid: Int, $impLink: bpchar, $colorScheme: String) {
          insert_organisations_one(object: {colorScheme: $colorScheme, impLink: $impLink, name: $name, namespaceid: $namespaceid}) {
            id
          }
        }
      `;
        return (await executeGraphQL(QUERY, jwt, { ...org, namespaceid }))
          .insert_organisations_one.id;
      },
      list: async (): Promise<Organisation[]> => {
        const QUERY = `
        query operation($namespaceid: Int) {
          organisations(where: {namespaceid: {_eq: $namespaceid}}) {
            colorScheme
            impLink
            name
            id
          }
        }
      `;
        return (await executeGraphQL(QUERY, jwt, { namespaceid }))
          .organisations;
      },
      update: async (id: string, org: Organisation): Promise<void> => {
        const QUERY = `
        mutation operation($id: uuid, $colorScheme: String, $impLink: bpchar, $name: bpchar) {
          update_organisations(where: {id: {_eq: $id}}, _set: {colorScheme: $colorScheme, impLink: $impLink, name: $name}) {
            affected_rows
          }
        }
      `;
        await executeGraphQL(QUERY, jwt, { ...org, id });
      },
      delete: async (id: string): Promise<void> => {
        const QUERY = `
        mutation operation($id: uuid) {
          delete_organisations(where: {id: {_eq: $id}}) {
            affected_rows
          }
        }
      `;
        await executeGraphQL(QUERY, jwt, { id });
      },
    },
    getStats: async (
      organisation: string[],
      gender: string,
      start: Date | null,
      end: Date | null,
      zip: number[],
      maxAge: number,
      minAge: number
    ): Promise<Stats[]> => {
      const latestBirthday = new Date(
        new Date().setFullYear(new Date().getFullYear() - minAge)
      );
      const earliestBirthday = new Date(
        new Date().setFullYear(new Date().getFullYear() - maxAge)
      );

      const dateQuery = (queryStart: string, queryEnd: string) =>
        `_and: [{ ${queryEnd}: {_gte: $start}}, { ${queryStart}: {_lte: $end}}]`;

      const organisationQuery = () =>
        organisation.length
          ? "organisations: {organisation: {name: {_in: $organisation}}}, "
          : "";

      const zipQuery = () => (zip.length ? `, zip: {_in: $zip}` : "");

      const QUERY = `
      query operation($gender: bpchar, $organisation: [bpchar!], $end: timestamptz, $start: timestamptz, $zip: [Int!], $latestBirthday: timestamptz, $earliestBirthday: timestamptz) {
        projects(where: {${organisationQuery()}${dateQuery(
        "publicationPeriodStart",
        "publicationPeriodEnd"
      )}}) {
          bookings(where: {${dateQuery("date", "date")}}) {
            people(where: {state: {_eq: 1}, person: {gender: {_eq: $gender}, birthday: {_lte: $earliestBirthday, _gte: $latestBirthday}${zipQuery()}}}) {
              personid
              person {
                gender
                birthday
                nationality
              }
            }
          }
          category
          waitingListSize
          bookingRequired
          maximumParticipants
        }
      }
      `;

      return (
        await executeGraphQL(QUERY, jwt, {
          organisation: organisation.length ? organisation : undefined,
          gender: gender || undefined,
          start: start
            ? new Date(start.getFullYear(), start.getMonth(), start.getDate())
            : null,
          end: end
            ? new Date(
                end.getFullYear(),
                end.getMonth(),
                end.getDate(),
                23,
                59,
                59,
                999
              )
            : null,
          zip,
          earliestBirthday,
          latestBirthday,
        })
      ).projects.map(
        (entry: {
          category: string;
          bookings: {
            people: {
              personid: string;
              person: { gender: string; birthday: string; nationality: string };
            }[];
          }[];
          bookingRequired: boolean;
          waitingListSize: number;
          maximumParticipants: number;
        }) => ({
          category: entry.category,
          signups: entry.bookingRequired
            ? entry.bookings.reduce(
                (total, current) => total + current.people.length,
                0
              )
            : entry.waitingListSize,
          registrations: entry.bookings.reduce(
            (total, current) => total + current.people.length,
            0
          ),
          ...entry.bookings.reduce(
            (total, current) => {
              current.people.forEach((person) => {
                switch (person.person.gender) {
                  case defaultGenders[0]:
                    return (total.male += 1);
                  case defaultGenders[1]:
                    return (total.female += 1);
                  default:
                    return (total.diverse += 1);
                }
              });

              return total;
            },
            { male: 0, female: 0, diverse: 0 }
          ),
          ...entry.bookings.reduce(
            (total, current) => {
              current.people.forEach((person) => {
                switch (person.person.nationality) {
                  case "deutsch":
                    return (total.german += 1);
                  default:
                    return (total.notGerman += 1);
                }
              });

              return total;
            },
            { german: 0, notGerman: 0 }
          ),
          averageAge: (() => {
            const average = entry.bookings.reduce(
              (total, current) => {
                const step = current.people.reduce(
                  (total, current) => {
                    total.average += new Date(
                      current.person.birthday
                    ).getTime();
                    total.count += 1;
                    return total;
                  },
                  { average: 0, count: 0 }
                );

                total.average += step.average;
                total.count += step.count;

                return total;
              },
              { average: 0, count: 0 }
            );

            return getAge(new Date(average.average / average.count));
          })(),
          maximumParticipants: entry.bookingRequired
            ? entry.maximumParticipants
            : 0,
        })
      );
    },
  };

  const settingsActions = {
    list: async (): Promise<Settings[]> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {namespaceid: {_eq: $namespaceid}}) {
          name
          value
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { namespaceid })).settings;
    },
    get: async (key: keyof FormatedSettings): Promise<string> => {
      const QUERY = `
      query operation($name: bpchar, $namespaceid: Int) {
        settings(where: {name: {_eq: $name}, namespaceid: {_eq: $namespaceid}}) {
          value
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { name: key, namespaceid }))
        .settings[0].value;
    },
    set: async (key: keyof FormatedSettings, value: string): Promise<void> => {
      const QUERY = `
      mutation operation($name: bpchar, $value: String, $namespaceid: Int) {
        insert_settings_one(object: {name: $name, namespaceid: $namespaceid, value: $value}, on_conflict: {constraint: settings_name_namespaceid_key, update_columns: value}) {
          name
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, { name: key, namespaceid, value });
    },
  };

  const userManagement = {
    list: async (): Promise<Account[]> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        accounts(where: {namespaceid: {_eq: $namespaceid}}) {
          access_level
          email
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { namespaceid })).accounts;
    },
    add: async (account: Account): Promise<void> => {
      if (!currentUser) throw new Error("No user logged in");
      const QUERY = `
      mutation operation($email: bpchar, $access_level: Int, $namespaceid: Int, $password: bpchar) {
        insert_accounts(objects: {email: $email, access_level: $access_level, namespaceid: $namespaceid, password: $password}) {
          affected_rows
        }
      }
    `;
      const passwordHash = await (
        await fetch(config.authserverLink + "hash", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
            origin: config.corsURL,
            referer: config.corsURL,
          },
          body: JSON.stringify({ password: account.password }),
          mode: "cors",
          credentials: "omit",
        })
      ).text();

      await executeGraphQL(QUERY, jwt, {
        ...account,
        namespaceid,
        password: passwordHash,
      });
    },
    update: async (email: string, access_level: number): Promise<void> => {
      const QUERY = `
      mutation operation($email: bpchar, $access_level: Int) {
        update_accounts(where: {email: {_eq: $email}}, _set: {access_level: $access_level}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, { email, access_level });
    },
    delete: async (email: string): Promise<void> => {
      const QUERY1 = `
      mutation operation($email: bpchar) {
        update_people(where: {linkedAccount: {_eq: $email}}, _set: {linkedAccount: null}) {
          affected_rows
        }
      }
      `;

      const QUERY2 = `
      mutation operation($email: bpchar) {
        delete_accounts(where: {email: {_eq: $email}}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY1, jwt, { email });
      await executeGraphQL(QUERY2, jwt, { email });
    },
  };

  const peopleActions = {
    list: async (): Promise<PersonOverviewObject[]> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        people(where: {namespaceid: {_eq: $namespaceid}}, order_by: {surname: asc, name: asc, birthday: asc}) {
          birthday
          city
          id
          name
          surname
          street
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { namespaceid })).people;
    },
    listZips: async (): Promise<number[]> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        people(where: {namespaceid: {_eq: $namespaceid}}, order_by: {zip: asc}, distinct_on: zip) {
          zip
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { namespaceid })).people.map(
        (people: { zip: string }) => people.zip
      );
    },
    get: async (id: string): Promise<Person> => {
      const QUERY = `
      query operation($id: uuid = "") {
        people(where: {id: {_eq: $id}}) {
          id
          name
          surname
          birthday
          allergies
          city
          gender
          goHomeAlone
          linkedAccount
          medication
          nationality
          other
          phone
          school
          street
          swim
          zip
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { id })).people[0];
    },
    add: async (person: Person): Promise<string> => {
      const QUERY = `
      mutation operation($street: String, $nationality: bpchar, $swim: Boolean, $allergies: String, $medication: String, $other: String, $surname: bpchar, $name: bpchar, $goHomeAlone: Boolean, $zip: Int, $city: bpchar, $namespaceid: Int, $school: bpchar, $phone: bpchar, $linkedAccount: bpchar, $gender: bpchar, $birthday: timestamptz) {
        insert_people(objects: {street: $street, nationality: $nationality, swim: $swim, allergies: $allergies, medication: $medication, other: $other, name: $name, surname: $surname, goHomeAlone: $goHomeAlone, zip: $zip, city: $city, namespaceid: $namespaceid, school: $school, phone: $phone, linkedAccount: $linkedAccount, gender: $gender, birthday: $birthday}) {
          returning {
            id
          }
        }
      }
    `;
      return await new Promise((resolve) => {
        // pseudo await jwt and namespaceid hooks (workaround)
        setjwt((jwt) => {
          setnamespaceid((namespaceid) => {
            new Promise(async (resolveInner) => {
              resolveInner(null);
              resolve(
                (await executeGraphQL(QUERY, jwt, { ...person, namespaceid }))
                  .insert_people.returning[0].id
              );
            });
            return namespaceid;
          });
          return jwt;
        });
      });
    },
    update: async (person: Person, id: string): Promise<void> => {
      const QUERY = `
    mutation operation($street: String, $nationality: bpchar, $swim: Boolean, $allergies: String, $medication: String, $other: String, $surname: bpchar, $name: bpchar, $goHomeAlone: Boolean, $zip: Int, $city: bpchar, $namespaceid: Int, $school: bpchar, $phone: bpchar, $linkedAccount: bpchar, $gender: bpchar, $birthday: timestamptz, $id: uuid = "") {
      update_people(where: {id: {_eq: $id}}, _set: {allergies: $allergies, birthday: $birthday, city: $city, gender: $gender, goHomeAlone: $goHomeAlone, linkedAccount: $linkedAccount, medication: $medication, name: $name, namespaceid: $namespaceid, nationality: $nationality, other: $other, phone: $phone, school: $school, street: $street, swim: $swim, surname: $surname, zip: $zip}) {
        affected_rows
      }
    }
  `;
      await executeGraphQL(QUERY, jwt, { namespaceid, ...person, id });
    },
    delete: async (id: string): Promise<void> => {
      try {
        const QUERY1 = `
        mutation operation($id: uuid) {
          delete_people_booking(where: {personid: {_eq: $id}, state: {_eq: 0}}) {
            affected_rows
          }
        }      
      `;
        const QUERY2 = `
      mutation operation($id: uuid = "") {
        delete_people(where: {id: {_eq: $id}}) {
          affected_rows
        }
      }
    `;
        await executeGraphQL(QUERY1, jwt, { id });
        await executeGraphQL(QUERY2, jwt, { id });
      } catch (error) {
        throw new Error(
          "Es können nur Personen gelöscht werden, die in keinem Projekt angemelted sind."
        );
      }
    },
    getParent: async (id: string): Promise<string> => {
      const QUERY = `
      query operation($personid: uuid) {
        people(where: {id: {_eq: $personid}}, limit: 1) {
          account {
            people {
              id
            }
          }
        }
      }
    `;
      return (await executeGraphQL(QUERY, jwt, { personid: id })).people[0]
        .account.people[0].id;
    },
    getHistory: async (id: string): Promise<ProjectHistory[]> => {
      const QUERY = `
      query operation($personid: uuid) {
        people(where: {id: {_eq: $personid}}) {
          bookings {
            state
            booking {
              project {
                id
                name
                datetime
              }
            }
          }
        }
      }
      `;
      return (
        await executeGraphQL(QUERY, jwt, { personid: id })
      ).people[0].bookings
        .map((booking: any) => ({
          ...booking.booking.project,
          state: booking.state,
        }))
        .map(parseDatetimeServer);
    },
    getDuplicates: async (): Promise<string[][]> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        people(where: {namespaceid: {_eq: $namespaceid}}, order_by: {name: asc}) {
          surname
          name
          birthday
          zip
          id
        }
      }      
    `;
      const people: {
        surname: string;
        name: string;
        birthday: string;
        zip: number;
        id: string;
      }[] = (await executeGraphQL(QUERY, jwt, { namespaceid })).people;
      let cohortedPeople: any = [];
      let cohortedPeopleArrayCount = 0;
      const cohortingHelper: any = {};
      people.forEach((person) => {
        if (cohortingHelper[person.zip]) {
          if (cohortingHelper[person.zip][person.surname]) {
            cohortedPeople[cohortingHelper[person.zip][person.surname]].push(
              person
            );
          } else {
            cohortingHelper[person.zip][person.surname] =
              cohortedPeopleArrayCount++;
            cohortedPeople[cohortingHelper[person.zip][person.surname]] = [
              person,
            ];
          }
        } else {
          cohortingHelper[person.zip] = {};
          cohortingHelper[person.zip][person.surname] =
            cohortedPeopleArrayCount++;
          cohortedPeople[cohortingHelper[person.zip][person.surname]] = [
            person,
          ];
        }
      });
      let similarPeopleCount = 0;
      const similarPeople: string[][] = [];
      cohortedPeople = cohortedPeople.filter((arr: any[]) => arr.length > 1);
      cohortedPeople.forEach((cohort: any[]) => {
        cohort.forEach((person: any, i, array) => {
          if (i >= array.length - 1) return;
          if (person.name !== array[i + 1].name) return similarPeopleCount++;
          if (person.birthday !== array[i + 1].birthday)
            return similarPeopleCount++;
          if (!similarPeople[similarPeopleCount])
            similarPeople[similarPeopleCount] = [];
          similarPeople[similarPeopleCount].push(person.id);
          similarPeople[similarPeopleCount].push(array[i + 1].id);
        });
        similarPeopleCount++;
      });

      similarPeople.forEach((personIds: string[], i, array) => {
        array[i] = Array.from(new Set(personIds));
      });

      return similarPeople.filter(
        (personIds: string[]) => personIds.length > 1
      );
    },
    rebindProjects: async (oldpersonid: string, newpersonid: string) => {
      const QUERY = `
      mutation operation($newpersonid: uuid, $oldpersonid: uuid = "") {
        update_people_booking(where: {personid: {_eq: $oldpersonid}}, _set: {personid: $newpersonid}) {
          affected_rows
        }
      }      
      `;
      await executeGraphQL(QUERY, jwt, { oldpersonid, newpersonid });
    },
  };

  const projectActions = {
    list: async (
      startDate?: Date,
      endDate?: Date
    ): Promise<ProjectOverviewObject[]> => {
      const QUERY = `
      query operation($namespaceid: Int, $startDate: timestamptz, $endDate: timestamptz, $limit: Int) {
        projects(where: {namespaceid: {_eq: $namespaceid}, publicationPeriodStart: {_lte: $endDate}, publicationPeriodEnd: {_gte: $startDate}}, limit: $limit) {
          id
          name
          state
          subtitle
          publicationPeriodEnd
          publicationPeriodStart
          datetime
          maximumAge
          minimumAge
          maximumParticipants
          waitingListSize
          bookingRequired
          organisations {
            organisation {
              name
            }
          }
          bookings {
            people(where: {state: {_eq: 1}}) {
              personid
            }
          }
          bookingPeriodEnd
          bookingPeriodStart
          waitinglistIncludedInMax
        }
      }
    `;
      const limit = 100000;
      return (
        await executeGraphQL(QUERY, jwt, {
          namespaceid,
          startDate,
          endDate,
          limit,
        })
      ).projects
        .map((project: any) => {
          project = parseDatetimeServer(project);
          project.bookings = project.bookings
            .map((booking: any) => booking.people.length)
            .reduce((a: number, b: number) => a + b, 0);
          return project;
        })
        .sort(
          (pro1: ProjectOverviewObject, pro2: ProjectOverviewObject) =>
            (millisUntilNextMeeting(pro1.datetime) || 0) -
            (millisUntilNextMeeting(pro2.datetime) || 0)
        );
    },
    get: async (id: string): Promise<Projects> => {
      const QUERY = `query operation($id: uuid) {
        projects(where: {id: {_eq: $id}}) {
          allowedGenders
          attachments
          bookingRequired
          category
          datetime
          description
          discountRate
          fees
          id
          image
          maximumAge
          maximumParticipants
          mimimumParticipants
          minimumAge
          name
          publicationPeriodEnd
          publicationPeriodStart
          publish
          state
          subtitle
          waitingListSize
          createTickets
          room {
            name
          }
          roomid
          organisations {
            organisationid
            organisation {
              name
            }
          }
          employee
          bookings {
            people {
              person {
                id
                name
                surname
                birthday
              }
              booking {
                payment {
                  paid
                  paymentDate
                  price
                  method
                  count
                  note
                }
                id
                imageRights
                date
              }
              state
            }
          }
          sepaEnabled
          paypalEnabled
          cashEnabled
          bookingPeriodEnd
          bookingPeriodStart
          zipFilter
          waitinglistIncludedInMax
        }
      }
      `;

      //9d0c981e-3a0e-4a26-b228-c473896d13a0
      const project = (await executeGraphQL(QUERY, jwt, { id })).projects[0];
      project.room = project.room.name;
      project.organisations = project.organisations.map((org: any) => {
        return {
          organisationid: org.organisationid,
          name: org.organisation.name,
        };
      });
      project.bookings = project.bookings
        .map((booking: any) => {
          return booking.people.map((person: any) => {
            return {
              ...person.booking,
              ...person.person,
              state: person.state,
              bookingid: person.booking.id,
              imageRights: person.booking.imageRights,
              date: new Date(person.booking.date),
            };
          });
        })
        .flat();
      return parseDatetimeServer(project);
    },
    add: async (project: ProjectEditObject): Promise<string> => {
      const organisationids = project.organisationids as string[];
      delete project.organisationids;
      const QUERY = `
      mutation operation($allowedGenders: smallint, $attachments: String, $bookingRequired: Boolean, $category: bpchar, $datetime: String, $description: String, $discountRate: Int, $employee: bpchar, $fees: Int, $image: bpchar, $maximumAge: Int, $maximumParticipants: Int, $mimimumParticipants: Int, $minimumAge: Int, $name: bpchar, $namespaceid: Int, $publicationPeriodEnd: timestamptz, $publicationPeriodStart: timestamptz, $publish: Boolean, $state: smallint, $subtitle: String, $waitingListSize: Int, $roomName: bpchar, $createTickets: Boolean = false, $paypalEnabled: Boolean = false, $cashEnabled: Boolean = false, $bookingPeriodEnd: timestamptz = "", $bookingPeriodStart: timestamptz = "", $sepaEnabled: Boolean = false, $zipFilter: String = "", $waitinglistIncludedInMax: Boolean = false) {
        insert_projects_one(object: {allowedGenders: $allowedGenders, attachments: $attachments, bookingRequired: $bookingRequired, category: $category, datetime: $datetime, description: $description, discountRate: $discountRate, employee: $employee, fees: $fees, image: $image, maximumAge: $maximumAge, maximumParticipants: $maximumParticipants, mimimumParticipants: $mimimumParticipants, minimumAge: $minimumAge, name: $name, namespaceid: $namespaceid, publicationPeriodEnd: $publicationPeriodEnd, publicationPeriodStart: $publicationPeriodStart, publish: $publish, state: $state, subtitle: $subtitle, waitingListSize: $waitingListSize, room: {data: {visibility: false, name: $roomName, street: "", characteristics: "", city: "", namespaceid: $namespaceid, size: 0}, on_conflict: {constraint: rooms_name_namespaceid_key, update_columns: name}}, createTickets: $createTickets, paypalEnabled: $paypalEnabled, cashEnabled: $cashEnabled, bookingPeriodEnd: $bookingPeriodEnd, bookingPeriodStart: $bookingPeriodStart, sepaEnabled: $sepaEnabled, zipFilter: $zipFilter, waitinglistIncludedInMax: $waitinglistIncludedInMax}) {
          id
        }
      }
      `;
      const projectid = (
        await executeGraphQL(QUERY, jwt, { ...project, namespaceid })
      ).insert_projects_one.id;

      const QUERY2 = `
        mutation operation($organisationid: uuid, $projectid: uuid) {
          insert_project_organisations_one(object: {organisationid: $organisationid, projectid: $projectid}, on_conflict: {constraint: project_organisations_pkey, update_columns: organisationid}) {
            projectid
            organisationid
          }
        }
        `;

      organisationids.forEach(async (organisationid) => {
        await executeGraphQL(QUERY2, jwt, { projectid, organisationid });
      });

      return projectid;
    },
    update: async (project: ProjectEditObject, id: string) => {
      const ROOM_QUERY = `
      mutation operation($name: bpchar = "", $namespaceid: Int) {
        insert_rooms_one(object: {name: $name, size: 0, visibility: false, characteristics: "", namespaceid: $namespaceid, street: ""}, on_conflict: {constraint: rooms_name_namespaceid_key, update_columns: name}) {
          id
        }
      }`;
      const roomid = (
        await executeGraphQL(ROOM_QUERY, jwt, {
          name: project.roomName,
          namespaceid,
        })
      ).insert_rooms_one.id;
      const organisationids = project.organisationids as string[];
      delete project.organisationids;
      delete project.roomName;
      const QUERY = `
      mutation operation($id: uuid, $attachments: String, $allowedGenders: smallint, $bookingRequired: Boolean, $category: bpchar, $datetime: String, $description: String, $discountRate: Int, $employee: bpchar, $fees: Int, $name: bpchar, $minimumAge: Int, $mimimumParticipants: Int, $maximumParticipants: Int, $maximumAge: Int, $image: bpchar, $publicationPeriodEnd: timestamptz, $publicationPeriodStart: timestamptz, $publish: Boolean, $state: smallint, $subtitle: String, $waitingListSize: Int, $roomid: uuid = "", $createTickets: Boolean, $paypalEnabled: Boolean = false, $sepaEnabled: Boolean = false, $cashEnabled: Boolean = false, $bookingPeriodEnd: timestamptz = "", $bookingPeriodStart: timestamptz = "", $zipFilter: String = "", $waitinglistIncludedInMax: Boolean = false) {
        update_projects(where: {id: {_eq: $id}}, _set: {allowedGenders: $allowedGenders, attachments: $attachments, bookingRequired: $bookingRequired, category: $category, datetime: $datetime, discountRate: $discountRate, description: $description, employee: $employee, fees: $fees, image: $image, maximumAge: $maximumAge, maximumParticipants: $maximumParticipants, mimimumParticipants: $mimimumParticipants, minimumAge: $minimumAge, name: $name, publicationPeriodEnd: $publicationPeriodEnd, publicationPeriodStart: $publicationPeriodStart, publish: $publish, state: $state, subtitle: $subtitle, waitingListSize: $waitingListSize, roomid: $roomid, createTickets: $createTickets, paypalEnabled: $paypalEnabled, sepaEnabled: $sepaEnabled, cashEnabled: $cashEnabled, bookingPeriodEnd: $bookingPeriodEnd, bookingPeriodStart: $bookingPeriodStart, zipFilter: $zipFilter, waitinglistIncludedInMax: $waitinglistIncludedInMax}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, { id, ...project, roomid });

      const QUERY2 = `
      mutation operation($organisationid: uuid, $projectid: uuid) {
        insert_project_organisations_one(object: {organisationid: $organisationid, projectid: $projectid}, on_conflict: {constraint: project_organisations_pkey, update_columns: organisationid}) {
          projectid
          organisationid
        }
      }
      `;
      organisationids.forEach(async (organisationid) => {
        await executeGraphQL(QUERY2, jwt, { projectid: id, organisationid });
      });
    },
    delete: async (id: string) => {
      const QUERY1 = `
      mutation operation($id: uuid) {
        delete_projects(where: {id: {_eq: $id}}) {
          affected_rows
        }
      }
    `;
      const QUERY2 = `
    mutation operation($id: uuid) {
      delete_project_organisations(where: {projectid: {_eq: $id}}) {
        affected_rows
      }
    }
    `;
      const QUERY3 = `
      mutation operation($id: uuid) {
        delete_people_booking(where: {booking: {projectid: {_eq: $id}}, state: {_eq: 0}}) {
          affected_rows
          returning {
            bookingid
          }
        }
      }
    `;
      const QUERY4 = `
      mutation operation($id: uuid) {
        delete_bookings(where: {id: {_eq: $id}}) {
          affected_rows
        }
      }    
    `;
      await executeGraphQL(QUERY2, jwt, { id });

      const bookings = (await executeGraphQL(QUERY3, jwt, { id }))
        .delete_people_booking.returning;
      await Promise.all(
        bookings.map(async (obj: { bookingid: string }) => {
          await executeGraphQL(QUERY4, jwt, { id: obj.bookingid });
        })
      );
      try {
        await executeGraphQL(QUERY1, jwt, { id });
      } catch (error) {
        throw new Error(
          "Das Projekt könnte nicht gelöscht werden, da noch Buchungen vorhanden sind. Bitte löschen Sie zuerst alle Buchungen und versuchen Sie es dann erneut."
        );
      }
    },
    clearWaitingListWithoutNotice: async (id: string) => {
      const QUERY = `
      mutation operation($projectid: uuid) {
        update_people_booking(where: {booking: {projectid: {_eq: $projectid}}, state: {_eq: 2}}, _set: {state: 0}) {
          affected_rows
        }
      }
    `;
      await executeGraphQL(QUERY, jwt, { projectid: id });
      await projectActions.updateState(id);
    },
    clearWaitingList: async (id: string) => {
      await projectActions.clearWaitingListWithoutNotice(id);
    },
    addBooking: async (
      projectID: string,
      personID: string,
      imageRights: boolean,
      paymentMethod: PaymentMethod,
      paid: boolean
    ): Promise<Booking[]> => {
      await customerProjectActions.bookProject(
        projectID,
        [personID],
        imageRights,
        "#",
        paymentMethod
      );
      if (paid) {
        const price = (await customerProjectActions.getProject(projectID)).fees;
        participantActions.updatePaymentState(
          personID,
          projectID,
          new Date(),
          price,
          1,
          paymentMethod
        );
      }
      await projectActions.updateState(projectID);
      return (await projectActions.get(projectID)).bookings;
    },
    getPeopleByBooking: async (bookingId: string) => {
      const QUERY = `
      query operation($bookingId: uuid) {
        bookings(where: {id: {_eq: $bookingId}}) {
          people_aggregate {
            nodes {
              personid
            }
          }
        }
      }
      `;

      return (
        await executeGraphQL(QUERY, jwt, { bookingId })
      ).bookings[0]?.people_aggregate.nodes.map(
        (value: { personid: string }) => value.personid
      ) as string[];
    },
    createDummyPeople: async (projectID: string, count: number) => {
      const QUERY = `
      mutation operation($id: uuid, $waitingListSize: Int) {
        update_projects(where: {id: {_eq: $id}}, _inc: {waitingListSize: $waitingListSize}) {
          affected_rows
        }
      }
      `;
      return await executeGraphQL(QUERY, jwt, {
        id: projectID,
        waitingListSize: count,
      });
    },
    // Not testet, but should work
    copyProjectParticipants: async (
      oldProjectID: string,
      newProjectID: string
    ) => {
      await projectActions.cleanProject(oldProjectID);
      interface Booking {
        date: string;
        imageRights: boolean;
        mail_confirmed: boolean;
        paymentid: string;
        projectid: string;
        signature: string;
        id: string;
      }

      interface Payment {
        count: number;
        method: PaymentMethod;
        paid: boolean;
        paymentDate: string;
        price: number;
        note: string;
      }

      const Q1 = `
      query operation($projectid: uuid) {
        bookings(where: {projectid: {_eq: $projectid}}) {
          date
          imageRights
          mail_confirmed
          paymentid
          projectid
          signature
          id
        }
      }
    `;
      const Q2 = `
    query operation($paymentids: [uuid!]) {
      payments(where: {id: {_in: $paymentids}}) {
        count
        method
        paid
        paymentDate
        price
        note
      }
    }
    `;
      const Q3 = `
      query operation($bookingids: [uuid!]) {
        people_booking(where: {bookingid: {_in: $bookingids}, state: {_gte: 1}}) {
          state
          personid
          bookingid
        }
      }
    `;

      const CREATE_PAYMMENTS = `
    mutation operation($objects: [payments_insert_input!]!) {
      insert_payments(objects: $objects) {
        returning {
          id
        }
      }
    }    
    `;
      const bookings: Booking[] = (
        await executeGraphQL(Q1, jwt, { projectid: oldProjectID })
      ).bookings;

      const paymentids: string[] = bookings
        .map((b: any) => b.paymentid)
        .filter(Boolean);

      const bookingids: string[] = bookings.map((b: any) => b.id);

      const payments: Payment[] = (
        await executeGraphQL(Q2, jwt, { paymentids })
      ).payments;

      const people_booking = (await executeGraphQL(Q3, jwt, { bookingids }))
        .people_booking;

      const newPaymentIDs = await Promise.all(
        payments.map(async (p: Payment) => {
          const newPayment = await executeGraphQL(CREATE_PAYMMENTS, jwt, {
            objects: [
              {
                count: p.count,
                method: p.method,
                paid: false,
                paymentDate: null,
                price: p.price,
                note: p.note,
              },
            ],
          });
          return newPayment.insert_payments.returning[0].id;
        })
      );

      const CREATE_BOOKINGS = `
      mutation operation($objects: [bookings_insert_input!]!) {
        insert_bookings(objects: $objects) {
          returning {
            id
          }
        }
      }
      `;
      const CREATE_PEOPLE_BOOKING = `
      mutation operation($objects: [people_booking_insert_input!]!) {
        insert_people_booking(objects: $objects) {
          affected_rows
        }
      }
      `;

      const newBookingids = await Promise.all(
        bookings.map(async (b: Booking) => {
          const newBooking = await executeGraphQL(CREATE_BOOKINGS, jwt, {
            objects: [
              {
                date: b.date,
                imageRights: b.imageRights,
                mail_confirmed: b.mail_confirmed,
                paymentid: newPaymentIDs[paymentids.indexOf(b.paymentid)],
                projectid: newProjectID,
                signature: b.signature,
              },
            ],
          });
          return newBooking.insert_bookings.returning[0].id;
        })
      );

      const newPeopleBookings = people_booking.map((p: any) => {
        return {
          bookingid: newBookingids[bookingids.indexOf(p.bookingid)],
          personid: p.personid,
          state: p.state,
          projectid: newProjectID,
        };
      });

      await executeGraphQL(CREATE_PEOPLE_BOOKING, jwt, {
        objects: newPeopleBookings,
      });
    },
    sendEmailToAllParticipants: async (
      projectid: string,
      subject: string,
      content: string,
      attachments: FileResponse[]
    ) => {
      const response = await fetch(config.bookingServerLink + "email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          projectid,
          subject,
          content,
          attachments,
        }),
        mode: "cors",
        credentials: "omit",
      });
      const responseText = await response.text();
      if (response.ok) return;
      throw new Error("Fehler: " + responseText);
    },
    sendEmailToAllUnpaidParticipants: async (projectid: string) => {
      const response = await fetch(
        config.bookingServerLink + "paymentRemainderMail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            projectid,
          }),
          mode: "cors",
          credentials: "omit",
        }
      );
      const responseText = await response.text();
      if (response.ok) return;
      throw new Error("Fehler: " + responseText);
    },
    listTemplates: async () => {
      const response = await fetch(config.templatesAPIPath + "templates", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
          Authorization: `Bearer ${jwt}`,
        },
        mode: "cors",
        credentials: "omit",
      });
      const responseText = await response.text();
      if (response.ok) return JSON.parse(responseText);
      throw new Error("Fehler: " + responseText);
    },
    createTemplate: async (projectid: string, template: string) => {
      const response = await fetch(config.templatesAPIPath + "templates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          projectid,
          template,
        }),
        mode: "cors",
        credentials: "omit",
      });
      const responseText = await response.text();
      if (response.ok)
        return new Promise((resolve, _) => setTimeout(resolve, 1000));
      throw new Error("Fehler: " + responseText);
    },
    updateState: async (projectid: string) => {
      const QUERY = `
      query operation($projectid: uuid) {
        projects(where: {id: {_eq: $projectid}}) {
          maximumParticipants
          waitingListSize
          bookings {
            people_aggregate(where: {state: {_gte: 1}}) {
              aggregate {
                count
              }
            }
          }
        }
      }`;
      const MUTATION = `
      mutation operation($projectid: uuid, $state: smallint) {
        update_projects(where: {id: {_eq: $projectid}}, _set: {state: $state}) {
          affected_rows
        }
      }
      `;
      const project = (await executeGraphQL(QUERY, jwt, { projectid }))
        .projects[0];
      project.bookings = project.bookings.reduce((acc: any, booking: any) => {
        acc += booking.people_aggregate.aggregate.count;
        return acc;
      }, 0);
      const { maximumParticipants, waitingListSize, bookings } = project;
      let projectState = ProjectState.Free;
      if (bookings >= maximumParticipants + waitingListSize) {
        projectState = ProjectState.Full;
      } else if (bookings >= maximumParticipants) {
        projectState = ProjectState.WaitingList;
      }

      await executeGraphQL(MUTATION, jwt, { projectid, state: projectState });
    },
    cleanProject: async (projectid: string | undefined) => {
      const QUERY = `
      query operation($projectid: uuid) {
        bookings(where: {project: {id: {_eq: $projectid}}}) {
          id
          people {
            state
          }
        }
      }`;
      const MUTATION = `
      mutation operation($bookingid: uuid) {
        delete_bookings(where: {id: {_eq: $bookingid}}) {
          affected_rows
        }
      }
      `;
      const bookings = (
        await executeGraphQL(QUERY, jwt, { projectid })
      ).bookings
        .map((b: any) => (b.people.length === 0 ? b.id : null))
        .filter(Boolean);

      await Promise.all(
        bookings.map(
          async (bookingid: any) =>
            await executeGraphQL(MUTATION, jwt, { bookingid })
        )
      );
    },
    resendConfirmationEmail: async (bookingid: string) => {
      await fetch(config.bookingServerLink + "resendMail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          bookingid,
        }),
        mode: "cors",
        credentials: "omit",
      });
    },
  };

  const participantActions = {
    add: async (
      personid: string,
      projectid: string,
      state: ParticipantState,
      price: number
    ) => {
      const PAYMENT_QUERY = `
      mutation operation($price: Int) {
        insert_payments_one(object: {count: 1, paid: false, price: $price}) {
          id
        }
      }
    `;
      const { insert_payments_one } = await executeGraphQL(PAYMENT_QUERY, jwt, {
        price,
      });
      const paymentid = insert_payments_one.id;

      const BOOKING_QUERY = `
      mutation operation($date: timestamptz, $projectid: uuid, $paymentid: uuid) {
        insert_bookings_one(object: {date: $date, mail_confirmed: true, projectid: $projectid, signature: "#", paymentid: $paymentid}) {
          id
        }
      }
      
    `;
      const { insert_bookings_one } = await executeGraphQL(BOOKING_QUERY, jwt, {
        projectid,
        date: new Date(),
        paymentid,
      });
      const bookingid = insert_bookings_one.id;

      const LINK_QUERY = `
      mutation operation($bookingid: uuid, $personid: uuid, $state: smallint) {
        insert_people_booking_one(object: {bookingid: $bookingid, personid: $personid, state: $state}) {
          bookingid
        }
      }      
    `;
      await executeGraphQL(LINK_QUERY, jwt, { personid, bookingid, state });
    },
    updateState: async (
      personid: string,
      projectid: string,
      state: ParticipantState
    ) => {
      const STATE_QUERY = `
      mutation operation($personid: uuid, $projectid: uuid, $state: smallint) {
        update_people_booking(where: {personid: {_eq: $personid}, booking: {projectid: {_eq: $projectid}}}, _set: {state: $state}) {
          returning {
            bookingid
          }
        }
      }
    `;
      const bookingid = (
        await executeGraphQL(STATE_QUERY, jwt, {
          personid,
          projectid,
          state,
        })
      ).update_people_booking.returning[0].bookingid;

      if (state === ParticipantState.booked) {
        await participantActions._sendStatusUpdateMail(bookingid);
      }

      await projectActions.updateState(projectid);
    },
    updateImageRights: async (bookingid: string, imageRights: boolean) => {
      const STATE_QUERY = `
      mutation operation($bookingid: uuid, $imageRights: Boolean = false) {
        update_bookings(where: {id: {_eq: $bookingid}}, _set: {imageRights: $imageRights}) {
          affected_rows
        }
      }
      
    `;

      await executeGraphQL(STATE_QUERY, jwt, {
        bookingid,
        imageRights,
      });
    },
    updatePaymentState: async (
      personid: string,
      projectid: string,
      paymentDate: Date,
      price: number,
      count: number,
      method: PaymentMethod
    ) => {
      const QUERY = `
      mutation operation($personid: uuid, $projectid: uuid, $price: Int, $count: Int, $method: smallint, $paymentDate: timestamptz) {
        update_payments(where: {bookings: {people: {personid: {_eq: $personid}}, projectid: {_eq: $projectid}}}, _set: {paid: true, paymentDate: $paymentDate, price: $price, count: $count, method: $method}) {
          affected_rows
        }
      }
      
    `;
      await executeGraphQL(QUERY, jwt, {
        personid,
        projectid,
        paymentDate,
        price,
        count,
        method,
      });
    },
    getPaymentState: async (
      personid: string,
      projectid: string
    ): Promise<{
      paymentDate: Date;
      price: number;
      count: number;
      method: PaymentMethod;
      paid: Boolean;
      note: string;
    }> => {
      const QUERY = `
      query operation($projectid: uuid, $personid: uuid) {
        payments(where: {bookings: {projectid: {_eq: $projectid}, people: {personid: {_eq: $personid}}}}) {
          count
          method
          paymentDate
          paid
          price
          note
        }
      }
    `;
      const { payments } = await executeGraphQL(QUERY, jwt, {
        personid,
        projectid,
      });
      return payments[0];
    },
    getBooking: async (
      personid: string,
      projectid: string
    ): Promise<MemberOverviewObject[]> => {
      const QUERY = `
      query operation($projectid: uuid = "", $personid: uuid = "") {
        bookings(where: {projectid: {_eq: $projectid}, people: {personid: {_eq: $personid}}}) {
          people {
            person {
              id
              name
              surname
              birthday
            }
          }
        }
      }
    `;
      const { bookings } = await executeGraphQL(QUERY, jwt, {
        personid,
        projectid,
      });
      return bookings.people.map(
        (person: { person: MemberOverviewObject }) => person.person
      );
    },
    remove: async (personid: string, projectid: string): Promise<void> => {
      const QUERY = `
      mutation operation($personid: uuid = "", $projectid: uuid = "") {
        update_people_booking(where: {personid: {_eq: $personid}, booking: {projectid: {_eq: $projectid}}}, _set: {state: 0}) {
          returning {
            bookingid
          }
        }
      }      
    `;
      const bookingid = (
        await executeGraphQL(QUERY, jwt, { personid, projectid })
      ).update_people_booking.returning[0].bookingid;

      await projectActions.updateState(projectid);
    },
    getSignature: async (
      personid: string,
      projectid: string
    ): Promise<string> => {
      const QUERY = `
      query operation($projectid: uuid = "", $personid: uuid = "") {
        bookings(where: {projectid: {_eq: $projectid}, people: {personid: {_eq: $personid}}}) {
          signature
        }
      }
    `;
      const result = await executeGraphQL(QUERY, jwt, { personid, projectid });
      return result.bookings[0].signature;
    },
    _sendStatusUpdateMail: async (bookingid: string) => {
      const response = await fetch(config.bookingServerLink + "updateMail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          origin: config.corsURL,
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          bookingid,
        }),
        mode: "cors",
        credentials: "omit",
      });
      const responseText = await response.text();
      if (response.ok) return;
      throw new Error("Fehler: " + responseText);
    },
  };

  const dataActions = {
    listFacilities: async (): Promise<string[]> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        organisations(where: {namespaceid: {_eq: $namespaceid}}) {
          name
        }
      }
    `;
      return (
        (await executeGraphQL(QUERY, undefined, { namespaceid }))
          .organisations as [{ name: string }]
      ).map((facility) => facility.name);
    },
    getColors: async (namespaceid: number | null): Promise<AppColor> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {name: {_in: ["primary_color", "text_color"]}, namespaceid: {_eq: $namespaceid}}) {
          value
          name
        }
      }
      `;
      const response = (await executeGraphQL(QUERY, undefined, { namespaceid }))
        .settings as {
        name: string;
        value: string;
      }[];
      return {
        primary_color: response.find((key) => key.name === "primary_color")
          ?.value as string,
        text_color: response.find((key) => key.name === "text_color")
          ?.value as string,
      };
    },
    getFavicon: async (namespaceid: number | null): Promise<string> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {name: {_eq: "icon_path"}, namespaceid: {_eq: $namespaceid}}) {
          value
        }
      }
      `;
      return (
        (await executeGraphQL(QUERY, undefined, { namespaceid })).settings as {
          value: string;
        }[]
      )?.[0]?.value;
    },
    getFacilityColor: async (facility: string): Promise<AppColor> => {
      const QUERY = `
      query operation($facility: bpchar, $namespaceid: Int) {
        organisations(where: {name: {_eq: $facility}, namespaceid: {_eq: $namespaceid}}) {
          colorScheme
        }
      }
      `;
      const response = JSON.parse(
        (await executeGraphQL(QUERY, undefined, { namespaceid, facility }))
          .organisations[0].colorScheme
      ) as {
        primary_color: string;
        text_color: string;
      };
      return response;
    },
    getFacilityImprint: async (facility: string): Promise<string> => {
      const QUERY = `
      query operation($facility: bpchar, $namespaceid: Int) {
        organisations(where: {name: {_eq: $facility}, namespaceid: {_eq: $namespaceid}}) {
          impLink
        }
      }
      `;
      try {
        return (
          await executeGraphQL(QUERY, undefined, { namespaceid, facility })
        ).organisations[0].impLink;
      } catch (_) {
        return data.impressum;
      }
    },
    getPossibleParentLinks: async (): Promise<string[]> => {
      const QUERY = `
      query operation {
        accounts {
          email
        }
      }
      `;
      return (
        (await executeGraphQL(QUERY, jwt, {})).accounts as { email: string }[]
      ).map((person) => person.email);
    },
    isInitialized: async (): Promise<boolean> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {name: {_eq: "initialized"}, namespaceid: {_eq: $namespaceid}}) {
          value
        }
      }
      `;
      return Boolean(
        (
          (await executeGraphQL(QUERY, jwt, { namespaceid })).settings as {
            value: string;
          }[]
        ).length
      );
    },
    getSupportEmail: async (): Promise<string> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {name: {_eq: "support_email"}, namespaceid: {_eq: $namespaceid}}) {
          value
        }
      }
      `;
      try {
        return (await executeGraphQL(QUERY, undefined, { namespaceid }))
          .settings[0].value;
      } catch (err) {
        return data.supportEmail;
      }
    },
    getLegalURLs: async (): Promise<LegalURLs> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {name: {_in: ["agb_url", "data_privacy_url", "image_rights_url", "imprint_url"]}, namespaceid: {_eq: $namespaceid}}) {
          name
          value
        }
      }
      `;
      try {
        return Object.fromEntries(
          (
            await executeGraphQL(QUERY, undefined, { namespaceid })
          ).settings.map((setting: { name: string; value: string }) => [
            setting.name,
            setting.value,
          ])
        ) as LegalURLs;
      } catch (_) {
        return {
          agb_url: data.agb,
          data_privacy_url: data.datenschutz,
          image_rights_url: data.bildrechte,
          imprint_url: data.impressum,
        };
      }
    },
    getMamimumMembersCount: async (): Promise<number> => {
      const QUERY = `
      query operation($namespaceid: Int) {
        settings(where: {name: {_eq: "members_per_account"}, namespaceid: {_eq: $namespaceid}}) {
          value
        }
      }
      `;
      return parseInt(
        (
          (await executeGraphQL(QUERY, undefined, { namespaceid }))
            .settings as {
            value: string;
          }[]
        )?.[0]?.value || (0 as any)
      );
    },
  };

  // Fetch data
  const snackbar = useSnackbar();
  const fetchTimeout = 1000 * 60;
  const serverError =
    "Die Verbindung zum Server konnte nicht hergestellt werden";

  const [listedProjects, setListedProjects] = React.useState<
    CustomerProjectOverviewObject[]
  >([]);
  const [adminListedProjects, setAdminListedProjects] = React.useState<
    ProjectOverviewObject[]
  >([]);
  const [facilities, setFacilities] = React.useState<string[]>([]);
  const [completeMembers, setCompleteMembers] = React.useState<Member[]>([]);
  const [members, setMembers] = React.useState<MemberOverviewObject[]>([]);
  const [project, setProject] = React.useState<CustomerProject | undefined>(
    undefined
  );
  const [employees, setEmployees] = React.useState<string[]>([]);
  const [locations, setLocations] = React.useState<Room[]>([]);
  const [organisations, setOrganisations] = React.useState<Organisation[]>([]);
  const [participants, setParticipants] = React.useState<
    PersonOverviewObject[]
  >([]);
  const [participantInfo, setParticipantInfo] = React.useState<
    Person | undefined
  >(undefined);
  const [participantHistory, setParticipantHistory] = React.useState<
    ProjectHistory[] | undefined
  >(undefined);
  const [usermanagement, setUsermanagement] = React.useState<Account[]>([]);
  const [settings, setSettings] = React.useState<FormatedSettings | undefined>(
    undefined
  );
  const [stats, setStats] = React.useState<Stats[]>([]);
  const [editProject, setEditProject] = React.useState<Projects | undefined>(
    undefined
  );
  const [categories, setCategories] = React.useState<string[]>([]);
  const [possibleParentLinks, setPossibleParentLinks] = React.useState<
    string[]
  >([]);
  const [templates, setTemplates] = React.useState<string[]>([]);
  const [maxMemberCount, setMaxMemberCount] = React.useState<number>(0);

  const [adminProjectDuration, setAdminProjectDuration] = React.useState<{
    durationStart?: Date | null;
    durationEnd?: Date | null;
  } | null>(null);
  const oldAdminProjectDuration = React.useRef<{
    durationStart?: Date | null;
    durationEnd?: Date | null;
  }>({});

  const [zips, setZips] = React.useState<number[]>([]);

  const useListedProjects = () => {
    const fetch = () =>
      customerProjectActions.listProjects().then(setListedProjects).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid]);

    return listedProjects;
  };

  const useAdminListedProjects = () => {
    const fetch = () =>
      projectActions
        .list(
          adminProjectDuration?.durationStart || undefined,
          adminProjectDuration?.durationEnd || undefined
        )
        .then(setAdminListedProjects)
        .snackbar({
          snackbar,
          error: serverError,
        });

    React.useEffect(() => {
      // Check if dates changed
      if (
        oldAdminProjectDuration.current.durationStart?.getTime() !==
          adminProjectDuration?.durationStart?.getTime() ||
        oldAdminProjectDuration.current.durationEnd?.getTime() !==
          adminProjectDuration?.durationEnd?.getTime() ||
        !adminProjectDuration
      )
        fetch();

      // Set dates for date check in next render
      if (adminProjectDuration)
        oldAdminProjectDuration.current = adminProjectDuration;
    }, [adminProjectDuration]);

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [adminListedProjects || listedProjects, setAdminListedProjects] as [
      ProjectOverviewObject[],
      React.Dispatch<React.SetStateAction<ProjectOverviewObject[]>>
    ];
  };

  const useFacilities = () => {
    const fetch = () =>
      dataActions.listFacilities().then(setFacilities).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid]);

    return facilities;
  };

  const useCompleteMembers = () => {
    const fetch = () =>
      accountManagement
        .listCompleteMembers()
        .then(setCompleteMembers)
        .snackbar({
          snackbar,
          error: serverError,
        });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [completeMembers, setCompleteMembers] as [
      Member[],
      React.Dispatch<React.SetStateAction<Member[]>>
    ];
  };

  const useMembers = () => {
    const fetch = () =>
      accountManagement.listMembers().then(setMembers).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return members || completeMembers;
  };

  const useProject = (id: string) => {
    const fetch = () =>
      customerProjectActions.getProject(id).then(setProject).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      // Check if selected project has another id
      if (project?.id !== id) setProject(undefined);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, id]);

    return project;
  };

  const useEmployees = () => {
    const fetch = () =>
      masterData.employees.list().then(setEmployees).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [employees, setEmployees] as [
      string[],
      React.Dispatch<React.SetStateAction<string[]>>
    ];
  };

  const useLocations = () => {
    const fetch = () =>
      masterData.rooms.list().then(setLocations).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [locations, setLocations] as [
      Room[],
      React.Dispatch<React.SetStateAction<Room[]>>
    ];
  };

  const useOrganisations = () => {
    const fetch = () =>
      masterData.organisation.list().then(setOrganisations).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [organisations, setOrganisations] as [
      Organisation[],
      React.Dispatch<React.SetStateAction<Organisation[]>>
    ];
  };

  const useParticipants = () => {
    const fetch = () =>
      peopleActions.list().then(setParticipants).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [participants, setParticipants] as [
      PersonOverviewObject[],
      React.Dispatch<React.SetStateAction<PersonOverviewObject[]>>
    ];
  };

  const useParticipantInfo = (id: string) => {
    const fetch = () =>
      peopleActions.get(id).then(setParticipantInfo).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      // Check if participant has another id
      if (participantInfo?.id !== id) setParticipantInfo(undefined);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, id, currentUser]);

    return [participantInfo, setParticipantInfo] as [
      Member | undefined,
      React.Dispatch<React.SetStateAction<Member | undefined>>
    ];
  };

  const useParticipantHistory = (id: string) => {
    const fetch = () =>
      peopleActions.getHistory(id).then(setParticipantHistory).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      if (participantInfo?.id !== id) setParticipantHistory(undefined);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, id, currentUser]);

    return [participantHistory, setParticipantHistory] as [
      ProjectHistory[] | undefined,
      React.Dispatch<React.SetStateAction<ProjectHistory[]>>
    ];
  };

  const useUsermanagement = () => {
    const fetch = () =>
      userManagement.list().then(setUsermanagement).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [usermanagement, setUsermanagement] as [
      Account[],
      React.Dispatch<React.SetStateAction<Account[]>>
    ];
  };

  const useSettings = () => {
    const fetch = () =>
      settingsActions
        .list()
        .then((response) =>
          setSettings(
            // Build object out of array like
            Object.fromEntries(
              response.map((setting) => [setting.name, setting.value])
            ) as unknown as FormatedSettings
          )
        )
        .snackbar({
          snackbar,
          error: serverError,
        });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return [settings, setSettings] as [
      FormatedSettings,
      React.Dispatch<React.SetStateAction<FormatedSettings>>
    ];
  };

  const useStats = (
    gender: string,
    organisation: string[],
    start: Date | null,
    end: Date | null,
    zip: number[],
    minAge: number,
    maxAge: number
  ) => {
    const fetch = () =>
      masterData
        .getStats(organisation, gender, start, end, zip, minAge, maxAge)
        .then(setStats)
        .snackbar({
          snackbar,
          error: serverError,
        });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [
      namespaceid,
      gender,
      organisation,
      start,
      end,
      zip,
      minAge,
      maxAge,
      currentUser,
    ]);

    return stats;
  };

  const useEditProject = (id: string) => {
    const fetch = () =>
      projectActions.get(id).then(setEditProject).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      // Check if selected project has another id
      if (editProject?.id !== id) setEditProject(undefined);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, id, currentUser]);

    return [editProject, setEditProject] as [
      Projects | undefined,
      React.Dispatch<React.SetStateAction<Projects | undefined>>
    ];
  };

  const useCategories = () => {
    const fetch = () =>
      settingsActions
        .get("categories")
        .then((categories) => setCategories(JSON.parse(categories)))
        .snackbar({
          snackbar,
          error: serverError,
        });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return categories || (settings && JSON.parse(settings?.categories));
  };

  const usePossibleParentLinks = () => {
    const fetch = () =>
      dataActions
        .getPossibleParentLinks()
        .then(setPossibleParentLinks)
        .snackbar({
          snackbar,
          error: serverError,
        });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return possibleParentLinks;
  };

  const useTemplates = () => {
    const fetch = () =>
      projectActions.listTemplates().then(setTemplates).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return templates;
  };

  const useMaximumMemberCount = () => {
    const fetch = () =>
      dataActions.getMamimumMembersCount().then(setMaxMemberCount).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return maxMemberCount;
  };

  const useZips = () => {
    const fetch = () =>
      peopleActions.listZips().then(setZips).snackbar({
        snackbar,
        error: serverError,
      });

    React.useEffect(() => {
      const interval = setInterval(fetch, fetchTimeout);
      fetch();

      return () => clearInterval(interval);
    }, [namespaceid, currentUser]);

    return zips;
  };

  const value = {
    currentUser,
    jwt,
    login,
    logout,
    accountManagement,
    customerProjectActions,
    projectActions,
    settingsActions,
    masterData,
    userManagement,
    peopleActions,
    dataActions,
    participantActions,
    useNamespace: [namespaceid, setnamespaceid] as [
      number | null,
      React.Dispatch<React.SetStateAction<number | null>>
    ],
    forceFetch,
    requestColorFetch,
    setAdminProjectDuration,
    useListedProjects,
    useAdminListedProjects,
    useFacilities,
    useCompleteMembers,
    useMembers,
    useProject,
    useEmployees,
    useLocations,
    useOrganisations,
    useParticipants,
    useParticipantInfo,
    useParticipantHistory,
    useUsermanagement,
    useSettings,
    useStats,
    useEditProject,
    useCategories,
    usePossibleParentLinks,
    useTemplates,
    useMaximumMemberCount,
    useZips,
  };

  return (
    <ServerContext.Provider value={value}>
      <Dialog open={namespaceErrorOpen}>
        <DialogTitle>Account Fehler</DialogTitle>
        {namespaceErrorInfoLoading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Dieser Account wird für{" "}
                <b>Varaus System von {namespaceErrorInfo.accountNamespace}</b>{" "}
                genutzt. Du bist allerdings gerade auf der Seite von{" "}
                <b>{namespaceErrorInfo.currentNamespace}</b>. Du kannst jetzt
                auf die Seite von {namespaceErrorInfo.accountNamespace} wechseln
                oder dich abmelden und hier bei{" "}
                {namespaceErrorInfo.currentNamespace} einen neuen Account mit
                einer anderen Email erstellen.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    `/?namespace=${currentUser?.namespaceid}`,
                    `_self`
                  );
                }}
              >
                Zu {namespaceErrorInfo.accountNamespace} wechseln
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  logout();
                  setNamespaceErrorOpen(false);
                }}
              >
                Abmelden
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      {props.children}
    </ServerContext.Provider>
  );
}

export interface AuthHook {
  currentUser: User | null;
  jwt: any;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => void;
  accountManagement: {
    signup: (email: string, password: string) => Promise<void>;
    updateEmail: (email: string) => Promise<void>;
    requestPasswordReset: (email?: string) => Promise<void>;
    resetPassword: (newPassword: string, key: string) => Promise<void>;
    addMember: (member: Member) => Promise<string>;
    updateMember: (id: string, member: Member) => Promise<void>;
    deleteMember: (id: string) => Promise<void>;
    listMembers: () => Promise<MemberOverviewObject[]>;
    listCompleteMembers: () => Promise<Member[]>;
    getMember: (id: string) => Promise<Member>;
  };
  customerProjectActions: {
    listProjects: () => Promise<CustomerProjectOverviewObject[]>;
    getProject: (id: string) => Promise<CustomerProject>;
    getPayPalStatus: () => Promise<boolean>;
    bookProject: (
      projectID: string,
      personIDs: string[],
      imageRights: boolean,
      signature: string,
      paymentMethod: PaymentMethod
    ) => Promise<{ bookingid: string; state: ParticipantState }>;
    createPaymentIntend: (bookingid: string) => Promise<string>;
    completePayment: (bookingid: string) => Promise<void>;
    getTicket: (bookingid: string) => Promise<string>;
  };
  projectActions: {
    list: () => Promise<ProjectOverviewObject[]>;
    get: (id: string) => Promise<Projects>;
    add: (project: ProjectEditObject) => Promise<string>;
    update: (project: ProjectEditObject, id: string) => Promise<void>;
    delete: (id: string) => Promise<void>;
    addBooking: (
      projectID: string,
      personID: string,
      imageRights: boolean,
      paymentMethod: PaymentMethod,
      paid: boolean
    ) => Promise<Booking[]>;
    getPeopleByBooking: (bookingId: string) => Promise<string[]>;
    createDummyPeople: (projectID: string, count: number) => Promise<boolean>;
    copyProjectParticipants: (
      oldProjectID: string,
      newProjectID: string
    ) => Promise<void>;
    sendEmailToAllParticipants: (
      projectID: string,
      subject: string,
      content: string,
      files: FileResponse[]
    ) => Promise<void>;
    sendEmailToAllUnpaidParticipants: (projectID: string) => Promise<void>;
    clearWaitingList: (id: string) => Promise<void>;
    clearWaitingListWithoutNotice: (id: string) => Promise<void>;
    listTemplates: () => Promise<string[]>;
    createTemplate: (projectid: string, template: string) => Promise<unknown>;
    updateState: (projectid: string) => Promise<void>;
    cleanProject: (projectid: string) => Promise<void>;
    resendConfirmationEmail: (bookingid: string) => Promise<void>;
  };
  masterData: {
    employees: {
      add: (name: string) => Promise<string>;
      list: () => Promise<string[]>;
      delete: (name: string) => Promise<void>;
    };
    rooms: {
      add: (room: Room) => Promise<string>;
      list: () => Promise<Room[]>;
      update: (id: string, room: Room) => Promise<void>;
      delete: (id: string) => Promise<void>;
    };
    organisation: {
      add: (org: Organisation) => Promise<string>;
      list: () => Promise<Organisation[]>;
      update: (id: string, org: Organisation) => Promise<void>;
      delete: (id: string) => Promise<void>;
    };
    getStats: (
      organisation: string[],
      gender: string,
      start: Date | null,
      end: Date | null,
      zip: number[],
      minAge: number,
      maxAge: number
    ) => Promise<any>;
  };
  settingsActions: {
    list: () => Promise<Settings[]>;
    get: (key: keyof FormatedSettings) => Promise<string>;
    set: (key: keyof FormatedSettings, value: string) => Promise<void>;
  };
  userManagement: {
    list: () => Promise<Account[]>;
    add: (account: Account) => Promise<void>;
    update: (email: string, access_level: number) => Promise<void>;
    delete: (email: string) => Promise<void>;
  };
  peopleActions: {
    list: () => Promise<PersonOverviewObject[]>;
    listZips: () => Promise<number[]>;
    get: (id: string) => Promise<Person>;
    add: (person: Person) => Promise<string>;
    update: (person: Person, id: string) => Promise<void>;
    delete: (id: string) => Promise<void>;
    getParent: (id: string) => Promise<string>;
    getHistory: (id: string) => Promise<ProjectHistory[]>;
    getDuplicates: () => Promise<string[][]>;
    rebindProjects: (oldPersonId: string, newPersonId: string) => Promise<void>;
  };
  participantActions: {
    add: (
      personid: string,
      projectid: string,
      state: ParticipantState,
      price: number
    ) => Promise<void>;
    updateState: (
      personid: string,
      projectid: string,
      // mailConfirmed: boolean,
      state: ParticipantState
    ) => Promise<void>;
    updateImageRights: (
      bookingid: string,
      imageRights: boolean
    ) => Promise<void>;
    updatePaymentState: (
      personid: string,
      projectid: string,
      paymentDate: Date,
      price: number,
      count: number,
      method: PaymentMethod
    ) => Promise<void>;
    getPaymentState: (
      personid: string,
      projectid: string
    ) => Promise<{
      paymentDate: Date;
      price: number;
      count: number;
      method: PaymentMethod;
      paid: Boolean;
    }>;
    getBooking: (
      personid: string,
      projectid: string
    ) => Promise<MemberOverviewObject[]>;
    remove: (personid: string, projectid: string) => Promise<void>;
    getSignature: (personid: string, projectid: string) => Promise<string>;
  };
  dataActions: {
    listFacilities: () => Promise<string[]>;
    getColors: (namespaceid: number | null) => Promise<AppColor>;
    getFavicon: (namespaceid: number | null) => Promise<string>;
    getFacilityColor: (facility: string) => Promise<AppColor>;
    getFacilityImprint: (facility: string) => Promise<string>;
    isInitialized: () => Promise<boolean>;
    getSupportEmail: () => Promise<string>;
    getLegalURLs: () => Promise<LegalURLs>;
    getMamimumMembersCount: () => Promise<number>;
  };

  useNamespace: [
    number | null,
    React.Dispatch<React.SetStateAction<number | null>>
  ];
  forceFetch: any;
  requestColorFetch: () => void;

  // Set data for setters
  setAdminProjectDuration: React.Dispatch<
    React.SetStateAction<{
      durationStart?: Date | null;
      durationEnd?: Date | null;
    } | null>
  >;

  // Fetched data with setters when needed (mutable states)
  useListedProjects: () => CustomerProjectOverviewObject[];
  useAdminListedProjects: () => [
    ProjectOverviewObject[],
    React.Dispatch<React.SetStateAction<ProjectOverviewObject[]>>
  ];
  useFacilities: () => string[];
  useCompleteMembers: () => [
    Member[],
    React.Dispatch<React.SetStateAction<Member[]>>
  ];
  useMembers: () => MemberOverviewObject[];
  useProject: (id: string) => CustomerProject | undefined;
  useEmployees: () => [
    string[],
    React.Dispatch<React.SetStateAction<string[]>>
  ];
  useLocations: () => [Room[], React.Dispatch<React.SetStateAction<Room[]>>];
  useOrganisations: () => [
    Organisation[],
    React.Dispatch<React.SetStateAction<Organisation[]>>
  ];
  useParticipants: () => [
    PersonOverviewObject[],
    React.Dispatch<React.SetStateAction<PersonOverviewObject[]>>
  ];
  useParticipantInfo: (
    id: string
  ) => [
    Member | undefined,
    React.Dispatch<React.SetStateAction<Member | undefined>>
  ];
  useParticipantHistory: (
    id: string
  ) => [
    ProjectHistory[] | undefined,
    React.Dispatch<React.SetStateAction<ProjectHistory[]>>
  ];
  useUsermanagement: () => [
    Account[],
    React.Dispatch<React.SetStateAction<Account[]>>
  ];
  useSettings: () => [
    FormatedSettings,
    React.Dispatch<React.SetStateAction<FormatedSettings>>
  ];
  useStats: (
    gender: string,
    organisation: string[],
    start: Date | null,
    end: Date | null,
    zip: number[],
    minAge: number,
    maxAge: number
  ) => Stats[];
  useEditProject: (
    id: string
  ) => [
    Projects | undefined,
    React.Dispatch<React.SetStateAction<Projects | undefined>>
  ];
  useCategories: () => string[];
  usePossibleParentLinks: () => string[];
  useTemplates: () => string[];
  useMaximumMemberCount: () => number;
  useZips: () => number[];
}

export type UserCredential = any;

export type AccountID = string;

export type Person = Member;

export interface Member {
  id?: string; // IGNORE
  name: string;
  surname: string;
  street: string;
  zip: number;
  city: string;
  birthday: string;
  nationality: string;
  gender?: string;
  phone?: string; // ROOT
  school?: string; // IGNORE FOR ROOT
  swim: boolean; // ROOT = True
  goHomeAlone: boolean; // ROOT = True
  allergies: string; // ROOT = ""
  medication: string; // ROOT = ""
  other: string; // ROOT = ""
  linkedAccount?: AccountID; // IGNORE
}

export interface MemberOverviewObject {
  id: string;
  name: string;
  surname: string;
  birthday: string;
}

export interface PersonOverviewObject {
  id: string;
  name: string;
  surname: string;
  birthday: string;
  street: string;
  city: string;
}

export interface CustomerProject {
  name: string;
  subtitle: string;
  description: string;
  allowedGenders: AllowedGenders;
  bookingRequired: boolean;
  fees: number;
  minimumAge: number;
  maximumAge: number;
  datetime: DatetimeRepeat;
  room: {
    name: string;
    street: string;
    zip: number;
    city: string;
  };
  organisations: string[];
  id: string;
  namespaceid: number;
  discountRate: number;
  image: string;
  state: ProjectState;
  paypalEnabled: boolean;
  cashEnabled: boolean;
  sepaEnabled: boolean;
  bookingPeriodEnd: string;
  bookingPeriodStart: string;
  zipFilter: string;
  waitinglistIncludedInMax: boolean;
}

export interface Room {
  name: string;
  street: string;
  zip: number;
  city: string;
  size: number;
  characteristics: string;
  id: string;
}

export interface Organisation {
  name: string;
  colorScheme: string;
  impLink: string;
  id: string;
}

export interface CustomerProjectOverviewObject {
  datetime: DatetimeRepeat;
  name: string;
  description: string;
  subtitle: string;
  image: string;
  state: ProjectState;
  maximumAge: number;
  minimumAge: number;
  organisations: { organisation: { name: string } }[];
  id: string;
}

export interface ProjectOverviewObject {
  publicationPeriodEnd: string;
  publicationPeriodStart: string;
  datetime: DatetimeRepeat;
  name: string;
  subtitle: string;
  state: ProjectState;
  maximumAge: number;
  minimumAge: number;
  organisations: { organisation: { name: string } }[];
  id: string;
  bookings: number;
  maximumParticipants: number;
  waitingListSize: number;
  bookingRequired: boolean;
  bookingPeriodEnd: string;
  bookingPeriodStart: string;
  waitinglistIncludedInMax: boolean;
}

export enum AllowedGenders {
  Both,
  WomenOnly,
  MenOnly,
}

export enum ProjectState {
  Full,
  Free,
  WaitingList,
}

export enum ParticipantState {
  cancelled,
  booked,
  waiting,
}

export interface Account {
  email: string;
  password?: string;
  access_level: number;
}

export interface Projects {
  allowedGenders: AllowedGenders;
  attachments: string;
  bookingRequired: boolean;
  category: string;
  datetime: DatetimeRepeat;
  description: string;
  discountRate: number;
  fees: number;
  id: string;
  image?: string;
  maximumAge: number;
  maximumParticipants: number;
  mimimumParticipants: number;
  minimumAge: number;
  name: string;
  publicationPeriodEnd: string;
  publicationPeriodStart: string;
  publish: boolean;
  state: ProjectState;
  subtitle: string;
  waitingListSize: number;
  room: string;
  roomid: string;
  organisations: {
    organisationid: string;
    organisation: string;
  }[];
  employee: string;
  bookings: Booking[];
  createTickets: boolean;

  paypalEnabled: boolean;
  cashEnabled: boolean;
  sepaEnabled: boolean;
  bookingPeriodEnd: string;
  bookingPeriodStart: string;
  zipFilter: string;
  waitinglistIncludedInMax: boolean;
}

export interface DatetimeRepeat {
  repeat: number;
  daily: DatetimeRepeatDaily;
  week: DatetimeRepeatWeek[];
  month: DatetimeRepeatMonth[];
  begin: Date | null;
  end: Date | null;
}

export interface DatetimeRepeatDaily {
  from: Date | null;
  to: Date | null;
}

export interface DatetimeRepeatWeek {
  weekdays: DatetimeRepeatWeekdays[];
  from: Date | null;
  to: Date | null;
}

export interface DatetimeRepeatMonth {
  type: DatetimeRepeatMonthType;
  timing: DatetimeRepeatMonthTiming;
  day: number;
  from: Date | null;
  to: Date | null;
}

export enum DatetimeRepeatWeekdays {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export enum DatetimeRepeatMonthType {
  SpecificDay,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export enum DatetimeRepeatMonthTiming {
  AfterStart,
  BeforeEnd,
}

export interface Booking {
  id: string;
  name: string;
  surname: string;
  birthday: string;
  // mail_confirmed: boolean;
  state: ParticipantState;
  bookingid: string;
  imageRights: boolean;
  payment?: Payment;
  date: Date;
}

export interface Payment {
  paid: boolean;
  paymentDate: string;
  price: number;
  method: PaymentMethod;
  count: number;
  note: string;
}

export interface ProjectEditObject {
  allowedGenders: AllowedGenders;
  attachments: string;
  bookingRequired: boolean;
  category: string;
  datetime: DatetimeRepeat;
  description: string;
  discountRate: number;
  fees: number;
  image?: string;
  maximumAge: number;
  maximumParticipants: number;
  mimimumParticipants: number;
  minimumAge: number;
  name: string;
  publicationPeriodEnd: string;
  publicationPeriodStart: string;
  publish: boolean;
  state: ProjectState;
  subtitle: string;
  waitingListSize: number;
  roomName?: string;
  employee: string;
  organisationids?: string[];
  createTickets: boolean;

  paypalEnabled: boolean;
  cashEnabled: boolean;
  sepaEnabled: boolean;
  bookingPeriodEnd: string;
  bookingPeriodStart: string;
  zipFilter: string;
  waitinglistIncludedInMax: boolean;
}

interface Settings {
  name: string;
  value: string;
}

export interface FormatedSettings {
  booking_mail_subject: string;
  booking_mail_attachments: string;
  booking_mail_html: string;
  status_update_mail_html: string;
  ticket_mail_html: string;
  already_registered_mail_content: string;
  already_registered_mail_subject: string;

  cash_mail_string: string;
  paypal_mail_string: string;
  sepa_mail_string: string;
  sepa_mail_attachments: string;

  payment_reminder_mail_html: string;

  password_reset_mail_subject: string;
  password_reset_mail_content: string;

  paypal_id: string;
  paypal_secret: string;

  primary_color: string;
  text_color: string;
  icon_path: string;

  categories: string;

  members_per_account: string;
  name: string;
  address: string;
  zip: string;
  city: string;
  phone: string;
  additional_info_tickets: string;

  initialized: any;

  data_privacy_url: string;
  agb_url: string;
  image_rights_url: string;
  imprint_url: string;
  support_email: string;

  excel_key: string;
}

export interface LegalURLs {
  data_privacy_url: string;
  agb_url: string;
  image_rights_url: string;
  imprint_url: string;
}

export interface Stats {
  category: string;
  signups: number;
  registrations: number;
  male: number;
  female: number;
  diverse: number;
  german: number;
  notGerman: number;
  averageAge: number;
  maximumParticipants: number;
}

export enum PaymentMethod {
  Cash,
  PayPal,
  SEPA,
}

export interface AppColor {
  primary_color: string;
  text_color: string;
}

export interface ProjectHistory {
  state: ParticipantState;
  id: string;
  name: string;
  datetime: DatetimeRepeat;
}
