import React from "react";

import { Button, IconButton, useMediaQuery, useTheme } from "@material-ui/core";

export interface SubHeaderIconButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
}

export default function SubHeaderIconButton(props: SubHeaderIconButtonProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { icon, children, ...rest } = props;

  return !mobile ? (
    <Button
      startIcon={icon}
      children={children}
      {...rest}
      variant="contained"
      color="primary"
    />
  ) : (
    <IconButton children={icon} {...rest} color="primary" />
  );
}
