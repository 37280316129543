import React from "react";
import { useHistory } from "react-router-dom";

import {
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItem,
  Divider,
  Tooltip,
  IconButton,
  Popover,
  TextField,
  Popper,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControlLabel,
  Switch,
  Checkbox,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CreateRounded,
  EuroRounded,
  PersonRounded,
  PhotoCameraRounded,
  CheckRounded,
  BlockRounded,
  Schedule,
  Note,
  Mail,
} from "@material-ui/icons";

import { useSnackbar } from "notistack";

import {
  Booking,
  ParticipantState,
  Payment,
  PaymentMethod,
  PersonOverviewObject,
  useServer,
} from "../Server/ServerContext";
import useSearch, { Search, TextHighlight } from "../Search/SearchProvider";
import FormComponent from "../FormComponent";
import TextEditor from "../TextEditor/TextEditor";
import EmailEditor from "../EmailEditor/EmailEditor";
import { Link } from "@idot-digital/file-selector";
import ConfirmDialog, { ConfirmDialogType } from "../ConfirmDialog";

export interface ProjectParticipantsEditorProps {
  bookings?: Booking[];
  removeBooking: (id: string) => void;
  updateBooking: (bookings: Booking[]) => void;
  updateBookingState: (id: string, state: ParticipantState) => void;
  projectID?: string;
  price?: (count: number) => number;
  waitingList?: boolean;
  cancelled?: boolean;
  clearWaitingList?: () => void;
  togglePaid?: (ids: string[], payment: Payment) => void;
  maximumParticipants: number;
  waitingListSize: number;
  alreadyBookedSlots: number;
  waitingListSlots: number;
  toggleImageRights?: (booking: Booking, imageRights: boolean) => void;
}

export default function ProjectParticipantsEditor(
  props: ProjectParticipantsEditorProps
) {
  const theme = useTheme();
  const { participantActions, projectActions, peopleActions } = useServer();
  const [members] = useServer().useParticipants();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { search, isSearched } = useSearch();

  const [addMember, setAddMember] = React.useState("");
  const [addMemberId, setAddMemberId] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState(PaymentMethod.Cash);
  const [paid, setPaid] = React.useState(false);
  const [addMemberOpen, setAddMemberOpen] = React.useState(false);
  const [imageRights, setImageRights] = React.useState(false);

  const emailTextEditor = React.useRef() as React.RefObject<TextEditor>;
  const [sendEmailToAll, setSendEmailToAll] = React.useState(false);
  const [emailSubject, setEmailSubject] = React.useState("");
  const [emailFiles, setEmailFiles] = React.useState([] as Link[]);

  const [editPaymentOpen, setEditPaymentOpen] = React.useState(false);
  const [editPayment, setEditPayment] = React.useState<Booking | null>(null);
  const [editPaymentType, setEditPaymentType] = React.useState(
    PaymentMethod.Cash
  );

  const [requestCancel, setRequestCancel] = React.useState<Booking | null>(
    null
  );
  const [resendConfirmationEmail, setResendConfirmationEmail] = React.useState<{
    open: boolean;
    persons?: string[];
    bookingid?: string;
  }>({ open: false });

  const [confirmClearWaitingListOpen, setConfirmClearWaitingListOpen] =
    React.useState(false);
  const [confirmSendPaymentReminder, setConfirmSendPaymentReminder] =
    React.useState(false);

  const [confirmToggleImageRights, setConfirmToggleImageRights] =
    React.useState<null | Booking>(null);

  React.useEffect(() => {
    setEditPaymentOpen(Boolean(editPayment));
    if (editPayment)
      setEditPaymentType(editPayment.payment?.method || PaymentMethod.Cash);
  }, [editPayment]);

  const [signatures, setSignatures] = React.useState<{ [key: string]: string }>(
    {}
  );
  const [openSignature, setOpenSignature] = React.useState<{
    anchor: HTMLElement | null;
    id: string;
  }>({ anchor: null, id: "" });

  const [openNotes, setOpenNotes] = React.useState<{
    anchor: HTMLElement | null;
    notes: string;
  }>({ anchor: null, notes: "" });

  const parsePaymentMethod = (method: PaymentMethod | undefined) => {
    switch (method) {
      case PaymentMethod.Cash:
        return "bar";
      case PaymentMethod.PayPal:
        return "per PayPal";
      case PaymentMethod.SEPA:
        return "mit SEPA";
      default:
        return "";
    }
  };

  const filter = (booking: Booking) => {
    const { id, birthday, state, bookingid, imageRights, payment, ...rest } =
      booking;
    return isSearched([
      rest,
      new Date(birthday).toLocaleDateString("de-DE"),
      parsePaymentMethod(booking.payment?.method),
    ]);
  };

  const removeFromProject = (id: string) =>
    participantActions
      .remove(id, props.projectID as string)
      .then(() => props.removeBooking(id))
      .snackbar({
        snackbar,
        success: "Teilnehmer erfolgreich aus dem Projekt entfernt",
        error: "Der Teilnehmer konnte nicht aus dem Projekt entfernt werden",
      });

  const getSignature = (id: string, target: HTMLElement) => {
    setOpenSignature({ anchor: target, id });

    if (!signatures[id])
      participantActions
        .getSignature(id, props.projectID as string)
        .then((signature) => setSignatures({ ...signatures, [id]: signature }))
        .snackbar({
          snackbar,
          error: "Die Unterschrift konnte nicht geladen werden",
        });
  };

  const togglePaid = async () => {
    if (editPayment) {
      const newPayment: Payment = {
        ...(editPayment.payment as Payment),
        paid: true,
        price: props.price?.(editPayment?.payment?.count as number) || 0,
        paymentDate: new Date().toISOString(),
        method: editPaymentType,
      };

      try {
        await participantActions
          .updatePaymentState(
            editPayment.id,
            props.projectID as string,
            new Date(newPayment.paymentDate),
            newPayment.price,
            newPayment.count,
            newPayment.method
          )
          .snackbar({
            snackbar,
            success: "Zahlstatus geändert",
            admin: true,
          });

        const ids = await projectActions.getPeopleByBooking(
          editPayment.bookingid
        );

        props.togglePaid?.(ids, newPayment);
        setEditPaymentOpen(false);
      } catch (e) {}
    }
  };

  const addToProject = () =>
    projectActions
      .addBooking(
        props.projectID as string,
        addMemberId,
        imageRights,
        paymentMethod,
        paid
      )
      .then((bookings) => {
        props.updateBooking(bookings);
        setAddMember("");
        setAddMemberId("");
      })
      .snackbar({
        snackbar,
        success: "Nutzer erfolgreich hinzugefügt",
        admin: true,
      });

  const updateState = async (id: string, state: ParticipantState) => {
    await participantActions.updateState(id, props.projectID as string, state);

    props.updateBookingState(id, state);
  };

  const clearWaitingList = () => {
    if (props.projectID)
      projectActions
        .clearWaitingList(props.projectID)
        .then(props.clearWaitingList)
        .snackbar({
          snackbar,
          success: "Warteliste erfolgreich geleert",
          admin: true,
        });
  };

  const sendEmailToParticipants = () =>
    projectActions
      .sendEmailToAllParticipants(
        props.projectID as string,
        emailSubject,
        emailTextEditor.current?.getContentAsHTML() as string,
        []
      )
      .snackbar({
        snackbar,
        success: "Email erfolgreich versandt",
        admin: true,
      });

  const sendEmailToUnpaidParticipants = () =>
    projectActions
      .sendEmailToAllUnpaidParticipants(props.projectID as string)
      .snackbar({
        snackbar,
        success: "Email erfolgreich versandt",
        admin: true,
      });

  const parseAddOption = (member: PersonOverviewObject | undefined) =>
    member && `${member.name} ${member.surname}`;

  const openResendConfirmationMail = async (bookingid: string) => {
    // setResendConfirmationEmail({ open: true });
    const personids = await projectActions.getPeopleByBooking(bookingid);
    const persons = await Promise.all(
      personids.map((id) => peopleActions.get(id))
    );
    const personNames = persons.map(
      (person) => person.name + " " + person.surname
    );
    setResendConfirmationEmail({
      open: true,
      persons: personNames,
      bookingid,
    });
  };

  const toggleImageRights = async (booking: Booking) => {
    if (!props.projectID) return;
    const imageRights = !booking.imageRights;
    await participantActions.updateImageRights(
      booking.bookingid,
      !booking.imageRights
    );
    props.toggleImageRights?.(booking, imageRights);
  };

  return (
    <>
      {!props.waitingList && !props.cancelled && (
        <>
          <Dialog
            open={addMemberOpen}
            onClose={() => setAddMemberOpen(false)}
            maxWidth="xs"
            fullWidth
            PaperProps={{ component: FormComponent }}
          >
            <DialogTitle>Nutzer hinzufügen</DialogTitle>
            <DialogContent>
              <Autocomplete
                options={members}
                getOptionLabel={(option) => parseAddOption(option) as string}
                inputValue={addMember}
                onInputChange={(e, value) => e && setAddMember(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nutzer hinzufügen"
                    margin="normal"
                    autoFocus
                  />
                )}
                PopperComponent={({ style, ...props }) => (
                  <Popper {...props} style={{ ...style, height: 0 }} />
                )}
                onChange={(_, member) => setAddMemberId(member?.id || "")}
                fullWidth
                autoHighlight
              />
              <Box display="flex" alignItems="flex-end">
                <TextField
                  label="Zahlungsmethode"
                  value={paymentMethod}
                  onChange={(event) =>
                    setPaymentMethod(parseInt(event.target.value))
                  }
                  margin="normal"
                  fullWidth
                  select
                >
                  <MenuItem value={PaymentMethod.Cash}>Bar</MenuItem>
                  <MenuItem value={PaymentMethod.PayPal}>Paypal</MenuItem>
                  <MenuItem value={PaymentMethod.SEPA}>SEPA</MenuItem>
                </TextField>
                <FormControlLabel
                  control={
                    <Switch
                      checked={paid}
                      onChange={(event) => setPaid(event.target.checked)}
                      color="primary"
                    />
                  }
                  label="Bezahlt"
                  labelPlacement="start"
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={imageRights}
                    onChange={(event) => setImageRights(event.target.checked)}
                    color="primary"
                  />
                }
                label="Bildrechte angenommen"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAddMemberOpen(false)}>Abbrechen</Button>
              <Button
                onClick={async () => {
                  if (
                    props.alreadyBookedSlots >= props.maximumParticipants &&
                    props.waitingListSlots >= props.waitingListSize
                  )
                    snackbar.enqueueSnackbar(
                      "Das Projekt ist bereits ausgebucht",
                      { variant: "error" }
                    );
                  else {
                    await addToProject();
                    setAddMemberOpen(false);
                  }
                }}
                variant="contained"
                color="primary"
                disabled={!addMemberId}
                type="submit"
              >
                Hinzufügen
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={sendEmailToAll}
            onClose={() => setSendEmailToAll(false)}
            fullWidth
            PaperProps={{ component: FormComponent }}
            TransitionProps={{
              onExited: () => {
                setEmailSubject("");
                setEmailFiles([]);
              },
            }}
          >
            <DialogTitle>Email an alle Teilnehmer senden</DialogTitle>
            <DialogContent>
              <EmailEditor
                setSubject={setEmailSubject}
                subject={emailSubject}
                setFiles={setEmailFiles}
                pickedFiles={emailFiles}
                textEditorRef={emailTextEditor}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSendEmailToAll(false)}>
                Abbrechen
              </Button>
              <Button
                onClick={async () => {
                  await sendEmailToParticipants();
                  setSendEmailToAll(false);
                }}
                variant="contained"
                color="primary"
                disabled={!emailSubject}
                type="submit"
              >
                Senden
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {props.waitingList && (
        <ConfirmDialog
          value={confirmClearWaitingListOpen}
          close={() => setConfirmClearWaitingListOpen(false)}
          onSuccess={() => {
            clearWaitingList();
            setConfirmClearWaitingListOpen(false);
          }}
          textRender={() =>
            `Sind Sie sicher, dass Sie alle Personen auf der Warteliste vom Projekt ablehnen möchten?`
          }
          type={ConfirmDialogType.custom}
        />
      )}
      <Dialog
        open={editPaymentOpen}
        onClose={() => setEditPaymentOpen(false)}
        TransitionProps={{ onExited: () => setEditPayment(null) }}
      >
        <DialogTitle>Zahlungseingang</DialogTitle>
        <DialogContent>
          <Typography>{`Der zu zahlende Betrag lautet: ${(
            props.price?.(editPayment?.payment?.count as number) || 0
          )
            .toString()
            .padStart(3, "0")
            .replace(/(\d*)(\d{2})/, "$1,$2")}€`}</Typography>
          <TextField
            value={editPaymentType}
            onChange={(event) =>
              setEditPaymentType(parseInt(event.target.value))
            }
            select
            fullWidth
            margin="normal"
            label="Zahlunsmethode"
          >
            <MenuItem value={PaymentMethod.Cash}>Bar</MenuItem>
            <MenuItem value={PaymentMethod.PayPal}>PayPal</MenuItem>
            <MenuItem value={PaymentMethod.SEPA}>SEPA</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditPaymentOpen(false)}>Abbruch</Button>
          <Button color="primary" variant="contained" onClick={togglePaid}>
            Bezahlt
          </Button>
        </DialogActions>
      </Dialog>
      {!props.cancelled && (
        <>
          <ConfirmDialog
            value={requestCancel}
            close={setRequestCancel}
            onSuccess={(booking: Booking) => removeFromProject(booking.id)}
            textRender={(booking: Booking) =>
              `Sind Sie sicher, dass Sie "${booking.name} ${booking.surname}" vom Projekt ablehnen möchten?`
            }
            type={ConfirmDialogType.custom}
          />
          <ConfirmDialog
            value={confirmSendPaymentReminder}
            close={() => setConfirmSendPaymentReminder(false)}
            onSuccess={() => {
              sendEmailToUnpaidParticipants();
              setConfirmSendPaymentReminder(false);
            }}
            textRender={() =>
              `Sind Sie sicher, dass Sie allen Personen, die noch nicht gezahlt haben, eine Erinnerung schicken wollen?`
            }
            type={ConfirmDialogType.custom}
          />
          <ConfirmDialog
            value={resendConfirmationEmail.open}
            close={() => setResendConfirmationEmail({ open: false })}
            onSuccess={() =>
              resendConfirmationEmail.bookingid &&
              projectActions.resendConfirmationEmail(
                resendConfirmationEmail.bookingid
              )
            }
            textRender={() =>
              `Wollen Sie die Bestätigung an alle Teilnehmer dieser Buchung (${
                resendConfirmationEmail.persons?.join(", ") || ""
              }) erneut senden?`
            }
            type={ConfirmDialogType.custom}
          />
        </>
      )}
      <Box display="flex" justifyContent="space-between">
        <Search />
        {!props.cancelled &&
          !!props.bookings?.filter((booking) => !booking.payment?.paid)
            .length && (
            <Button
              onClick={() => setConfirmSendPaymentReminder(true)}
              color="primary"
            >
              Email an alle offenen Zahlungen senden
            </Button>
          )}
        {!props.waitingList && !props.cancelled && (
          <>
            {Boolean(props.bookings?.length) && (
              <Button onClick={() => setSendEmailToAll(true)} color="primary">
                Email an alle Teilnehmer senden
              </Button>
            )}

            <Button
              onClick={() => {
                if (
                  props.alreadyBookedSlots >= props.maximumParticipants &&
                  props.waitingListSlots >= props.waitingListSize
                )
                  snackbar.enqueueSnackbar(
                    "Das Projekt ist bereits ausgebucht",
                    { variant: "error" }
                  );
                else setAddMemberOpen(true);
              }}
              variant="contained"
              color="primary"
            >
              Nutzer hinzufügen
            </Button>
          </>
        )}
        {props.waitingList && (
          <Button
            onClick={() => setConfirmClearWaitingListOpen(true)}
            variant="contained"
            color="primary"
          >
            Warteliste leeren
          </Button>
        )}
      </Box>
      <ConfirmDialog
        value={confirmToggleImageRights !== null}
        close={() => setConfirmToggleImageRights(null)}
        onSuccess={() => {
          toggleImageRights(confirmToggleImageRights as Booking);
          setConfirmToggleImageRights(null);
        }}
        textRender={() =>
          `Sind Sie sicher, dass Sie die Akzeptanz der Bildrechte ändern möchten? Diese Änderung bezieht sich auf alle Teilnehmer dieser Buchung!`
        }
        type={ConfirmDialogType.custom}
      />
      <Popover
        open={openNotes.anchor !== null}
        onClose={() => setOpenNotes({ anchor: null, notes: openNotes.notes })}
        anchorEl={openNotes?.anchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography style={{ padding: theme.spacing(1) }}>
          {openNotes.notes}
        </Typography>
      </Popover>
      <Popover
        open={openSignature.anchor !== null}
        onClose={() => setOpenSignature({ anchor: null, id: openSignature.id })}
        anchorEl={openSignature?.anchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {openSignature &&
          (signatures[openSignature.id] === "#" ? (
            <Typography style={{ padding: theme.spacing(1) }}>
              Keine Unterschrift vorhanden. Der Teilnehmer wurde von einem
              Mitarbeiter in das Projekt eingetragen.
            </Typography>
          ) : (
            <img
              src={signatures[openSignature.id]}
              alt="Unterschrift"
              width="450"
            />
          ))}
      </Popover>
      <List dense>
        {props.bookings
          ?.filter(filter)
          .sort((a, b) => a.date.getTime() - b.date.getTime())
          .map((booking: Booking) => (
            <React.Fragment key={booking.bookingid + booking.id}>
              <ListItem button>
                <ListItemText
                  primary={
                    <TextHighlight
                      search={search}
                    >{`${booking.name} ${booking.surname}`}</TextHighlight>
                  }
                  secondary={
                    <TextHighlight search={search}>
                      {`${new Date(booking.birthday).toLocaleDateString(
                        "de-DE"
                      )},
                      gebucht am ${booking.date.toLocaleString("de-DE", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`}
                    </TextHighlight>
                  }
                />
                <ListItemSecondaryAction>
                  {!props.cancelled && (
                    <Tooltip title="Bestätigungsmail erneut schicken">
                      <IconButton
                        onClick={() => {
                          openResendConfirmationMail(booking.bookingid);
                        }}
                      >
                        <Mail color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!booking.payment?.note ? (
                    <IconButton disabled>
                      <Note color="disabled" />
                    </IconButton>
                  ) : (
                    <Tooltip title="Notizen einsehen">
                      <IconButton
                        onClick={(event) =>
                          setOpenNotes({
                            anchor: event.target as HTMLElement,
                            notes: booking.payment?.note || "Keine Notizen",
                          })
                        }
                      >
                        <Note color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={
                      booking.payment?.paid
                        ? `Dieser Teilnehmer hat am ${new Date(
                            booking.payment.paymentDate
                          ).toLocaleDateString("de-DE")} ${parsePaymentMethod(
                            booking.payment.method
                          )} bezahlt`
                        : "Dieser Teilnehmer hat noch nicht bezahlt"
                    }
                  >
                    <IconButton
                      onClick={() =>
                        !booking.payment?.paid && setEditPayment(booking)
                      }
                    >
                      <EuroRounded
                        color={booking.payment?.paid ? "primary" : "disabled"}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Der Teilnehmer hat am ${booking.date.toLocaleDateString(
                      "de"
                    )} gebucht.`}
                  >
                    <IconButton>
                      <Schedule color={"primary"} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Bildrechte ${
                      booking.imageRights ? "akzeptiert" : "abgelehnt"
                    }`}
                  >
                    <IconButton
                      onClick={() => setConfirmToggleImageRights(booking)}
                    >
                      <PhotoCameraRounded
                        color={booking.imageRights ? "primary" : "disabled"}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Unterschrift einsehen">
                    <IconButton
                      onClick={(event) =>
                        getSignature(booking.id, event.target as HTMLElement)
                      }
                    >
                      <CreateRounded color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Personendaten bearbeiten oder ansehen">
                    <IconButton
                      onClick={() =>
                        // Reroute to person in order to edit data, check previous projects and find possible link to parent
                        history.push(`/admin/participantinfo/${booking.id}`)
                      }
                    >
                      <PersonRounded color="primary" />
                    </IconButton>
                  </Tooltip>
                  {props.waitingList && (
                    <Tooltip title="Person zum Projekt aufnehmen">
                      <IconButton
                        onClick={() => {
                          if (
                            props.alreadyBookedSlots >=
                            props.maximumParticipants
                          )
                            snackbar.enqueueSnackbar(
                              "Das Projekt ist bereits ausgebucht",
                              { variant: "error" }
                            );
                          else updateState(booking.id, ParticipantState.booked);
                        }}
                      >
                        <CheckRounded color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!props.cancelled && (
                    <Tooltip title="Person vom Projekt ablehnen">
                      <IconButton onClick={() => setRequestCancel(booking)}>
                        <BlockRounded color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {props.cancelled && (
                    <Tooltip title="Person wieder zum Projekt hinzufügen">
                      <IconButton
                        onClick={async () => {
                          if (
                            props.alreadyBookedSlots >=
                            props.maximumParticipants
                          ) {
                            if (
                              props.waitingListSlots >= props.waitingListSize
                            ) {
                            } else {
                              await updateState(
                                booking.id,
                                ParticipantState.waiting
                              );
                              snackbar.enqueueSnackbar(
                                "Da das Projekt bereits voll ist, wurde der Teilnehmer auf die Warteliste gesetzt",
                                { variant: "info" }
                              );
                            }
                          } else
                            updateState(booking.id, ParticipantState.booked);
                        }}
                      >
                        <CheckRounded />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </>
  );
}
