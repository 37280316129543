import React from "react";

import Body from "./Root/Body";
import HeaderBarPublic from "./Root/HeaderBar";
import PaypalPayment from "./Payment/PaypalPayment";
import SepaPayment from "./Payment/SepaPayment";
import CashPayment from "./Payment/CashPayment";
import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ParticipantState,
  PaymentMethod,
  ProjectState,
  useServer,
} from "./Server/ServerContext";
import useLocalStorage from "./LocalStorage";
import { Redirect, useHistory } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import BackToSign from "./Payment/BackToSign";
import SubHeaderBar from "./Root/SubHeaderBar";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
    "& > div": {
      width: "100%",
      display: (loading: boolean) => (loading ? "none" : ""),
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90vw",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

export default function Payment() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { customerProjectActions } = useServer();
  const { storage, parsedStorage, setParsedStorage } = useLocalStorage();
  const project = useServer().useProject(storage.booking || "");

  const [loading, setLoading] = React.useState(true);

  const [isPayPalEnabled, setIsPayPalEnabled] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        const enabled = await customerProjectActions.getPayPalStatus();
        setIsPayPalEnabled(enabled);
      } catch (err) {
        console.error(err);
        setIsPayPalEnabled(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const styles = useStyles(loading);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const bookProject = async (paymentMethod: PaymentMethod) => {
    if (paymentMethod !== PaymentMethod.PayPal) {
      setLoading(true);
    }

    const bookingid = await customerProjectActions
      .bookProject(
        storage.booking as string,
        parsedStorage("personIDs") as string[],
        parsedStorage("imageRights") as boolean,
        storage.sign as string,
        paymentMethod
      )
      .snackbar({
        snackbar,
        onSuccess: (res) => {
          setParsedStorage({ forcePaypal: false });
          setLoading(false);
          if (res.state !== ParticipantState.booked)
            setParsedStorage({ waiting: true });
          if (paymentMethod !== PaymentMethod.PayPal) {
            history.push("/completed");
          }
        },
        onError: (code) => {
          setLoading(false);
          snackbar.enqueueSnackbar(
            code.message || "Beim Bezahlvorgang ist etwas schiefgelaufen.",
            { variant: "error" }
          );
        },
      });
    return bookingid.bookingid;
  };

  React.useEffect(() => {
    // Check if project is free
    if (project && (project.fees === 0 || project.state !== ProjectState.Free))
      bookProject(PaymentMethod.Cash);
  }, [project]);

  return storage.booking &&
    storage.personIDs &&
    storage.imageRights &&
    storage.sign ? (
    <>
      <HeaderBarPublic title="Zahlung" />
      <SubHeaderBar left={<BackToSign />} />
      <Body className={styles.body} position="relative">
        {loading ? (
          <Box
            position="absolute"
            top="0"
            left="0"
            height="100%"
            width="100%"
            display="flex !important"
            flexDirection="column"
            alignItems="center"
            zIndex="1"
            paddingTop={mobile ? 1 : 4}
            style={{ background: "white" }}
            margin="0"
          >
            <Typography variant="h6">Bezahlung</Typography>
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="h6">Bezahlung</Typography>
        )}

        {(!parsedStorage("forcePaypal") ||
          project?.state === ProjectState.WaitingList) && (
          <>
            {project?.sepaEnabled && (
              <SepaPayment
                bookProject={() => bookProject(PaymentMethod.SEPA)}
              />
            )}
            {project?.cashEnabled && (
              <CashPayment
                bookProject={() => bookProject(PaymentMethod.Cash)}
              />
            )}
          </>
        )}
        {project?.state === ProjectState.Free && isPayPalEnabled && (
          <PaypalPayment
            bookProject={() => bookProject(PaymentMethod.PayPal)}
          />
        )}
      </Body>
    </>
  ) : storage.booking ? (
    <Redirect to="/booking" />
  ) : (
    <Redirect to="/" />
  );
}
