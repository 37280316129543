import { Box, TextField } from "@material-ui/core";
import TextEditor from "../TextEditor/TextEditor";
import React from "react";
import FileSelectorDialog from "../FileSelectorDialog";
import { FileSelectorProps, Link } from "@idot-digital/file-selector";

interface EmailEditorProps {
  subject: string;
  setSubject: (subject: string) => void;
  setFiles: (files: Link[]) => void;
  textEditorRef: React.RefObject<TextEditor>;
  content?: string;
}

export default function EmailEditor(
  props: EmailEditorProps & Omit<FileSelectorProps, "links">
) {
  const { subject, setSubject, setFiles, textEditorRef, content, ...rest } =
    props;
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box width="80%">
          <TextField
            label="Betreff"
            fullWidth
            onChange={(event) => setSubject(event.target.value)}
            value={subject}
            margin="normal"
          />
        </Box>
        <Box width="20%">
          <FileSelectorDialog
            callbackDone={(files) => setFiles(files.map((file) => file.path))}
            multiple
            fullWidth
            {...rest}
          >
            Anhang
          </FileSelectorDialog>
        </Box>
      </Box>
      <Box border={1} borderColor="primary.main" borderRadius={1} width="100%">
        <TextEditor
          alternativeTextInsertion={content || ""}
          ref={textEditorRef}
        />
      </Box>
    </>
  );
}
