import React from "react";

import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  bar: {
    display: "flex",
    margin: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
    justifyContent: "space-between",
  },
}));

export interface SubHeaderBarProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export default function SubHeaderBar(props: SubHeaderBarProps) {
  const styles = useStyles();

  return (
    <Box className={styles.bar}>
      <Box className={styles.wrapper}>{props.left}</Box>
      <Box className={styles.wrapper}>{props.right}</Box>
    </Box>
  );
}
