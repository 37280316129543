import React from "react";

import { Box, BoxProps, useMediaQuery, useTheme } from "@material-ui/core";

export interface BodyProps {
  children: React.ReactNode;
}

export default function Body(props: BodyProps | BoxProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box padding={mobile ? 1 : 4} {...props}>
      {props.children}
    </Box>
  );
}
